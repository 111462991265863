import { useState, useMemo } from 'react';
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { DraggableTableRow } from './DraggableTableRow';
import { StaticTableRow } from './StaticTableRow';

type DndDataTableProps = {
  columns: {
    id: number | string;
    name?: string;
    key?: string;
    render?: (item: any, method?: any) => JSX.Element;
    value?: string;
  }[];
  data: any[];
  setData: any;
  loading: boolean;
};

export const DndDataTable = ({
  columns,
  data,
  setData,
  loading = false,
}: DndDataTableProps) => {
  const [activeId, setActiveId] = useState(null);
  const items = useMemo(() => data?.map(({ id }) => id), [data]);
  // Use the state and functions returned from useTable to build your UI

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {}),
  );

  function handleDragStart(event: any) {
    setActiveId(event.active.id);
  }

  function handleDragEnd(event: any) {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = items.indexOf(active.id);
      const newIndex = items.indexOf(over.id);
      const newDataOrder = arrayMove(data, oldIndex, newIndex);
      setData(newDataOrder, newDataOrder[newIndex], newIndex);
    }

    setActiveId(null);
  }

  function handleDragCancel() {
    setActiveId(null);
  }

  const selectedRow = useMemo(() => {
    if (!activeId) {
      return null;
    }
    const row = data.find(
      ({ id: dataId }: { id: string }) => dataId === activeId,
    );

    return row;
  }, [activeId, data]);

  // Render the UI for your table

  return (
    <div
      className={`flex flex-col ${
        loading ? 'pointer-events-none opacity-50' : ''
      }`}
    >
      <div className="-my-2 sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
          <div className={'shadow border-b border-gray-200 sm:rounded-lg'}>
            <DndContext
              sensors={sensors}
              onDragEnd={handleDragEnd}
              onDragStart={handleDragStart}
              onDragCancel={handleDragCancel}
              collisionDetection={closestCenter}
              modifiers={[restrictToVerticalAxis]}
            >
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {columns &&
                      columns.map((column) => (
                        <th
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          key={column.id}
                          scope="col"
                        >
                          <span className="flex items-center justify-center cursor-pointer">
                            {column.name}
                          </span>
                        </th>
                      ))}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  <SortableContext
                    items={items}
                    strategy={verticalListSortingStrategy}
                  >
                    {data &&
                      data.map((item: any, i) => (
                        <DraggableTableRow
                          key={item.id}
                          row={item}
                          columns={columns}
                        />
                      ))}
                  </SortableContext>
                </tbody>
              </table>
              <DragOverlay>
                {activeId && (
                  <table className="min-w-full divide-y divide-gray-200">
                    <tbody>
                      <StaticTableRow row={selectedRow} columns={columns} />
                    </tbody>
                  </table>
                )}
              </DragOverlay>
            </DndContext>
          </div>
        </div>
      </div>
    </div>
  );
};
