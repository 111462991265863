import React from 'react';
import { Modal } from 'components/Modal';
import { Text } from 'components/Text';
import { Button } from 'components/Button';

type CustomDomainModalProps = {
  show: boolean;
  setShowModal: (value: boolean) => void;
  customDomain: string | null;
  setCustomDomain: (value: string) => void;
  handleSave: () => void;
  handleDelete: () => void;
};

export const CustomDomainModal = ({
  show,
  setShowModal,
  customDomain,
  setCustomDomain,
  handleSave,
  handleDelete,
}: CustomDomainModalProps) => {
  return (
    <Modal
      openModal={(value) => setShowModal(value)}
      open={show}
      containerClass="sm:max-w-lg sm:w-full"
    >
      <Text value="Setup Custom Subdomain" type="h4" />
      <div className="items-center mt-3">
        <Text
          value="Make your tracking page easy to find by adding your Custom Subdomain"
          type="sm"
          className="text-gray-800 mr-3 mb-1"
        />
        <Text
          value="Custom Subdomain"
          type="sm"
          className="text-gray-500 mr-3 mb-1 mt-3"
        />
        <div className="flex">
          <input
            name="senderName"
            className="border border-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md p-2"
            value={customDomain ?? ''}
            onChange={(e) => setCustomDomain(e.target.value ?? '')}
          />
          <Text
            value=".shipaid.com"
            type="h4"
            className="text-gray-800 mr-3 mb-1 mt-3 ml-4"
          />
        </div>
      </div>
      <div className="flex mt-6">
        <Button
          onClick={handleSave}
          className="bg-blue-600 mr-3 hover:bg-blue-700"
          disabled={!customDomain || customDomain === ''}
        >
          Save
        </Button>
        <Button
          onClick={handleDelete}
          className="bg-red-500 text-white hover:bg-red-700"
          disabled={!customDomain || customDomain === ''}
        >
          Delete
        </Button>
        <Button
          onClick={() => setShowModal(false)}
          className="bg-red-500 text-white hover:bg-red-700 ml-2"
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};
