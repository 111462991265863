import React from 'react';
import { useNavigate } from 'react-router-dom';

export const InstallWidget: React.FC = () => {
  const navigate = useNavigate();

  const handleInstallClick = () => {
    navigate('/settings/install');
    localStorage.setItem('completed-onboarding', 'true');
  };

  return (
    <div className="flex flex-col items-center justify-center p-6 bg-white rounded-lg">
      <h2 className="text-xl font-semibold mb-4">Install Shipaid Widget</h2>
      <p className="mb-6 text-gray-600">
        Click below to view installation options. If already installed, you can
        skip this step.
      </p>
      <div className="flex space-x-4">
        <button
          onClick={handleInstallClick}
          className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          View Options
        </button>
        <button
          onClick={() => {
            localStorage.setItem('completed-onboarding', 'true');
            navigate('/dashboard');
          }}
          className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
        >
          Skip
        </button>
      </div>
    </div>
  );
};
