import React, { Fragment, useState, useEffect, useMemo } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { SelectorIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

type SingleSelectProps = {
  selected: number | string | null;
  options: (number | string)[] | { label: string; val: string }[];
  setSelected: (value: number | string | null) => void;
  label?: string;
  placeholder?: string;
  id?: string;
  style?: any;
};

export const SingleSelect = ({
  options,
  selected,
  setSelected,
  label,
  placeholder,
  id,
  style,
}: SingleSelectProps) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    function handleBackgroundClick(e: any) {
      const elem = document.getElementById(id || 'select');
      if (!elem?.contains(e.target)) setIsOpen(false);
    }
    document.addEventListener('mousedown', handleBackgroundClick);
    // cleanup, remove event listener
    return () => {
      document.removeEventListener('mousedown', handleBackgroundClick);
    };
  }, [id]);

  const selectedLabel = useMemo(() => {
    if (!options.length) return '';

    const isObject = typeof options[0] === 'object';

    if (isObject) {
      return (
        (options as { label: string; val: string }[]).find(
          (i) => i.val === selected,
        )?.label || ''
      );
    } else {
      return selected || '';
    }
  }, [selected, options]);

  return (
    <Listbox
      value={selected}
      onChange={(val: any) => setSelected(val)}
      as="div"
      onClick={() => setIsOpen(!isOpen)}
      id={id || 'select'}
    >
      {() => (
        <>
          {label && (
            <Listbox.Label className="block text-sm font-medium text-gray-700">
              {label}
            </Listbox.Label>
          )}
          <div className="mt-1 relative">
            <Listbox.Button
              style={{ minHeight: 38, ...style }}
              className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-pointer focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              {placeholder && !selected && (
                <span className="text-gray-700">{placeholder}</span>
              )}
              {selected && (
                <span className="block truncate">{selectedLabel}</span>
              )}
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={isOpen}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                onClick={(e: any) => {
                  e.stopPropagation();
                }}
                static
                className="absolute z-50 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
              >
                {options.map((option) => {
                  const label =
                    typeof option === 'object' ? option.label : option;
                  const val = typeof option === 'object' ? option.val : option;

                  return (
                    <Listbox.Option
                      key={val}
                      className={() =>
                        classNames(
                          'hover:bg-indigo-600 hover:text-white cursor-pointer select-none relative py-2 pl-3 pr-9',
                          selected === val && 'bg-indigo-600 text-white',
                          selected !== val && 'text-gray-900',
                        )
                      }
                      value={val}
                    >
                      {() => (
                        <span
                          className={classNames(
                            'block truncate',
                            selected === val && 'font-semibold',
                          )}
                        >
                          {label}
                        </span>
                      )}
                    </Listbox.Option>
                  );
                })}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};
