import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Text } from 'components/Text';
import { Stats } from 'components/Stats';
import { DataFilter } from 'components/DataFilter';
import { MultiSelect } from 'components/MultiSelect';
import { SearchBox } from 'components/Search';
import { Pagination } from 'components/Pagination';
import { useQuery, useLazyQuery } from '@apollo/client';
import { DatePicker } from 'components/DatePicker';
import { LoadingScreen } from 'components/LoadingScreen';
import { columns, shipmentStatus } from './data/orders-data';
import { OrdersTable } from './OrdersTable';
import { Order } from 'types/order';
import {
  // GET_ORDERS,
  GET_ORDERS_BY,
  GET_ORDERS_COUNT,
  FILTER_ORDERS,
} from 'gql/queries';
import { withNotification } from 'components/Notification';
import { apiErrorHandler, NotificationTypes } from 'utils';
import { useStore } from 'context/store-context';

export const Orders = withNotification(({ showNotification }: any) => {
  const limit = 20;
  const [allOrders, setAllOrders] = useState<any | []>([]);
  const [ordersCount, setOrdersCount] = useState(0);
  const [filterInput, setFilterInput] = useState<any>({});
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [allExpanded, setAllExpanded] = useState(false);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [selectedShipmentStatus, setSelectedShipmentStatus] = useState<
    (string | number)[]
  >([]);
  const navigate = useNavigate();
  const { storeId, storeProperties } = useStore();

  // const [getOrders, { data, loading }] = useLazyQuery(GET_ORDERS, {
  //   onCompleted: (data) => {
  //     setAllOrders(data.orders);
  //     setOrdersCount(data?.ordersAggregate?.aggregate?.count);
  //   },
  //   onError(error) {
  //     const newError = apiErrorHandler(error);
  //     showNotification(NotificationTypes.error, newError?.message);
  //   },
  // });

  const { data: countData } = useQuery(GET_ORDERS_COUNT, {
    variables: {
      storeId,
    },
  });

  const [filterOrders, { data, loading }] = useLazyQuery(FILTER_ORDERS, {
    onCompleted: (data) => {
      setAllOrders(data?.orders);
      setOrdersCount(data?.ordersAggregate?.aggregate?.count);
    },
    onError(error) {
      const newError = apiErrorHandler(error);
      showNotification(NotificationTypes.error, newError?.message);
    },
  });

  const [orderedOrders] = useLazyQuery(GET_ORDERS_BY, {
    onCompleted: (data) => {
      setAllOrders(data?.orders);
      setOrdersCount(data?.ordersAggregate?.aggregate?.count);
    },
    onError(error) {
      const newError = apiErrorHandler(error);
      showNotification(NotificationTypes.error, newError?.message);
    },
  });

  // useEffect(() => {
  //   storeId &&
  //     getOrders({
  //       variables: {
  //         storeId,
  //         limit,
  //         offset,
  //         order_by: [
  //           {
  //             createdAt: 'desc',
  //           },
  //         ],
  //       },
  //     });
  // }, [getOrders, storeId, offset]);

  useEffect(() => {
    storeId &&
      // Object.keys(filterInput).length &&
      filterOrders({
        variables: {
          filters: { ...filterInput, storeId: { _eq: storeId } },
          limit,
          offset,
          order_by: [
            {
              createdAt: 'desc',
            },
          ],
        },
      });
  }, [filterInput, filterOrders, storeId, offset]);

  // handle change in shipment status filter
  useEffect(() => {
    setPage(1);
    setOffset(0);
    if (!selectedShipmentStatus.length) {
      const filter = { ...filterInput };
      if ('trackingStatus' in filter) {
        delete filter.trackingStatus;
        setFilterInput(filter);
        filterOrders({
          variables: {
            filters: { ...filter, storeId: { _eq: storeId } },
            limit,
            offset,
            order_by: [
              {
                createdAt: 'desc',
              },
            ],
          },
        });
      }
      return;
    }
    const mappedShipmentStatus = selectedShipmentStatus.map((s) =>
      getMappedShipmentStatus(`${s}`),
    );
    setFilterInput({
      ...filterInput,
      trackingStatus: { _in: mappedShipmentStatus },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedShipmentStatus]);

  const search = () => {
    setFilterInput({
      ...filterInput,
      _or: [
        { number: { _ilike: `%${searchValue}%` } },
        { customerEmail: { _ilike: `%${searchValue}%` } },
        { customerName: { _ilike: `%${searchValue}%` } },
      ],
    });
  };

  const handleSarch = (value: string) => {
    if (value === '') {
      const obj = { ...filterInput };
      delete obj._or;
      setSearchValue(value);
      /**
       * this was done this way to ensure that whenever the search is cleared, 
        it also checks that there are no filter rules there to ensure that it sets the 
        table back to default
      */
      Object.keys(obj).length === 0 && setAllOrders(data?.orders);
      return setFilterInput(obj);
    }
    setSearchValue(value);
  };

  const getMappedFilterOption = (option: string): string => {
    if (option === 'Guaranteed') return 'Protected';
    else if (option === 'Not Guaranteed') return 'Unprotected';
    else if (option === 'Issue Filed') return 'Claim Filed';
    else return option;
  };

  const getMappedShipmentStatus = (status: string): string => {
    const trackStatus = shipmentStatus.find((s) => s.label === status);
    return trackStatus?.value ?? '';
  };

  const onFilter = (option: string) => {
    setPage(1);
    setOffset(0);
    if (option === '') {
      const obj = { ...filterInput };
      delete obj.protectionStatus;
      setFilterInput(obj);
      return searchValue === '' && setAllOrders(data?.orders);
    }
    return setFilterInput({
      ...filterInput,
      protectionStatus: { _eq: getMappedFilterOption(option) },
    });
  };

  const onPageChange = (pageNumber: number) => {
    setPage(pageNumber);
    if (pageNumber < page) {
      if (pageNumber - 1 === 0) {
        return setOffset(0);
      }
      return setOffset(limit * (pageNumber - 1));
    }
    return setOffset(limit * (pageNumber - 1));
  };

  const handleSetDate = (date: any) => {
    setDateRange(date);
    const obj = { ...filterInput };

    if (!date[0] && !date[1]) {
      delete obj._and;
      setFilterInput(obj);
      return searchValue === '' && setAllOrders(data.orders);
    }
    if (date[1])
      return setFilterInput({
        ...filterInput,
        _and: [
          { createdAt: { _gte: new Date(date[0]).toISOString() } },
          { createdAt: { _lte: new Date(date[1]).toISOString() } },
        ],
      });
  };

  const onFileClaim = (order: Order) => {
    navigate('/claims/new-claim', {
      state: { order, returnLocation: '/orders' },
    });
  };

  const handleOrderByColumn = (column: string, direction: string) => {
    storeId &&
      orderedOrders({
        variables: {
          limit,
          offset,
          order_by: [
            {
              [column]: direction,
            },
          ],
          storeId,
        },
      });
  };

  const handleExpandOrder = (index: number) => {
    const expandedOrders = [...allOrders];
    expandedOrders[index] = {
      ...allOrders[index],
      expand: !allOrders[index]?.expand,
    };
    setAllOrders(expandedOrders);
  };

  const handleExpandAllOrders = () => {
    const expandedOrders = allOrders.map((o: Order) => ({
      ...o,
      expand: !o?.expand,
    }));
    setAllOrders(expandedOrders);
    setAllExpanded(!allExpanded);
  };

  const stats = [
    {
      name: 'Total Orders',
      currentStat: `${
        (countData && countData?.totalOrders?.aggregate.total) ?? 0
      }`,
    },
    {
      name: 'Guaranteed Orders',
      currentStat: `${
        (countData && countData?.protectedOrders?.aggregate?.total) ?? 0
      }`,
    },
    {
      name: 'Other Orders',
      currentStat: `${
        (countData && countData?.unprotectedOrders?.aggregate?.total) ?? 0
      }`,
    },
  ];
  const filterOptions = ['Guaranteed', 'Not Guaranteed', 'Issue Filed'];

  if (loading && allOrders.length < 1) return <LoadingScreen />;

  const orderByCols = [
    'createdAt',
    'customerEmail',
    'customerName',
    'subtotal',
  ];

  const orders_count = ordersCount;
  // Object.keys(filterInput).length === 0 ? ordersCount : allOrders.length;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <div className="">
        <Text value="All Orders" type="h4" />
      </div>
      <div className="">
        <Stats stats={stats} isSpaced />
      </div>
      <div className="md:flex justify-between mb-2 mt-12 sm:block">
        <div className="mb-4 sm:mb-4 md:mb-2 flex items-center">
          <div className="w-80">
            <SearchBox
              searchValue={searchValue}
              onChange={(searchValue) => handleSarch(searchValue)}
              placeholder="Search by order no, name or email"
              search={search}
            />
          </div>
          {loading && (
            <div
              style={{
                borderColor: 'rgba(37,99,235, 0.2)',
                borderTopColor: '#2564eb',
              }}
              className="inline-block border-2 rounded-full animate-spin w-3 h-3 ml-2"
            />
          )}
        </div>
        <div className=" display-block sm:flex">
          <span className="mr-5">
            <MultiSelect
              options={shipmentStatus.map((s) => s.label)}
              selected={selectedShipmentStatus}
              setSelected={setSelectedShipmentStatus}
              placeholder={'Shipment Status'}
              style={{ minHeight: 48, marginTop: '-5px', width: 350 }}
            />
          </span>
          <DataFilter
            filterBy="Protection Status"
            options={filterOptions}
            setSelectedOption={(option) => onFilter(option)}
            selectedOption={
              filterInput?.protectionStatus
                ? filterInput.protectionStatus?._eq
                : ''
            }
            wrapperClass="mr-4"
          />
          <DatePicker
            className="mt-2 sm:mt-0"
            selectsRange
            startDate={dateRange[0]}
            endDate={dateRange[1]}
            setValue={handleSetDate}
            placeHolder="Select date range"
            isClearable
          />
        </div>
      </div>
      <div className="">
        <OrdersTable
          orders={
            allOrders?.length > 0
              ? [...allOrders].map((item: Order) => ({
                  ...item,
                  onFileClaim: () => onFileClaim(item),
                  currency: storeProperties?.currency,
                }))
              : []
          }
          columns={columns}
          allExpanded={allExpanded}
          emptyMessage={
            searchValue
              ? `No search results matches "${searchValue}"`
              : 'No data'
          }
          orderByOptions={orderByCols}
          handleSelectedColumn={handleOrderByColumn}
          handleExpandOrder={handleExpandOrder}
          handleExpandAll={handleExpandAllOrders}
        />
      </div>
      <div className="mt-3">
        {orders_count > limit && (
          <Pagination
            totalPages={Math.ceil(orders_count / limit)}
            currentPage={page}
            resultsRange={limit}
            totalResults={orders_count ?? 0}
            isFilterApplied={Object.keys(filterInput).length ? true : false}
            onPageClick={onPageChange}
          />
        )}
      </div>
    </div>
  );
});
