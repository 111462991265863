import { useEffect, useCallback } from 'react';
import ErrorBoundary from 'components/ErrorBoundary';
import { useUser } from 'context/user-context';
import { isShipaidAdmin } from 'utils';
import { AdminApp } from './AdminApp';
import { MerchantApp } from './MerchantApp';
import { useStore } from 'context/store-context';

export const Authenticated = () => {
  const adminData = localStorage.getItem(
    process.env.REACT_APP_ADMIN_STORAGE_KEY || 'admin-context',
  );
  let adminContext: any;
  if (adminData) adminContext = JSON.parse(adminData);
  const userContext = useUser();
  const storeContext = useStore();
  const isUserShipaidAdmin = isShipaidAdmin(userContext.user);

  const updateAdminContextOnReload = useCallback(() => {
    if (adminContext?.admin?.user?.user?.id) {
      localStorage.setItem(
        process.env.REACT_APP_ADMIN_STORAGE_KEY || 'admin-context',
        JSON.stringify({
          ...adminContext,
          userContextData: userContext,
          storeContextData: storeContext,
        }),
      );
    }
  }, [adminContext, storeContext, userContext]);

  useEffect(() => {
    window.addEventListener('beforeunload', updateAdminContextOnReload);
    return () => {
      window.removeEventListener('beforeunload', updateAdminContextOnReload);
    };
  }, [updateAdminContextOnReload]);

  if (userContext) {
    pendo.initialize({
      visitor: {
        id: userContext?.user?.id ?? '',
        email: userContext?.user?.email ?? '',
        full_name: userContext?.user?.displayName ?? '',
        role: userContext?.user?.defaultRole ?? '',
      },
      // You can add any additional visitor level key-values here,
      // as long as it's not one of the above reserved names.
      account: {
        id: storeContext?.storeId ?? '',
        name: storeContext?.storeName ?? '',
        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
      },
    });
  }

  return isUserShipaidAdmin ? (
    <AdminApp />
  ) : (
    // @ts-ignore
    <ErrorBoundary>
      <MerchantApp />
    </ErrorBoundary>
  );
};
