/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Modal } from 'components/Modal';
import { Text } from 'components/Text';
import { Button } from 'components/Button';
import { Dropdown } from 'components/Dropdown';

import {
  customEmailTemplateIntegrationTypes,
  customEmailTemplateTypes,
  templatesUsageTypes,
} from './data/templates-data';
import { openOrFocusTab } from './utils';
import { useTemplateEmails } from '../../../hooks/useTemplateEmails';
import EditorField from './EditorField';

export const EditTemplateModal = ({
  isOpen,
  onClose,
  isLoading,
  template,
  usageType,
  userTemplates,
  templateIntegrations,
}) => {
  const [eventType, setEventType] = useState(null);
  const [templatePreview, setTemplatePreview] = useState(false);
  const [isTemplateReady, setIsTemplateReady] = useState(false);

  const handleOnClose = (updatedTemplate, info) => {
    setTemplatePreview(false);
    localStorage.removeItem('template-preview');
    setIsTemplateReady(false);
    emailEditorRef.current = null;
    onClose?.(updatedTemplate, info);
    return null;
  };

  const { resetTemplate, submitTemplate, emailEditorRef, loading } =
    useTemplateEmails({
      callback: handleOnClose,
    });

  const emailTypes = Object.values(customEmailTemplateTypes);

  const noCreatedTemplates = emailTypes.filter(
    (type) =>
      !userTemplates?.some(
        (template) =>
          template.eventType === type.key && template.usage === usageType,
      ),
  );

  const templateValues = {
    subject: template?.subject ?? 'tracking',
    id: template?.id,
    eventType: eventType ?? template?.eventType,
    usage: template?.usage,
    isTrackingDefault: template?.isTrackingDefault ?? false,
    platformIntegrationType:
      template?.platformIntegrationType ??
      customEmailTemplateIntegrationTypes.custom,
    integrationTemplateId: template?.integrationTemplateId,
    templateJson: template?.templateJson,
    templateHtml: template?.templateHtml,
    active: template?.active,
  };

  // console.log('templateValues', templateValues);
  const handleCreatePreview = () => {
    emailEditorRef.current.editor.exportHtml(async (data) => {
      const { html, design } = data;
      localStorage.setItem(
        'template-preview',
        JSON.stringify({ isActive: true, html, design }),
      );
    });

    const targetUrl = `${window.location.protocol}//${window.location.host}/template-preview`;
    const targetWindowName = 'templatePreview';

    openOrFocusTab(window, targetWindowName, targetUrl);
    setTemplatePreview(true);
  };

  const shouldEditType =
    templatesUsageTypes.tracking === usageType &&
    !template?.isTrackingDefault &&
    !template?.eventType;

  const handleSetEventType = (option) => {
    const eventType = emailTypes.find((t) => t.label === option);
    if (!eventType) return;
    setEventType(eventType.key);
  };

  const renderCustomEditor = (values) => {
    return (
      <EditorField
        platformIntegrationType={values?.platformIntegrationType}
        values={values}
        ref={emailEditorRef}
        setIsTemplateReady={setIsTemplateReady}
        template={template}
      />
    );
  };

  return (
    <Modal
      openModal={(value) => onClose(value)}
      open={isOpen}
      containerClass="sm:max-w-lg sm:w-full"
      customStyle={{ maxWidth: '90rem' }}
    >
      <div className="md:flex justify-between items-center">
        <div className="md:flex items-center">
          <Text
            value={
              template?.isTrackingDefault
                ? 'Default template'
                : `Template: Order ${
                    emailTypes.find((et) => et.key === template?.eventType)
                      ?.label ?? ''
                  }`
            }
            type="h4"
          />
          {shouldEditType ? (
            <div className="flex mt-3 ml-48">
              <Dropdown
                options={noCreatedTemplates.map((t) => t.label)}
                onSelectOption={(option) => handleSetEventType(option)}
                btnClassName="border border-gray-300"
                placeholder="Select Event Type (Required)"
                selectedOption={
                  eventType
                    ? emailTypes.find((t) => t.key === eventType)?.label
                    : ''
                }
                downCaret
              />
            </div>
          ) : null}
        </div>
        <div className="flex justify-end">
          <Button
            onClick={() => {
              setEventType(null);
              handleOnClose();
            }}
            className="bg-red-500 mr-3 hover:bg-red-700"
          >
            <Text value="Close" />
          </Button>
          <Button
            onClick={() =>
              resetTemplate(template.eventType, template.usage, template)
            }
            className="bg-blue-600 mr-3 hover:bg-blue-700"
            isLoading={isLoading}
            disabled={loading || isLoading || !isTemplateReady}
          >
            Reset
          </Button>
          <Button
            onClick={handleCreatePreview}
            className="bg-blue-600 mr-3 hover:bg-blue-700"
            isLoading={isLoading}
            disabled={loading || isLoading || !isTemplateReady}
          >
            {templatePreview ? 'Open Preview' : 'Create Preview'}{' '}
          </Button>
          <Button
            onClick={() => {
              submitTemplate({
                values: {
                  ...templateValues,
                  eventType: eventType ? eventType : templateValues.eventType,
                },
              });
              setEventType(null);
            }}
            className="bg-blue-600 mr-12 hover:bg-blue-700"
            isLoading={isLoading}
            disabled={loading || isLoading || !isTemplateReady}
          >
            Save Changes
          </Button>
        </div>
      </div>
      {renderCustomEditor(templateValues)}
    </Modal>
  );
};
