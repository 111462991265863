import React, { useEffect, useState } from 'react';
import { Text } from 'components/Text';
import { DataTable } from 'components/DataTable';
import { Pagination } from 'components/Pagination';
import { useLazyQuery } from '@apollo/client';
import { LoadingScreen } from 'components/LoadingScreen';
import { invitesColumns } from './data/teams-data';
import { GET_STORE_USER_INVITES } from 'gql/queries';
import { Back } from 'components/Back';
import { useStore } from 'context/store-context';
import { apiErrorHandler, NotificationTypes } from 'utils';
import { withNotification } from 'components/Notification';

export const Invites = withNotification(({ showNotification }: any) => {
  const [page, setPage] = useState(1);
  const { storeId } = useStore();
  const [getInvites, { data, loading }] = useLazyQuery(GET_STORE_USER_INVITES, {
    variables: {
      storeId,
    },
    onError(error) {
      const newError = apiErrorHandler(error);
      showNotification(NotificationTypes.error, newError?.message);
    },
  });

  useEffect(() => {
    getInvites();
  }, [getInvites]);

  const onPageChange = (page: number) => {
    setPage(page);
  };

  if (loading) return <LoadingScreen />;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <Back className="mb-4" />
      <div className="">
        <Text
          value={`${data?.storeUserInvites.length ?? 0} Invite(s)`}
          type="h4"
        />
      </div>
      <div className="mt-4">
        <DataTable
          data={data?.storeUserInvites.length > 0 ? data?.storeUserInvites : []}
          columns={invitesColumns}
          emptyMessage={`No invites available`}
        />
      </div>
      <div className="mt-3">
        {data?.storeUserInvites?.length > 10 && (
          <Pagination
            totalPages={1}
            currentPage={page}
            resultsRange={10}
            totalResults={data?.storeUserInvites?.length}
            onPageClick={onPageChange}
          />
        )}
      </div>
    </div>
  );
});
