import React, { useState } from 'react';
import { Text } from 'components/Text';
import { Button } from 'components/Button';
import { Toggle } from 'components/Toggle';
import { GET_ALL_ANNOUNCEMENTS } from 'gql/queries';
import {
  ADD_ANNOUNCEMENT,
  UPDATE_ANNOUNCEMENT_ACTIVE_STATUS,
  DELETE_ANNOUNCEMENTS,
} from 'gql/mutations';
import { useMutation, useQuery } from '@apollo/client';
import { withNotification } from 'components/Notification';
import { apiErrorHandler, NotificationTypes, SizeEnum } from 'utils';
import { Announcement } from 'types/announcement';
import { DataTable } from 'components/DataTable';
import { format } from 'date-fns';

export const Announcements = withNotification(({ showNotification }: any) => {
  const [text, setText] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [checkedItemIds, setCheckedItemIds] = useState<any>([]);

  const { data } = useQuery(GET_ALL_ANNOUNCEMENTS, {
    onError(error) {
      showNotification(
        NotificationTypes.error,
        apiErrorHandler(error)?.message,
      );
    },
  });

  const [addNewAnnouncementMutation, { loading: addNewAnnouncementLoading }] =
    useMutation(ADD_ANNOUNCEMENT, {
      onError(error) {
        showNotification(NotificationTypes.error, error?.message);
      },
    });

  const [updateAnnouncementActiveStatusMutation] = useMutation(
    UPDATE_ANNOUNCEMENT_ACTIVE_STATUS,
    {
      onError(error) {
        showNotification(NotificationTypes.error, error?.message);
      },
    },
  );

  const [deleteAnnouncementsMutation] = useMutation(DELETE_ANNOUNCEMENTS, {
    onError(error) {
      showNotification(NotificationTypes.error, error?.message);
    },
  });

  const addNewAnnouncement = () => {
    if (text && typeof isActive === 'boolean') {
      addNewAnnouncementMutation({
        variables: {
          text,
          isActive,
        },
        refetchQueries: ['announcements'],
      });
    } else {
      showNotification(
        NotificationTypes.error,
        'Please specify the text for the announcement',
      );
    }
  };

  const updateAnnouncementActiveStatus = (item: Announcement) => {
    const ids = checkedItemIds.includes(item.id) ? checkedItemIds : [item.id];

    updateAnnouncementActiveStatusMutation({
      variables: {
        ids,
        active: !item.active,
      },
      optimisticResponse: {
        update_announcements: {
          returning: ids.map((id: string) => ({
            __typename: 'announcements',
            id,
            active: !item.active,
          })),
        },
      },
      update(cache, { data: { update_announcements } }) {
        const cachedData = cache.readQuery({
          query: GET_ALL_ANNOUNCEMENTS,
        });

        const cachedAnnouncements = (cachedData as any).announcements;

        const announcements = cachedAnnouncements.map((a: Announcement) => {
          const itemWasUpdated = update_announcements.returning
            .map((item: any) => item.id)
            .includes(a.id);

          return {
            ...a,
            active: itemWasUpdated ? !item.active : a.active,
          };
        });

        cache.writeQuery({
          query: GET_ALL_ANNOUNCEMENTS,
          data: { announcements },
        });
      },
      refetchQueries: ['announcements'],
    });
  };

  const onCheckAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (checkedItemIds.length !== data?.announcements?.length) {
      setCheckedItemIds(
        data?.announcements.map((item: Announcement) => item.id),
      );
    } else {
      setCheckedItemIds([]);
    }
  };

  const handleDelete = (item: Announcement) => {
    const ids = checkedItemIds.includes(item.id) ? checkedItemIds : [item.id];

    deleteAnnouncementsMutation({
      optimisticResponse: {
        delete_announcements: {
          returning: ids.map((id: string) => ({
            __typename: 'announcements',
            id,
          })),
        },
      },
      update: (cache, { data: { delete_announcements } }) => {
        const cachedData = cache.readQuery({
          query: GET_ALL_ANNOUNCEMENTS,
        });

        const cachedAnnouncements = (cachedData as any).announcements;

        const announcements = cachedAnnouncements.filter(
          (a: Announcement) =>
            !delete_announcements.returning
              .map((item: any) => item.id)
              .includes(a.id),
        );

        cache.writeQuery({
          query: GET_ALL_ANNOUNCEMENTS,
          data: { announcements },
        });
      },
      variables: {
        ids,
      },
    });
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <Text value="Announcements" type="h3" />
      <div className="mt-6 w-full">
        <div className="sm:w-6/12 lg:w-10/12 mr-6">
          <Text value="New Announcement" type="bold" className="mb-2" />
          <textarea
            rows={3}
            className="p-2 text-md border border-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block border-gray-300 w-full rounded-md"
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
        </div>
        <div className="mt-6">
          <Toggle
            leftLabel="Is active"
            isEnabled={isActive}
            onChange={() => setIsActive(!isActive)}
            disabled={addNewAnnouncementLoading}
          />
          <Button
            onClick={addNewAnnouncement}
            variant="contained"
            color="primary"
            disabled={addNewAnnouncementLoading}
            className="mt-6"
          >
            New Announcement
          </Button>
        </div>
      </div>
      <section className="mt-12">
        <Text value="All Announcements" type="bold" className="mb-2" />
        <DataTable
          checkbox
          allChecked={checkedItemIds}
          onSelectAll={onCheckAll}
          handleSelectedRow={(item: Announcement) =>
            setCheckedItemIds(
              checkedItemIds.includes(item.id)
                ? checkedItemIds.filter(
                    (checkedItemId: string) => checkedItemId !== item.id,
                  )
                : [...checkedItemIds, item.id],
            )
          }
          data={data?.announcements || []}
          columns={[
            {
              name: 'Announcmement',
              id: 1,
              key: 'text',
            },
            {
              name: 'Date',
              id: 2,
              render: (item: Announcement) => (
                <Text value={format(new Date(item.createdAt), 'MM-dd-yyyy')} />
              ),
            },
            {
              name: 'Is Active',
              id: 3,
              render: (item: Announcement) => (
                <Toggle
                  isEnabled={item.active}
                  onChange={() => updateAnnouncementActiveStatus(item)}
                />
              ),
            },
            {
              name: '',
              id: 4,
              render: (item: Announcement) => {
                return (
                  <Button
                    size={SizeEnum.xs}
                    type="button"
                    variant="outlined"
                    onClick={() => handleDelete(item)}
                  >
                    Delete
                  </Button>
                );
              },
            },
          ]}
        />
      </section>
    </div>
  );
});
