import React, { useMemo } from 'react';
import { Text } from 'components/Text';
import { OrderItemValues } from 'types/order';
import {
  XCircleIcon,
  DocumentSearchIcon,
  ClockIcon,
  QuestionMarkCircleIcon,
  ExclamationIcon,
} from '@heroicons/react/outline';
import classNames from 'classnames';
import { formatCurrency, getStoreCurrencyFormatter } from 'utils';
import { useStore } from 'context/store-context';
import { ClaimItemResolvedStatusEnum, ClaimItemValues } from 'types/claim';
import { ClaimResolvedStatusBadges } from 'containers/NestedContainers/Claim';

type OrderDetailProps = {
  order: OrderItemValues;
  onRemoveItem?: () => void;
  showCategories?: boolean;
  showClaimItemResolution?: boolean;
  disabledClamResolution?: boolean;
  selectedCategory?: string;
  claimItem: ClaimItemValues;
  isSelected?: boolean;
  hasBorderBottom?: boolean;
  quantityEditable?: boolean;
  resolution?: ClaimItemResolvedStatusEnum | undefined;
  onViewResolution: () => void;
  onResolvePartialItems?: (claimItem: ClaimItemValues) => void;
};

const categories = [
  { title: 'Lost', icon: DocumentSearchIcon, key: 'LOST' },
  { title: 'Stolen', icon: ExclamationIcon, key: 'STOLEN' },
  { title: 'Damaged', icon: XCircleIcon, key: 'DAMAGED' },
  { title: 'Late', icon: ClockIcon, key: 'LATE' },
  { title: 'None', icon: QuestionMarkCircleIcon, key: 'OTHER' },
];

export const OrderDetail = ({
  order,
  selectedCategory,
  showCategories,
  claimItem,
  showClaimItemResolution,
  onViewResolution,
  resolution,
  disabledClamResolution,
  hasBorderBottom = true,
  quantityEditable = false,
  onResolvePartialItems,
}: OrderDetailProps) => {
  const { storeProperties } = useStore();

  const category = categories.find((c) => c.key === selectedCategory);

  const showPartialResolution = useMemo(() => {
    if (!claimItem || !claimItem.settlement) return false;

    return claimItem?.quantity !== claimItem?.settlement?.quantity;
  }, [claimItem]);

  return (
    <div
      className={`w-full items-top sm:items-center sm:py-4 mt-2 sm:mt-0 ${
        hasBorderBottom ? 'border-b' : ''
      } ${resolution ? 'cursor-pointer' : ''}`}
      onClick={resolution && onViewResolution}
    >
      <div className="sm:flex justify-between w-full">
        <div className="flex w-full whitespace-normal">
          <img
            className="mr-3 h-10 w-10 sm:h-28 sm:w-28 rounded object-cover"
            src={order.imageSrc}
            alt="order"
          />
          <div className="w-full">
            <div className="text-blue-600 whitespace-normal w-fit truncate text-left">
              {order.title}
            </div>
            <div className="flex">
              <Text value={`Qty: `} className="mr-2" />
              <Text
                value={`${
                  showPartialResolution
                    ? claimItem.settlement?.quantity +
                      ' of ' +
                      claimItem.quantity
                    : claimItem.quantity
                }`}
                className="text-gray-500"
              />
            </div>
            {showPartialResolution && claimItem?.quantity && (
              <p
                onClick={onResolvePartialItems?.bind(null, claimItem)}
                className="text-blue-600 text-left cursor-pointer text-sm text-yellow-700"
              >
                Resolve remaining{' '}
                {claimItem?.quantity - claimItem.settlement?.quantity} items
              </p>
            )}

            <div className="flex flex-wrap mt-1 w-full overflow-x-auto pt-3">
              {showCategories && category && (
                <div
                  className={classNames(
                    selectedCategory === category.key
                      ? 'border border-blue-600'
                      : 'border',
                    'rounded-md border flex justify-center items-center p-2 mr-2 mb-2',
                  )}
                >
                  <category.icon
                    className={classNames(
                      selectedCategory === category.key
                        ? 'text-blue-600'
                        : 'text-gray-600',
                      'h-5 w-5 text-center mr-2',
                    )}
                  />
                  <Text
                    value={category.title}
                    type="sm"
                    className={classNames(
                      selectedCategory === category.key
                        ? 'text-blue-600'
                        : 'text-gray-600',
                      'text-center',
                    )}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {order.subtotal && (
          <div className="flex justify-end flex-col item-end mr-2">
            <Text
              value={`${getStoreCurrencyFormatter(
                storeProperties?.currency,
                formatCurrency(
                  order.price * Number(claimItem.quantity) -
                    (order?.discountTotal ?? 0),
                ),
              )}`}
              type="body"
              className="font-bold sm:font-normal mb-5 sm:mb-0 text-right"
            />

            {resolution && (
              <div>
                <div
                  className={
                    'flex justify-end items-right mb-2 w-64 cursor-pointer'
                  }
                >
                  <ClaimResolvedStatusBadges status={resolution} />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
