import { PhoneIcon } from '@heroicons/react/outline';
import { Button } from 'components/Button';
import { BodyText } from 'components/MardownBodyText';
import { Text } from 'components/Text';
// import { image } from 'html2canvas/dist/types/css/types/image';
// import { Button } from 'components/Button';
// import ShippingImg from '../../../assets/images/google-map.png';
// import DeadlineImg from '../../../assets/images/calendar-date.png';
// import { useNavigate } from 'react-router-dom';
// import classNames from 'classnames';
// import { getOptionById } from './options';
// import { useStore } from 'context/store-context';
const OrderEditing = () => {
  // const navigate = useNavigate();
  // const { storeProperties } = useStore();
  // const { deadline, shipping } = storeProperties?.orderSettings || {
  //   deadline: { timing: 'THIRTY_MIN', description: '' },
  //   shipping: { allow_customer_to_edit_shipping: false },
  // };

  // const orderSettingsList = [
  //   {
  //     text: 'Shipping Address Edits',
  //     subText:
  //       'Allow customers to edit their shipping address and benefit from a free address verification.',
  //     label: 'Shipping img',
  //     imgSrc: ShippingImg,
  //     href: 'shipping-edit',
  //     header: Object.values(shipping).includes(true) ? 'On' : 'Off',
  //     isSwitch: true,
  //     status: Object.values(shipping).includes(true),
  //   },
  //   {
  //     text: 'Editing Deadline',
  //     subText:
  //       'Set a time limit on the duration customers have after placing an order to make edits.',
  //     label: 'Deadline Img',
  //     imgSrc: DeadlineImg,
  //     href: 'deadline',
  //     header: deadline.timing,
  //     isSwitch: false,
  //     status: true,
  //   },
  // ];

  // const RenderBadge = (text: string, isToggle: boolean, status: boolean) => {
  //   let statusStyle = 'bg-green-100 text-green-800';

  //   if (!status) {
  //     statusStyle = 'bg-red-100 text-red-800';
  //   }

  //   return (
  //     <div
  //       className={classNames(
  //         isToggle ? statusStyle : 'bg-blue-100 text-blue-800',
  //         'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0',
  //       )}
  //     >
  //       {text}
  //     </div>
  //   );
  // };

  const paragraphs = [
    {
      text: `There are two methods to configure **Shipaid Order Edit** in your store.`,
    },
    {
      text: `The first method is to go to **Online Store**, select the theme you want to modify from the theme library, and click **Customize**. Then, in the dropdown menu at the top, choose Checkout and new customer accounts, and navigate to the **Order Status** or **Thank You** page to add the **Shipaid Order Edit** block.`,
      image: `https://res.cloudinary.com/shipaid/image/upload/v1728627852/extensions/Way_1_jwxjlu.gif`,
      imageStyle: {
        width: 'auto',
        height: 'auto',
      },
    },
    {
      text: `The second method is through the store's Settings by selecting Checkout. From there, you can customize the checkout where you want to integrate **Shipaid Order Edit** by clicking **Customize**. Select **Order Status** or **Thank You** page from the dropdown menu to add the extension.`,
      image: `https://res.cloudinary.com/shipaid/image/upload/v1728627864/extensions/Way_2_rekub8.gif`,
      imageStyle: {
        width: 'auto',
        height: 'auto',
      },
    },
    {
      text: `To activate **Shipaid Order Edit**, navigate to either the **Thank You page** or **Order Status page**. Then, on the left sidebar, click on **Add Block** and select the **Shipaid Order Edit** block. After adding it, you can proceed to configure the desired settings.`,
      image: `https://res.cloudinary.com/shipaid/image/upload/v1728627879/extensions/Both_abcglm.gif`,
      imageStyle: {
        width: 'auto',
        height: 'auto',
      },
    },
    {
      text: `**If you have any questions about how to complete the process, please don't hesitate to contact us. We're here to assist you!**`,
    },
  ];
  const renderRequestCallButton = ({ className }: { className?: string }) => (
    <a
      href="https://calendly.com/shipaid-support/install"
      target="_blank"
      rel="noreferrer"
    >
      <Button className={className} isRounded onClick={() => {}}>
        <PhoneIcon className="mr-4" width={20} /> Book An Expert Call
      </Button>
    </a>
  );

  return (
    <div>
      <div className="flex flex-col mx-10 gap-5">
        <div className="mb-2">
          <Text value="Order Editing Settings" type="h1" />
        </div>
        <div className="mt-2">
          <div>
            {paragraphs.map(
              (p: {
                image?: string;
                imageStyle?: {
                  width: number | string;
                  height: number | string;
                };
                text: string;
              }) => {
                return (
                  <div key={p.text} className="mb-4 mx-40 flex flex-col">
                    <BodyText className="text-justify" text={p.text} />
                    {p.image && (
                      <img
                        alt={p.text}
                        style={p.imageStyle || { width: 360, height: 324 }}
                        className="my-4 border border-gray-300 object-cover block margin-auto"
                        src={p.image}
                      />
                    )}
                  </div>
                );
              },
            )}
          </div>
          <div className="flex justify-end mb-4">
            {renderRequestCallButton({ className: 'mt-6' })}
          </div>
        </div>
        {/* <div className="flex flex-col">
          <div className="-my-2 sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
              <div className={'shadow border-b border-gray-200 sm:rounded-lg'}>
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50"></thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {orderSettingsList.map((setting, index) => (
                      <tr
                        className="table-data-row"
                        key={`order-settings-${index}`}
                      >
                        <td className="px-1 py-1 whitespace-nowrap text-gray-600 text-sm font-medium text-center">
                          <img
                            style={{ width: 50, height: 50 }}
                            className="inline-block rounded-full"
                            src={setting.imgSrc ?? ''}
                            alt={setting.label ?? ''}
                            referrerPolicy="no-referrer"
                          />
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-gray-600 text-sm font-medium">
                          <div className="flex gap-5">
                            <Text
                              value={setting.text}
                              type="bold"
                              className="text-lg"
                            />
                            {RenderBadge(
                              getOptionById(setting.header),
                              setting.isSwitch,
                              setting.status,
                            )}
                          </div>
                          <Text
                            value={setting.subText}
                            type="sm"
                            className="text-gray-400"
                          />
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-gray-600 text-sm font-medium text-center">
                          <Button
                            isRounded
                            onClick={() => {
                              navigate(`/order-settings/${setting.href ?? ''}`);
                            }}
                          >
                            Manage
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default OrderEditing;
