import React, { useState } from 'react';
import { Text } from 'components/Text';
import { Order, OrderItemValues } from 'types/order';
import classNames from 'classnames';
import { Dropdown } from './Dropdown';

type ClaimSelectionItemProps = {
  orderItem: OrderItemValues;
  selected?: boolean;
  onSelect?: (itemId: string) => void;
  updateQuantity?: (option: string, itemId: string) => void;
  order?: Order;
  index?: number;
};
export const ClaimSelectionItem = ({
  orderItem,
  selected,
  onSelect,
  updateQuantity,
  order,
  index = 0,
}: ClaimSelectionItemProps) => {
  const [isSelected, setIsSelected] = useState(false);

  const handleParentClick = () => {
    onSelect && onSelect(orderItem?.id);
  };

  const handleChildClick = (
    option: string,
    e?: React.MouseEvent<HTMLElement>,
  ) => {
    updateQuantity && updateQuantity(option, orderItem?.id);
    setIsSelected(true);
    e && e.stopPropagation();
  };

  const getQuantity = order?.items.find(
    (item) => item.id === orderItem.id,
  )?.quantity;

  const quantity = order?.items[index].quantity
    ? Array.from({ length: Number(getQuantity) }, (v, i) => `${i + 1}`)
    : [];

  return (
    <div
      className={classNames(
        selected ? 'border-2 border-blue-600' : 'border-0',
        'flex items-top sm:items-center sm:p-3 p-1 bg-gray-100 rounded-md cursor-pointer w-full',
      )}
    >
      <img
        className="mr-4 h-24 w-24 sm:h-24 sm:w-24 rounded object-cover"
        src={orderItem.imageSrc}
        alt="orderItem"
        onClick={() => handleParentClick()}
      />
      <div onClick={() => handleParentClick()}>
        <Text value={orderItem.title} type="body" />
        <Text
          value={
            orderItem.updatedAt
              ? orderItem.deliveredAt
                ? `Delivered on ${new Date(
                    orderItem?.deliveredAt,
                  ).toUTCString()}`
                : orderItem.fulfilledAt
                ? `Fulfilled on ${new Date(
                    orderItem?.fulfilledAt,
                  ).toUTCString()}`
                : `Ordered on ${new Date(orderItem?.updatedAt).toUTCString()}`
              : ''
          }
          type="sm"
          className="text-gray-500"
        />
        <div
          className="flex mt-2 h-auto items-center"
          onClick={(e) => e.stopPropagation()}
        >
          {/* <Text
            value={`${orderItem.quantity}`}
            type="sm"
            className="bg-blue-600 rounded text-white py-2 px-3 mr-3"
          /> */}
          {orderItem.quantity && selected && (
            <Dropdown
              options={quantity}
              onSelectOption={(option, e) => handleChildClick(option, e)}
              selectedOption={isSelected ? `${orderItem.quantity}` : ''}
              btnClassName="py-2 px-3 border-gray-300 border"
              placeholder="select quantity"
              downCaret
            />
          )}
        </div>
      </div>
    </div>
  );
};
