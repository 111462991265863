import React, { useState } from 'react';
import {
  XCircleIcon,
  DocumentSearchIcon,
  ClockIcon,
  QuestionMarkCircleIcon,
  ExclamationIcon,
} from '@heroicons/react/outline';
import { Text } from 'components/Text';
import classNames from 'classnames';
import { ClaimsImagesUpload } from 'components/ClaimsImagesUpload';

const categories = [
  { title: 'Lost', icon: DocumentSearchIcon, key: 'LOST' },
  { title: 'Stolen', icon: ExclamationIcon, key: 'STOLEN' },
  { title: 'Damaged', icon: XCircleIcon, key: 'DAMAGED' },
  { title: 'Late', icon: ClockIcon, key: 'LATE' },
  { title: 'Other', icon: QuestionMarkCircleIcon, key: 'OTHER' },
];

type CauseofClaimProps = {
  handleImageUrls: (urls: string[]) => void;
  handleSetCause: (cause: string) => void;
  setNotes: (note: string) => void;
};

export const CauseOfClaim = ({
  handleImageUrls,
  handleSetCause,
  setNotes,
}: CauseofClaimProps) => {
  const [selectedCause, setSelectedCause] = useState('');
  const [imageUrls, setImageUrls] = useState<string[]>([]);

  const handleNotesChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setNotes(value);
  };

  return (
    <div>
      <div className="flex gap-4 sm:overflow-none overflow-x-scroll">
        {categories.map((category) => (
          <div
            onClick={() => {
              setSelectedCause(category.key);
              handleSetCause(category.key);
            }}
            key={category.key}
            className={classNames(
              selectedCause === category.key
                ? 'border border-blue-600'
                : 'border',
              'sm:w-32 sm:h-32 w-20 h-20 rounded-md border flex flex-col justify-center items-center cursor-pointer',
            )}
          >
            <category.icon
              className={classNames(
                selectedCause === category.key
                  ? 'text-blue-600'
                  : 'text-gray-600',
                'h-6 w-6 sm:h-10 sm:w-10 text-center',
              )}
            />
            <Text
              value={category.title}
              className={classNames(
                selectedCause === category.key
                  ? 'text-blue-600'
                  : 'text-gray-600',
                'text-center text-xs sm:text-base',
              )}
            />
          </div>
        ))}
      </div>
      <div className="mt-10">
        <ClaimsImagesUpload
          onUpload={({ urls }: { urls: string[] }) => {
            setImageUrls([...imageUrls, ...urls]);
            handleImageUrls([...imageUrls, ...urls]);
          }}
        />
      </div>

      <div className="mt-8">
        <Text
          value="Issue note"
          type="h4"
          className="text-gray-500 font-normal"
        />
        <Text
          value="Input any notes about the order or issue for future reference"
          type="sm"
          className="text-gray-500 font-medium"
        />
        <textarea
          rows={4}
          className="border border-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 w-full sm:text-sm border-gray-300 rounded-md mr-3 mt-3 p-2"
          placeholder="Leave a comment"
          onChange={(e) => handleNotesChange(e)}
        />
        {/* <Button onClick={() => null} size={SizeEnum.md}>
          Comment
        </Button> */}
      </div>
      {/* <div>
        {comments.length > 0 &&
          comments.map((comment) => (
            <Comment comment={comment} key={Math.random()} />
          ))}
      </div> */}
    </div>
  );
};
