import React from 'react';
import { Text } from 'components/Text';
import { SizeEnum } from 'utils';
import { Button } from 'components/Button';

export const Account = () => {
  return (
    <div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <Text value="Account" type="h1" />
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-3">
        <Text value="Your Account" type="h4" />
        <div className="block sm:flex mt-3 py-6 sm:w-1/2 justify-between">
          <div className="flex mr-3">
            <div className="h-20 w-20 border rounded-full bg-blue-300"></div>
            <div className="ml-4">
              <Text
                value="Upload your photo"
                type="body"
                className="text-gray-600 font-bold"
              />
              <Text
                value="This helps your teammates recognize you at the office"
                type="sm"
                className="text-gray-400 w-64"
              />
            </div>
          </div>
          <Button
            onClick={() => null}
            size={SizeEnum.md}
            className="bg-blue-600 text-white hover:bg-blue-700 h-max px-6 mt-4"
          >
            Upload photo
          </Button>
        </div>
        <div className=" border-b" />

        <div className="flex w-full sm:w-1/2 justify-between py-4 mt-3">
          <div>
            <Text
              value="Name"
              type="body"
              className="text-gray-600 font-bold"
            />
            <Text value="John Doe" type="sm" className="text-gray-500" />
          </div>
          <Button
            onClick={() => null}
            size={SizeEnum.md}
            className="border-blue-600 bg-transparent text-blue-600 hover:bg-blue-50 h-max px-6"
          >
            Edit
          </Button>
        </div>

        <div className="flex w-full sm:w-1/2 justify-between py-4 mb-3">
          <div>
            <Text
              value="Email address"
              type="body"
              className="text-gray-600 font-bold"
            />
            <Text value="doe@mail.com" type="sm" className="text-gray-500" />
          </div>
          <Button
            onClick={() => null}
            size={SizeEnum.md}
            className="border-blue-600 bg-transparent text-blue-600 hover:bg-blue-50 h-max px-6"
          >
            Edit
          </Button>
        </div>
        <div className=" border-b" />

        <div className="flex w-full sm:w-1/2 justify-between py-4 mt-3">
          <div>
            <Text value="Login" type="h4" className="text-gray-600" />
            <Text
              value="Password"
              type="body"
              className="text-gray-600 font-bold"
            />
            <Text
              value="Password last updated: November 17"
              type="sm"
              className="text-gray-500"
            />
          </div>
          <Button
            onClick={() => null}
            size={SizeEnum.md}
            className="border-blue-600 bg-transparent text-blue-600 hover:bg-blue-50 h-max px-6"
          >
            Update
          </Button>
        </div>

        <div className="flex w-full sm:w-1/2 justify-between py-4">
          <div>
            <Text value="Security" type="h4" className="text-gray-600" />
            <Text
              value="Two-factor authentication"
              type="body"
              className="text-gray-600 font-bold"
            />
            <Text
              value="After entering your password, you will be asked for a secnond verification steff"
              type="sm"
              className="text-gray-500"
            />
          </div>
          <Button
            onClick={() => null}
            size={SizeEnum.md}
            className="border-blue-600 bg-transparent text-blue-600 hover:bg-blue-50 h-max px-6"
          >
            Enable
          </Button>
        </div>

        <div className=" w-full sm:w-1/2 justify-between py-4">
          <div>
            <Text
              value="Sign out from all devices"
              type="body"
              className="text-gray-600 font-bold"
            />
            <Text
              value="Logged in on a shared device but forgot to sign out? End all sessions by signing out from all devices"
              type="sm"
              className="text-gray-500"
            />
          </div>
          <Button
            onClick={() => null}
            size={SizeEnum.md}
            className="border-blue-600 bg-transparent text-blue-600 hover:bg-blue-50 h-max px-6 mt-3"
          >
            Sign out from all your devices
          </Button>
        </div>

        <div className=" w-full sm:w-1/2 justify-between py-4">
          <div>
            <Text
              value="Delete your account"
              type="body"
              className="text-gray-600 font-bold"
            />
            <Text
              value="By deleting your account, you will no longer be able to access any of your orders or claims"
              type="sm"
              className="text-gray-500"
            />
          </div>
          <Button
            onClick={() => null}
            size={SizeEnum.md}
            className="bg-red-500 text-white hover:bg-red-700 h-max px-6 mt-3"
          >
            Delete your account
          </Button>
        </div>
      </div>
    </div>
  );
};
