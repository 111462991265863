import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { Text } from 'components/Text';
import { NavItem } from 'types/sidebar';

type SidebarNavLinkProps = {
  item: NavItem;
  setSidebarOpen?: (value: boolean) => void;
};

export const SidebarNavLink = ({
  item,
  setSidebarOpen,
}: SidebarNavLinkProps) => {
  const location = useLocation();

  return (
    <NavLink
      to={item.to}
      className={({ isActive }) =>
        classNames(
          isActive
            ? 'bg-blue-50 border-blue-600 text-blue-600'
            : 'border-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900',
          'group flex items-center px-3 py-4 text-sm font-medium border-l-4 justify-between',
        )
      }
      onClick={() => (setSidebarOpen ? setSidebarOpen(false) : null)}
    >
      <div className="group flex items-center">
        <item.icon
          className={classNames(
            location.pathname.split('/').includes(item.key)
              ? 'text-blue-500'
              : 'text-gray-400 group-hover:text-gray-500',
            'mr-3 flex-shrink-0 h-6 w-6',
          )}
          aria-hidden="true"
        />
        {item.name}
      </div>
      {item.count && item.count > 0 ? (
        <div className="bg-blue-600 text-center rounded-full h-5 w-7">
          <Text
            value={`${item.count}`}
            className="leading-loose text-white text-[10px]"
          />
        </div>
      ) : null}
    </NavLink>
  );
};
