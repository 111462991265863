/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';

import { Text } from 'components/Text';
import { withNotification } from 'components/Notification';
import { CoverImageUpload } from 'components/CoverImageUpload';
import { NotificationTypes, apiErrorHandler } from 'utils';
import { GET_STORE_TRACKING_PAGE_SETTINGS } from 'gql/queries';
import { UPDATE_STORE_TRACKING_PAGE_SETTINGS } from 'gql/mutations';

import { useStore } from 'context/store-context';

export const CoverImage = withNotification(({ showNotification }: any) => {
  const { storeId } = useStore();

  const [coverImage, setCoverImage] = useState<string | null>(null);
  const [getCustomerTrackingPageSettings] = useLazyQuery(
    GET_STORE_TRACKING_PAGE_SETTINGS,
    {
      onError(error) {
        const newError = apiErrorHandler(error);
        showNotification(NotificationTypes.error, newError?.message);
      },
      onCompleted(data) {
        if (data.storesSettings.length) {
          setCoverImage(data.storesSettings[0].trackingPortalCoverImage);
        }
      },
    },
  );

  const [saveCustomDomain] = useMutation(UPDATE_STORE_TRACKING_PAGE_SETTINGS, {
    onError(error) {
      const newError = apiErrorHandler(error);
      showNotification(NotificationTypes.error, newError?.message);
    },
    onCompleted(data) {
      showNotification(
        NotificationTypes.success,
        'Your cover image is updated successfully!',
      );
      setCoverImage(data?.saveStoresSettings?.trackingPortalCoverImage ?? '');
    },
  });

  useEffect(() => {
    const getTrackingPageSettings = async () => {
      try {
        if (storeId) {
          getCustomerTrackingPageSettings({
            fetchPolicy: 'cache-and-network',
            variables: {
              storeId,
            },
          });
        }
      } catch (e) {
        console.log('error', e);
      }
    };
    getTrackingPageSettings();
  }, []);

  const handleSaveCustomDomain = (coverImageUrl: string | null) => {
    saveCustomDomain({
      variables: {
        input: {
          storeId,
          trackingPortalCoverImage: coverImageUrl,
        },
      },
    });
  };

  return (
    <div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-6">
        <div>
          <Text value="Cover Image" type="h1" />
        </div>
        <div>
          <Text
            value="Key image that shows off your brand in the Tracking Portal. Recomended: 1920x1080 pixels"
            type="body"
            className="mt-8 mb-8 text-gray-500"
          />
        </div>
        <CoverImageUpload
          onUpload={({ urls }) => handleSaveCustomDomain(urls[0])}
          onRemoveImage={() => handleSaveCustomDomain(null)}
          coverImages={coverImage ? [{ url: coverImage }] : []}
        />
      </div>
    </div>
  );
});
