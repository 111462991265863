import React from 'react';
import { Modal } from 'components/Modal';
import { Text } from 'components/Text';
import { Button } from 'components/Button';
import { getStoreCurrencyFormatter } from 'utils';
import { useStore } from 'context/store-context';

type PercentageModalProps = {
  show: boolean;
  setShowModal: (value: boolean) => void;
  isLoading?: boolean;
  handleSetRule?: (type: string) => void;
  percentageInput: {
    maximumFee: string;
    minimumFee: string;
    percentage: string;
  };
  setInput: (field: string, value: string) => void;
};

export const PercentageModal = ({
  show,
  setShowModal,
  isLoading,
  handleSetRule,
  percentageInput,
  setInput,
}: PercentageModalProps) => {
  const { storeProperties } = useStore();

  const handleFieldUpdate = (key: string, value: string) => {
    const num = Number(value);
    if (isNaN(num)) {
      return;
    }
    setInput(key, value);
  };

  return (
    <Modal
      openModal={(value) => setShowModal(value)}
      open={show}
      containerClass="sm:max-w-lg sm:w-full"
    >
      <Text value="Guarantee fee" type="h4" />

      <div className="block sm:flex sm:items-center mt-5">
        <input
          name="fee"
          className="border border-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md p-2"
          placeholder={`e.g ${getStoreCurrencyFormatter(
            storeProperties?.currency,
            0,
          )}`}
          value={percentageInput.minimumFee}
          onChange={(e) =>
            handleFieldUpdate(
              'minimumFee',
              e.target.value.replace(/[$£€CA$]/g, ''),
            )
          }
        />
        <Text value="Minimum" type="body" className="text-gray-500 ml-3 mb-1" />
      </div>
      <div className="block sm:flex sm:items-center mt-5">
        <input
          required
          name="maximumFee"
          className="border border-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md p-2"
          placeholder={`e.g ${getStoreCurrencyFormatter(
            storeProperties?.currency,
            10000,
          )}`}
          value={percentageInput.maximumFee}
          onChange={(e) =>
            handleFieldUpdate(
              'maximumFee',
              e.target.value.replace(/[$£€CA$]/g, ''),
            )
          }
        />
        <Text value="Maximum" type="body" className="text-gray-500 ml-3 mb-1" />
      </div>
      <div className="block sm:flex sm:items-center mt-5">
        <input
          name="percentage"
          className="border border-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md p-2"
          placeholder="e.g 3%"
          value={`${percentageInput.percentage}%`}
          onChange={(e) =>
            handleFieldUpdate('percentage', e.target.value.replaceAll('%', ''))
          }
        />
        <Text
          value="% Of Cart Value"
          type="sm"
          className="text-gray-500 ml-3 mb-1"
        />
      </div>
      <div className="flex mt-6">
        <Button
          onClick={() => handleSetRule && handleSetRule('percentage')}
          className="bg-blue-600 mr-3 hover:bg-blue-700"
          isLoading={isLoading}
        >
          Save Changes
        </Button>
        <Button
          onClick={() => setShowModal(false)}
          className="bg-transparent border border-rose-500 text-gray-500 hover:bg-rose-100"
        >
          <Text value="Cancel" className="text-rose-500" />
        </Button>
      </div>
    </Modal>
  );
};
