import React, { useState } from 'react';
import { UploadIcon } from '@heroicons/react/outline';
import { SizeEnum } from 'utils';
import { Button } from './Button';
import { ImageUpload } from './ImageUpload';
import { Text } from './Text';

export const AccountImageUpload = ({
  onUpload,
  defaultImage,
  isLoading,
  heading,
  subheading,
  id,
}: {
  onUpload: ({ urls }: { urls: string[] }) => void;
  defaultImage?: string;
  isLoading?: boolean;
  heading?: string;
  id?: string;
  subheading?: string;
}) => {
  const [, setIsUploading] = useState(false);
  const title = heading ? heading : 'Upload your profile photo';
  const subtitle = subheading
    ? subheading
    : 'This makes it easier for your teammates to recognize you';

  return (
    <ImageUpload
      shouldUploadOnDrop={false}
      onUpload={onUpload}
      setIsUploading={setIsUploading}
    >
      {({ upload, files, getRootProps, getInputProps }) => {
        const thumbnail = (
          <div className="relative flex flex-col justify-center items-center">
            <img
              id="avatar-upload"
              className="object-cover h-20 w-20 rounded-full"
              src={files[0] ? files[0]?.preview : defaultImage}
              alt={files[0]?.name}
            />

            <UploadIcon
              className="absolute opacity-50 hover:opacity-70"
              width={60}
              height={60}
            />
          </div>
        );

        return (
          <div className="block sm:flex justify-between py-6 sm:w-1/2">
            <section className="flex align-baseline" {...getRootProps()}>
              <label
                htmlFor={`${id}file-upload`}
                onClick={(e) => e.stopPropagation()}
              >
                <div
                  className="bg-blue-300 h-20 w-20 rounded-full mr-3"
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  {defaultImage ? thumbnail : files[0] && thumbnail}
                </div>
              </label>
              <label
                className="flex flex-col justify-center cursor-pointer"
                htmlFor={`${id}file-upload`}
              >
                <Text
                  type="bold"
                  value={title}
                  className="text-gray-600 font-bold"
                />
                <Text
                  type="sm"
                  value={subtitle}
                  className="text-gray-400 w-64"
                />
                <input
                  id={`${id}file-upload`}
                  name={`${id}file-upload`}
                  type="file"
                  className="sr-only"
                  {...getInputProps()}
                />
              </label>
            </section>
            <section className="flex flex-col justify-center">
              <Button
                size={SizeEnum.md}
                onClick={() => {
                  upload(files);
                }}
                className="bg-blue-600 text-white hover:bg-blue-700 h-max px-6 mt-4 justify-center"
                isLoading={isLoading}
              >
                Upload Photo
              </Button>
            </section>
          </div>
        );
      }}
    </ImageUpload>
  );
};
