import { useState, useRef } from 'react';
import { useMutation } from '@apollo/client';
import { useStore } from 'context/store-context';
import { useUser } from 'context/user-context';

import {
  TEST_EMAIL_NOTIFICATION,
  UPDATE_TRACKING_TEMPLATE,
} from 'gql/mutations';
import {
  customEmailTemplateIntegrationTypes,
  defaultEmailTemplates,
  defaultTrackingTemplate,
  templatesUsageTypes,
} from '../containers/NestedContainers/Tracking/data/templates-data';

export const useTemplateEmails = ({
  callback = (val?: any, info?: any) => null,
} = {}) => {
  const emailEditorRef = useRef<any | null>(null);
  const { storeId } = useStore();
  const { user } = useUser();
  const [loading, setLoading] = useState(false);

  const [templateDesign, setTemplateDesign] = useState<any>({
    templateJson: null,
    templateHtml: null,
  });

  const [sendTestEmailNotification] = useMutation(TEST_EMAIL_NOTIFICATION, {
    onError() {
      callback?.(null, {
        success: false,
        message: 'Failed to send test email!',
        updateTemplate: false,
      });
    },
    onCompleted() {
      callback?.(null, {
        success: true,
        message: 'Test Email is sent!',
        updateTemplate: false,
        templateDesign,
      });
    },
  });

  const [updateTrackingTemplate] = useMutation(UPDATE_TRACKING_TEMPLATE, {
    onError() {
      callback?.(null, {
        success: false,
        message: 'Error Updating Template!',
        updateTemplate: false,
      });
    },
    onCompleted(data) {
      callback?.(data.saveTrackingTemplate, {
        success: true,
        message: 'Template Updated Successfully!',
        updateTemplate: true,
      });
    },
  });

  const resetTemplate = async (
    eventType: string,
    usage: string,
    template: any,
  ) => {
    try {
      const defaultTemplate =
        usage === templatesUsageTypes.tracking
          ? defaultTrackingTemplate
          : defaultEmailTemplates.find(
              (template) =>
                template.eventType === eventType && template.usage === usage,
            );
      if (!defaultTemplate) return;
      setLoading(true);
      const bodyData =
        usage === templatesUsageTypes.tracking
          ? {
              storeId,
              id: template.id ?? defaultTemplate.id,
              isTrackingDefault: template?.isTrackingDefault ?? false,
              eventType: template?.eventType,
              active: defaultTemplate?.active,
              templateJson: defaultTemplate?.templateJson ?? {},
              templateHtml: defaultTemplate?.templateHtml ?? '',
              subject: template?.subject ?? 'tracking',
              platformIntegrationType:
                customEmailTemplateIntegrationTypes.custom,
              usage: defaultTemplate.usage,
            }
          : {
              storeId,
              id: template.id ?? defaultTemplate.id,
              eventType: defaultTemplate?.eventType,
              active: defaultTemplate?.active,
              templateJson: defaultTemplate?.templateJson ?? {},
              templateHtml: defaultTemplate?.templateHtml ?? '',
              subject: defaultTemplate?.subject,
              platformIntegrationType:
                customEmailTemplateIntegrationTypes.custom,
              usage: defaultTemplate.usage,
            };
      updateTrackingTemplate({
        variables: {
          input: bodyData,
        },
      });
    } catch (e) {
      console.log('error', e);
    } finally {
      setLoading(false);
    }
  };

  const submitCustomTemplate = ({ values }: any) => {
    if (!values.eventType && !values?.isTrackingDefault) {
      callback?.(null, {
        success: false,
        message: 'Event Type is required!',
        updateTemplate: false,
      });
      return;
    }
    emailEditorRef?.current?.editor.exportHtml(
      async (data: any) => {
        const { html, design } = data;
        try {
          setLoading(true);
          updateTrackingTemplate({
            variables: {
              input: {
                storeId,
                id: values.id,
                active: values.active,
                isTrackingDefault: values?.isTrackingDefault ?? false,
                eventType: values.eventType,
                platformIntegrationType: values.platformIntegrationType,
                subject: values.subject ?? 'tracking',
                templateJson: design,
                templateHtml: html,
                usage: values.usage,
              },
            },
          });
        } catch (e) {
          console.log('error', e);
        } finally {
          setLoading(false);
        }
      },
      { minify: true },
    );
  };

  const submitTemplate = async ({ values }: any) => {
    if (
      values.platformIntegrationType ===
        customEmailTemplateIntegrationTypes.custom &&
      !values?.simpleIntegration
    ) {
      return submitCustomTemplate({ values });
    }
    try {
      setLoading(true);
      updateTrackingTemplate({
        variables: {
          input: {
            storeId,
            id: '100',
            active: values.active,
            isTrackingDefault: false,
            eventType: values.eventType,
            platformIntegrationType: values.platformIntegrationType,
            subject: values.subject ?? 'tracking',
            templateJson: values.templateJson,
            templateHtml: values.templateHtml,
            usage: values.usage,
          },
        },
      });
    } catch (e) {
      console.log('error', e);
    } finally {
      setLoading(false);
    }
    return;
  };

  const handleSendTestEmail = async (values: any) => {
    if (!values) return;
    try {
      if (user && storeId) {
        setTemplateDesign({
          templateJson: values?.templateJson,
          templateHtml: values?.templateHtml,
        });
        sendTestEmailNotification({
          variables: {
            input: {
              storeId,
              to: user.email,
              subject: values.subject,
              html: values.templateHtml,
            },
          },
        });
        return;
      }
    } catch (e) {
      console.log('error', e);
    } finally {
    }
  };

  const sendTestEmail = ({ values }: any) => {
    if (
      values.platformIntegrationType ===
      customEmailTemplateIntegrationTypes.custom
    ) {
      emailEditorRef?.current?.editor.exportHtml(
        (data: any) => {
          const { html, design } = data;
          handleSendTestEmail({
            ...values,
            templateHtml: html,
            templateJson: design,
          });
        },
        { minify: true },
      );
      return;
    }
    handleSendTestEmail(values);
  };

  return {
    submitTemplate,
    resetTemplate,
    sendTestEmail,
    loading,
    emailEditorRef,
  };
};
