import { useMutation } from '@apollo/client';
import { UPDATE_STORE } from 'gql/mutations';
import { useState, useCallback } from 'react';
import { apiErrorHandler, NotificationTypes } from 'utils';
import { withNotification } from './Notification';

type DebounceFunction<T extends any[]> = (...args: T) => void;

const debounce = <T extends any[]>(
  func: (...args: T) => void,
  delay: number,
): DebounceFunction<T> => {
  let timeoutId: NodeJS.Timeout;

  return (...args: T) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
};

const AutoOptInProbability = withNotification(
  ({ store, showNotification }: { store: any; showNotification: any }) => {
    const [value, setValue] = useState<string>(
      (100 * (Number(store.autoOptInProbability) || 1)).toString(),
    );

    const [updateStore, { error: updateStoreError }] = useMutation(
      UPDATE_STORE,
      {
        onError(error) {
          const newError = apiErrorHandler(error);
          showNotification(NotificationTypes.error, newError?.message);
        },
      },
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateOptInProbability = useCallback(
      debounce((query: string) => {
        updateStore({
          variables: {
            storeId: store.id,
            set: {
              autoOptInProbability: parseFloat(query) / 100,
            },
          },
        })
          .then(() => {
            showNotification(
              NotificationTypes.success,
              'Auto Opt-in probability updated',
            );
          })
          .catch(() => {
            showNotification(
              NotificationTypes.error,
              updateStoreError
                ? updateStoreError.message
                : 'Auto Opt-in probability not updated',
            );
          });
      }, 500),
      [updateStore, store.id, showNotification],
    );

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
      updateOptInProbability(event.target.value);
    };

    return (
      <input
        type="number"
        step={10}
        max={100}
        min={10}
        className="w-14 text-right"
        value={value}
        onChange={handleChange}
      />
    );
  },
);

export default AutoOptInProbability;
