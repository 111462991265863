import React from 'react';
import { XCircleIcon } from '@heroicons/react/solid';
import { SpeakerphoneIcon } from '@heroicons/react/outline';
import { Text } from 'components/Text';
import { truncateString } from 'utils';

type NotificationBarProps = {
  message: string;
  actionHandler?: (value?: any) => void;
  action?: string;
};

export const NotificationBar = ({
  message,
  action,
  actionHandler,
}: NotificationBarProps) => {
  return (
    <div className="w-full text-gray-400 bg-blue-600 flex p-2 items-top justify-between">
      <div className="inset-y-0 flex items-center pointer-events-none pl-2">
        <div className="hidden sm:block md:block">
          <button
            type="button"
            onClick={actionHandler}
            className="flex items-center justify-center p-1 border border-transparent rounded text-blue-600 bg-white hover:bg-gray-100 mr-4 w-max h-max"
          >
            <SpeakerphoneIcon
              className="h-5 w-5 text-blue-600"
              aria-hidden="true"
            />
          </button>
        </div>
        <Text
          value={truncateString(message, 100)}
          className="text-white pr-3"
          type="body"
        />
      </div>
      <div className="flex items-top mt-2 sm:items-center md:items-center sm:mt-0 md:mt-0">
        {action && (
          <button
            type="button"
            onClick={actionHandler}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-4 font-medium rounded shadow-sm text-blue-600 bg-white hover:bg-gray-100 mr-4 w-max h-max"
          >
            {action}
          </button>
        )}
        <div className="mr-1">
          <XCircleIcon className="w-5 h-5 text-white" />
        </div>
      </div>
    </div>
  );
};
