import React from 'react';
import Select from 'react-select';
import { Text } from 'components/Text';
import { MultiSelect } from 'components/MultiSelect';
import { XCircleIcon } from '@heroicons/react/outline';
import {
  groupedExpressionFieldOptions,
  allGroupedExpressionFieldOptions,
  operatorOptions,
  getDrownDownOptionByFieldName,
} from '../containers/NestedContainers/ClaimAutomation/utils';
type ClaimAutomationRuleProps = {
  index: number;
  onRemoveItem: () => void;
  statement: any;
  onChange: (field: string, value: string) => void;
};

export const ClaimAutomationRule = ({
  index,
  onRemoveItem,
  statement,
  onChange,
}: ClaimAutomationRuleProps) => {
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: string,
  ) => {
    const { value } = e.target;

    onChange(field, value);
  };

  const getOperatorOptions = () => {
    if (statement.expressionField !== '') {
      const expressionType = allGroupedExpressionFieldOptions.find(
        (exp) => exp.value === statement.expressionField,
      );
      if (expressionType?.type) {
        return operatorOptions.filter((o) => o.type === expressionType.type);
      }
    }
    return operatorOptions;
  };

  const handleDrownDownSelect = (val: any) => {
    onChange('expressionValue', `${val.join()}`);
  };
  const getExpressionValueField = () => {
    if (statement.config.field === 'numberInput') {
      return (
        <input
          name="expressionValue"
          type="number"
          className="mt-3 border border-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:w-96 sm:text-sm border-gray-300 rounded-md p-2"
          placeholder={statement.config.placeHolder}
          value={
            statement.expressionValue !== '' ? statement.expressionValue : null
          }
          disabled={
            statement.expressionField === '' || statement.expression === ''
          }
          onChange={(e) => handleChange(e, 'expressionValue')}
        />
      );
    }
    if (statement.config.field === 'stringDropDown') {
      const options = getDrownDownOptionByFieldName(statement.expressionField);
      return (
        <MultiSelect
          // key={Math.random()}
          options={options.map((s) => s.label)}
          selected={
            statement.expressionValue !== ''
              ? statement.expressionValue.split(',')
              : []
          }
          setSelected={handleDrownDownSelect}
          placeholder={statement.config.placeHolder}
          style={{ minHeight: 35, marginTop: '10px', width: 385 }}
          maxValuesAllowed={
            ['_eq', '_neq'].includes(statement.expression) ? 1 : undefined
          }
        />
      );
    }
    return (
      <input
        name="expressionValue"
        className="mt-3 border border-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:w-96 sm:text-sm border-gray-300 rounded-md p-2"
        placeholder={statement.config.placeHolder}
        value={
          statement.expressionValue !== '' ? statement.expressionValue : ''
        }
        disabled={
          statement.expressionField === '' || statement.expression === ''
        }
        onChange={(e) => handleChange(e, 'expressionValue')}
      />
    );
  };

  return (
    <div className="items-center flex">
      <div className="flex items-end">
        <div className="flex items-center">
          <div className="block sm:flex sm:items-center mt-4">
            <Text
              value={statement.connector !== '' ? statement.connector : 'IF'}
              type="bold"
              className="text-gray-500 mr-3 mb-2 w-11"
            />
            <div className="flex items-center">
              <div style={{ width: 350 }}>
                <Select
                  value={
                    allGroupedExpressionFieldOptions.find(
                      (exp) => exp.value === statement.expressionField,
                    ) ?? null
                  }
                  onChange={(val: any) => {
                    onChange('expressionField', val?.value);
                  }}
                  options={groupedExpressionFieldOptions}
                  // styles={{ width: ''}}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="ml-5" style={{ width: 250 }}>
            <Select
              value={
                operatorOptions.find(
                  (exp) => exp.value === statement.expression,
                ) ?? null
              }
              onChange={(val: any) => {
                onChange('expression', val?.value);
              }}
              options={getOperatorOptions()}
              isDisabled={statement.expressionField === ''}
              // styles={{ width: ''}}
            />
          </div>
        </div>
      </div>
      <div className="ml-4">
        <div className="flex items-center">
          {getExpressionValueField()}
          {index > 0 ? (
            <XCircleIcon
              className="w-5 h-5 text-red-500 cursor-pointer ml-3"
              onClick={onRemoveItem}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};
