import { claimReasons, shipmentStatus } from '../../data/orders-data';

const customerOptions = [
  { value: 'customerTags', label: 'Customer Tags', type: 'stringList' },
  {
    value: 'customerOrderCount',
    label: 'Customer Order Count',
    type: 'number',
  },
  {
    value: 'customerStoreClaimCount',
    label: 'Customer Store Claim Count',
    type: 'number',
  },
];

const claimOptions = [
  { value: 'productCount', label: 'Claim Product Count', type: 'number' },
  { value: 'claimReason', label: 'Claim Reason', type: 'string' },
];

const productOptions = [
  { value: 'productTags', label: 'Product Tags', type: 'stringList' },
  { value: 'productCollections', label: 'Product Collections', type: 'string' },
  { value: 'productTitle', label: 'Product Title', type: 'string' },
  { value: 'productPrice', label: 'Product Price', type: 'number' },
  { value: 'productInventory', label: 'Product Inventory', type: 'string' },
];

const orderOptions = [
  { value: 'orderValue', label: 'Order Value', type: 'number' },
  { value: 'orderTags', label: 'Order Tags', type: 'stringList' },
  {
    value: 'orderShippingMethod',
    label: 'Order Shipping Methods',
    type: 'string',
  },
  { value: 'orderDiscount', label: 'Order Discount', type: 'number' },
  { value: 'orderCity', label: 'Order City', type: 'string' },
  { value: 'orderCountry', label: 'Order Country', type: 'string' },
  { value: 'orderZip', label: 'Order Zip', type: 'string' },
  {
    value: 'orderItemFulfillmentStatus',
    label: 'Order Fulfillment Status',
    type: 'string',
  },
  {
    value: 'orderTrackingStatus',
    label: 'Order Tracking Status',
    type: 'string',
  },
];

export const groupedExpressionFieldOptions = [
  {
    label: 'Customer',
    options: customerOptions,
  },
  {
    label: 'Claim',
    options: claimOptions,
  },
  {
    label: 'Product',
    options: productOptions,
  },
  {
    label: 'Order',
    options: orderOptions,
  },
];

export const allGroupedExpressionFieldOptions = groupedExpressionFieldOptions
  .map((o) => o.options)
  .flat(1);

export const operatorOptions = [
  { value: '_matchOneOf', label: 'Matches One Of', type: 'stringList' },
  { value: '_matchAll', label: 'Matches All', type: 'stringList' },
  { value: '_nMatchAll', label: 'Does Not Include', type: 'stringList' },

  { value: '_eq', label: 'Equals', type: 'number' },
  { value: '_oneOf', label: 'One Of', type: 'number' },
  { value: '_neq', label: 'Not Equals', type: 'number' },
  { value: '_nOneOf', label: 'Not One Of', type: 'number' },
  { value: '_gt', label: 'Higher Than', type: 'number' },
  { value: '_lt', label: 'Lower Than', type: 'number' },
  { value: '_gte', label: 'Equal Or Higher Than', type: 'number' },
  { value: '_lte', label: 'Equal Or Lower Than', type: 'number' },
  { value: '_eInterval', label: 'Exclusive Interval', type: 'number' },
  { value: '_iInterval', label: 'Inclusive interval', type: 'number' },

  { value: '_eq', label: 'Equals', type: 'string' },
  { value: '_oneOf', label: 'One Of', type: 'string' },
  { value: '_neq', label: 'Not Equals', type: 'string' },
  { value: '_nOneOf', label: 'Not One Of', type: 'string' },
  // { value: "_contains", label: "Contains", type: "string" },
];

export const ruleActionOptions = [
  { value: 'REORDER', label: 'REORDER' },
  { value: 'REFUND', label: 'REFUND' },
  { value: 'STORE_CREDIT', label: 'STORE CREDIT' },
  { value: 'GIFT_CARD', label: 'GIFT CARD' },
  { value: 'DISCOUNT_COUPON', label: 'DISCOUNT COUPON' },
  { value: 'OTHER', label: 'OTHER' },
  { value: 'DENY', label: 'DENY' },
  { value: 'PREFERRED_RESOLUTION', label: 'PREFERRED RESOLUTION' },
];

const inputFieldMapping = {
  customerTags: {
    _matchOneOf: {
      field: 'stringInput',
      placeHolder: 'Member,Prime,Agent',
    },
    _matchAll: {
      field: 'stringInput',
      placeHolder: 'Member,Prime,Agent',
    },
    _nMatchAll: {
      field: 'stringInput',
      placeHolder: 'External,WholeSale',
    },
  },
  customerOrderCount: {
    _eq: {
      field: 'numberInput',
      placeHolder: '10',
    },
    _oneOf: {
      field: 'stringInput',
      placeHolder: '10,20,30',
    },
    _neq: {
      field: 'numberInput',
      placeHolder: '10',
    },
    _nOneOf: {
      field: 'stringInput',
      placeHolder: '10,20,30',
    },
    _gt: {
      field: 'numberInput',
      placeHolder: '10',
    },
    _lt: {
      field: 'numberInput',
      placeHolder: '10',
    },
    _gte: {
      field: 'numberInput',
      placeHolder: '10',
    },
    _lte: {
      field: 'numberInput',
      placeHolder: '10',
    },
    _eInterval: {
      field: 'numberRangeInput',
      placeHolder: '10,50',
    },
    _iInterval: {
      field: 'numberRangeInput',
      placeHolder: '10,50',
    },
  },
  customerStoreClaimCount: {
    _eq: {
      field: 'numberInput',
      placeHolder: '10',
    },
    _oneOf: {
      field: 'stringInput',
      placeHolder: '10,20,30',
    },
    _neq: {
      field: 'numberInput',
      placeHolder: '10',
    },
    _nOneOf: {
      field: 'stringInput',
      placeHolder: '10,20,30',
    },
    _gt: {
      field: 'numberInput',
      placeHolder: '10',
    },
    _lt: {
      field: 'numberInput',
      placeHolder: '10',
    },
    _gte: {
      field: 'numberInput',
      placeHolder: '10',
    },
    _lte: {
      field: 'numberInput',
      placeHolder: '10',
    },
    _eInterval: {
      field: 'numberRangeInput',
      placeHolder: '10,50',
    },
    _iInterval: {
      field: 'numberRangeInput',
      placeHolder: '10,50',
    },
  },

  productCount: {
    _eq: {
      field: 'numberInput',
      placeHolder: '5',
    },
    _oneOf: {
      field: 'stringInput',
      placeHolder: '3,5,8',
    },
    _neq: {
      field: 'numberInput',
      placeHolder: '3',
    },
    _nOneOf: {
      field: 'stringInput',
      placeHolder: '3,5,8',
    },
    _gt: {
      field: 'numberInput',
      placeHolder: '5',
    },
    _lt: {
      field: 'numberInput',
      placeHolder: '5',
    },
    _gte: {
      field: 'numberInput',
      placeHolder: '5',
    },
    _lte: {
      field: 'numberInput',
      placeHolder: '5',
    },
    _eInterval: {
      field: 'numberRangeInput',
      placeHolder: '1,5',
    },
    _iInterval: {
      field: 'numberRangeInput',
      placeHolder: '1,5',
    },
  },

  productTags: {
    _matchOneOf: {
      field: 'stringInput',
      placeHolder: 'Premium,Exclusive,Sale',
    },
    _matchAll: {
      field: 'stringInput',
      placeHolder: 'Premium,Exclusive,Sale',
    },
    _nMatchAll: {
      field: 'stringInput',
      placeHolder: 'Premium,Exclusive,Sale',
    },
  },
  productCollections: {
    _eq: {
      field: 'stringInput',
      placeHolder: 'Adjustable Chain',
    },
    _oneOf: {
      field: 'stringInput',
      placeHolder: 'Adjustable Chain,Raw Juice,Glue Box',
    },
    _neq: {
      field: 'stringInput',
      placeHolder: 'Adjustable Chain',
    },
    _nOneOf: {
      field: 'stringInput',
      placeHolder: 'Adjustable Chain,Raw Juice,Glue Box',
    },
  },
  productTitle: {
    _eq: {
      field: 'stringInput',
      placeHolder: 'Adjustable Chain',
    },
    _oneOf: {
      field: 'stringInput',
      placeHolder: 'Adjustable Chain,Raw Juice,Glue Box',
    },
    _neq: {
      field: 'stringInput',
      placeHolder: 'Adjustable Chain',
    },
    _nOneOf: {
      field: 'stringInput',
      placeHolder: 'Adjustable Chain,Raw Juice,Glue Box',
    },
  },
  productPrice: {
    _eq: {
      field: 'numberInput',
      placeHolder: '99',
    },
    _oneOf: {
      field: 'stringInput',
      placeHolder: '49,99,199',
    },
    _neq: {
      field: 'numberInput',
      placeHolder: '99',
    },
    _nOneOf: {
      field: 'stringInput',
      placeHolder: '49,99,199',
    },
    _gt: {
      field: 'numberInput',
      placeHolder: '99',
    },
    _lt: {
      field: 'numberInput',
      placeHolder: '99',
    },
    _gte: {
      field: 'numberInput',
      placeHolder: '99',
    },
    _lte: {
      field: 'numberInput',
      placeHolder: '99',
    },
    _eInterval: {
      field: 'numberRangeInput',
      placeHolder: '99,199',
    },
    _iInterval: {
      field: 'numberRangeInput',
      placeHolder: '99,199',
    },
  },
  productInventory: {
    _eq: {
      field: 'stringInput',
      placeHolder: 'Adjustable Chain',
    },
    _oneOf: {
      field: 'stringInput',
      placeHolder: 'Adjustable Chain,Raw Juice,Glue Box',
    },
    _neq: {
      field: 'stringInput',
      placeHolder: 'Adjustable Chain',
    },
    _nOneOf: {
      field: 'stringInput',
      placeHolder: 'Adjustable Chain,Raw Juice,Glue Box',
    },
  },

  orderValue: {
    _eq: {
      field: 'numberInput',
      placeHolder: '99',
    },
    _oneOf: {
      field: 'stringInput',
      placeHolder: '49,99,199',
    },
    _neq: {
      field: 'numberInput',
      placeHolder: '99',
    },
    _nOneOf: {
      field: 'stringInput',
      placeHolder: '49,99,199',
    },
    _gt: {
      field: 'numberInput',
      placeHolder: '99',
    },
    _lt: {
      field: 'numberInput',
      placeHolder: '99',
    },
    _gte: {
      field: 'numberInput',
      placeHolder: '99',
    },
    _lte: {
      field: 'numberInput',
      placeHolder: '99',
    },
    _eInterval: {
      field: 'numberRangeInput',
      placeHolder: '99,199',
    },
    _iInterval: {
      field: 'numberRangeInput',
      placeHolder: '99,199',
    },
  },
  orderTags: {
    _matchOneOf: {
      field: 'stringInput',
      placeHolder: 'Express,Sale,Discounted',
    },
    _matchAll: {
      field: 'stringInput',
      placeHolder: 'Express,Sale,Discounted',
    },
    _nMatchAll: {
      field: 'stringInput',
      placeHolder: 'Express,Sale,Discounted',
    },
  },
  orderShippingMethod: {
    _eq: {
      field: 'stringInput',
      placeHolder: 'UPS - Next Day Air',
    },
    _oneOf: {
      field: 'stringInput',
      placeHolder: 'UPS - Next Day Air,FedEx - Ground',
    },
    _neq: {
      field: 'stringInput',
      placeHolder: 'UPS - Next Day Air',
    },
    _nOneOf: {
      field: 'stringInput',
      placeHolder: 'UPS - Next Day Air,FedEx - Ground',
    },
  },
  orderDiscount: {
    _eq: {
      field: 'numberInput',
      placeHolder: '99',
    },
    _oneOf: {
      field: 'stringInput',
      placeHolder: '49,99,199',
    },
    _neq: {
      field: 'numberInput',
      placeHolder: '99',
    },
    _nOneOf: {
      field: 'stringInput',
      placeHolder: '49,99,199',
    },
    _gt: {
      field: 'numberInput',
      placeHolder: '99',
    },
    _lt: {
      field: 'numberInput',
      placeHolder: '99',
    },
    _gte: {
      field: 'numberInput',
      placeHolder: '99',
    },
    _lte: {
      field: 'numberInput',
      placeHolder: '99',
    },
    _eInterval: {
      field: 'numberRangeInput',
      placeHolder: '99,199',
    },
    _iInterval: {
      field: 'numberRangeInput',
      placeHolder: '99,199',
    },
  },
  orderCity: {
    _eq: {
      field: 'stringInput',
      placeHolder: 'New York',
    },
    _oneOf: {
      field: 'stringInput',
      placeHolder: 'New York,Los Angeles',
    },
    _neq: {
      field: 'stringInput',
      placeHolder: 'New York',
    },
    _nOneOf: {
      field: 'stringInput',
      placeHolder: 'New York,Los Angeles',
    },
  },
  orderCountry: {
    _eq: {
      field: 'stringInput',
      placeHolder: 'USA',
    },
    _oneOf: {
      field: 'stringInput',
      placeHolder: 'USA,Canada',
    },
    _neq: {
      field: 'stringInput',
      placeHolder: 'USA',
    },
    _nOneOf: {
      field: 'stringInput',
      placeHolder: 'USA,Canada',
    },
  },
  orderZip: {
    _eq: {
      field: 'stringInput',
      placeHolder: '90001',
    },
    _oneOf: {
      field: 'stringInput',
      placeHolder: '10001,90001',
    },
    _neq: {
      field: 'stringInput',
      placeHolder: '10001',
    },
    _nOneOf: {
      field: 'stringInput',
      placeHolder: '10001,90001',
    },
  },
  orderItemFulfillmentStatus: {
    _eq: {
      field: 'stringInput',
      placeHolder: 'Fulfilled',
    },
    _oneOf: {
      field: 'stringInput',
      placeHolder: 'Fulfilled,Unulfilled',
    },
    _neq: {
      field: 'stringInput',
      placeHolder: 'Fulfilled',
    },
    _nOneOf: {
      field: 'stringInput',
      placeHolder: 'Fulfilled,Unulfilled',
    },
  },
  orderTrackingStatus: {
    _eq: {
      field: 'stringDropDown',
      placeHolder: 'Tracking Status',
    },
    _oneOf: {
      field: 'stringDropDown',
      placeHolder: 'Tracking Status',
    },
    _neq: {
      field: 'stringDropDown',
      placeHolder: 'Tracking Status',
    },
    _nOneOf: {
      field: 'stringDropDown',
      placeHolder: 'Tracking Status',
    },
  },
  claimReason: {
    _eq: {
      field: 'stringDropDown',
      placeHolder: 'Claim Reason',
    },
    _oneOf: {
      field: 'stringDropDown',
      placeHolder: 'Claim Reason',
    },
    _neq: {
      field: 'stringDropDown',
      placeHolder: 'Claim Reason',
    },
    _nOneOf: {
      field: 'stringDropDown',
      placeHolder: 'Claim Reason',
    },
  },
};

const singleValueOperator = ['_eq', '_neq', '_gt', '_gte', '_lte'];
const arrayValueOperators = [
  '_matchOneOf',
  '_matchAll',
  '_nMatchAll',
  '_oneOf',
  '_nOneOf',
];
const rangeValueOperators = ['_eInterval', '_iInterval'];

export const getStatementInputConfig = (field, expression) => {
  const config = inputFieldMapping[field]
    ? inputFieldMapping[field][expression]
    : null;
  if (config) return config;
  return {
    field: 'stringInput',
    placeHolder: 'Value',
  };
};

export const getDrownDownOptionByFieldName = (field) => {
  switch (field) {
    case 'orderTrackingStatus':
      return shipmentStatus;
    case 'claimReason':
      return claimReasons;
    default:
      return [];
  }
};

export const validateAndUpdateRule = (rule) => {
  const messages = [];
  const statements = [];
  if (rule.title === '') {
    messages.push('Rule Name is required');
  }
  if (rule.action === '') {
    messages.push('Rule Action is required');
  }
  rule.statements.forEach((statement, ind) => {
    if (statement.expressionField === '')
      messages.push(`Condition ${ind + 1}: Expression Field is required`);
    if (statement.expression === '')
      messages.push(`Condition ${ind + 1}: Operator Field is required`);
    if (statement.expressionValue === '')
      messages.push(`Condition ${ind + 1}: Value Field is required`);
  });
  if (!messages.length) {
    rule.statements.forEach((statement, ind) => {
      const expectedFieldType = getExpectedFieldTypeByOperation(
        statement.expressionField,
        statement.expression,
      );
      let transformedValue = statement.expressionValue;
      if (expectedFieldType === 'number') {
        if (isNaN(transformedValue))
          messages.push(`Condition ${ind + 1}: Expected Value is number`);
        else transformedValue = Number(transformedValue);
      } else if (expectedFieldType === 'numberInterval') {
        const exp = statement.expressionValue.split(',');
        if (exp.length !== 2)
          messages.push(`Condition ${ind + 1}: Expected Number Range values`);
        else if (exp.length === 2 && (isNaN(exp[0]) || isNaN(exp[1])))
          messages.push(`Condition ${ind + 1}: Expected Number Values`);
        else if (exp.length === 2 && !isNaN(exp[0]) && !isNaN(exp[1]))
          transformedValue = [Number(exp[0]), Number([exp[1]])];
      } else if (expectedFieldType === 'numberArray') {
        const exp = statement.expressionValue.split(',');
        const validateNumErr = exp.some((v) => isNaN(v));
        if (validateNumErr)
          messages.push(`Condition ${ind + 1}: Expected Number Values`);
        else transformedValue = exp.map((v) => Number(v));
      } else if (
        expectedFieldType === 'stringList' ||
        expectedFieldType === 'stringArray'
      ) {
        const exp = statement.expressionValue.split(',');
        transformedValue = exp.map((v) => v.replaceAll(' ', ''));
      }
      statements.push({
        ...statement,
        expressionValue: transformedValue,
        fieldType: expectedFieldType,
      });
    });
  }
  return {
    hasError: !!messages.length,
    messages,
    statements,
  };
};

const getExpectedFieldTypeByOperation = (field, operator) => {
  const fieldType = allGroupedExpressionFieldOptions.find(
    (f) => f.value === field,
  );
  if (fieldType.type === 'number' && singleValueOperator.includes(operator))
    return 'number';
  else if (
    fieldType.type === 'number' &&
    rangeValueOperators.includes(operator)
  )
    return 'numberInterval';
  else if (
    fieldType.type === 'number' &&
    arrayValueOperators.includes(operator)
  )
    return 'numberArray';
  else if (fieldType.type === 'stringList') return 'stringArray';
  else if (
    fieldType.type === 'string' &&
    singleValueOperator.includes(operator)
  )
    return 'string';
  else if (
    fieldType.type === 'string' &&
    arrayValueOperators.includes(operator)
  )
    return 'stringArray';
  return 'string';
};

export const transformRuleStatementsToSave = (statements) => {
  const maxStatementIndex = statements.length - 1;
  const transformedStatements = statements.map((statement, ind) => {
    return {
      [statement.expressionField]: {
        fieldType: statement.fieldType,
        connector:
          ind === maxStatementIndex ? '' : statements[ind + 1].connector,
        expression: {
          [statement.expression]: statement.expressionValue,
        },
      },
    };
  });
  return transformedStatements;
};

export const getNewRuleTemplate = () => {
  return {
    id: null,
    title: '',
    active: true,
    statements: [
      {
        expressionField: '',
        expression: '',
        expressionValue: '',
        connector: '',
        config: { field: 'stringInput', placeHolder: 'Value' },
      },
    ],
    action: '',
  };
};
