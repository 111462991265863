import { useEffect, useState } from 'react';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/solid';
import classNames from 'classnames';
import { Dropdown } from './Dropdown';

interface PaginationProps {
  totalPages: number;
  currentPage: number;
  resultsRange: number;
  totalResults: number;
  isFilterApplied?: boolean;
  onPageClick: (page: number) => void;
}

/* Allow user to increase page limit in future  */
const limitOptions = ['20', '50', '100'];

export const Pagination = ({
  totalPages,
  currentPage,
  resultsRange,
  totalResults,
  isFilterApplied = false,
  onPageClick,
}: PaginationProps) => {
  const [{ minResult, maxResult }, setMinMaxResult] = useState({
    minResult: 1,
    maxResult: resultsRange * currentPage,
  });
  // As the currentPage and resultsRange changes, the min/max results changes too
  useEffect(() => {
    setMinMaxResult({
      minResult: resultsRange * currentPage - resultsRange + 1,
      maxResult: resultsRange * currentPage,
    });
  }, [resultsRange, currentPage]);

  const handlePageNumberChange = (pageNumber: string) => {
    let pageNumberToSet = 1;
    if (pageNumber === '') {
      onPageClick(1);
      return;
    }
    pageNumberToSet = Number(pageNumber);
    if (pageNumberToSet <= 0) {
      onPageClick(1);
      return;
    }
    if (pageNumberToSet > totalPages) {
      onPageClick(totalPages);
      return;
    }
    onPageClick(pageNumberToSet);
  };

  const handlePageLimitUpdate = (pageLimit: string) => {
    return;
  };

  const disabledPageButtonStyle = 'opacity-50 cursor-not-allowed';

  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
      <div className="flex-1 flex justify-between sm:hidden">
        <button
          className={classNames(
            'relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50',
            currentPage <= 1 && disabledPageButtonStyle,
          )}
          onClick={() => currentPage > 1 && onPageClick(currentPage - 1)}
        >
          Previous
        </button>
        <button
          className={classNames(
            'ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50',
            currentPage >= totalPages && disabledPageButtonStyle,
          )}
          onClick={() =>
            currentPage < totalPages && onPageClick(currentPage + 1)
          }
        >
          Next
        </button>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{minResult}</span> to{' '}
            <span className="font-medium">
              {isFilterApplied ? totalResults : maxResult}
            </span>{' '}
            of <span className="font-medium">{totalResults}</span> results
          </p>
        </div>
        <div>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <button
              className={classNames(
                'relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50',
              )}
              onClick={() => onPageClick(1)}
            >
              <span className="sr-only">First Page</span>
              <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              className={classNames(
                'relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50',
                currentPage <= 1 && disabledPageButtonStyle,
              )}
              onClick={() => currentPage > 1 && onPageClick(currentPage - 1)}
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}

            {/* TODO: we need a way to handle pages in excess of 10. Not a problem for now  */}
            <div>
              <p className="text-sm text-gray-700 px-4 pt-2">Page</p>
            </div>
            <input
              type="number"
              name="amount"
              className="border border-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md p-2 w-14 opacity-1"
              placeholder={''}
              onChange={(e) => handlePageNumberChange(e.target.value)}
              max={totalPages}
              min={1}
              value={currentPage}
            />
            <div>
              <p className="text-sm text-gray-700 px-4 pt-2">of {totalPages}</p>
            </div>
            {/* {Array.from(new Array(totalPages)).map((_, index) => {
              const page = index + 1;

              return (
                <button
                  aria-current="page"
                  key={page}
                  onClick={() => onPageClick(page)}
                  className={
                    page === currentPage
                      ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium'
                      : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium'
                  }
                >
                  {page}
                </button>
              );
            })} */}
            <button
              className={classNames(
                'relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50',
                currentPage >= totalPages && disabledPageButtonStyle,
              )}
              onClick={() =>
                currentPage < totalPages && onPageClick(currentPage + 1)
              }
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              className={classNames(
                'relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50',
              )}
              onClick={() => onPageClick(totalPages)}
            >
              <span className="sr-only">Last Page</span>
              <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {!isFilterApplied ? (
              <>
                <div className={classNames('pl-4')}>
                  <Dropdown
                    options={limitOptions}
                    onSelectOption={(option) => handlePageLimitUpdate(option)}
                    selectedOption={`${resultsRange}`}
                    btnClassName="py-2 px-3 border-gray-300 border"
                    placeholder="Select Page Limit"
                    downCaret
                    disabled={true}
                  />
                </div>
                <div>
                  <p className="text-sm text-gray-700 px-4 pt-2">per page</p>
                </div>
              </>
            ) : null}
          </nav>
        </div>
      </div>
    </div>
  );
};
