/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  ArrowCircleRightIcon,
  DocumentDuplicateIcon,
} from '@heroicons/react/outline';

import { Text } from 'components/Text';
import { withNotification } from 'components/Notification';
import { Button } from 'components/Button';
import { CustomDomainModal } from 'components/CustomDomainModal';
import { NotificationTypes, apiErrorHandler, SizeEnum } from 'utils';
import { GET_STORE_TRACKING_PAGE_SETTINGS } from 'gql/queries';
import { UPDATE_STORE_TRACKING_PAGE_SETTINGS } from 'gql/mutations';

import { useStore } from 'context/store-context';

export const CustomSubDomain = withNotification(({ showNotification }: any) => {
  const { storeId } = useStore();

  const [isCopied, setIsCopied] = useState(false);
  const [showCustomDomainModal, setShowCustomDomainModal] =
    useState<boolean>(false);
  const [customDomain, setCustomDomain] = useState<string | null>(null);

  const [getCustomerTrackingPageSettings] = useLazyQuery(
    GET_STORE_TRACKING_PAGE_SETTINGS,
    {
      onError(error) {
        const newError = apiErrorHandler(error);
        showNotification(NotificationTypes.error, newError?.message);
      },
      onCompleted(data) {
        if (data.storesSettings.length) {
          setCustomDomain(data.storesSettings[0].customDomain);
        }
      },
    },
  );

  const [saveCustomDomain] = useMutation(UPDATE_STORE_TRACKING_PAGE_SETTINGS, {
    onError(error) {
      const newError = apiErrorHandler(error);
      showNotification(NotificationTypes.error, newError?.message);
    },
    onCompleted(data) {
      showNotification(
        NotificationTypes.success,
        'Your custom domain is updated successfully!',
      );
      setCustomDomain(data?.saveStoresSettings?.customDomain ?? '');
      setShowCustomDomainModal(false);
    },
  });

  useEffect(() => {
    const getTrackingPageSettings = async () => {
      try {
        if (storeId) {
          getCustomerTrackingPageSettings({
            fetchPolicy: 'cache-and-network',
            variables: {
              storeId,
            },
          });
        }
      } catch (e) {
        console.log('error', e);
      }
    };
    getTrackingPageSettings();
  }, []);

  const handleSaveCustomDomain = () => {
    saveCustomDomain({
      variables: {
        input: {
          storeId,
          customDomain,
        },
      },
    });
  };

  const handleDeleteCustomDomain = () => {
    saveCustomDomain({
      variables: {
        input: {
          storeId,
          customDomain: null,
        },
      },
    });
  };

  const handleCopy = () => {
    if (!navigator?.clipboard) return;

    navigator.clipboard.writeText(`https://${customDomain}.shipaid.com`);

    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 5000);
  };

  return (
    <div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-6">
        <div>
          <Text value="Custom Subdomain" type="h1" />
        </div>
        <div>
          <Text
            value="Make your tracking page easy to find by adding your Custom Subdomain"
            type="body"
            className="mt-8 text-gray-500"
          />
        </div>
        {!customDomain ? (
          <Text
            value={'No custom domain configured'}
            type="body"
            className="mt-8 text-gray-500"
          />
        ) : (
          <div>
            <Text
              value="You can copy the tracking page link and add it to your store's website"
              type="body"
              className="mt-5 text-gray-500"
            />
            <div className="flex">
              <a
                href={`https://${customDomain}.shipaid.com`}
                target="_blank"
                rel="noreferrer"
              >
                <Text
                  value={`${customDomain}.shipaid.com`}
                  type="body"
                  className="text-blue-500 underline mt-8"
                />
              </a>
              <ArrowCircleRightIcon
                className="hover:opacity-70 text-blue-500 mt-8 ml-3"
                width={30}
                height={30}
                onClick={() =>
                  window.open(`https://${customDomain}.shipaid.com`, '_blank')
                }
              />
              <DocumentDuplicateIcon
                className={`hover:opacity-70  mt-8 ml-20 ${
                  isCopied ? 'text-blue-500' : 'text-grey-500'
                }`}
                width={30}
                height={30}
                onClick={handleCopy}
              />
            </div>
          </div>
        )}
        <div className="mt-8">
          <Button
            onClick={() => setShowCustomDomainModal(true)}
            className="bg-blue-600 hover:bg-blue-700"
            size={SizeEnum.lg}
          >
            Update Custom Subdomain
          </Button>
        </div>
      </div>
      <CustomDomainModal
        show={showCustomDomainModal}
        setShowModal={(val) => setShowCustomDomainModal(val)}
        customDomain={customDomain}
        setCustomDomain={setCustomDomain}
        handleSave={handleSaveCustomDomain}
        handleDelete={handleDeleteCustomDomain}
      />
    </div>
  );
});
