import { Announcement } from './announcement';

export interface User {
  avatarUrl: string;
  createdAt: string;
  defaultRole: string;
  displayName: string;
  email?: string;
  id: string;
  isAnonymous: boolean;
  locale: string;
  metadata: any;
  roles: string[];
}

export interface UserContextInterface {
  user?: User;
  role?: userRoles;
  resetPassword?: {
    resetPassword: (email: string) => Promise<void>;
    error: any;
    isError: boolean;
    isLoading: boolean;
    isSent: boolean;
  };
  changeEmail?: {
    changeEmail: (email: string) => Promise<void>;
    error: any;
    isError: boolean;
    isLoading: boolean;
    needsEmailVerification: boolean;
  };
  changePassword?: {
    changePassword: (email: string) => Promise<void>;
    error: any;
    isError: boolean;
    isLoading: boolean;
    isSent: boolean;
  };

  setUser?: (user: User) => void;
  announcement?: Announcement | null;
}

export enum userRoles {
  MANAGER = 'MANAGER',
  VIEWER = 'VIEWER',
  ADMIN = 'ADMIN',
  SHIPAID_ADMIN = 'shipaid_admin',
}
