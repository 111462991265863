import React, { useState } from 'react';
import { Text } from 'components/Text';
import { OrderItem } from 'components/OrderItem';
import { QuestionMarkCircleIcon } from '@heroicons/react/solid';
import {
  calculatePriceSum,
  getStoreCurrencyFormatter,
  getStoreCurrencySymbol,
} from 'utils';
import { Button } from 'components/Button';
import { Claim, ClaimItemValues } from 'types/claim';
import { useStore } from 'context/store-context';
import classNames from 'classnames';
import { Order } from 'types/order';

type OtherProps = {
  claimItems: ClaimItemValues[];
  handleRemoveItem?: (order: ClaimItemValues) => void;
  handleOtherItems?: (input: any) => void;
  loading?: boolean;
  claim?: Claim;
  handleQuantityUpdate: (value: string, item: ClaimItemValues) => void;
  staticClaimItems: ClaimItemValues[];
  order?: Order | null;
};

export const Other = ({
  claimItems,
  handleOtherItems,
  loading,
  handleRemoveItem,
  claim,
  handleQuantityUpdate,
  staticClaimItems,
  order,
}: OtherProps) => {
  const { storeProperties } = useStore();
  const [input, setInput] = useState({
    note: '',
    notify: storeProperties?.storeClaimsSettings?.notifyCustomerChecked ?? true,
    amount: '',
  });

  const handleResolve = () => {
    const refundInput =
      input.amount !== '' ? input : { note: input.note, notify: input.notify };
    handleOtherItems && handleOtherItems(refundInput);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string,
  ) => {
    const { value } = e.target;

    setInput({ ...input, [field]: field === 'amount' ? Number(value) : value });
  };

  const handleCheck = (field: string) => {
    setInput({ ...input, [field]: !input.notify });
  };

  const getSubtotal = (claimItems: ClaimItemValues[]) =>
    claimItems && claimItems[0].orderItem
      ? `$${calculatePriceSum(
          claimItems?.map((item) => {
            return { ...item, ...item.orderItem };
          }),
          'total',
        )}`
      : claimItems?.length
      ? `$${calculatePriceSum(claimItems, 'total')}`
      : '';

  const getOrderDiscount = (
    claim: Claim | undefined,
    claimItems: ClaimItemValues[],
  ) => {
    if (!(claimItems && claimItems.length)) return 0;

    const lineItemsTotalDiscount =
      claimItems && claimItems[0].orderItem
        ? Number(
            calculatePriceSum(
              claimItems?.map((item) => {
                return { ...item, ...item.orderItem };
              }),
              'discountTotal',
            ),
          )
        : 0;
    const additionalOrderDiscount = Number(
      claim?.order?.discountTotal ?? order?.discountTotal ?? 0,
    );
    const orderDiscount = Number(
      Number(additionalOrderDiscount) - Number(lineItemsTotalDiscount),
    ).toFixed(2);
    return Number(orderDiscount);
  };

  const getOrderTotal = (claimItems: ClaimItemValues[]) => {
    const orderItemsTotal =
      claimItems && claimItems?.length > 0 && claimItems[0].orderItem
        ? calculatePriceSum(
            claimItems?.map((item) => {
              const quantity = item.quantity ?? 1;
              const total = item.orderItem.price ?? 0;
              const itemTotal = Number(
                quantity * total -
                  (quantity > 0
                    ? Number(
                        quantity *
                          Number(
                            (item?.orderItem?.discountTotal ?? 0) /
                              item?.orderItem.quantity,
                          ),
                      )
                    : 0),
              );
              return { ...item, ...item.orderItem, total: itemTotal };
            }),
            'total',
          )
        : claimItems?.length
        ? calculatePriceSum(claimItems, 'total')
        : 0;
    // const orderTotal = Number(
    //   Number(orderItemsTotal) - Number(getOrderDiscount(claim, claimItems)),
    // ).toFixed(2);
    return Number(orderItemsTotal);
  };

  const getTotal = (claimItems: ClaimItemValues[]) => {
    const orderTotal = Number(getOrderTotal(claimItems));
    return orderTotal !== 0 ? `$${orderTotal}` : '';
  };

  return (
    <div className="block sm:flex sm:gap-4 mt-4">
      <div className="sm:w-2/3 border rounded p-5">
        <div className="flex justify-between px-3 py-2 bg-gray-50 rounded-md">
          <Text value="Other" type="bold" className="" />
          <QuestionMarkCircleIcon className="h-5 w-5" />
        </div>
        <div className="h-72 overflow-y-auto mt-3">
          {claimItems.length > 0 ? (
            claimItems.map((claimItem, index) => (
              <OrderItem
                key={claimItem.id}
                order={claimItem.orderItem}
                onRemoveItem={() =>
                  handleRemoveItem && handleRemoveItem(claimItem)
                }
                showIcon={false}
                onHandleQuantityUpdate={(value) =>
                  handleQuantityUpdate(value, claimItem)
                }
                staticClaimItems={staticClaimItems} //claimItem that does not change when the quantity is updated
                claimItem={claimItem}
                index={index}
                isDisplay
              />
            ))
          ) : (
            <Text value="You need items" />
          )}
        </div>
        <div className="mt-6">
          <Text
            value="Resolution Cost (required)*"
            type="bold"
            className={classNames(
              'mb-4',
              !input.amount ? 'text-red-500' : 'text-gray-500',
            )}
          />

          <div className="relative">
            <span className="absolute left-2 top-[6px]">
              {getStoreCurrencySymbol(storeProperties?.currency).charAt(0)}
            </span>
            <input
              type="number"
              name="amount"
              className="border border-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md p-2 pl-5"
              placeholder={`e.g ${claimItems[0]?.orderItem?.price ?? '10.00'}`}
              onChange={(e) => handleChange(e, 'amount')}
            />
          </div>

          <Text
            value="Notes (for internal use)"
            type="bold"
            className="text-gray-500 mt-4"
          />
          <textarea
            rows={4}
            className="border border-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 w-full sm:text-sm border-gray-300 rounded-md mr-3 mt-3 p-2"
            placeholder="Notes"
            onChange={(e) => handleChange(e, 'note')}
          />
          <div className="mt-5 flex items-center">
            <input
              name="notify"
              type="checkbox"
              className="focus:ring-indigo-500 h-3 w-3 text-indigo-600 border-gray-300 rounded mr-1"
              onChange={(e) => handleCheck('notify')}
              checked={input.notify}
            />
            <Text
              value="Send a notification to customers"
              className="text-gray-500"
              type="sm"
            />
          </div>
        </div>
        <div className="mt-4 flex justify-end">
          <Button
            disabled={!input.amount}
            onClick={handleResolve}
            isLoading={loading}
          >
            Resolve
          </Button>
        </div>
      </div>
      <div className="sm:w-1/3 border rounded p-5 mt-3 sm:mt-0 h-max">
        <Text
          value="Summary"
          type="bold"
          className="px-3 py-2 bg-gray-50 rounded-md"
        />
        <div className="flex justify-between py-3">
          <Text value="Items" type="sm" className="text-gray-600 font-bold" />
          <Text
            value={`${claimItems?.length} items`}
            className="text-blue-500"
          />
        </div>
        <div className="flex justify-between py-3">
          <Text
            value="Sub Total"
            type="sm"
            className="text-gray-600 font-bold"
          />
          <Text
            value={
              input.amount
                ? getStoreCurrencyFormatter(
                    storeProperties?.currency,
                    input.amount,
                  )
                : getSubtotal(claimItems)
            }
            className="text-gray-500"
          />
        </div>
        <div className="flex justify-between py-3">
          <Text
            value="Discount"
            type="sm"
            className="text-gray-600 font-bold"
          />
          <Text
            value={`$${getOrderDiscount(claim, claimItems ?? [])}`}
            className="text-gray-500"
          />
        </div>
        <div className="flex justify-between py-3 mt-1">
          <Text
            value="Resolution Cost"
            type="sm"
            className="text-gray-600 font-bold"
          />
          <Text
            value={
              input.amount
                ? getStoreCurrencyFormatter(
                    storeProperties?.currency,
                    input.amount,
                  )
                : getTotal(claimItems)
            }
            type="sm"
            className="text-gray-500"
          />
        </div>
        {claim?.notes && (
          <div className=" mt-4 pb-4">
            <Text
              value="Notes"
              type="h4"
              className="px-3 py-2 bg-gray-50 rounded-md"
            />
            <Text value={claim?.notes} className="text-gray-500 mt-4" />
          </div>
        )}
      </div>
    </div>
  );
};
