import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Text } from 'components/Text';
import { SizeEnum } from 'utils';
import { Button } from 'components/Button';
import { useMutation } from '@apollo/client';
import { useUser } from 'context/user-context';
import { AccountImageUpload } from 'components/AccountImageUpload';
import { UPDATE_USER_INFO } from 'gql/mutations';
import { Modal } from 'components/Modal';
import { NotificationTypes, apiErrorHandler } from 'utils';
import { withNotification } from 'components/Notification';
import { useSignOut } from '@nhost/react';

export const Account = withNotification(({ showNotification }: any) => {
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);

  const showResetPassoword = searchParams.get('password');

  const { user, changeEmail, changePassword } = useUser();

  const [userInfo, setUserInfo] = useState({
    name: user?.displayName || '',
    email: user?.email || '',
  });

  useEffect(() => {
    if (showResetPassoword) setChangePaswordModal(true);
  }, [showResetPassoword]);

  const [userPassword, setUserPassword] = useState({
    newPassword: '',
    confirmPassword: '',
  });

  const [showModal, setShowModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [changePaswordModal, setChangePaswordModal] = useState(false);
  const [updateAvatar, { error, loading: avatarLoading }] = useMutation(
    UPDATE_USER_INFO,
    {
      onError(error) {
        const newError = apiErrorHandler(error);
        showNotification(NotificationTypes.error, newError?.message);
      },
    },
  );
  const [updateName, { error: nameError, loading: nameLoading }] = useMutation(
    UPDATE_USER_INFO,
    {
      onError(error) {
        const newError = apiErrorHandler(error);
        showNotification(NotificationTypes.error, newError?.message);
      },
    },
  );
  const { signOut } = useSignOut(true);

  const handleUserAvatarUpdate = (imgUrl: string) => {
    updateAvatar({
      variables: { id: user?.id, userObj: { avatarUrl: imgUrl } },
    })
      .then((data) => {
        setShowModal(false);
        return showNotification(
          NotificationTypes.success,
          'User profile photo updated',
        );
      })
      .catch((err) => {
        return showNotification(
          NotificationTypes.error,
          error ? error.message : 'Could not update profile photo',
        );
      });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  const handlePasswordFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value, name } = event.target;
    setUserPassword({ ...userPassword, [name]: value });
  };

  const handleUpdate = () => {
    updateName({
      variables: { id: user?.id, userObj: { displayName: userInfo.name } },
    })
      .then((data) => {
        setShowModal(false);
        return showNotification(NotificationTypes.success, 'User data updated');
      })
      .catch((err) => {
        return showNotification(
          NotificationTypes.error,
          nameError ? nameError.message : 'Could not update name',
        );
      });
  };

  const handleUpdateEmail = () => {
    changeEmail
      ?.changeEmail(userInfo.email)
      .then((data) => {
        setShowEmailModal(false);
        return showNotification(
          NotificationTypes.success,
          'Email verification email sent',
        );
      })
      .catch((err) => {
        setShowEmailModal(false);
        return showNotification(
          NotificationTypes.success,
          'Email address reset failed',
        );
      });
  };

  const handleChangePassword = () => {
    if (userPassword.newPassword !== userPassword.confirmPassword) {
      setUserPassword({
        newPassword: '',
        confirmPassword: '',
      });
      return showNotification(
        NotificationTypes.pending,
        'Passwords dont match! re-enter passwords',
      );
    }

    changePassword
      ?.changePassword(userPassword.newPassword)
      .then((data) => {
        setUserPassword({
          newPassword: '',
          confirmPassword: '',
        });

        searchParams.delete('password');
        navigate('/settings/account');

        setChangePaswordModal(false);
        return showNotification(
          NotificationTypes.success,
          'Password changed successfully',
        );
      })
      .catch((err) => {
        return showNotification(
          NotificationTypes.error,
          'Password reset failed',
        );
      });
  };

  return (
    <div>
      <Modal
        openModal={(value) => setShowModal(value)}
        open={showModal}
        containerClass="sm:max-w-lg w-full sm:w-auto p-5"
      >
        <Text value="Update name" type="h2" />
        <div className="mt-4">
          <input
            type="name"
            name="name"
            id="name"
            className="border p-2 sm:w-64 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="enter user's name e.g. Isaac John"
            value={userInfo.name}
            onChange={handleChange}
          />
        </div>
        <Button
          onClick={handleUpdate}
          size={SizeEnum.md}
          className="bg-blue-600 text-white hover:bg-blue-700 h-max px-6 mt-4 sm:w-64 w-full justify-center"
          isLoading={nameLoading}
        >
          Update name
        </Button>
      </Modal>
      <Modal
        openModal={(value) => setShowEmailModal(value)}
        open={showEmailModal}
        containerClass="sm:max-w-lg w-full sm:w-auto p-5"
      >
        <Text value="Update Email" type="h2" />
        <div className="mt-4">
          <input
            type="email"
            name="email"
            id="email"
            className="border p-2 sm:w-64 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="enter user's email e.g.you@example.com"
            value={userInfo.email}
            onChange={handleChange}
          />
        </div>
        <Button
          onClick={() => handleUpdateEmail()}
          size={SizeEnum.md}
          className="bg-blue-600 text-white hover:bg-blue-700 h-max px-6 mt-4 sm:w-64 w-full justify-center"
          isLoading={changeEmail?.isLoading}
        >
          Update Email
        </Button>
      </Modal>
      <Modal
        openModal={(value) => setChangePaswordModal(value)}
        open={changePaswordModal}
        containerClass="sm:max-w-lg w-full sm:w-auto p-5"
      >
        {/* New Password */}
        <Text value="New Password" type="h4" />
        <div className="mt-4 mb-4">
          <input
            type="password"
            name="newPassword"
            id="newPassword"
            className="border p-2 sm:w-64 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="Enter your new password"
            value={userPassword.newPassword}
            onChange={handlePasswordFieldChange}
          />
        </div>

        {/* Confirm Password */}
        <Text value="Confirm Password" type="h4" />
        <div className="mt-4">
          <input
            type="password"
            name="confirmPassword"
            id="confirmPassword"
            className="border p-2 sm:w-64 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="Confirm your new password"
            value={userPassword.confirmPassword}
            onChange={handlePasswordFieldChange}
          />
        </div>

        <Button
          onClick={() => handleChangePassword()}
          size={SizeEnum.md}
          className="bg-blue-600 text-white hover:bg-blue-700 h-max px-6 mt-4 sm:w-64 w-full justify-center"
          isLoading={changeEmail?.isLoading}
        >
          Update Password
        </Button>
      </Modal>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <Text value="Account" type="h1" />
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-3">
        <AccountImageUpload
          onUpload={({ urls }) => handleUserAvatarUpdate(urls[0])}
          defaultImage={user?.avatarUrl}
          isLoading={avatarLoading}
        />
        <div className=" border-b" />
        <div className="flex w-full sm:w-1/2 justify-between py-4 mt-3">
          <div>
            <Text
              value="Name"
              type="body"
              className="text-gray-600 font-bold"
            />
            <Text
              value={userInfo.name || ''}
              type="sm"
              className="text-gray-500"
            />
          </div>
          <Button
            onClick={() => setShowModal(true)}
            size={SizeEnum.md}
            className="border-blue-600 bg-transparent text-blue-600 hover:bg-blue-50 h-max px-6"
          >
            Edit
          </Button>
        </div>
        <div className="flex w-full sm:w-1/2 justify-between py-4 mb-3">
          <div>
            <Text
              value="Email address"
              type="body"
              className="text-gray-600 font-bold"
            />
            <Text
              value={userInfo?.email || ''}
              type="sm"
              className="text-gray-500"
            />
          </div>
          <Button
            onClick={() => setShowEmailModal(true)}
            size={SizeEnum.md}
            className="border-blue-600 bg-transparent text-blue-600 hover:bg-blue-50 h-max px-6"
          >
            Edit
          </Button>
        </div>
        <div className=" border-b" />
        <div className="flex w-full sm:w-1/2 justify-between py-4 mt-3">
          <div>
            <Text value="Login" type="h4" className="text-gray-600" />
            <Text
              value="Password"
              type="body"
              className="text-gray-600 font-bold"
            />
            <Text
              value="Password last updated: November 17"
              type="sm"
              className="text-gray-500"
            />
          </div>
          <Button
            onClick={() => setChangePaswordModal(true)}
            size={SizeEnum.md}
            className="border-blue-600 bg-transparent text-blue-600 hover:bg-blue-50 h-max px-6"
            isLoading={changePassword?.isLoading}
          >
            Change Password
          </Button>
        </div>
        <div className="flex w-full sm:w-1/2 justify-between py-4">
          <div>
            <Text value="Security" type="h4" className="text-gray-600" />
            <Text
              value="Two-factor authentication"
              type="body"
              className="text-gray-600 font-bold"
            />
            <Text
              value="After entering your password, you will be asked for a secnond verification steff"
              type="sm"
              className="text-gray-500"
            />
          </div>
          <Button
            onClick={() => null}
            size={SizeEnum.md}
            className="border-blue-600 bg-transparent text-blue-600 hover:bg-blue-50 h-max px-6"
          >
            Enable
          </Button>
        </div>
        <div className=" w-full sm:w-1/2 justify-between py-4">
          <div>
            <Text
              value="Sign out from all devices"
              type="body"
              className="text-gray-600 font-bold"
            />
            <Text
              value="Logged in on a shared device but forgot to sign out? End all sessions by signing out from all devices"
              type="sm"
              className="text-gray-500"
            />
          </div>
          <Button
            onClick={() => signOut(true)}
            size={SizeEnum.md}
            className="border-blue-600 bg-transparent text-blue-600 hover:bg-blue-50 h-max px-6 mt-3"
          >
            Sign out from all your devices
          </Button>
        </div>
        <div className=" w-full sm:w-1/2 justify-between py-4">
          <div>
            <Text
              value="Delete your account"
              type="body"
              className="text-gray-600 font-bold"
            />
            <Text
              value="By deleting your account, you will no longer be able to access any of your orders or claims"
              type="sm"
              className="text-gray-500"
            />
          </div>
          <Button
            onClick={() => null}
            size={SizeEnum.md}
            className="bg-red-500 text-white hover:bg-red-700 h-max px-6 mt-3"
          >
            Delete your account
          </Button>
        </div>
      </div>
    </div>
  );
});
