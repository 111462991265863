import React from 'react';
import { SearchIcon } from '@heroicons/react/solid';

type SearchBoxProps = {
  searchValue: string;
  onChange: (value: string) => void;
  placeholder?: string;
  search: () => void;
};

export const SearchBox = ({
  searchValue,
  onChange,
  search,
  placeholder,
}: SearchBoxProps) => {
  const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const { value } = event.target;
    onChange(value);
  };

  const hasValue = searchValue !== '';

  return (
    <div className="relative w-full text-gray-400 focus-within:text-gray-600 rounded-md border flex p-0.5">
      <div className="inset-y-0 flex items-center pointer-events-none pl-2">
        <SearchIcon className="h-5 w-5" aria-hidden="true" />
      </div>
      <input
        className="block w-full pl-4 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-base"
        placeholder={placeholder ? placeholder : 'Search'}
        type="search"
        name="search"
        value={searchValue}
        onChange={(e) => onChangeValue(e)}
        onKeyDown={(e) => e.key === 'Enter' && search()}
        onBlur={(e) => hasValue && search()}
        autoComplete="off"
      />
    </div>
  );
};
