/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { MailIcon } from '@heroicons/react/outline';

import { Text } from 'components/Text';
import { withNotification } from 'components/Notification';
import { Button } from 'components/Button';
import { BrandEmailModal } from 'components/BrandEmaiModal';
import { NotificationTypes, apiErrorHandler, SizeEnum } from 'utils';
import { GET_CUSTOMER_NOTIFICATON_SETTINGS } from 'gql/queries';
import {
  VERIFY_EMAIL_DOMAIN,
  UPDATE_CUSTOMER_NOTIFICATION_SETTINGS,
  RESET_CUSTOMER_NOTIFICATION_SETTINGS,
} from 'gql/mutations';

import { useStore } from 'context/store-context';
import { useUser } from 'context/user-context';

export const SenderEmail = withNotification(({ showNotification }: any) => {
  const { storeId } = useStore();
  const { user } = useUser();

  const [showBrandEmailModal, setShowBrandEmailModal] =
    useState<boolean>(false);
  const [brandEmailInput, setBrandEmailInput] = useState({
    id: null,
    senderName: '',
    senderEmail: user?.email ?? '',
    replyToName: '',
    replyToEmail: user?.email ?? '',
  });
  const [brandEmailVerification, setBrandEmailVerification] = useState<any>({});

  const [getCustomerNoitificationSettings] = useLazyQuery(
    GET_CUSTOMER_NOTIFICATON_SETTINGS,
    {
      onError(error) {
        const newError = apiErrorHandler(error);
        showNotification(NotificationTypes.error, newError?.message);
      },
      onCompleted(data) {
        if (data.customerNotificationsSettings.length) {
          setBrandEmailInput(data.customerNotificationsSettings[0]);
        }
      },
    },
  );

  const [verifyEmailDomain] = useMutation(VERIFY_EMAIL_DOMAIN, {
    onError(error) {
      const newError = apiErrorHandler(error);
      showNotification(NotificationTypes.error, newError?.message);
    },
    onCompleted(data) {
      setBrandEmailVerification(data?.verifyEmailDomain);
    },
  });

  const [saveBrandEmails] = useMutation(UPDATE_CUSTOMER_NOTIFICATION_SETTINGS, {
    onError(error) {
      const newError = apiErrorHandler(error);
      showNotification(NotificationTypes.error, newError?.message);
    },
    onCompleted(data) {
      showNotification(
        NotificationTypes.success,
        'Your brand email is saved successfully!',
      );
      setBrandEmailInput(data?.saveCustomerNotificationsSettings);
      setShowBrandEmailModal(false);
      setBrandEmailVerification({});
    },
  });

  const [deleteBrandEmails] = useMutation(
    RESET_CUSTOMER_NOTIFICATION_SETTINGS,
    {
      onError(error) {
        const newError = apiErrorHandler(error);
        showNotification(NotificationTypes.error, newError?.message);
      },
      onCompleted(data) {
        showNotification(
          NotificationTypes.success,
          'Your brand email is removed successfully!',
        );
        setBrandEmailInput({
          id: null,
          senderName: '',
          senderEmail: user?.email ?? '',
          replyToName: '',
          replyToEmail: user?.email ?? '',
        });
        setShowBrandEmailModal(false);
      },
    },
  );

  useEffect(() => {
    const getNotificationSettings = async () => {
      try {
        if (storeId) {
          getCustomerNoitificationSettings({
            variables: {
              storeId,
            },
          });
        }
      } catch (e) {
        console.log('error', e);
      }
    };
    getNotificationSettings();
  }, []);

  const handleChangeBrandEmail = (field: string, value: string) => {
    setBrandEmailInput({ ...brandEmailInput, [field]: value });
  };

  const handleVerifyEmails = () => {
    verifyEmailDomain({
      variables: {
        input: {
          storeId,
          senderName: brandEmailInput.senderName,
          senderEmail: brandEmailInput.senderEmail,
          replyToName: brandEmailInput.replyToName,
          replyToEmail: brandEmailInput.replyToEmail,
        },
      },
    });
  };

  const handleSaveBrandEmails = () => {
    saveBrandEmails({
      variables: {
        input: {
          storeId,
          senderName: brandEmailInput.senderName,
          senderEmail: brandEmailInput.senderEmail,
          replyToName: brandEmailInput.replyToName,
          replyToEmail: brandEmailInput.replyToEmail,
        },
      },
    });
  };

  const handleDeleteBrandEmails = () => {
    deleteBrandEmails({
      variables: {
        input: {
          storeId,
        },
      },
    });
  };

  return (
    <div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-6">
        <div>
          <Text value="Sender Email" type="h1" />
        </div>
        <div>
          <Text
            value="Notification emails can be sent from this email address"
            type="body"
            className="mt-8 text-gray-500"
          />
        </div>
        <div>
          <MailIcon
            className="absolute opacity-50 hover:opacity-70"
            width={30}
            height={30}
          />
          <Text
            value={
              brandEmailInput.id
                ? brandEmailInput.senderEmail
                : 'No sender email configured'
            }
            type="body"
            className="mt-8 text-gray-500 ml-10"
          />
        </div>
        <div className="mt-12">
          <Button
            onClick={() => setShowBrandEmailModal(true)}
            className="bg-blue-600 hover:bg-blue-700"
            size={SizeEnum.lg}
          >
            Update Sender Email
          </Button>
        </div>
      </div>
      <BrandEmailModal
        show={showBrandEmailModal}
        setShowModal={(val) => setShowBrandEmailModal(val)}
        brandEmailInput={brandEmailInput}
        brandEmailVerification={brandEmailVerification}
        setInput={(field, value) => handleChangeBrandEmail(field, value)}
        onVerifyEmails={handleVerifyEmails}
        handleSave={handleSaveBrandEmails}
        handleDelete={handleDeleteBrandEmails}
      />
    </div>
  );
});
