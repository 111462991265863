import { useStore } from 'context/store-context';
import { Text } from 'components/Text';
import { Button } from '../Button';
import { SET_STORE_PLAN } from 'gql/mutations';
import { useMutation } from '@apollo/client';

const ContactSupport = () => {
  return (
    <div className="mt-6 text-center">
      <Text
        value="Need help?"
        type="body"
        className="text-gray-700 font-semibold mb-2"
      />
      <Text
        value="If you're experiencing issues, please contact our support team."
        type="body"
        className="text-gray-600 mb-4"
      />
      <a
        href="mailto:support@shipaid.com"
        className="text-blue-600 hover:text-blue-800 text-sm font-medium"
      >
        Contact Support
      </a>
    </div>
  );
};

export const MerchantBilling = ({
  waitingPayment,
}: {
  waitingPayment: boolean;
}) => {
  const { storeProperties, storeId } = useStore();

  const [setStorePlan, { loading: setStorePlanLoading }] = useMutation(
    SET_STORE_PLAN,
    {
      onCompleted: (data) => {
        const {
          setStorePlan: { chargeUrl },
        } = data;
        // redirect user to the chargeUrl link
        window.location.href = chargeUrl;
      },
      onError: (error) => {},
    },
  );

  const handleChargeReset = () => {
    setStorePlan({
      variables: {
        storeId,
      },
    });
  };

  const noPlan = !storeProperties?.plan?.chargeUrl;

  if (waitingPayment) {
    return (
      <div className="flex flex-col items-center bg-white bg-opacity-100 p-4 flex flex-col items-center">
        <Text
          value="Waiting for billing authorization..."
          type="body"
          className="text-gray-600 mb-2"
        />
        <div>
          <span className="text-gray-600 mb-2">
            If not, click the refresh button.{' '}
          </span>
          <button
            onClick={() => {
              window.location.reload();
            }}
            className="text-blue-600 hover:text-blue-800 text-sm font-medium cursor-pointer bg-transparent border-none p-0"
          >
            Refresh
          </button>
        </div>

        <ContactSupport />
      </div>
    );
  }

  return (
    <div className="bg-white bg-opacity-100">
      <div className="sm:max-w-lg sm:w-full p-6 relative text-center">
        {noPlan ? (
          <Text value="Reset Store Billing (required)" type="h4" />
        ) : (
          <Text value="Authorize Shipaid Billing (required)" type="h4" />
        )}
        <div className="text-gray-500">
          <Text
            className="my-4 text-center"
            value={
              noPlan
                ? `Click the link below to reset and authorize the Shipaid billing on Shopify. You will be redirected once the billing has been authorized`
                : `Click the link below to authorize the Shipaid billing on Shopify. You will be redirected once the billing has been authorized`
            }
          />

          {noPlan ? (
            <Button
              onClick={handleChargeReset}
              className="bg-blue-600 hover:bg-blue-700 w-full justify-center"
              disabled={setStorePlanLoading}
            >
              Reset Billing
            </Button>
          ) : (
            <a rel="noreferrer" href={storeProperties?.plan?.chargeUrl}>
              <Button
                onClick={() => {}}
                className="bg-blue-600 hover:bg-blue-700 w-full justify-center"
              >
                Authorize Billing
              </Button>
            </a>
          )}
        </div>
        <ContactSupport />
      </div>
    </div>
  );
};
