import { useAuthenticationStatus } from '@nhost/react';
import { Authenticated } from 'containers/Authenticated';
import { AppProviders } from 'context';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';

export function AuthGate() {
  const { isAuthenticated, isLoading } = useAuthenticationStatus();
  const navigate = useNavigate();
  const inviteId = localStorage.getItem('inviteId');

  useEffect(() => {
    if (isAuthenticated && inviteId && !isLoading)
      navigate(`/invite/accept?invite=${inviteId}`);
    else if (!isAuthenticated && !isLoading) navigate('/login');
  }, [isAuthenticated, isLoading, navigate, inviteId]);

  if (isLoading) {
    return null;
  } else if (isAuthenticated) {
    return (
      <AppProviders>
        <Authenticated />
      </AppProviders>
    );
  } else {
    return null;
  }
}
