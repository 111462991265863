import React from 'react';
import { Text } from 'components/Text';
import { XCircleIcon } from '@heroicons/react/outline';
import { RuleItem } from 'types/plans';
import { useStore } from 'context/store-context';
import { getStoreCurrencyFormatter } from 'utils';

type FixedRuleProps = {
  onRemoveItem: () => void;
  rule: RuleItem;
  onChange: (field: string, value: string) => void;
};

export const FixedRule = ({ onRemoveItem, rule, onChange }: FixedRuleProps) => {
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: string,
  ) => {
    const { value } = e.target;

    onChange(field, value);
  };
  const { storeProperties } = useStore();

  return (
    <div className="items-center flex">
      <div className="flex items-end">
        <div className="flex items-center">
          <div>
            <Text
              value="Price range"
              type="sm"
              className="text-gray-500 mr-3 mb-2"
            />
            <div className="flex items-center">
              <input
                type="number"
                name="rangeLower"
                className="w-20 border border-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md p-2"
                placeholder="e.g 0"
                value={rule.rangeLower}
                onChange={(e) => handleChange(e, 'rangeLower')}
                min={0}
              />
              <div className="mx-3">-</div>
            </div>
          </div>
        </div>
        <div>
          <input
            type="number"
            name="rangeUpper"
            className="w-20 border border-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md p-2"
            placeholder="e.g 100"
            value={rule.rangeUpper}
            onChange={(e) => handleChange(e, 'rangeUpper')}
            min={0}
          />
        </div>
      </div>
      <div className="ml-4">
        <Text
          value="Insurance fee"
          type="sm"
          className="text-gray-500 mr-3 mb-2"
        />
        <div className="flex items-center">
          <input
            type="number"
            name="email"
            className="w-20 border border-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md p-2"
            placeholder={`e.g ${getStoreCurrencyFormatter(
              storeProperties?.currency,
              3,
            )}`}
            value={rule.fee}
            onChange={(e) => handleChange(e, 'fee')}
          />
          <XCircleIcon
            className="w-5 h-5 text-red-500 cursor-pointer ml-3"
            onClick={onRemoveItem}
          />
        </div>
      </div>
    </div>
  );
};
