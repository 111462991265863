import React from 'react';
import { Text } from 'components/Text';
import { AdminUser } from 'types/admin';
import { format } from 'date-fns';

export const columns = [
  {
    name: 'Name',
    id: 1,
    render: (item: AdminUser) => (
      <div className="flex items-center">
        <img
          className="mr-4 h-10 w-10 sm:h-10 sm:w-10 rounded-full"
          src={item.avatarUrl}
          alt={item.displayName}
        />
        <Text value={item.displayName} />
      </div>
    ),
  },
  {
    name: 'Email',
    id: 2,
    key: 'email',
  },
  {
    name: 'Creation Date',
    id: 2,
    render: (item: AdminUser) => (
      <Text value={format(new Date(item.createdAt), 'yyyy-MM-dd')} />
    ),
  },
  {
    name: 'Last Active',
    id: 3,
    render: (item: AdminUser) => (
      <Text
        value={
          item.lastSeen ? format(new Date(item.lastSeen), 'yyyy-MM-dd') : ''
        }
      />
    ),
  },
];
