import { useMutation } from '@apollo/client';
import { Text } from 'components/Text';
import { BodyText } from 'components/MardownBodyText';

import { useStore } from 'context/store-context';
import { columns } from './data/store-integration-data';
import { DataTable } from 'components/DataTable';
import { GENERATE_INSTALL_LINK } from 'gql/mutations';

export const Integrations = () => {
  const storeContext = useStore();

  const userStore = storeContext.userStores?.find(
    (userStore) => storeContext.storeId === userStore.storeId,
  );

  const [generateInstallLink, { loading }] = useMutation(
    GENERATE_INSTALL_LINK,
    {
      onCompleted: (data) => {
        window.open(data?.generateInstallLink, '_blank');
      },
    },
  );

  const handleUpdatePermission = (store: any) => {
    generateInstallLink({
      variables: {
        store: store.domain,
      },
    });
  };

  return (
    <div className="pb-8">
      <div className="max-w-7xl mx-auto mb-12 px-4 sm:px-6 md:px-8">
        <Text value="Manage Integrations" type="h1" />
        <BodyText
          className="max-w-4xl mt-4"
          text="Thank you for choosing **Shipaid**! We're constantly updating our app and adding new features to the platform.
            To unlock these new features for your store, it's essential that you grant the latest required permissions to us.
            Below, you'll find details and instructions on how to update the permissions."
        />
        <BodyText
          className="max-w-4xl mt-4"
          text="**Please note that after updating the permissions, you'll be prompted to re-authorize the ShipAid billing. This step is necessary for our widget to function and for the updated permissions to take effect.**"
        />
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <DataTable
          data={(storeContext?.userStores ?? []).map((store: any) => ({
            ...store,
            loading,
            platform: 'Shopify',
            isPermissionUpdateRequired: Boolean(
              userStore &&
                storeContext?.storeProperties?.shouldUpdateAppInstall,
            ),
            updatePermission: () => handleUpdatePermission(store),
          }))}
          columns={columns}
        />
      </div>
    </div>
  );
};

export default Integrations;
