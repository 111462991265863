import { useState } from 'react';
import { useLocation } from 'react-router-dom';

export const NavigationListener = () => {
  let location = useLocation();

  const [currentLocation, setCurrentLocation] = useState(location.pathname);

  if (currentLocation !== location.pathname) {
    window.scrollTo(0, 0);
    setCurrentLocation(location.pathname);
  }

  return null;
};
