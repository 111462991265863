import React, { useState, useEffect } from 'react';
import html2canvas from 'html2canvas';
import { Text } from 'components/Text';
import { Toggle } from 'components/Toggle';
import { Button } from 'components/Button';
import { CloneTemplateModal } from 'components/CloneTemplateModal';
import { SizeEnum } from 'utils';
import {
  customEmailTemplateTypes,
  defaultTrackingTemplate,
  templatesUsageTypes,
} from './data/templates-data';

const TemplateImage = ({ template }: any) => {
  const [image, setImage] = useState<any>({});

  useEffect(() => {
    const container = document.createElement('div');
    container.innerHTML = template.templateHtml;
    document.body.appendChild(container);

    html2canvas(container, { useCORS: true })
      .then(function (canvas: any) {
        const dataURL = canvas.toDataURL();
        setImage(dataURL);
      })
      .catch(function (error: any) {
        console.log(error);
        setImage(null);
      });
    document.body.removeChild(container);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template]);

  return (
    <img
      src={image}
      className="flex h-24 w-48 border-solid border-2 rounded-lg overflow-hidden"
      alt="template"
    />
  );
};

type TrackingTemplatesProps = {
  userTemplates: any[];
  isLoading: boolean;
  onEditTemplate: any;
  onChangeActiveTemplate: any;
  onNewTemplate: any;
  onCloseModal: any;
};

export const TrackingTemplates = ({
  userTemplates,
  // isLoading,
  onEditTemplate,
  onChangeActiveTemplate,
  onNewTemplate,
  onCloseModal,
}: TrackingTemplatesProps) => {
  const [cloneModal, setCloneModal] = useState(null);

  const handleOnCloseModal = (template: any, info: any) => {
    setCloneModal(null);
    onCloseModal(template, info);
  };

  const templateList = userTemplates?.length
    ? userTemplates
    : [defaultTrackingTemplate];

  const hasAllTemplates =
    Object.keys(customEmailTemplateTypes).length === templateList.length - 1;

  const emailTypes = Object.values(customEmailTemplateTypes);

  const noCreatedTemplates = emailTypes.filter(
    (type) =>
      !userTemplates?.some(
        (template) =>
          template.eventType === type.key &&
          template.usage === templatesUsageTypes.tracking,
      ),
  );
  return (
    <div>
      <div className="mt-8">
        {!hasAllTemplates ? (
          <Button
            onClick={() => onNewTemplate()}
            className="bg-blue-600 hover:bg-blue-700"
            size={SizeEnum.lg}
          >
            Add Template
          </Button>
        ) : null}
      </div>
      <div className="mt-8">
        <div className="flex flex-col">
          <div className="-my-2 sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
              <div
                className={
                  'shadow border-b border-gray-200 sm:rounded-lg overflow-x-scroll'
                }
              >
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50"></thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {templateList?.map((template, index) => {
                      const type: any = template.isTrackingDefault
                        ? 'Default Template'
                        : `Order
                      ${
                        Object.values(customEmailTemplateTypes).find(
                          (et) => et.key === template.eventType,
                        )?.label ?? ''
                      }`;
                      return (
                        <tr className="table-data-row" key={template.id}>
                          <td
                            key={template.id + 1}
                            className="px-1 py-1 whitespace-nowrap text-gray-600 text-sm font-medium"
                          >
                            <TemplateImage template={template} index={index} />
                          </td>
                          <td
                            key={template.id + 2}
                            className="px-6 py-4 whitespace-nowrap text-gray-600 text-sm font-medium"
                          >
                            <Text
                              value={type}
                              type="bold"
                              className="text-lg"
                            />
                          </td>
                          <td
                            key={template.id + 3}
                            className="px-6 py-4 whitespace-nowrap text-gray-600 text-sm font-medium"
                          >
                            <Toggle
                              key={template.id + 4}
                              isEnabled={template.active}
                              disabled={template.isTrackingDefault}
                              onChange={() => onChangeActiveTemplate(template)}
                              isSmall
                            />
                          </td>
                          <td
                            key={template.id + 5}
                            className="px-6 py-4 whitespace-nowrap text-gray-600 text-sm font-medium"
                          >
                            <Button
                              isRounded
                              onClick={() => onEditTemplate(template)}
                            >
                              Edit
                            </Button>
                            {noCreatedTemplates.length ? (
                              <Button
                                isRounded
                                className="ml-5"
                                disabled={template.isTrackingDefault}
                                onClick={() => setCloneModal(template)}
                              >
                                Clone
                              </Button>
                            ) : null}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CloneTemplateModal
        template={cloneModal}
        setCloneModal={setCloneModal}
        onClose={handleOnCloseModal}
        noCreatedTemplates={noCreatedTemplates}
      />
    </div>
  );
};
