import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Text } from 'components/Text';
import { DataTable } from 'components/DataTable';
import { merchanStoreColumns, merchantColumns } from './data/merchants-data';
import { UserCircleIcon } from '@heroicons/react/solid';
import { GET_ADMIN_USER_BY_ID } from 'gql/queries';
import { withNotification } from 'components/Notification';
import { NotificationTypes, apiErrorHandler } from 'utils';
import { LoadingScreen } from 'components/LoadingScreen';
import { AdminCreditModal } from 'components/AdminCreditModal';
import { MerchantUser } from 'types/admin';
import { format } from 'date-fns';
import { Back } from 'components/Back';
import { useUser } from 'context/user-context';
import { useStore } from 'context/store-context';
import { omit } from 'lodash';
import { UPDATE_STORE } from 'gql/mutations';

export const MerchantProfile = withNotification(({ showNotification }: any) => {
  const { id } = useParams();
  const userContext = useUser();
  const storeContext = useStore();
  const navigate = useNavigate();
  const [merchant, setMerchant] = useState<MerchantUser | null>(null);
  const [merchantStore, setMerchantStore] = useState<any>();
  const [showCreditModal, setShowCreditModal] = useState(false);
  const [storeCredit, setStoreCredit] = useState('');

  const { data, loading, refetch } = useQuery(GET_ADMIN_USER_BY_ID, {
    variables: {
      merchantId: id,
    },
    onError(error) {
      showNotification(NotificationTypes.error, error?.message);
    },
  });

  const [updateStore, { error: updateStoreError }] = useMutation(UPDATE_STORE, {
    onError(error) {
      const newError = apiErrorHandler(error);
      showNotification(NotificationTypes.error, newError?.message);
    },
  });

  useEffect(() => {
    if (data?.users?.length) {
      setMerchant(data.users[0]);
    }
  }, [data]);

  const handleStoreCredit = (store: any) => {
    setMerchantStore(store);
    setStoreCredit(store?.storeCredit ?? 0);
    setShowCreditModal(true);
  };

  const handleUpdateStoreCredit = (amount: string) => {
    updateStore({
      variables: {
        storeId: merchantStore.id,
        set: { storeCredit: parseFloat(amount) },
      },
      onCompleted: () => {
        setMerchantStore(null);
        refetch();
      },
    })
      .then((data) => {
        return showNotification(
          NotificationTypes.success,
          'Credit Amount Updated',
        );
      })
      .catch(() => {
        return showNotification(
          NotificationTypes.error,
          updateStoreError ? updateStoreError.message : 'Details not updated',
        );
      });
    setShowCreditModal(false);
  };
  if (loading) return <LoadingScreen />;

  return (
    <div>
      <AdminCreditModal
        show={showCreditModal}
        setShowModal={(val) => setShowCreditModal(val)}
        storeCredit={storeCredit}
        handleUpdateStoreCredit={handleUpdateStoreCredit}
        store={merchantStore}
        isLoading={loading}
        setStoreCredit={(amount) => setStoreCredit(amount)}
      />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <div className="flex">
          <Back />
          <div className="flex flex-col items-center justify-center w-full">
            <div className="bg-white shadow overflow-hidden sm:rounded-lg p-4 w-1/2 flex flex-col items-center justify-center">
              <Text
                value={merchant?.displayName || ''}
                type="h1"
                className="font-normal"
              />
              {!merchant?.avatarUrl ? (
                <UserCircleIcon
                  className="text-gray-400"
                  style={{ width: 180, height: 180 }}
                />
              ) : (
                <img
                  style={{ height: 180, width: 180, borderRadius: '50%' }}
                  className="object-cover"
                  src={merchant?.avatarUrl || ''}
                  alt="company logo"
                />
              )}
              <div className="flex mt-2">
                <Text value="Email:" />
                <Text
                  value={merchant?.email || ''}
                  type="bold"
                  className="ml-2"
                />
              </div>
              <div className="flex mt-2">
                <Text value="Created On:" />
                <Text
                  value={
                    merchant?.createdAt
                      ? format(new Date(merchant.createdAt), 'yyyy-MM-dd')
                      : ''
                  }
                  type="bold"
                  className="ml-2"
                />
              </div>
              <div className="flex mt-2">
                <Text value="Last Active:" />
                <Text
                  value={
                    merchant?.lastSeen
                      ? format(
                          new Date(merchant.lastSeen),
                          'yyyy-MM-dd hh:mm:ss aa',
                        )
                      : ''
                  }
                  type="bold"
                  className="ml-2"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <Text value="Stores" type="h4" className="mb-5" />
          <DataTable
            data={(merchant?.stores || []).map((store) => ({
              ...store.store,
              godModeLogin: () => {
                if (userContext?.setUser) {
                  userContext.setUser({
                    ...omit(merchant, ['invites', 'stores']),
                  });
                }
                if (
                  storeContext?.updateUserStore &&
                  storeContext?.getStoreInitials &&
                  storeContext?.getStoreColor
                ) {
                  storeContext.updateUserStore({
                    storeId: store.store.id,
                    initials: storeContext.getStoreInitials(store.store.name),
                    storeColor: storeContext.getStoreColor(0) + '',
                    name: store.store.name || store.store.store,
                    domain: store.store.store,
                    plan: store.store.plan,
                    completedSteps: store.store.completedSteps,
                  });
                }
                localStorage.setItem(
                  process.env.REACT_APP_ADMIN_STORAGE_KEY || 'admin-context',
                  JSON.stringify({
                    admin: {
                      user: { ...userContext },
                      store: { ...storeContext },
                    },
                  }),
                );
                navigate('/');
              },
              handleAdminStoreCredit: () => handleStoreCredit(store.store),
            }))}
            columns={merchanStoreColumns}
            emptyMessage="No data"
          />
        </div>
        <div className="mt-5">
          <Text value="Invited Users" type="h4" className="mb-5" />
          <DataTable
            data={(merchant?.invites || []).map((invitedUser) => ({
              ...invitedUser.user,
            }))}
            columns={merchantColumns}
            emptyMessage="No data"
          />
        </div>
      </div>
    </div>
  );
});
