import React, { FormEvent, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import FragileBox from '../assets/images/fagile-box.png';
import Google from '../assets/images/google.png';
import GreenTick from '../assets/images/greenTick.png';
import { Button } from '../components/Button';
import { SizeEnum, validateEmail } from '../utils';
import FormInput from '../components/FormInput';
import AuthPage from '../components/AuthPage';
import { useSignUpEmailPassword, useProviderLink } from '@nhost/react';

import { Text } from 'components/Text';
import classNames from 'classnames';

function SignUp() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [refCode, setRefCode] = useState(searchParams.get('ref') || '');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { google } = useProviderLink();
  const [errorObj, setErrorObj] = useState({
    message: '',
    error: '',
    status: 0,
  });
  const {
    isLoading,
    isSuccess,
    needsEmailVerification,
    error,
    signUpEmailPassword,
  } = useSignUpEmailPassword();

  useEffect(() => {
    if (error) {
      setErrorObj({ ...error });
    }
  }, [error]);

  function handleValidation() {
    if (email.trim() === '') {
      const error = { message: 'Email is required', error: 'invalid-email' };
      setErrorObj({ ...errorObj, ...error });
      return error;
    }
    if (!validateEmail(email)) {
      const error = {
        message: 'Email is not valid, please enter a valid email',
        error: 'invalid-email',
      };
      setErrorObj({ ...errorObj, ...error });
      return error;
    }
    if (password.trim() === '') {
      const error = {
        message: 'Password is required',
        error: 'invalid-password',
      };
      setErrorObj({ ...errorObj, ...error });
      return error;
    }
    setErrorObj({ message: '', error: '', status: 0 });
    return false;
  }

  function handleSignup(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const hasError = handleValidation();
    if (!hasError)
      signUpEmailPassword(email, password, {
        metadata: {
          ref_id: refCode,
        },
      });
  }

  function handleSignupWithGoogle() {
    window.location.href = google;
  }
  const shopifySignupURI = localStorage.getItem('shipaid___post-install');

  if (isSuccess && shopifySignupURI) {
    window.location.href = shopifySignupURI;
  } else if (isSuccess) {
    navigate('/');
  }

  const disableForm = isLoading || needsEmailVerification;

  const handleRefCodeChange = (event: any) => {
    const value = event.target.value;
    setRefCode(value);
    searchParams.set('ref', value);
    navigate(`?${searchParams.toString()}`);
  };

  return (
    <AuthPage backgroundImage={FragileBox}>
      <div className="text-center my-6 w-11/12">
        <h6 className="uppercase text-shipaidBlue my-4 font-bold">Account</h6>

        <h2 className="text-3xl md:text-5xl my-4">Welcome!</h2>

        {shopifySignupURI ? (
          <>
            <div className="flex items-center max-w-4xl">
              <img className="h-6 w-6 mr-3 ml-10" src={GreenTick} alt="tick" />
              Thank you for installing the ShipAid Shopify App!
            </div>
            <div>
              Please signup below to finish setup and continue managing your
              account.
            </div>
          </>
        ) : null}

        <div className="text-sm md:text-xl">
          <span>Already have an account? </span>
          <span className="text-shipaidBlue">
            <Link to="/login">Login</Link>
          </span>
        </div>

        <Button
          size={SizeEnum.sm}
          isRounded
          onClick={handleSignupWithGoogle}
          className="bg-transparent border border-black w-full rounded-full flex justify-center items-center align-middle text-black text-center mt-4 hover:bg-white"
        >
          <img className="h-6 w-6" src={Google} alt="google logo" />
          <span className="block pl-2"> Sign up with google</span>
        </Button>

        <div className="my-4">
          <fieldset className="border-t border-slate-300">
            <legend className="mx-auto px-2 text-black text-sm">
              Or Continue with
            </legend>
          </fieldset>
        </div>
      </div>

      <div className="form w-full  md:w-[450px]">
        <form className="w-full" onSubmit={handleSignup}>
          <div className="mb-4">
            <FormInput
              id="email"
              name="email"
              label="Your Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="franklyfrank@example.com"
              type="email"
            />
          </div>

          <div className="mb-4">
            <FormInput
              id="password"
              name="password"
              label="Your Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Better be a good one"
              type="password"
            />
          </div>
          <div className="mb-4 w-1/2">
            <FormInput
              id="ref-code"
              name="refCode"
              label="Code"
              value={refCode}
              onChange={handleRefCodeChange}
              placeholder="Referral Code (Optional)"
              type="text"
            />
          </div>
          <Text
            type="sm"
            value={errorObj?.message}
            className={classNames('text-center text-red-600')}
          />
          <Button
            size={SizeEnum.xl}
            isRounded
            type="submit"
            disabled={disableForm}
            className="bg-shipaidBlue text-white w-full rounded-full flex justify-center items-center align-middle text-center mt-4 hover:bg-opacity-80"
          >
            Submit
          </Button>
        </form>
      </div>
    </AuthPage>
  );
}

export default SignUp;
