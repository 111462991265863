import { Claim, ClaimItemValues } from 'types/claim';
import { Text } from './Text';
import moment from 'moment';
import { Button } from './Button';
import { Modal } from './Modal';
import { useState } from 'react';
import { withNotification } from './Notification';
import { NotificationTypes, apiErrorHandler, formatStatusText } from 'utils';
import { useMutation } from '@apollo/client';
import { CANCEL_RESOLUTION } from 'gql/mutations';

type ClaimResolutionActivitiesProps = {
  claim: Claim;
  claimItem: ClaimItemValues;
  option?: string;
};

type Activity = {
  timestamp: string;
  content: string;
  action: {
    text: string;
    callback: () => void;
    customClass?: string;
  };
};

export const ClaimResolutionActivities = withNotification(
  ({
    claim,
    claimItem,
    showNotification,
    option,
  }: ClaimResolutionActivitiesProps & {
    showNotification: (type: any, content: string) => void;
  }) => {
    const settlement = claimItem.settlement
      ? claim?.settlements?.find((s: any) =>
          s.items?.find((si: any) => si?.claimItem?.id === claimItem?.id),
        )
      : null;

    const [confirmationModal, setConfirmatioModal] = useState<boolean>(false);

    const toggleConfirmationModal = () =>
      setConfirmatioModal((isOpen) => !isOpen);

    const [cancelResolution, { loading: cancelResolutionLoading }] =
      useMutation(CANCEL_RESOLUTION, {
        onError(error) {
          const newError = apiErrorHandler(error);
          showNotification(NotificationTypes.error, newError?.message);
        },
        onCompleted(data) {
          showNotification(
            NotificationTypes.success,
            'Resolution is cancelled',
          );
          setConfirmatioModal(false);

          return setTimeout(() => {
            return window.location.reload();
          }, 300);
        },
      });

    const handleCancelResolution = () => {
      if (!claimItem) {
        showNotification(NotificationTypes.error, 'Invalid Item Quantity');
        return;
      }

      const settlementItemId = settlement.items.find(
        (si: any) => si?.claimItem?.id === claimItem?.id,
      )?.id;
      let payload = {
        claimId: claim.id,
        storeId: claim.storeId,
        claimItemId: claimItem.id,
        settlementItemId: settlementItemId,
      };

      cancelResolution({
        variables: { input: payload },
      });
    };

    if (!settlement) return null;

    const activities: Activity[] = [
      {
        timestamp: settlement.updated_at,
        content: `<ul style="list-style: inside;">${
          claimItem.settlement.solvedByRule?.title
            ? `<li>Solved by Rule: <strong>${claimItem.settlement.solvedByRule.title}</strong></li>`
            : ''
        }<li>Claim item <b>${
          claimItem.orderItem.title
        }</b> has been resolved with status <b class="text-blue-600">${formatStatusText(
          `${claimItem.resolution}`,
        )}</b></li> <li>Option: ${
          option ? `${formatStatusText(option)}` : ''
        }</li></ul>`,
        action: {
          text: 'Cancel Resolution',
          callback: () => {
            setConfirmatioModal(true);
          },
          customClass:
            'text-red-700 bg-red-100 border-red-400 hover:bg-red-200 focus:outline-none focus:ring focus:ring-red-300',
        },
      },
    ];

    return (
      <div className="my-5">
        <div className="border border-gray-300 p-3 rounded-md mt-4">
          <Text value="Activity" type="h4" className="" />

          <div className="my-5">
            {activities.map((activity, idx) => (
              <div key={`activity-${idx}`}>
                <div>{moment(activity.timestamp).fromNow()}</div>
                <div className="flex flex-row justify-between items-center">
                  <div
                    className="text-base text-gray-500"
                    dangerouslySetInnerHTML={{ __html: activity.content }}
                  />
                  <div className="text-xs">
                    {moment(activity.timestamp).format('HH:mm')}
                  </div>
                </div>
                <Button
                  onClick={activity.action.callback}
                  className={`my-2 ${activity.action.customClass}`}
                >
                  {activity.action.text}
                </Button>
              </div>
            ))}
          </div>
        </div>

        <Modal
          customStyle={{ width: '400px' }}
          open={confirmationModal}
          openModal={toggleConfirmationModal}
        >
          <div className="confirmation-modal-header font-bold text-lg">
            Confirm
          </div>
          <div className="confirmation-modal-content my-4 text-sm">
            Cancelling this resolution might not reflect in your store and you
            may need to cancel the reshipment or refund created manually in your
            store admin
          </div>
          <div className="confirmation-modal-footer flex justify-between items-center">
            <div
              onClick={toggleConfirmationModal}
              className="text-gray-800 cursor-pointer font-semibold"
            >
              Back
            </div>
            <Button
              onClick={handleCancelResolution}
              disabled={cancelResolutionLoading}
              className="text-red-700 bg-red-100 border-red-400 hover:bg-red-200 focus:outline-none focus:ring focus:ring-red-300"
            >
              Cancel Resolution
            </Button>
          </div>
        </Modal>
      </div>
    );
  },
);
