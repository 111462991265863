import React from 'react';
import Select from 'react-select';
import { Modal } from 'components/Modal';
import { Text } from 'components/Text';
import { Button } from 'components/Button';
import { ClaimAutomationRule } from 'components/ClaimAutomationRule';
import { ruleActionOptions } from '../containers/NestedContainers/ClaimAutomation/utils';
import { SizeEnum } from 'utils';

type ClaimAutomationRuleModalProps = {
  show: boolean;
  setEditingRule: (value: any) => void;
  editingRule: any;
  addNewRule: (value: string) => void;
  removeRule: (index: number) => void;
  onChangeRuleValue: (field: string, value: string, index: number) => void;
  handleSaveRule: (rule: any) => void;
  isLoading?: boolean;
  validationErrors: string | null;
};

export const ClaimAutomationRuleModal = ({
  show,
  setEditingRule,
  editingRule,
  addNewRule,
  removeRule,
  onChangeRuleValue,
  handleSaveRule,
  isLoading,
  validationErrors,
}: ClaimAutomationRuleModalProps) => {
  if (!editingRule) return null;
  return (
    <Modal
      openModal={() => setEditingRule(null)}
      open={show}
      containerClass="sm:max-w-lg sm:w-full"
      customStyle={{ maxWidth: '75rem' }}
    >
      <Text value="Automation Rule" type="h4" />
      {validationErrors ? (
        <Text
          type="bold"
          className="text-red-500 mr-3 mb-2 mt-8 sm:mb-0"
          value={validationErrors}
        />
      ) : null}
      <div className="block sm:flex sm:items-center mt-8">
        <Text
          value="Name"
          type="bold"
          className="text-gray-500 mr-3 mb-2 sm:mb-0"
        />
        <input
          name="title"
          className="border border-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:w-96 sm:text-sm border-gray-300 rounded-md p-2"
          placeholder="Standard Rule"
          value={editingRule.title}
          onChange={(e) => onChangeRuleValue('title', e.target.value, 0)}
        />
      </div>
      <div className="py-3">
        {editingRule.statements.length > 0 &&
          editingRule.statements.map((statement: any, index: number) => (
            <ClaimAutomationRule
              key={index}
              index={index}
              onRemoveItem={() => removeRule(index)}
              statement={statement}
              onChange={(field, value) =>
                onChangeRuleValue(field, value, index)
              }
            />
          ))}
        <Button
          onClick={() => addNewRule('AND')}
          className="bg-transparent mr-3 border border-gray-500  hover:bg-gray-100 mt-12"
        >
          <Text value="AND" className="text-gray-500" />
        </Button>
        <Button
          onClick={() => addNewRule('OR')}
          className="bg-transparent mr-3 border border-gray-500  hover:bg-gray-100 mt-12"
        >
          <Text value="OR" className="text-gray-500" />
        </Button>
      </div>
      <div className="block sm:flex sm:items-center mt-4">
        <Text
          value="Action"
          type="h4"
          className="text-gray-500 mr-3 mb-2 sm:mb-0"
        />
        <div style={{ width: 350 }}>
          <Select
            value={
              ruleActionOptions.find((o) => o.value === editingRule.action) ??
              null
            }
            onChange={(val: any) => {
              onChangeRuleValue('action', val?.value, 0);
            }}
            options={ruleActionOptions}
          />
        </div>
      </div>
      <div className="flex mt-12">
        <Button
          onClick={() => handleSaveRule(editingRule)}
          className="bg-blue-600 mr-3 hover:bg-blue-700"
          size={SizeEnum.lg}
          isLoading={isLoading}
        >
          {editingRule.id ? 'Update Rule' : 'Create Rule'}
        </Button>
        <Button
          onClick={() => setEditingRule(null)}
          size={SizeEnum.lg}
          className="bg-red-500 text-white hover:bg-red-700 h-max px-6"
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};
