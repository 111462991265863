/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { cloneDeep } from 'lodash';
import { Text } from 'components/Text';
import { Button } from 'components/Button';
import { LoadingScreen } from 'components/LoadingScreen';
import { apiErrorHandler, SizeEnum } from 'utils';
import { columns } from './data/claim-automation-data';
import { useMutation } from '@apollo/client';
import { useStore } from 'context/store-context';
import { withNotification } from 'components/Notification';
import { NotificationTypes } from 'utils';
import { ClaimAutomationRuleModal } from 'components/ClaimAutomationRuleModal';
import {
  getStatementInputConfig,
  transformRuleStatementsToSave,
  getNewRuleTemplate,
  validateAndUpdateRule,
} from './ClaimAutomation/utils';
import { GET_CLAIM_AUTOMATION_RULES } from 'gql/queries';
import {
  UPDATE_CLAIM_AUTOMATION_RULE,
  REMOVE_CLAIM_AUTOMATION_RULE,
  UPDATE_CLAIM_AUTOMATION_RULE_PRIORITY,
} from 'gql/mutations';
import { DndDataTable } from 'components/DNTable/DndDataTable';

export const AutomationRules = withNotification(({ showNotification }: any) => {
  const store = useStore();
  const { storeId } = store;

  const [rules, setRules] = useState([]);
  const [initialRules, setInitialRules] = useState([]);
  const [editingRule, setEditingRule] = useState<any>(null);
  const [validationErrors, setValidationErrors] = useState<string | null>(null);

  const [getClaimAutomationRules, { loading }] = useLazyQuery(
    GET_CLAIM_AUTOMATION_RULES,
    {
      onError(error) {
        const newError = apiErrorHandler(error);
        showNotification(NotificationTypes.error, newError?.message);
      },
      onCompleted(data) {
        setRules(data.claimRules);
        setInitialRules(data.claimRules);
      },
    },
  );

  const [saveClaimAutomationRule] = useMutation(UPDATE_CLAIM_AUTOMATION_RULE, {
    onError(error) {
      const newError = apiErrorHandler(error);
      showNotification(NotificationTypes.error, newError?.message);
    },
    onCompleted(data) {
      showNotification(
        NotificationTypes.success,
        'Your claim automation rule is saved successfully!',
      );
      setRules(data.saveClaimRules);
      setEditingRule(null);
      setValidationErrors(null);
    },
  });

  const [updateClaimRulePriority, { loading: updatingRulePriority }] =
    useMutation(UPDATE_CLAIM_AUTOMATION_RULE_PRIORITY, {
      onError(error) {
        const newError = apiErrorHandler(error);
        showNotification(NotificationTypes.error, newError?.message);
        setRules(initialRules);
      },
      onCompleted(data) {
        showNotification(
          NotificationTypes.success,
          'Your claim automation rule priority was changed successfully!',
        );
        setRules(data.changeClaimRulePriority);
        setValidationErrors(null);
      },
    });

  const [deleteClaimAutomationRule] = useMutation(
    REMOVE_CLAIM_AUTOMATION_RULE,
    {
      onError(error) {
        const newError = apiErrorHandler(error);
        showNotification(NotificationTypes.error, newError?.message);
      },
      onCompleted(data) {
        showNotification(
          NotificationTypes.success,
          'Your claim automation rule is removed successfully!',
        );
        setRules(data.deleteClaimAutomationRules);
      },
    },
  );

  useEffect(() => {
    const getStoreClaimAutomationRules = async () => {
      try {
        if (storeId) {
          getClaimAutomationRules({
            variables: {
              storeId,
            },
          });
        }
      } catch (e) {
        console.log('error', e);
      }
    };
    getStoreClaimAutomationRules();
  }, []);

  const handleEditRule = (rule: any) => {
    const editableRule = JSON.parse(rule.ruleBeforeTransform);
    setEditingRule({ ...editableRule, id: rule.id });
  };

  const handleDeleteRule = (rule: any) => {
    deleteClaimAutomationRule({
      variables: {
        input: {
          id: rule.id,
          storeId,
        },
      },
    });
  };

  const handleUpdateRuleStatus = (rule: any) => {
    saveClaimAutomationRule({
      variables: {
        input: {
          id: rule.id,
          storeId,
          title: rule.title,
          active: !rule.active,
          statements: rule.statements,
          action: rule.action,
          priority: rule.priority,
          actionNotes: rule.actionNotes,
          refundShipping: rule.refundShipping,
          ruleBeforeTransform: rule.ruleBeforeTransform,
        },
      },
    });
  };

  const handleUpdateRulePriority = (
    newRulesOrder: any,
    rule: any,
    ruleIndex: number,
  ) => {
    setRules(newRulesOrder);
    updateClaimRulePriority({
      variables: {
        input: {
          id: rule.id,
          storeId,
          priority: ruleIndex + 1,
        },
      },
    });
  };

  const handleAddNewRuleStatement = (connector: string) => {
    const newObj = {
      expressionField: '',
      expression: '',
      expressionValue: '',
      config: { field: 'stringInput', placeHolder: 'Value' },
      connector,
    };
    const updatedRule = {
      ...editingRule,
      statements: [...editingRule.statements, newObj],
    };
    setEditingRule(updatedRule);
  };

  const handleRemoveRuleStatement = (ruleIndex: number) => {
    const filteredRuleStatements = [...editingRule.statements].filter(
      (rule, index) => index !== ruleIndex,
    );
    setEditingRule({ ...editingRule, statements: filteredRuleStatements });
  };

  const handleChange = (field: string, value: string, index: number) => {
    setValidationErrors(null);
    if (field === 'title') {
      const updatedRule = { ...editingRule, title: value };
      setEditingRule(updatedRule);
      return;
    }
    if (field === 'action') {
      const updatedRule = { ...editingRule, action: value };
      setEditingRule(updatedRule);
      return;
    }
    const objIndex: number = [...editingRule.statements].findIndex(
      (obj, i) => i === index,
    );
    const rulesCopy = cloneDeep([...editingRule.statements]);
    if (field === 'expressionField') {
      rulesCopy[objIndex].expressionField = value;
      rulesCopy[objIndex].expression = '';
      rulesCopy[objIndex].expressionValue = '';
    }
    if (field === 'expression') {
      rulesCopy[objIndex].expression = value;
      rulesCopy[objIndex].expressionValue = '';
    }
    if (field === 'expressionValue')
      rulesCopy[objIndex].expressionValue = value;

    const expressionField = rulesCopy[objIndex].expressionField;
    const expression = rulesCopy[objIndex].expression;
    const config = getStatementInputConfig(expressionField, expression);
    rulesCopy[objIndex].config = config;
    setEditingRule({ ...editingRule, statements: rulesCopy });
  };

  const handleSaveRule = (rule: any) => {
    // add validation to rule and statements
    const { hasError, messages, statements } = validateAndUpdateRule(rule);
    if (hasError) {
      setValidationErrors(messages.join(', '));
      return;
    }
    const transformedStatements = transformRuleStatementsToSave(statements);

    const ruleIndex = rules.findIndex((item: any) => item.id === rule.id);

    saveClaimAutomationRule({
      variables: {
        input: {
          id: rule.id,
          storeId,
          title: rule.title,
          active: rule.active,
          statements: transformedStatements,
          action: rule.action,
          priority: ruleIndex >= 0 ? ruleIndex + 1 : rules.length,
          actionNotes: '',
          refundShipping: false,
          ruleBeforeTransform: JSON.stringify(rule),
        },
      },
    });
  };

  if (store?.storeLoading && !store?.storeProperties) return <LoadingScreen />;

  return (
    <div>
      <ClaimAutomationRuleModal
        show={!!editingRule}
        setEditingRule={setEditingRule}
        editingRule={editingRule}
        addNewRule={handleAddNewRuleStatement}
        removeRule={handleRemoveRuleStatement}
        onChangeRuleValue={handleChange}
        handleSaveRule={handleSaveRule}
        validationErrors={validationErrors}
        isLoading={loading}
      />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <Text value="Automation Rules" type="h1" />
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-3">
        <div className="mt-12">
          <Button
            onClick={() => setEditingRule(getNewRuleTemplate())}
            className={`bg-blue-600 hover:bg-blue-700 ${
              updatingRulePriority ? 'cursor-not-allowed' : ''
            }`}
            size={SizeEnum.lg}
            disabled={updatingRulePriority}
          >
            Create Rule
          </Button>
        </div>
        <div
          className={`mt-8 table-container ${
            updatingRulePriority ? 'cursor-wait' : ''
          }`}
        >
          <DndDataTable
            data={rules.map((rule: any) => ({
              ...rule,
              updateRuleStatus: () => handleUpdateRuleStatus(rule),
              editRule: () => handleEditRule(rule),
              deleteRule: () => handleDeleteRule(rule),
            }))}
            columns={columns}
            setData={handleUpdateRulePriority}
            loading={updatingRulePriority}
          />
        </div>
      </div>
    </div>
  );
});
