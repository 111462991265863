import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Caret from '../../assets/icons/caret.svg';
import classes from '../../modulecss/ordersTableHead.module.css';

type ClaimsTableprops = {
  columns: {
    id: number | string;
    name?: string;
    key?: string;
    render?: (item: any, method?: any) => JSX.Element;
    value?: string;
  }[];
  claims: any[];
  checkbox?: boolean;
  onSelectAll?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectedRow?: (item: any) => void;
  allChecked?: any[];
  emptyMessage?: string;
  orderByOptions?: string[];
  handleSelectedColumn?: (column: string, direction: string) => void;
};

export const ClaimsTable = ({
  columns,
  claims,
  checkbox,
  handleSelectedRow,
  onSelectAll,
  allChecked,
  emptyMessage,
  orderByOptions,
  handleSelectedColumn,
}: ClaimsTableprops) => {
  const [selectedColumn, setSelectedColumn] = useState<string | null>(null);
  const [direction, setDirection] = useState<string>('desc');

  const handleSelectColumn = (column: string | undefined) => {
    if (column && !orderByOptions?.includes(column)) return;
    column && setSelectedColumn(column);
    handleDirection(column);
  };

  useEffect(() => {
    if (!selectedColumn && orderByOptions && orderByOptions?.length > 0) {
      setSelectedColumn(orderByOptions[0]);
    }
  }, [orderByOptions, setSelectedColumn, selectedColumn]);

  const handleDirection = (column: string | undefined) => {
    if (direction === 'desc') {
      column && handleSelectedColumn && handleSelectedColumn(column, 'asc');
      return setDirection('asc');
    }
    column && handleSelectedColumn && handleSelectedColumn(column, 'desc');
    return setDirection('desc');
  };

  return (
    <div className="flex flex-col">
      <div className="-my-2 sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
          <div
            className={
              'shadow border-b border-gray-200 sm:rounded-lg overflow-x-scroll'
            }
          >
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {checkbox && (
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <input
                        type="checkbox"
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        onChange={onSelectAll}
                      />
                    </th>
                  )}
                  <th
                    className={`${classes.fixedCell} text-xs font-medium text-gray-700 uppercase tracking-wider`}
                  >
                    <div
                      className="flex justify-center items-center"
                      style={{ width: 150 }}
                    >
                      <div style={{ width: 150 }}>ISSUE NUMBER #</div>
                    </div>
                  </th>
                  {columns &&
                    columns.map((column) => (
                      <th
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        key={column.id}
                        scope="col"
                        onClick={() => handleSelectColumn(column?.value)}
                      >
                        <span className="flex items-center cursor-pointer">
                          {column.name}
                          {selectedColumn &&
                            selectedColumn === column?.value && (
                              <img
                                src={Caret}
                                alt="caret"
                                height="15"
                                width="15"
                                className={classNames(
                                  direction === 'desc'
                                    ? 'rotate-0'
                                    : '-rotate-180',
                                  'text-blue-600',
                                )}
                                color="#2563eb"
                              />
                            )}
                        </span>
                      </th>
                    ))}
                  <th
                    className={`${classes.fixedCell} px-6 py-4 text-xs font-medium text-gray-700 uppercase tracking-wider`}
                    style={{ right: 0, textAlign: 'center' }}
                  >
                    <div
                      className="flex justify-center items-center"
                      style={{ width: 75 }}
                    >
                      ACTIONS
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {claims &&
                  claims.map((item: any, i: number) => (
                    <tr className="table-data-row" key={Math.random() * 1000}>
                      {checkbox && (
                        <td className="px-6 py-4 whitespace-nowrap text-gray-600 text-sm font-medium">
                          <input
                            name="comments"
                            type="checkbox"
                            checked={
                              allChecked && allChecked?.includes(item.id)
                            }
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                            onChange={() =>
                              handleSelectedRow ? handleSelectedRow(item) : null
                            }
                          />
                        </td>
                      )}
                      <td
                        className={`${classes.fixedCell} text-gray-600 text-sm font-medium`}
                      >
                        <div
                          className="flex justify-center items-center"
                          style={{ width: 150 }}
                        >
                          <div style={{ width: 75 }}>{item.number}</div>
                        </div>
                      </td>
                      {columns &&
                        columns.map((column) => {
                          return (
                            <td
                              key={column.id}
                              className="px-6 py-4 whitespace-nowrap text-gray-600 text-sm font-medium"
                            >
                              {column.render ? column.render(item) : null}
                              {column.key ? item[column.key] : null}
                            </td>
                          );
                        })}
                      <td
                        key={Math.random() * 1000}
                        className={`${classes.fixedCell} px-4 py-2 text-gray-600 text-sm font-medium`}
                        style={{ right: 0, textAlign: 'center' }}
                      >
                        <div
                          className="flex justify-center items-center"
                          style={{ width: 75 }}
                        >
                          <div className="flex justify-center items-center ml-5">
                            <button
                              className="text-blue-600 font-sm mr-4 font-bold cursor-pointer"
                              onClick={item.onViewClaim}
                            >
                              View
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                {claims.length < 1 && (
                  <tr>
                    <td
                      colSpan={checkbox ? columns.length + 1 : columns.length}
                      className="text-center text-gray-400 p-4"
                    >
                      {emptyMessage}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
