import React from 'react';
import { Text } from 'components/Text';

export const cardDetails = {
  image:
    'https://cdn.freebiesupply.com/logos/large/2x/visa-logo-png-transparent.png',
  merchant: 'Visa',
  number: '**** 3549',
  expiryDate: 'July 2022',
};

export const periodDetails: any = {
  monthly: {
    start: {
      price: '39',
      extra: '15% of Protection Revenue',
    },
    grow: {
      price: '59',
      extra: '12% of Protection Revenue',
    },
    scale: {
      price: '99',
      extra: 'Only 9% of Protection Revenue',
    },
  },
  yearly: {
    start: {
      price: '399',
      extra: '13% of Protection Revenue',
      extraDescription: '2% less than monthly',
      oldPrice: '468',
    },
    grow: {
      price: '599',
      extra: '10% of Protection Revenue',
      extraDescription: '2% less than monthly',
      oldPrice: '708',
    },
    scale: {
      price: '999',
      extra: 'Only 7% of Protection Revenue',
      extraDescription: '2% less than monthly',
      oldPrice: '1,188',
    },
  },
};

export interface billing {
  invoice_no: string;
  billing_date: string;
  type: string;
  description: string;
  status: string;
  total: number;
  id: number;
}

export const billings = [
  {
    invoice_no: '10001',
    billing_date: '01/10/2021',
    type: 'subscription',
    description: 'GROW Plan',
    status: 'Paid',
    total: 3,
    id: 1,
  },
  {
    invoice_no: '10002',
    billing_date: '21/10/2021',
    type: 'protection fee',
    description: 'Game Cooper',
    status: 'Open',
    total: 3,
    id: 2,
  },
  {
    invoice_no: '10003',
    billing_date: '01/12/2021',
    type: 'subscription',
    description: 'Lane Cooper',
    status: 'Paid',
    total: 3,
    id: 3,
  },
  {
    invoice_no: '10004',
    billing_date: '11/10/2021',
    type: 'subscription',
    description: 'City Cooper',
    status: 'Paid',
    total: 3,
    id: 4,
  },
  {
    invoice_no: '10001',
    billing_date: '01/10/2021',
    type: 'protection fee',
    description: 'boom Cooper',
    status: 'Open',
    total: 3,
    id: 5,
  },
];
export const columns = [
  {
    name: 'Invoice Number',
    id: 1,
    render: (item: any) => <Text value={`#${item.number ?? 'N/A'}`} />,
    value: 'number',
  },
  {
    name: 'Date',
    id: 2,
    key: 'billing_date',
    render: (item: any) => (
      <Text value={new Date(item.createdAt).toLocaleDateString()} />
    ),
    value: 'createdAt',
  },
  {
    name: 'Type',
    id: 3,
    key: 'type',
    render: (item: any) => <Text value={`${item.billType ?? 'N/A'}`} />,
    value: 'billType',
  },
  {
    name: 'Description',
    id: 4,
    key: 'description',
    render: (item: any) => (
      <Text
        value={`${
          item?.billType === 'FEES' ? 'Guarantee fee' : 'Subscription' ?? 'N/A'
        }`}
      />
    ),
    value: 'description',
  },
  {
    name: 'Total',
    id: 6,
    render: (item: any) => (
      <Text
        // The invoice figures should only be shown in USD as Shopify only charges merchants in USD
        // and not in the local currency of the store
        value={`$${item.amount}`}
      />
    ),
  },
  {
    name: 'Status',
    id: 5,
    render: (item: any) => {
      if (item.paid)
        return (
          <Text
            value={`Paid`}
            className="bg-green-100 px-3 py-1 rounded-full text-green-700 text-center w-fit"
            type="xs"
          />
        );
      if (!item.paid)
        return (
          <Text
            value={`Unpaid`}
            className="bg-red-100 px-3 py-1 rounded-full text-red-700 text-center w-fit"
            type="xs"
          />
        );
      return (
        <Text
          value={`${item.status}`}
          className="bg-blue-100 px-3 py-1 rounded-full text-blue-700 text-center w-fit"
          type="xs"
        />
      );
    },
  },
  {
    name: 'Action',
    id: 11,
    render: (item: any) => {
      if (item.invoiceUrl !== 'N/A') {
        return (
          <div className="flex justify-between">
            <a
              className="text-blue-600 font-sm mr-4 font-bold cursor-aointer"
              onClick={item.onShowDetails}
              href={item.invoiceUrl}
              target="_blank"
              rel="noreferrer"
            >
              Details
            </a>
          </div>
        );
      } else {
        return (
          <Text
            value={`${item.invoiceUrl}`}
            className="bg-blue-100 px-3 py-1 rounded-full text-blue-700 text-center w-fit"
            type="xs"
          />
        );
      }
    },
  },
];

export const subscriptionColumn = [
  {
    name: 'Invoice Number',
    id: 1,
    key: 'invoice_no',
  },
  {
    name: 'Invoice Date',
    id: 2,
    key: 'invoice_date',
  },
  {
    name: 'Active Plan ',
    id: 3,
    key: 'active_plan',
  },
  {
    name: 'Subscription Fee',
    id: 4,
    key: 'subscription_fee',
  },
];

export const subscriptionBilling = [
  {
    invoice_no: '10001',
    invoice_date: '01/10/2021',
    active_plan: 'GROW Plan',
    subscription_fee: '39',
  },
  {
    invoice_no: '10002',
    invoice_date: '21/10/2021',
    active_plan: 'Game Cooper',
    subscription_fee: '40',
  },
  {
    invoice_no: '10003',
    invoice_date: '01/12/2021',
    active_plan: 'Lane Cooper',
    subscription_fee: '45',
  },
  {
    invoice_no: '10004',
    invoice_date: '11/10/2021',
    active_plan: 'City Cooper',
    subscription_fee: '20',
  },
];

export const protectionColumn = [
  {
    name: 'Order Number',
    id: 1,
    key: 'order_no',
  },
  {
    name: 'Order Date',
    id: 2,
    key: 'order_date',
  },
  {
    name: 'Protection Paid',
    id: 3,
    key: 'protection_paid',
  },
  {
    name: 'Protection Fee',
    id: 4,
    key: 'protection_fee',
  },
];

export const protectionBilling = [
  {
    order_no: '10001',
    order_date: '01/10/2021',
    protection_paid: 'GROW Plan',
    protection_fee: '39',
  },
  {
    order_no: '10002',
    order_date: '21/10/2021',
    protection_paid: 'Game Cooper',
    protection_fee: '40',
  },
  {
    order_no: '10004',
    order_date: '11/10/2021',
    protection_paid: 'City Cooper',
    protection_fee: '20',
  },
  {
    order_no: '10001',
    order_date: '01/10/2021',
    protection_paid: 'boom Cooper',
    protection_fee: '99',
  },
];

export const planFeatures = {
  Start: [
    'Up to 200 orders per month',
    'Free expert installation',
    'Order & claim management',
    'E-mail support',
  ],
  Grow: [
    'Up to 500 orders per month',
    'Free expert installation',
    'Order & claim management',
    'E-mail support',
    'Chat support',
  ],
  Scale: [
    'Unlimited orders per month',
    'Customer Resolution Portal',
    'Free expert installation',
    'Order & claim management',
    'E-mail support',
    'Chat support',
  ],
};
