import React, { useEffect, useState, useMemo } from 'react';
import { Text } from 'components/Text';
import { Button } from 'components/Button';
import { LoadingScreen } from 'components/LoadingScreen';
import { apiErrorHandler, SizeEnum } from 'utils';
import { useMutation } from '@apollo/client';
import { useStore } from 'context/store-context';
import classNames from 'classnames';
import { UPDATE_STORE } from 'gql/mutations';
import { withNotification } from 'components/Notification';
import { NotificationTypes } from 'utils';
import { TeamImageUpload } from 'components/TeamImageUpload';
import { CustomerPortalBtn } from 'components/CustomerPortalBtn';
import { Tabs } from 'components/Tabs';
import { Toggle } from 'components/Toggle';
import { MultiSelect } from 'components/MultiSelect';
import { StoreCreditOptions } from './StoreCredit';
import Alert from 'components/Alert';
import { useNavigate } from 'react-router';
import useGodUser from 'hooks/useGodUser';
import { useUser } from 'context/user-context';

enum CustomerSettingTabs {
  COMPANY_SETTING = 'Company',
  POLICY_SETTING = 'Policy',
  RESOLUTION_SETTING = 'Resolutions',
  MEDIA_SETTING = 'Media',
}

export const Customer = withNotification(({ showNotification }: any) => {
  const navigate = useNavigate();
  const user = useUser();
  const isGodUser = useGodUser();

  const shouldShowCustomResolutionEdit = useMemo(() => {
    const isAdmin = user.role === 'ADMIN';
    return isGodUser || isAdmin;
  }, [user, isGodUser]);

  const store = useStore();
  const [teamData, setTeamData] = useState({
    companyName: '',
    companyEmail: '',
    companyPhone: '',
    companyWebsite: '',
    logoUrl: '',
    bannerUrl: '',
    requireDeliveryGuaranteePolicy: false,
    policyUrl: '',
    enableStoreCredit: false,
    storeCreditOptions: [],
    requirePreferredResolution: false,
    isPreferredResolutionMandatory: false,
    isLateClaimCauseEnabled: true,
    isStolenClaimCauseEnabled: true,
    requireVideoUpload: false,
    isVideoUploadMandatory: false,
  });

  const [customResolutionNotes, setCustomResolutionNotes] = useState({
    reorder: {
      enabled: false,
      text: '',
    },
    refund: {
      enabled: false,
      text: '',
    },
  });

  useEffect(() => {
    if (store?.storeProperties?.customResolutionNotes) {
      const reorder = store?.storeProperties?.customResolutionNotes?.reorder;
      const refund = store?.storeProperties?.customResolutionNotes?.refund;
      setCustomResolutionNotes({
        reorder: {
          enabled: Boolean(reorder?.enabled),
          text:
            typeof reorder?.text === 'string'
              ? reorder?.text
              : 'Re-ordered by ShipAid',
        },
        refund: {
          enabled: Boolean(refund?.enabled),
          text:
            typeof refund?.text === 'string'
              ? refund?.text
              : 'Refunded by ShipAid',
        },
      });
    }
  }, [store?.storeProperties?.customResolutionNotes]);

  const { storeId } = store;
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedField, setSelectedField] = useState<string | null>(null);
  const [selectedUser, setSelectedUser] = useState<any | null>(null);

  const [selectedTab, setSelectedTab] = useState<CustomerSettingTabs>(
    CustomerSettingTabs.COMPANY_SETTING,
  );

  const [
    updateStore,
    { error: updateStoreError, loading: updateStoreLoading },
  ] = useMutation(UPDATE_STORE, {
    onError(error) {
      const newError = apiErrorHandler(error);
      showNotification(NotificationTypes.error, newError?.message);
    },
  });

  useEffect(() => {
    if (store.storeProperties) {
      setTeamData({
        companyName: store.storeProperties?.name ?? '',
        companyEmail: store.storeProperties?.contactEmail ?? '',
        companyPhone: store.storeProperties?.contactPhone ?? '',
        companyWebsite: store.storeProperties?.store ?? '',
        logoUrl: store.storeProperties.logoUrl ?? '',
        bannerUrl: store.storeProperties.bannerUrl ?? '',
        requireDeliveryGuaranteePolicy:
          store.storeProperties.requireDeliveryGuaranteePolicy ?? false,
        policyUrl: store.storeProperties.policyUrl ?? '',
        requirePreferredResolution:
          store.storeProperties.resolvePortalOptions
            .requirePreferredResolution ?? false,
        isPreferredResolutionMandatory:
          store.storeProperties.resolvePortalOptions
            .isPreferredResolutionMandatory ?? false,
        isLateClaimCauseEnabled:
          store.storeProperties.resolvePortalOptions.isLateClaimCauseEnabled ??
          true,
        isStolenClaimCauseEnabled:
          store.storeProperties.resolvePortalOptions
            .isStolenClaimCauseEnabled ?? true,
        requireVideoUpload:
          store.storeProperties.resolvePortalOptions.requireVideoUpload ??
          false,
        isVideoUploadMandatory:
          store.storeProperties.resolvePortalOptions.isVideoUploadMandatory ??
          false,
        enableStoreCredit:
          store.storeProperties.resolvePortalOptions.enableStoreCredit ?? false,
        storeCreditOptions:
          store.storeProperties.resolvePortalOptions.storeCreditOptions ?? [],
      });
    }
  }, [store.storeProperties, setTeamData]);

  useEffect(() => {
    if (
      store.storeProperties &&
      !store.storeProperties.completedSteps?.hasVisitedResolutionPage
    ) {
      updateStore({
        variables: {
          storeId,
          set: {
            completedSteps: {
              ...store.storeProperties.completedSteps,
              hasVisitedResolutionPage: true,
            },
          },
        },
      });
    }
  });

  useEffect(() => {
    if (selectedUser) {
      setShowConfirmationModal(true);
    }
  }, [selectedUser]);

  useEffect(() => {
    if (!showConfirmationModal) {
      setSelectedUser(null);
    }
  }, [showConfirmationModal]);

  const handleChangeTeam = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setTeamData({ ...teamData, [name]: value });
  };

  const handleCustomNoteText = (
    field: 'refund' | 'reorder',
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value: text } = event.target;
    setCustomResolutionNotes((customResolutionNotes) => ({
      ...customResolutionNotes,
      [field]: {
        ...customResolutionNotes[field],
        text,
      },
    }));
  };

  const handleCustomNoteEnabled = (field: 'reorder' | 'refund', v: boolean) =>
    setCustomResolutionNotes((prev) => ({
      ...prev,
      [field]: {
        ...prev[field],
        enabled: v,
      },
    }));

  const handleUpdateStore = () => {
    if (
      teamData.requireDeliveryGuaranteePolicy &&
      (!teamData.policyUrl || teamData.policyUrl === '')
    ) {
      return showNotification(
        NotificationTypes.error,
        'Please provide a valid Policy URL',
      );
    }

    if (teamData.enableStoreCredit && !teamData.storeCreditOptions?.length) {
      return showNotification(
        NotificationTypes.error,
        'Please select atleast 1 store credit option',
      );
    }
    updateStore({
      variables: {
        storeId,
        set: {
          contactEmail: teamData.companyEmail,
          name: teamData.companyName,
          contactPhone: teamData.companyPhone,
          policyUrl: teamData.policyUrl,
          requireDeliveryGuaranteePolicy:
            teamData.requireDeliveryGuaranteePolicy,
          resolvePortalOptions: {
            requirePreferredResolution: teamData.requirePreferredResolution,
            isPreferredResolutionMandatory:
              teamData.isPreferredResolutionMandatory,
            isLateClaimCauseEnabled: teamData.isLateClaimCauseEnabled,
            isStolenClaimCauseEnabled: teamData.isStolenClaimCauseEnabled,
            requireVideoUpload: teamData.requireVideoUpload,
            isVideoUploadMandatory: teamData.isVideoUploadMandatory,
            enableStoreCredit: teamData.enableStoreCredit,
            storeCreditOptions: teamData.storeCreditOptions,
          },
          customResolutionNotes,
        },
      },
    })
      .then((data) => {
        setSelectedField(null);
        return showNotification(
          NotificationTypes.success,
          'Company details updated',
        );
      })
      .catch((e) => {
        return showNotification(
          NotificationTypes.error,
          updateStoreError ? updateStoreError.message : 'Details not updated',
        );
      });
  };

  const handleCompanyLogoUpdate = (
    imgUrl: string,
    field: string,
    label: string = 'Image',
  ) => {
    updateStore({
      variables: { storeId, set: { [field]: imgUrl } },
    })
      .then((data) => {
        setSelectedField(null);
        store.refetchStoreProperties && store.refetchStoreProperties();
        return showNotification(
          NotificationTypes.success,

          `${label} updated`,
        );
      })
      .catch((err) => {
        return showNotification(
          NotificationTypes.error,
          updateStoreError
            ? updateStoreError.message
            : `Could not update ${label.toLowerCase()} photo`,
        );
      });
  };

  const shouldUpdateAppInstall = store?.storeProperties?.shouldUpdateAppInstall;

  const onRedirectToPermissions = () => {
    navigate('/settings/integration');
  };

  if (store?.storeLoading && !store?.storeProperties) return <LoadingScreen />;

  return (
    <div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <div className="flex items-center justify-between max-w-2xl">
          <Text value="Resolution Settings" type="h1" />
          <div>
            <CustomerPortalBtn />
          </div>
        </div>

        <Tabs
          tabs={Object.values(CustomerSettingTabs).map((t) => ({ name: t }))}
          selectedTab={selectedTab}
          setSelectedTab={(t: string) =>
            setSelectedTab(t as CustomerSettingTabs)
          }
        />
      </div>

      {selectedTab === CustomerSettingTabs.COMPANY_SETTING && (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-3">
          <TeamImageUpload
            onUpload={({ urls }) =>
              handleCompanyLogoUpdate(urls[0], 'logoUrl', 'Company logo')
            }
            image={teamData?.logoUrl}
            isLoading={updateStoreLoading}
            id="logo"
            heading="Company Logo"
            subheading="Click image to select (min resolution for best results: 150px x 150px. Maintain aspect ratio)"
          />
          <div className="mt-3">
            <TeamImageUpload
              onUpload={({ urls }) =>
                handleCompanyLogoUpdate(urls[0], 'bannerUrl', 'Company banner')
              }
              id="banner"
              image={teamData?.bannerUrl}
              isLoading={updateStoreLoading}
              heading="Company Banner"
              subheading="Click image to select (min resolution for best results: 1280px x 240px. Maintain aspect ratio)"
            />
            <div
              className="flex w-full sm:w-1/2 justify-between py-4"
              style={{ width: '50%' }}
            >
              <div>
                <Text
                  value="Company Email"
                  type="body"
                  className="text-gray-600 font-bold"
                />
                <input
                  type="text"
                  name="companyEmail"
                  id="companyEmail"
                  className={classNames(
                    selectedField === 'companyEmail'
                      ? 'border-b border-blue-400 focus:border-blue-400'
                      : 'bg-transparent',
                    'focus:ring-0 focus:border-0 block w-full sm:text-sm outline-0 text-gray-600 py-2 pr-2',
                  )}
                  placeholder="you@example.com"
                  value={teamData.companyEmail}
                  onChange={handleChangeTeam}
                  disabled={selectedField === 'companyEmail' ? false : true}
                />
              </div>
              <Button
                onClick={() => setSelectedField('companyEmail')}
                size={SizeEnum.md}
                className="h-max px-6"
              >
                Edit
              </Button>
            </div>

            <div
              className="flex w-full sm:w-1/2 justify-between py-4"
              style={{ width: '50%' }}
            >
              <div>
                <Text
                  value="Company Name"
                  type="body"
                  className="text-gray-600 font-bold"
                />
                <input
                  type="text"
                  name="companyName"
                  id="email"
                  className={classNames(
                    selectedField === 'companyName'
                      ? 'border-b border-blue-400'
                      : 'bg-transparent',
                    'focus:ring-0 focus:border-0 block w-full sm:w-96 sm:text-sm outline-0 text-gray-600 py-2 pr-2',
                  )}
                  placeholder="you@example.com"
                  value={teamData.companyName}
                  onChange={handleChangeTeam}
                  disabled={selectedField === 'companyName' ? false : true}
                />
              </div>
              <Button
                onClick={() => setSelectedField('companyName')}
                size={SizeEnum.md}
                className="h-max px-6"
              >
                Edit
              </Button>
            </div>

            <div
              className="flex w-full sm:w-1/2 justify-between py-4"
              style={{ width: '50%' }}
            >
              <div>
                <Text
                  value="Company Phone"
                  type="body"
                  className="text-gray-600 font-bold"
                />
                <input
                  type="text"
                  name="companyPhone"
                  id="email"
                  className={classNames(
                    selectedField === 'companyPhone'
                      ? 'border-b border-blue-400 focus:border-blue-400'
                      : 'bg-transparent',
                    'focus:ring-0 focus:border-0 block w-full sm:text-sm outline-0 text-gray-600 py-2 pr-2',
                  )}
                  placeholder="eg: +1-541-754-3010"
                  value={teamData.companyPhone}
                  onChange={handleChangeTeam}
                  disabled={selectedField === 'companyPhone' ? false : true}
                />
              </div>
              <Button
                onClick={() => setSelectedField('companyPhone')}
                size={SizeEnum.md}
                className="h-max px-6"
              >
                Edit
              </Button>
            </div>

            <div
              className="flex w-full sm:w-1/2 justify-between py-4"
              style={{ width: '50%' }}
            >
              <div>
                <Text
                  value="Company Store Domain"
                  type="body"
                  className="text-gray-600 font-bold"
                />
                <Text
                  value={teamData.companyWebsite}
                  className="py-2 pr-2 text-gray-600"
                  type="sm"
                />
              </div>
              {/* <Button
              onClick={() => setSelectedField('companyWebsite')}
              size={SizeEnum.md}
              className="border-blue-600 bg-transparent text-blue-600 hover:bg-blue-50 h-max px-6"
            >
              Edit
            </Button> */}
            </div>
          </div>
        </div>
      )}

      {selectedTab === CustomerSettingTabs.POLICY_SETTING && (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-3">
          <div className="mt-3">
            <div
              className="md:flex justify-between items-center py-4"
              style={{ width: '50%' }}
            >
              <div className="md:flex items-center">
                <Text
                  value="Refund Policy"
                  type="body"
                  className="text-gray-600 font-bold"
                />
              </div>
              <div className="flex justify-end">
                <Toggle
                  isEnabled={teamData.requireDeliveryGuaranteePolicy}
                  onChange={(v: boolean) =>
                    setTeamData({
                      ...teamData,
                      requireDeliveryGuaranteePolicy: v,
                    })
                  }
                  disabled={!teamData.requireDeliveryGuaranteePolicy}
                />
              </div>
            </div>
            <div
              className="flex w-full sm:w-1/2 justify-between py-4"
              style={{ width: '50%' }}
            >
              <div>
                <Text
                  value="Policy URL"
                  type="body"
                  className="text-gray-600 font-bold"
                />
                <input
                  type="text"
                  name="policyUrl"
                  id="policyUrl"
                  className={classNames(
                    selectedField === 'policyUrl'
                      ? 'border-b border-blue-400'
                      : 'bg-transparent',
                    'focus:ring-0 focus:border-0 block w-full sm:w-96 sm:text-sm outline-0 text-gray-600 py-2 pr-2',
                  )}
                  // placeholder="you@example.com"
                  value={teamData.policyUrl}
                  onChange={handleChangeTeam}
                  disabled={selectedField === 'policyUrl' ? false : true}
                />
              </div>
              <Button
                onClick={() => setSelectedField('policyUrl')}
                size={SizeEnum.md}
                className="h-max px-6"
              >
                Edit
              </Button>
            </div>
          </div>
        </div>
      )}

      {selectedTab === CustomerSettingTabs.RESOLUTION_SETTING && (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-3">
          <div className="mt-3">
            {shouldUpdateAppInstall && (
              <Alert
                message="You need to upgrade Shopify permissions before using store credits"
                type="warning"
                actions={[
                  <Button
                    key={'action'}
                    onClick={onRedirectToPermissions}
                    type="button"
                  >
                    Upgrade Permissions
                  </Button>,
                ]}
              />
            )}

            <div
              className="md:flex justify-between items-center py-4"
              style={{ width: '50%' }}
            >
              <div className="md:flex items-center">
                <Text
                  value="Enable Store Credit"
                  type="body"
                  className="text-gray-600 font-bold"
                />
              </div>
              <div className="flex justify-end">
                <Toggle
                  isEnabled={teamData.enableStoreCredit}
                  onChange={(v: boolean) => {
                    if (shouldUpdateAppInstall) {
                      return onRedirectToPermissions();
                    }

                    setTeamData({
                      ...teamData,
                      enableStoreCredit: v,
                      storeCreditOptions: !v ? [] : teamData.storeCreditOptions,
                    });
                  }}
                  disabled={!teamData.enableStoreCredit}
                />
              </div>
            </div>
            {teamData.enableStoreCredit && (
              <div
                className="md:flex justify-between items-center py-2"
                style={{ width: '50%' }}
              >
                <div className="md:flex items-center">
                  <Text
                    value="Select Store Credit Options"
                    type="body"
                    className="text-gray-600 font-bold"
                  />
                </div>
                <div className="flex justify-end">
                  <MultiSelect
                    placeholder="Select Options"
                    selected={teamData.storeCreditOptions}
                    setSelected={(options: any[]) =>
                      setTeamData({
                        ...teamData,
                        storeCreditOptions: options as never[],
                      })
                    }
                    options={Object.values(StoreCreditOptions).map((c) => ({
                      label: c.replace(/_/g, ' '),
                      val: c,
                      disabled:
                        !store.storeProperties?.isStoreShopifyPlus &&
                        c === StoreCreditOptions.GiftCard,
                    }))}
                    style={{ width: '225px' }}
                  />
                </div>
              </div>
            )}

            <div
              className="md:flex justify-between items-center py-4"
              style={{ width: '50%' }}
            >
              <div className="md:flex items-center">
                <Text
                  value="Preferred Resolution"
                  type="body"
                  className="text-gray-600 font-bold"
                />
              </div>
              <div className="flex justify-end">
                <Toggle
                  isEnabled={teamData.requirePreferredResolution}
                  onChange={(v: boolean) =>
                    setTeamData({
                      ...teamData,
                      requirePreferredResolution: v,
                      isPreferredResolutionMandatory: !v
                        ? false
                        : teamData.isPreferredResolutionMandatory,
                    })
                  }
                  disabled={!teamData.requirePreferredResolution}
                />
              </div>
            </div>
            {teamData.requirePreferredResolution ? (
              <div
                className="md:flex justify-between items-center py-4"
                style={{ width: '50%' }}
              >
                <div className="md:flex items-center">
                  <Text
                    value="Mandatory Preferred Resolution"
                    type="body"
                    className="text-gray-600 font-bold"
                  />
                </div>
                <div className="flex justify-end">
                  <Toggle
                    isEnabled={teamData.isPreferredResolutionMandatory}
                    onChange={(v: boolean) =>
                      setTeamData({
                        ...teamData,
                        isPreferredResolutionMandatory: v,
                      })
                    }
                    disabled={!teamData.isPreferredResolutionMandatory}
                  />
                </div>
              </div>
            ) : null}
            <div
              className="md:flex justify-between items-center py-4"
              style={{ width: '50%' }}
            >
              <div className="md:flex items-center">
                <Text
                  value="Issue Cause: Late"
                  type="body"
                  className="text-gray-600 font-bold"
                />
              </div>
              <div className="flex justify-end">
                <Toggle
                  isEnabled={teamData.isLateClaimCauseEnabled}
                  onChange={(v: boolean) =>
                    setTeamData({
                      ...teamData,
                      isLateClaimCauseEnabled: v,
                    })
                  }
                  disabled={!teamData.isLateClaimCauseEnabled}
                />
              </div>
            </div>
            <div
              className="md:flex justify-between items-center py-4"
              style={{ width: '50%' }}
            >
              <div className="md:flex items-center">
                <Text
                  value="Issue Cause: Stolen"
                  type="body"
                  className="text-gray-600 font-bold"
                />
              </div>
              <div className="flex justify-end">
                <Toggle
                  isEnabled={teamData.isStolenClaimCauseEnabled}
                  onChange={(v: boolean) =>
                    setTeamData({
                      ...teamData,
                      isStolenClaimCauseEnabled: v,
                    })
                  }
                  disabled={!teamData.isStolenClaimCauseEnabled}
                />
              </div>
            </div>
          </div>
          {shouldShowCustomResolutionEdit && (
            <div className="mt-6">
              <Text
                value="Custom Resolution Notes"
                type="h4"
                className="text-gray-800 font-bold mb-4"
              />
              <div className="space-y-4 md:flex md:space-y-0 md:space-x-4">
                <div className="md:w-1/2">
                  <div className="flex justify-between items-center mb-2">
                    <Text
                      value="ReOrder"
                      type="body"
                      className="text-gray-600 font-bold"
                    />
                    <Toggle
                      isEnabled={customResolutionNotes.reorder.enabled}
                      onChange={handleCustomNoteEnabled.bind(null, 'reorder')}
                      disabled={!customResolutionNotes.reorder.enabled}
                    />
                  </div>
                  <input
                    type="text"
                    value={customResolutionNotes?.reorder?.text}
                    onChange={handleCustomNoteText.bind(null, 'reorder')}
                    disabled={!customResolutionNotes.reorder.enabled}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                <div className="md:w-1/2">
                  <div className="flex justify-between items-center">
                    <Text
                      value="Refund"
                      type="body"
                      className="text-gray-600 font-bold"
                    />
                    <Toggle
                      isEnabled={customResolutionNotes.refund.enabled}
                      onChange={handleCustomNoteEnabled.bind(null, 'refund')}
                      disabled={!customResolutionNotes.refund.enabled}
                    />
                  </div>
                  <input
                    type="text"
                    value={customResolutionNotes?.refund?.text}
                    disabled={!customResolutionNotes.refund.enabled}
                    onChange={handleCustomNoteText.bind(null, 'refund')}
                    className="mt-2 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {selectedTab === CustomerSettingTabs.MEDIA_SETTING && (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-3">
          <div className="mt-3">
            <div
              className="md:flex justify-between items-center py-4"
              style={{ width: '50%' }}
            >
              <div className="md:flex items-center">
                <Text
                  value="Video Upload"
                  type="body"
                  className="text-gray-600 font-bold"
                />
              </div>
              <div className="flex justify-end">
                <Toggle
                  isEnabled={teamData.requireVideoUpload}
                  onChange={(v: boolean) =>
                    setTeamData({
                      ...teamData,
                      requireVideoUpload: v,
                      isVideoUploadMandatory: !v
                        ? false
                        : teamData.isVideoUploadMandatory,
                    })
                  }
                  disabled={!teamData.requireVideoUpload}
                />
              </div>
            </div>
            {teamData.requireVideoUpload && (
              <div
                className="md:flex justify-between items-center py-4"
                style={{ width: '50%' }}
              >
                <div className="md:flex items-center">
                  <Text
                    value="Video Upload Mandatory"
                    type="body"
                    className="text-gray-600 font-bold"
                  />
                </div>
                <div className="flex justify-end">
                  <Toggle
                    isEnabled={teamData.isVideoUploadMandatory}
                    onChange={(v: boolean) =>
                      setTeamData({
                        ...teamData,
                        isVideoUploadMandatory: v,
                      })
                    }
                    disabled={!teamData.isVideoUploadMandatory}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-3">
        <Button
          onClick={handleUpdateStore}
          size={SizeEnum.md}
          className="bg-blue-600 text-white hover:bg-blue-700 h-max px-6 mt-4"
          isLoading={updateStoreLoading}
        >
          Update Settings
        </Button>
      </div>
    </div>
  );
});
