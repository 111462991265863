import React, { useState } from 'react';
import { Text } from 'components/Text';
import { DataTable } from 'components/DataTable';
import { columns, people, stats } from './data/components-data';
import { DataFilter } from 'components/DataFilter';
import { SearchBox } from 'components/Search';
import { NotificationBar } from 'components/NotificationBar';
import { Pagination } from 'components/Pagination';
import { Stats } from 'components/Stats';
import { Toggle } from 'components/Toggle';
import { Button } from 'components/Button';
import { DatePicker } from 'components/DatePicker';
import { CheckIcon } from '@heroicons/react/outline';
import { PackagePlan } from 'components/PackagePlan';
import { Tabs } from 'components/Tabs';
import { MultiSelect } from 'components/MultiSelect';
import { AccountImageUpload } from 'components/AccountImageUpload';
import { ClaimsImagesUpload } from 'components/ClaimsImagesUpload';
import { CompanyLogoUpload } from 'components/CompanyLogoUpload';
import { Modal } from 'components/Modal';
import { AutoComplete } from 'components/AutoComplete';

export const Components = () => {
  // to test the filter
  const [filterByoption, setFilterByoption] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(1);
  const [allPeople, setAllPeople] = useState(people);
  const [booleanToggle, setBooleanToggle] = useState(false);
  const [showModal, setModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [selectedTab, setSelectedTab] = useState('Company');
  const [selectedOptions, setSelectedOptions] = useState<(string | number)[]>(
    [],
  );
  const [autoCompleteValue, setAutoCompleteValue] = useState(null);
  // TEST IMAGE UPLOAD COMPONENT
  const [, setImageUrl] = useState('');
  const [imageUrls, setImageUrls] = useState<string[]>([]);

  const filterOption = people.map((item: any) => item.role);

  const onFilter = (option: string, category: string) => {
    if (option === '') {
      setFilterByoption(option);
      return setAllPeople(people);
    }
    setFilterByoption(option);
    const filteredPeople = [...people].filter(
      (item: any) => item[category] === option,
    );
    setAllPeople(filteredPeople);
  };

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const onChangeAutoCompleteValue = (selectedOption: any) => {
    setAutoCompleteValue(selectedOption);
  };

  return (
    <div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <Text value="All Components" type="h1" />
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-4">
        <Text
          value="Notification bar"
          type="h4"
          className="text-gray-500 mb-3"
        />
        <div className=" mb-4">
          <NotificationBar
            message="This is just to test the notification bar at the top and it takes the width of its container"
            action="Learn more"
            actionHandler={() => null}
          />
        </div>
        <Text value="Search box" type="h4" className="text-gray-500 mb-3" />
        <div className="w-64 mb-4">
          <SearchBox
            search={() => {}}
            searchValue={searchValue}
            onChange={(searchValue) => setSearchValue(searchValue)}
          />
        </div>
        <Text value="Data filter" type="h4" className="text-gray-500 mb-3" />
        <DataFilter
          filterBy="role"
          options={filterOption}
          setSelectedOption={(option) => onFilter(option, 'role')}
          selectedOption={filterByoption}
        />
        {/**data filter just to test the filter*/}
        <Text
          value="Data Table"
          type="h4"
          className="text-gray-500 mb-3 mt-3"
        />
        <DataTable data={allPeople} columns={columns} />
        {/**data table just to test the table*/}
        <Text
          value="Pagination"
          type="h4"
          className="text-gray-500 mb-3 mt-4"
        />
        <Pagination
          totalPages={1}
          currentPage={page}
          resultsRange={10}
          totalResults={allPeople.length}
          onPageClick={onPageChange}
        />
        {/* stats component */}
        <Text value="Stats" type="h4" className="text-gray-500 mt-4" />
        <Stats stats={stats} isSpaced />
        {/* toggle component */}
        <Text
          value="Boolean Toggle"
          type="h4"
          className="text-gray-500 mb-4 mt-4"
        />
        <Toggle
          isEnabled={booleanToggle}
          onChange={(val) => setBooleanToggle(val)}
          leftLabel="asd"
          leftDescription="asdasd"
          rightLabel="asd"
          rightDescription="asdasd"
        />
        {/* button component */}
        <Text value="Button" type="h4" className="text-gray-500 mb-4 mt-4" />
        <div className="flex">
          <Button
            onClick={() => {
              console.log('button clicked 1');
            }}
            variant="outlined"
            color="danger"
          >
            click me
          </Button>
          <Button
            className="ml-5"
            onClick={() => {
              console.log('button clicked 2');
            }}
          >
            click me
          </Button>
          <Button
            className="ml-5"
            onClick={() => {
              console.log('button clicked 3');
            }}
            isIconOnly
            variant="outlined"
          >
            <CheckIcon className="h-5 w-5" />
          </Button>
        </div>
        {/* Date picker */}
        <Text
          value="Date Picker"
          type="h4"
          className="text-gray-500 mb-4 mt-4"
        />
        <div className="sm:flex">
          <DatePicker
            value={selectedDate}
            setValue={setSelectedDate}
            isClearable
            placeHolder="Select date"
            showYearDropdown
          />
          <DatePicker
            className="sm:ml-5 mt-3 sm:mt-0"
            selectsRange
            startDate={dateRange[0]}
            endDate={dateRange[1]}
            setValue={setDateRange}
            placeHolder="Select range"
          />
        </div>
        <Text
          value="Package plan"
          type="h4"
          className="text-gray-500 mb-3 mt-4"
        />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-5 sm:grid-cols-3">
          <PackagePlan
            header="Percentage"
            action="Edit"
            actionHandler={() => null}
            active
          >
            <div className="flex items-end my-4 px-5">
              <Text value="$3" type="h2" className="font-bolder mr-2" />
              <Text value="minimum" type="sm" className="text-gray-500 mb-1" />
            </div>
            <div className="flex items-end px-5 py-4">
              <Text value="3%" type="h2" className="font-bolder mr-2" />
              <Text value="/order" type="sm" className="text-gray-500 mb-1" />
            </div>
          </PackagePlan>
          <PackagePlan
            header="Fixed"
            action="Activate"
            actionHandler={() => null}
            bottomBorder
          >
            <div className="flex items-end my-4 px-5">
              <Text value="$3" type="h2" className="font-bolder mr-2" />
              <Text value="minimum" type="sm" className="text-gray-500 mb-1" />
            </div>
            <div className="flex items-end px-5 py-4">
              <Text value="3%" type="h2" className="font-bolder mr-2" />
              <Text value="/order" type="sm" className="text-gray-500 mb-1" />
            </div>
          </PackagePlan>
        </div>
        <div className="mt-12">
          <AccountImageUpload onUpload={({ urls }) => setImageUrl(urls[0])} />
          <hr className="my-8" />
          <CompanyLogoUpload onUpload={({ urls }) => setImageUrl(urls[0])} />
          <hr className="my-8" />
          <ClaimsImagesUpload
            onUpload={({ urls }: { urls: string[] }) =>
              setImageUrls([...imageUrls, ...urls])
            }
          />
        </div>
        <Text value="Tabs" type="h4" className="text-gray-500 mt-6" />
        <Tabs
          tabs={[
            { name: 'My Account' },
            { name: 'Company' },
            { name: 'Team Members' },
            { name: 'Billing' },
          ]}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        <Text
          value="Multi Select"
          type="h4"
          className="text-gray-500 mt-4 mb-4"
        />
        <MultiSelect
          label="Choose options"
          placeholder="Select Options"
          selected={selectedOptions}
          setSelected={setSelectedOptions}
          options={['option 1', 'option 2', 'option 3', 'option 4', 'option 5']}
        />
        <AutoComplete
          options={[
            { id: 1, name: 'Wade Cooper' },
            { id: 2, name: 'Arlene Mccoy' },
            { id: 3, name: 'Devon Webb' },
            { id: 4, name: 'Tom Cook' },
            { id: 5, name: 'Tanya Fox' },
            { id: 6, name: 'Hellen Schmidt' },
          ]}
          onChange={onChangeAutoCompleteValue}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          selected={autoCompleteValue}
        />
        <div>
          {/* Modal */}
          <Text value="Modal" type="h4" className="text-gray-500 mb-4 mt-4" />
          <Button
            onClick={() => setModalOpen(true)}
            className="bg-blue-600 hover:bg-blue-700 focus:outline-0"
          >
            Open Modal
          </Button>
          <Modal
            openModal={(value) => setModalOpen(value)}
            open={showModal}
            containerClass="sm:max-w-lg sm:w-full"
          />
        </div>
      </div>
    </div>
  );
};
