import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/outline';
import { SidebarNavChildren } from './SidebarNavChildren';
import { NavItem } from 'types/sidebar';

type SidebarNavDivProps = {
  item: NavItem;
};

export const SidebarNavDiv = ({ item }: SidebarNavDivProps) => {
  const [showChildren, setShowChildren] = useState(false);
  const location = useLocation();
  const iconClassName =
    'text-gray-500 group-hover:text-gray-700 ml-6 flex-shrink-0 h-5 w-5';
  return (
    <>
      <div
        className={classNames(
          location.pathname.split('/').includes(item.key)
            ? 'bg-blue-50 border-blue-600 text-blue-600'
            : 'border-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900',
          'group flex items-center px-3 py-4 text-sm font-medium border-l-4 cursor-pointer justify-between',
        )}
        onClick={() => (item?.children ? setShowChildren(!showChildren) : null)}
      >
        <div className="group flex items-center">
          <item.icon
            className={classNames(
              location.pathname.split('/').includes(item.key)
                ? 'text-blue-500'
                : 'text-gray-400 group-hover:text-gray-500',
              'mr-3 flex-shrink-0 h-6 w-6',
            )}
            aria-hidden="true"
          />
          {item.name}
        </div>
        {item.children ? (
          !showChildren ? (
            <ChevronRightIcon className={iconClassName} />
          ) : (
            <ChevronDownIcon className={iconClassName} />
          )
        ) : null}
      </div>
      {item.children &&
        item.children.map((item) => (
          <SidebarNavChildren
            showChildren={showChildren}
            item={item}
            key={item.to}
          />
        ))}
    </>
  );
};
