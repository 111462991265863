import React from 'react';
import { SizeEnum } from 'utils';
import { Button } from './Button';
import { Text } from './Text';
import ErrorImg from '../assets/images/500_error.svg';

type ErrorBoundaryProps = {
  children?: React.ReactNode;
};
class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  state = {
    errorMessage: '',
  };

  static getDerivedStateFromError(error: any) {
    return { errorMessage: error.toString() };
  }

  componentDidCatch(error: any, info: any) {
    this.logErrorToServices(error.toString(), info.componentStack);
  }

  onRefresh = () => {
    window.location.href = '/';
  };

  // A fake logging service.
  logErrorToServices = console.log;

  render() {
    if (this.state.errorMessage) {
      return (
        <div className="w-full flex flex-col items-center mt-24">
          <img src={ErrorImg} alt="error" className="w-72 sm:w-96" />
          <Text
            value="Oops! Something went wrong"
            type="h1"
            className="mt-5 text-gray-800 text-center"
          />
          <Text
            value="We understand that this must feel really inconvenient right now, we apologize for that. Please click the button below to give us another chance."
            type="sm"
            className="text-gray-500 w-72 sm:w-96 text-center mt-2"
          />
          <Button
            onClick={this.onRefresh}
            size={SizeEnum.md}
            className="w-fit mt-4"
          >
            {'<'} Go back home
          </Button>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
