import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { Text } from 'components/Text';
import { LoadingScreen } from 'components/LoadingScreen';
import { useParams, useLocation } from 'react-router-dom';
import { GET_CLAIM } from 'gql/queries';
import { DownloadIcon } from '@heroicons/react/outline';
import {
  DENY_CLAIM,
  REFUND_CLAIM,
  REORDER_CLAIM,
  INSERT_CLAIM_IMAGES,
  REMOVE_CLAIM_IMAGE,
  OTHER_CLAIM,
  STORE_CREDIT_CLAIM,
  INSERT_CLAIM_VIDEOS,
  REMOVE_CLAIM_VIDEO,
} from 'gql/mutations';

import {
  Claim,
  ClaimItemResolvedStatusEnum,
  ClaimItemValues,
  statusType,
} from 'types/claim';
import { Order } from 'types/order';
import { ClaimsImagesUpload } from 'components/ClaimsImagesUpload';
import { Reorder } from './Reorder';
import { Refund } from './Refund';
import { Other } from './Other';
import { Denyclaim } from './DenyClaim';
import { format } from 'date-fns';
import { useStore } from 'context/store-context';
import { ResolutionRefund } from 'components/ResolutionRefund';
import { ResolutionDeny } from 'components/ResolutionDeny';
import { ResolutionReorder } from 'components/ResolutionReorder';
import { ResolutionOther } from 'components/ResolutionOther';
import { Back } from 'components/Back';
import classNames from 'classnames';
import {
  formatCurrency,
  getStoreCurrencyFormatter,
  apiErrorHandler,
  formatStatusText,
  getThumnailUrl,
} from 'utils';
import { withNotification } from 'components/Notification';
import { NotificationTypes } from 'utils';
import { Modal } from 'components/Modal';
import ClaimItemsList from 'components/ClaimItemsList';
import { Dropdown } from 'components/Dropdown';
import { StoreCredit } from './StoreCredit';
import { ResolutionStoreCredit } from 'components/ResolutionStoreCredit';
import { ClaimsVideosUpload } from 'components/ClaimsVideosUpload';
import { ViewVideo } from './ViewVideo';

const resolvedStatuses = [
  'RESOLVED',
  'PARTIALLY_RESOLVED',
  'DENIED',
  'ARCHIVED',
];

const statusColorClasses = (status: string | undefined) => {
  if (!status) {
    return '';
  }
  switch (status) {
    case statusType.denied:
      return 'text-red-700 bg-red-100';
    case statusType.partially_resolved:
      return 'text-yellow-700 bg-yellow-100';
    case statusType.resolved:
      return 'bg-green-100 text-green-700';
    default:
      return 'text-blue-700 bg-blue-100';
  }
};

export const ClaimResolvedStatusBadges = ({
  status,
  customCss = '',
}: {
  status: string | undefined;
  customCss?: string;
}) => {
  let textAndBgColorCss = '';

  if (!status) {
    return null;
  }
  switch (status) {
    case ClaimItemResolvedStatusEnum.DENY:
      textAndBgColorCss = 'text-red-700 bg-red-100';
      break;
    default:
      textAndBgColorCss = 'text-blue-700 bg-blue-100';
  }
  return (
    <Text
      value={formatStatusText(status)}
      type="bold"
      className={`px-3 py-1 rounded text-center ${textAndBgColorCss} ${customCss}`}
    />
  );
};

export const ClaimResolutionOptions = (settings: {
  enableStoreCredit: boolean;
}) =>
  Object.values(ClaimItemResolvedStatusEnum)
    .filter(
      (i) =>
        ![
          ClaimItemResolvedStatusEnum.IN_REVIEW,
          !settings.enableStoreCredit
            ? ClaimItemResolvedStatusEnum.STORE_CREDIT
            : '',
        ].includes(i),
    )
    .map((key) => ({
      key,
      render: (
        <div className="px-2 py-1">
          <ClaimResolvedStatusBadges status={key} customCss="px-2" />
        </div>
      ),
    }));

const ClaimResolutionModalHeader = ({
  claim,
  claimItem,
  resolution,
  storeProperties,
}: {
  resolution?: any;
  claim: any;
  claimItem?: any;
  storeProperties: any;
}) => {
  return (
    <section className="mt-8">
      <div>
        <Text
          value={`Resolution`}
          type="h1"
          className="font-normal text-blue-700"
        />

        {!claimItem ? (
          <div className="flex justify-start items-center flex-wrap">
            <Text
              value={'Proceed to resolve this item/s as'}
              type="body"
              className="text-gray-500 font-normal mr-2"
            />
            <ClaimResolvedStatusBadges status={resolution} />
          </div>
        ) : (
          <Text
            value={'See issue resolution details below for the above item'}
            type="body"
            className="text-gray-500 font-normal"
          />
        )}
      </div>
      {storeProperties?.resolvePortalOptions?.requirePreferredResolution ? (
        <div>
          <div>
            <Text
              value={`Preferred Resolution: ${
                formatStatusText(claim?.preferredResolution) ?? 'N/A'
              }`}
              type="body"
              className="font-semibold text-gray-700 my-2"
            />
          </div>
          {claim?.preferredResolution === 'OTHER' ? (
            <div>
              <textarea
                rows={1}
                className="border border-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 w-full sm:text-sm border-gray-300 rounded-md mr-3 p-1"
                value={claim?.preferredResolutionNotes ?? ''}
              />
            </div>
          ) : null}
        </div>
      ) : null}
    </section>
  );
};

export const SingleClaim = withNotification(({ showNotification }: any) => {
  const [claim, setClaim] = useState<Claim | null>(null);
  const [showImageModal, setShowImageModal] = useState(false);
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState<string | null>(null);
  const [selectedClaimItems, setSelectedClaimItems] = useState<
    ClaimItemValues[]
  >([]);

  const [currentClaimItem, setCurrentClaimItem] =
    useState<ClaimItemValues | null>(null);

  const [resolution, setResolution] = useState<
    ClaimItemResolvedStatusEnum | undefined
  >();

  const [resolutionActionModal, setResolutionActionModal] = useState(false);
  const [resolutionViewModal, setResolutionViewModal] = useState(false);

  const params = useParams();
  const location = useLocation();

  const { storeId, storeProperties } = useStore();
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [videoUrls, setVideoUrls] = useState<string[]>([]);

  console.log(storeId, storeProperties);
  const enableStoreCredit =
    storeProperties?.resolvePortalOptions?.enableStoreCredit || false;

  const { data, loading } = useQuery(GET_CLAIM, {
    variables: {
      storeId,
      id: params.claimId,
    },
    onError(error) {
      showNotification(NotificationTypes.error, error?.message);
    },
  });
  const [refetchClaim, { data: refetchData }] = useLazyQuery(GET_CLAIM);

  const [reorderClaim, { loading: reorderClaimLoading }] = useMutation(
    REORDER_CLAIM,
    {
      onError(error) {
        const newError = apiErrorHandler(error);
        showNotification(NotificationTypes.error, newError?.message);
      },
      onCompleted(data) {
        showNotification(NotificationTypes.success, 'Issue resolved');
        refetchClaim({
          fetchPolicy: 'cache-and-network',
          variables: {
            storeId,
            id: params.claimId,
          },
        });
        setResolutionViewModal(false);
        setResolutionActionModal(false);

        return window.open(data?.reorderClaim[0].orderUrl, '_blank');
      },
    },
  );

  const [refundClaim, { loading: refundClaimLoading }] = useMutation(
    REFUND_CLAIM,
    {
      onError(error) {
        const newError = apiErrorHandler(error);
        showNotification(NotificationTypes.error, newError?.message);
      },
      onCompleted(data) {
        showNotification(NotificationTypes.success, 'Issue resolved');
        refetchClaim({
          fetchPolicy: 'cache-and-network',
          variables: {
            storeId,
            id: params.claimId,
          },
        });
        setResolutionViewModal(false);
        setResolutionActionModal(false);
      },
    },
  );

  const [storeCreditClaim, { loading: storeCreditClaimLoading }] = useMutation(
    STORE_CREDIT_CLAIM,
    {
      onError(error) {
        const newError = apiErrorHandler(error);
        showNotification(NotificationTypes.error, newError?.message);
      },
      onCompleted(data) {
        showNotification(NotificationTypes.success, 'Issue resolved');
        refetchClaim({
          fetchPolicy: 'cache-and-network',
          variables: {
            storeId,
            id: params.claimId,
          },
        });
        setResolutionViewModal(false);
        setResolutionActionModal(false);
      },
    },
  );

  const [otherClaim, { loading: otherClaimLoading }] = useMutation(
    OTHER_CLAIM,
    {
      onError(error) {
        const newError = apiErrorHandler(error);
        showNotification(NotificationTypes.error, newError?.message);
      },
      onCompleted(data) {
        showNotification(NotificationTypes.success, 'Issue resolved');
        refetchClaim({
          fetchPolicy: 'cache-and-network',
          variables: {
            storeId,
            id: params.claimId,
          },
        });
        setResolutionViewModal(false);
        setResolutionActionModal(false);
      },
    },
  );

  const [denyClaim, { loading: denyClaimLoading }] = useMutation(DENY_CLAIM, {
    onError(error) {
      const newError = apiErrorHandler(error);
      showNotification(NotificationTypes.error, newError?.message);
    },
    onCompleted(data) {
      showNotification(NotificationTypes.success, 'Issue Denied');
      refetchClaim({
        fetchPolicy: 'cache-and-network',
        variables: {
          storeId,
          id: params.claimId,
        },
      });
      setResolutionViewModal(false);
      setResolutionActionModal(false);
    },
  });

  const [insertClaimImages] = useMutation(INSERT_CLAIM_IMAGES, {
    onError(error) {
      const newError = apiErrorHandler(error);
      showNotification(NotificationTypes.error, newError?.message);
    },
    onCompleted(data) {
      setClaim(data?.insertClaimImages.returning[0].claim);
    },
  });

  const [insertClaimVideos] = useMutation(INSERT_CLAIM_VIDEOS, {
    onError(error) {
      const newError = apiErrorHandler(error);
      showNotification(NotificationTypes.error, newError?.message);
    },
    onCompleted(data) {
      setClaim(data?.insertClaimVideos.returning[0].claim);
    },
  });

  const [removeClaimImage] = useMutation(REMOVE_CLAIM_IMAGE, {
    onError(error) {
      const newError = apiErrorHandler(error);
      showNotification(NotificationTypes.error, newError?.message);
    },
    onCompleted(data) {
      setClaim(data?.deleteClaimImage.claim);
    },
  });

  const [removeClaimVideo] = useMutation(REMOVE_CLAIM_VIDEO, {
    onError(error) {
      const newError = apiErrorHandler(error);
      showNotification(NotificationTypes.error, newError?.message);
    },
    onCompleted(data) {
      setClaim(data?.deleteClaimVideo.claim);
    },
  });

  useEffect(() => {
    if (data?.claims) {
      setClaim(data.claims[0]);
    }
  }, [data]);

  useEffect(() => {
    if (claim) {
      const unResolvedItems = claim.items.filter((item) => {
        return !item.settlement;
      });

      setSelectedClaimItems(unResolvedItems);
    }
  }, [claim]);

  useEffect(() => {
    if (selectedClaimItems?.length) {
      setCurrentClaimItem(selectedClaimItems[0]);
    }
  }, [selectedClaimItems]);

  useEffect(() => {
    if (refetchData?.claims) {
      setClaim(refetchData.claims[0]);
      setSelectedClaimItems(refetchData.claims[0].items);
    }
  }, [refetchData]);

  useEffect(() => {
    if (image) {
      setShowImageModal(true);
    }
  }, [image]);

  const handleAddClaimImages = (urls: string[]) => {
    insertClaimImages({
      variables: {
        objects: urls.map((item) => ({
          claimId: claim?.id,
          url: item,
        })),
      },
    });
  };

  const handleAddClaimVideos = (urls: string[]) => {
    insertClaimVideos({
      variables: {
        objects: urls.map((item) => ({
          claimId: claim?.id,
          url: item,
        })),
      },
    });
  };

  const handleRemoveClaimItem = (orderItem: ClaimItemValues) => {
    if (selectedClaimItems.includes(orderItem)) {
      const filtered = selectedClaimItems.filter(
        (item) => item.id !== orderItem.id,
      );
      return setSelectedClaimItems(filtered);
    }
    setSelectedClaimItems([...selectedClaimItems, orderItem]);
  };

  const handleQuantityUpdate = (value: string, claimItem: ClaimItemValues) => {
    const updatedClaimItems = [...selectedClaimItems].map((item) =>
      item.id === claimItem.id ? { ...item, quantity: Number(value) } : item,
    );
    setSelectedClaimItems(updatedClaimItems);
  };

  const handleReorderClaim = (input: any) => {
    if (!currentClaimItem) {
      return alert('No item found');
    }
    let payload = {
      ...input,
      storeId,
      id: params.claimId,
      items: selectedClaimItems
        .map((item) => ({
          id: item.id,
          quantity: item.quantity,
        }))
        .filter((item) => item.quantity), // Filter items with 0 quantity,
    };
    if (!payload.items.length) {
      showNotification(NotificationTypes.error, 'Invalid Item Quantity');
    }
    reorderClaim({
      variables: { input: payload },
    });
  };

  const handleOtherClaims = (input: any) => {
    let payload = {
      ...input,
      storeId,
      id: params.claimId,
      items: selectedClaimItems.map((item) => ({
        id: item.id,
        quantity: item.quantity,
      })),
    };
    otherClaim({
      variables: { input: payload },
    });
  };

  const handleRefundClaim = (input: any) => {
    let payload = {
      ...input,
      storeId,
      id: params.claimId,
      items: selectedClaimItems
        .map((item) => ({
          id: item.id,
          quantity: item.quantity,
        }))
        .filter((item) => item.quantity), // Filter items with 0 quantity,
    };
    if (!payload.items.length) {
      showNotification(NotificationTypes.error, 'Invalid Item Quantity');
    }
    refundClaim({
      variables: { input: payload },
    });
  };

  const handleStoreCreditClaim = (input: any) => {
    let payload = {
      ...input,
      storeId,
      id: params.claimId,
      items: selectedClaimItems
        .map((item) => ({
          id: item.id,
          quantity: item.quantity,
        }))
        .filter((item) => item.quantity), // Filter items with 0 quantity,
    };
    if (!payload.items.length) {
      showNotification(NotificationTypes.error, 'Invalid Item Quantity');
    }
    storeCreditClaim({
      variables: { input: payload },
    });
  };

  const handleDenyClaim = (input: any) => {
    let payload = {
      ...input,
      storeId,
      id: params.claimId,
      items: selectedClaimItems
        .map((item) => ({
          id: item.id,
          quantity: item.quantity,
        }))
        .filter((item) => item.quantity),
    };
    denyClaim({
      variables: { input: payload },
    });
  };

  const handleRemoveClaimImage = (id: string) => {
    removeClaimImage({
      variables: {
        id,
      },
    });
  };

  const handleRemoveClaimVideo = (id: string) => {
    removeClaimVideo({
      variables: {
        id,
      },
    });
  };

  const handleShowImage = (image: any) => {
    setImage(image);
  };

  const handleShowVideo = (video: string) => {
    setVideo(video);
  };

  const handleImageModal = (value: any) => {
    setShowImageModal(value);
    if (!value) {
      setImage(null);
    }
  };

  const handleImageDownload = (imgUrl: string) => {
    fetch(imgUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'image/png',
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `claim-${claim?.number}_image.png`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link?.parentNode?.removeChild(link);
      });
  };

  // The null type here is necessary because some records in the db are null
  const getFulFillmentStatus = (status: string | undefined | null) => {
    if (status === 'fulfilled') return 'FulFilled';
    else if (status === 'partial') return 'Partially Fulfilled';
    return 'Unfulfilled';
  };

  if (loading) return <LoadingScreen />;

  const state = location.state as { returnLocation: string };

  const renderEmptyTrackingComponent = () => {
    return <Text value={'N/A'} type="body" className="text-gray-500" />;
  };

  const getOrderItemsTrackingLinks = (order: Order | undefined) => {
    if (!order || !order.items) {
      return renderEmptyTrackingComponent();
    }
    const itemsTrackingLink = order.items.filter(
      (item) => item.trackingUrl && item.trackingUrl !== '',
    );
    if (!itemsTrackingLink.length) {
      return renderEmptyTrackingComponent();
    }
    return itemsTrackingLink.map((item) => {
      return (
        <span key={item.title}>
          <a
            href={`${process.env.REACT_APP_TRACKING_PORTAL_URL}/${item.trackingId}`}
            target="_blank"
            rel="noreferrer"
          >
            <Text
              value={item.title}
              type="body"
              className="text-blue-500 underline"
            />
          </a>
        </span>
      );
    });
  };

  const onSelectClaimItem = (id: string | undefined) => {
    if (selectedClaimItems.find((si) => si.id === id)) {
      setSelectedClaimItems((items) => items.filter((si) => si.id !== id));
    } else {
      const claimItem = claim?.items.find((c) => c.id === id);

      if (claimItem) {
        setSelectedClaimItems((items) => [...items, claimItem]);
      }
    }
  };

  const onSelectCurrentClaimItem = (id: string) => {
    const item = claim?.items.find((si) => si.id === id);
    if (item) {
      setCurrentClaimItem(item);
    }
  };

  const onSelectAll = () => {
    if (!claim) return;
    const unResolvedItems = claim.items.filter((item) => {
      return !item.settlement;
    });
    setSelectedClaimItems(
      selectedClaimItems.length === unResolvedItems.length
        ? []
        : unResolvedItems || [],
    );
  };

  const onSelectResolution = (r: ClaimItemResolvedStatusEnum) =>
    setResolution(r);

  const settlement = claim?.settlements?.find((s: any) =>
    s.items?.find((si: any) => si?.claimItem?.id === currentClaimItem?.id),
  );

  const onResolvePartialItems = (claimItem: ClaimItemValues) => {
    setResolution(claimItem.resolution as ClaimItemResolvedStatusEnum);
    setResolutionActionModal(true);
    setSelectedClaimItems([claimItem]);
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 pb-10">
      <Modal
        open={showImageModal}
        openModal={handleImageModal}
        containerClass="bg-transparent shadow-none max-w-2xl"
        iconClassName="text-white"
      >
        {image && <img src={image} alt="claim" className="max-w-full" />}
        {image && (
          <div
            className="flex w-24 rounded p-2 bg-gray-800 z-10 items-center p-1 cursor-pointer"
            onClick={() => handleImageDownload(image)}
          >
            <DownloadIcon height={15} width={15} color="#ffffff" />
            <Text className="text-gray-200 ml-2 text-sm" value="Download" />
          </div>
        )}
      </Modal>
      {video && (
        <ViewVideo videoUrl={video} onCloseView={() => setVideo(null)} />
      )}
      <div className="flex justify-between sm:items-center items-start">
        <div className="flex sm:items-center items-start">
          <Back
            className="mr-5"
            returnLocation={state?.returnLocation && state?.returnLocation}
          />
          <Text value={`Issue ${claim?.number}`} type="h4" />
        </div>
        <Text
          className={classNames(
            statusColorClasses(claim?.status),
            ' px-3 py-1 h-fit rounded-full text-center font-bold',
          )}
          value={formatStatusText(`${claim?.status}`)}
          type="body"
        />
      </div>
      <div className="sm:flex mt-7 gap-4">
        <div className="w-full sm:w-1/3">
          <Text value="General information" type="h4" className="mb-3" />
          <div className="border-b py-2">
            <Text
              value="Order Number"
              type="body"
              className="font-semibold text-gray-700"
            />
            <Text
              value={claim?.order.number}
              type="body"
              className="text-gray-500"
            />
          </div>
          <div className="border-b py-2">
            <Text
              value="Order Date"
              type="body"
              className="font-semibold text-gray-700"
            />
            <Text
              value={`${
                claim
                  ? format(
                      new Date(claim?.order?.createdAt),
                      'do MMM yyyy hh:mm',
                    )
                  : ''
              }`}
              type="body"
              className="text-gray-500"
            />
          </div>
          <div className="border-b py-2">
            <Text
              value="Customer Name"
              type="body"
              className="font-semibold text-gray-700"
            />
            <Text
              value={claim?.order.customerName}
              type="body"
              className="text-gray-500"
            />
          </div>
          <div className="border-b py-2">
            <Text
              value="Email"
              type="body"
              className="font-semibold text-gray-700"
            />
            <Text
              value={claim?.order.customerEmail ?? 'N/A'}
              type="body"
              className="text-gray-500"
            />
          </div>
          <div className="border-b py-2">
            <Text
              value="Phone"
              type="body"
              className="font-semibold text-gray-700"
            />
            <Text
              value={claim?.order.customerPhone ?? 'N/A'}
              type="body"
              className="text-gray-500"
            />
          </div>
          <div className="border-b py-2">
            <Text
              value="Order Total"
              type="body"
              className="font-semibold text-gray-700"
            />
            <Text
              value={`${getStoreCurrencyFormatter(
                storeProperties?.currency,
                formatCurrency(claim?.order.total ?? 0),
              )}`}
              type="body"
              className="text-gray-500"
            />
          </div>
          <div className="border-b py-2">
            <Text
              value="Guarantee Total"
              type="body"
              className="font-semibold text-gray-700"
            />
            <Text
              value={`${getStoreCurrencyFormatter(
                storeProperties?.currency,
                claim?.order.protectionTotal,
              )}`}
              type="body"
              className="text-gray-500"
            />
          </div>
          <div className="border-b py-2">
            <Text
              value="Fulfillment Status"
              type="body"
              className="font-semibold text-gray-700"
            />
            <Text
              value={
                Boolean(claim?.order?.fulfillmentStatus)
                  ? getFulFillmentStatus(claim?.order?.fulfillmentStatus)
                  : 'Unfulfilled'
              }
              type="body"
              className="text-gray-500"
            />
          </div>
          <div className="border-b py-2">
            <Text
              value="Tracking"
              type="body"
              className="font-semibold text-gray-700"
            />
            {getOrderItemsTrackingLinks(claim?.order)}
          </div>
          <div className="border-b py-2">
            <Text
              value="Customer Notes"
              type="body"
              className="font-semibold text-gray-700"
            />
            <Text
              value={
                Boolean(claim?.customerNotes) ? claim?.customerNotes : 'N/A'
              }
              type="body"
              className="text-gray-500"
            />
          </div>
          {storeProperties?.resolvePortalOptions?.requirePreferredResolution ? (
            <div className="border-b py-2">
              <Text
                value="Preferred Resolution"
                type="body"
                className="font-semibold text-gray-700"
              />
              <Text
                value={formatStatusText(claim?.preferredResolution) ?? 'N/A'}
                type="body"
                className="text-gray-500"
              />
            </div>
          ) : null}
        </div>
        <ClaimItemsList
          claim={claim}
          items={selectedClaimItems}
          resolution={resolution}
          onSelectAll={onSelectAll}
          onSelectClaimItem={onSelectClaimItem}
          onSelectResolution={onSelectResolution}
          onShowResolutionAction={() => setResolutionActionModal(true)}
          onViewResolution={(id) => {
            onSelectCurrentClaimItem(id);
            setResolutionViewModal(true);
          }}
          onResolvePartialItems={onResolvePartialItems}
        />
      </div>
      <div className="my-5">
        <Text
          value="Issue Images"
          type="h3"
          className="font-normal text-blue-700"
        />
      </div>
      {claim && ['DENIED', 'RESOLVED'].includes(claim.status) && (
        <div className="flex flex-wrap">
          {claim.images.length > 0 ? (
            claim.images.map((image) => {
              return (
                <img
                  key={image.url}
                  src={image.url}
                  alt="claim item"
                  onClick={() => handleShowImage(image.url)}
                  style={{
                    width: 125,
                    height: 125,
                    objectFit: 'cover',
                    background: 'rgb(231 231 231)',
                    border: '5px solid rgb(231 231 231 / 75%)',
                    borderRadius: '5px',
                  }}
                  className="mr-2 mb-3 sm:mb-0 cursor-pointer"
                />
              );
            })
          ) : (
            <div>No Images added to this issue</div>
          )}
        </div>
      )}

      {claim && !resolvedStatuses.includes(claim?.status) && (
        <div>
          <ClaimsImagesUpload
            onUpload={({ urls }: { urls: string[] }) => {
              setImageUrls([...imageUrls, ...urls]);
              handleAddClaimImages(urls);
            }}
            noOfImages={5 - claim.images.length}
            claimImages={claim.images}
            onRemoveImage={handleRemoveClaimImage}
            onShowImage={handleShowImage}
          />
        </div>
      )}
      <div className="my-5">
        <Text
          value="Issue Videos"
          type="h3"
          className="font-normal text-blue-700"
        />
      </div>
      {claim && ['DENIED', 'RESOLVED'].includes(claim.status) && (
        <div className="flex flex-wrap">
          {claim.videos.length > 0 ? (
            claim.videos.map((video) => {
              return (
                <div
                  key={video.id}
                  onClick={handleShowVideo?.bind(null, video.url)}
                  className="h-24 w-24 relative sm:h-24 sm:w-24 cursor-pointer mr-10 mb-3 sm:mb-0"
                >
                  <img
                    src={getThumnailUrl(video.url)}
                    alt="Video Thumbnail"
                    style={{
                      maxWidth: 125,
                      height: 125,
                      objectFit: 'cover',
                      background: 'rgb(231 231 231)',
                      border: '5px solid rgb(231 231 231 / 75%)',
                      borderRadius: '5px',
                    }}
                    className="cursor-pointer"
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 64 64"
                    style={{
                      position: 'absolute',
                      top: '65%',
                      left: '65%',
                      transform: 'translate(-50%, -50%)',
                      width: '35px',
                      height: '35px',
                      opacity: 0.7,
                      cursor: 'pointer',
                    }}
                  >
                    <circle cx="32" cy="32" r="32" fill="rgba(0, 0, 0, 0.5)" />
                    <polygon points="24,16 48,32 24,48" fill="white" />
                  </svg>
                </div>
              );
            })
          ) : (
            <div>No Videos added to this issue</div>
          )}
        </div>
      )}

      {claim && !resolvedStatuses.includes(claim?.status) && (
        <div>
          <ClaimsVideosUpload
            onUpload={({ urls }: { urls: string[] }) => {
              setVideoUrls([...videoUrls, ...urls]);
              handleAddClaimVideos(urls);
            }}
            noOfVideos={5 - claim.videos.length}
            videos={claim.videos}
            onRemoveVideo={handleRemoveClaimVideo}
            onShowVideo={handleShowVideo}
          />
        </div>
      )}

      {claim && (
        <>
          <Modal
            open={resolutionActionModal}
            openModal={() => setResolutionActionModal(false)}
            customStyle={{ width: '80%' }}
          >
            <div className="flex justify-between items-center flex-wrap">
              <ClaimResolutionModalHeader
                claim={claim}
                resolution={resolution}
                storeProperties={storeProperties}
              />
              <Dropdown
                placeholder="Choose Resolution"
                btnClassName="w-40 border rounded-sm"
                options={ClaimResolutionOptions({ enableStoreCredit })}
                onSelectOption={(o) => {
                  onSelectResolution(o as ClaimItemResolvedStatusEnum);
                }}
              />
            </div>

            <div>
              <div>
                {resolution === ClaimItemResolvedStatusEnum.REORDER && (
                  <Reorder
                    claimItems={selectedClaimItems}
                    handleRemoveItem={(item) => handleRemoveClaimItem(item)}
                    handleReorderItems={handleReorderClaim}
                    loading={reorderClaimLoading}
                    claim={claim}
                    handleQuantityUpdate={handleQuantityUpdate}
                    staticClaimItems={
                      refetchData?.claims[0]?.items ?? data?.claims[0]?.items
                    }
                  />
                )}

                {resolution === ClaimItemResolvedStatusEnum.REFUND && (
                  <Refund
                    claimItems={selectedClaimItems}
                    handleRemoveItem={(item) => handleRemoveClaimItem(item)}
                    handleRefundItems={handleRefundClaim}
                    loading={refundClaimLoading}
                    claim={claim}
                    handleQuantityUpdate={handleQuantityUpdate}
                    staticClaimItems={
                      refetchData?.claims[0]?.items ?? data?.claims[0]?.items
                    }
                  />
                )}
                {resolution === ClaimItemResolvedStatusEnum.STORE_CREDIT && (
                  <StoreCredit
                    claimItems={selectedClaimItems}
                    handleRemoveItem={(item) => handleRemoveClaimItem(item)}
                    handleStoreCreditItems={handleStoreCreditClaim}
                    loading={storeCreditClaimLoading}
                    claim={claim}
                    handleQuantityUpdate={handleQuantityUpdate}
                    staticClaimItems={
                      refetchData?.claims[0]?.items ?? data?.claims[0]?.items
                    }
                  />
                )}
                {resolution === ClaimItemResolvedStatusEnum.OTHER && (
                  <Other
                    claimItems={selectedClaimItems}
                    handleRemoveItem={(item) => handleRemoveClaimItem(item)}
                    handleOtherItems={handleOtherClaims}
                    loading={otherClaimLoading}
                    claim={claim}
                    handleQuantityUpdate={handleQuantityUpdate}
                    staticClaimItems={
                      refetchData?.claims[0]?.items ?? data?.claims[0]?.items
                    }
                  />
                )}

                {resolution === ClaimItemResolvedStatusEnum.DENY && (
                  <Denyclaim
                    claimItems={selectedClaimItems}
                    staticClaimItems={
                      refetchData?.claims[0]?.items ?? data?.claims[0]?.items
                    }
                    handleRemoveItem={(item) => handleRemoveClaimItem(item)}
                    handleQuantityUpdate={handleQuantityUpdate}
                    handleDenyItems={handleDenyClaim}
                    loading={denyClaimLoading}
                  />
                )}
              </div>
            </div>
          </Modal>

          <Modal
            open={resolutionViewModal}
            openModal={() => setResolutionViewModal(false)}
          >
            <ClaimResolutionModalHeader
              claim={claim}
              claimItem={currentClaimItem}
              storeProperties={storeProperties}
            />
            <div>
              {currentClaimItem?.resolution === 'REFUND' && (
                <ResolutionRefund
                  resolution={settlement}
                  key={settlement.id}
                  claim={{ ...claim, items: [currentClaimItem] }}
                  claimItem={currentClaimItem}
                />
              )}
              {currentClaimItem?.resolution === 'STORE_CREDIT' && (
                <ResolutionStoreCredit
                  resolution={settlement}
                  key={settlement.id}
                  claim={{ ...claim, items: [currentClaimItem] }}
                  claimItem={currentClaimItem}
                />
              )}
              {currentClaimItem?.resolution === 'DENY' && (
                <ResolutionDeny
                  resolution={settlement}
                  key={settlement.id}
                  claim={{ ...claim, items: [currentClaimItem] }}
                  claimItem={currentClaimItem}
                />
              )}
              {currentClaimItem?.resolution === 'REORDER' && (
                <ResolutionReorder
                  resolution={settlement}
                  key={settlement.id}
                  claim={{ ...claim, items: [currentClaimItem] }}
                  claimItem={currentClaimItem}
                />
              )}
              {currentClaimItem?.resolution === 'OTHER' && (
                <ResolutionOther
                  resolution={settlement}
                  key={settlement.id}
                  claim={{ ...claim, items: [currentClaimItem] }}
                  claimItem={currentClaimItem}
                />
              )}
            </div>
          </Modal>
        </>
      )}
    </div>
  );
});
