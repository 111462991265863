import { Text } from 'components/Text';
import { Toggle } from 'components/Toggle';

export const info = [
  {
    title: 'Company Email',
    data: 'boomy@company.com',
    key: 'email',
  },
  {
    title: 'Company Name',
    data: 'Great company',
    key: 'name',
  },
  {
    title: 'Company Phone',
    data: '746748294',
    key: 'phone',
  },
  {
    title: 'Company Website',
    data: 'https://www.google.com',
    key: 'website',
  },
];

export const people = [
  {
    name: 'Stefan Alexiev',
    email: 'boomy@company.com',
    role: 'owner',
  },
  {
    name: 'John Doe',
    email: 'boomboom@mail.com',
    role: 'name',
  },
];

export const columns = [
  {
    name: 'Name',
    id: 1,
    render: (item: any) => (
      <div className="flex items-center">
        <div className="flex w-10 h-10 items-center justify-center bg-blue-400 rounded-full text-white mr-2">
          {item.user?.displayName[0]}
        </div>
        {item.user?.displayName}
      </div>
    ),
  },
  {
    name: 'Email',
    id: 2,
    render: (item: any) => <Text value={item.user?.email} />,
  },
  {
    name: 'Role',
    id: 3,
    render: (item: any) => <Text value={item.userRole?.role} />,
  },
  {
    name: 'Action',
    id: 4,
    render: (item: any) => {
      return item.authuser?.email !== item.user?.email &&
        item.authUserRole === 'ADMIN' ? (
        <div>
          <Toggle
            isEnabled={Boolean(item?.user.notifications?.length > 0)}
            onChange={
              item?.user.notifications?.length > 0
                ? item.removeUser
                : item.addUser
            }
            isSmall
          />
        </div>
      ) : (
        <div />
      );
    },
  },
];
