import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

type DropDownOption = {
  render?: React.ReactNode;
  key?: string;
};

type DropdownProps = {
  options: string[] | DropDownOption[];
  onSelectOption: (option: string, e?: React.MouseEvent<HTMLElement>) => void;
  selectedOption?: string;
  icon?: JSX.Element;
  btnClassName?: string;
  placeholder?: string;
  downCaret?: boolean;
  itemsClassName?: string;
  disabled?: boolean;
};

export const Dropdown = ({
  icon,
  options,
  onSelectOption,
  btnClassName,
  placeholder,
  selectedOption,
  downCaret,
  itemsClassName,
  disabled,
}: DropdownProps) => {
  return (
    <Menu
      as="div"
      className={`relative inline-block text-left ${
        disabled ? 'opacity-50' : ''
      }`}
    >
      <div>
        <Menu.Button
          className={classNames(
            btnClassName,
            'flex justify-evenly w-fit items-center capitalize rounded-md px-2 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-offset-gray-100',
          )}
          aria-label="dropdown"
          disabled={disabled}
        >
          {icon && icon}
          {!icon
            ? selectedOption
              ? selectedOption
              : placeholder
            : placeholder}
          {downCaret && !disabled && (
            <ChevronDownIcon
              className="-mr-1 ml-2 h-5 w-5"
              aria-hidden="true"
            />
          )}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={classNames(
            'origin-top-right absolute mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-40',
            itemsClassName,
          )}
        >
          <div className="py-1">
            {options.map((option) =>
              typeof option === 'string' ? (
                <Menu.Item key={option}>
                  <p
                    onClick={(e) => onSelectOption(option, e)}
                    className={
                      'text-gray-700 cursor-pointer block px-4 py-2 text-sm hover:bg-gray-50'
                    }
                  >
                    {option}
                  </p>
                </Menu.Item>
              ) : (
                <Menu.Item key={option.key}>
                  <div
                    className="cursor-pointer"
                    onClick={(e) => onSelectOption(String(option.key), e)}
                  >
                    {option.render}
                  </div>
                </Menu.Item>
              ),
            )}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
