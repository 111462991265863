import React, { Fragment } from 'react';
import { Switch } from '@headlessui/react';
import classNames from 'classnames';

type ToggleProps = {
  isEnabled: boolean;
  onChange: (value: boolean) => void;
  isSmall?: boolean;
  leftLabel?: string;
  rightLabel?: string;
  leftDescription?: string;
  rightDescription?: string;
  className?: string;
  passive?: boolean;
  disabled?: boolean;
};

export const Toggle = ({
  isEnabled,
  onChange,
  isSmall = false,
  passive = false,
  leftLabel,
  leftDescription,
  rightLabel,
  rightDescription,
  className,
  disabled,
}: ToggleProps) => {
  return (
    <Fragment>
      <Switch.Group as="div" className="flex items-center">
        <span className="flex flex-col">
          {leftLabel && (
            <Switch.Label
              as="span"
              className="text-sm font-medium text-gray-900 mr-4 cursor-pointer"
              passive={passive}
            >
              {leftLabel}
            </Switch.Label>
          )}
          {leftDescription && (
            <Switch.Description
              as="span"
              className="text-sm text-gray-500 mr-4"
            >
              {leftDescription}
            </Switch.Description>
          )}
        </span>
        <Switch
          checked={isEnabled}
          onChange={onChange}
          className={classNames(
            disabled && 'opacity-50',
            isEnabled ? 'bg-blue-800' : 'bg-blue-100',
            isSmall ? 'h-5 w-9' : 'h-6 w-14',
            'relative inline-flex flex-shrink-0 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
            className,
          )}
        >
          <span
            aria-hidden="true"
            className={classNames(
              isEnabled
                ? 'translate-x-8 bg-blue-100'
                : 'translate-x-0 bg-blue-800',
              isSmall ? 'h-4 w-4' : 'h-5 w-5',
              isEnabled && isSmall && 'translate-x-4',
              'pointer-events-none inline-block rounded-full shadow transform ring-0 transition ease-in-out duration-200',
            )}
          />
        </Switch>
        <span className="flex flex-col">
          {rightLabel && (
            <Switch.Label
              as="span"
              className="text-sm font-medium text-gray-900 ml-4 cursor-pointer"
              passive={passive}
            >
              {rightLabel}
            </Switch.Label>
          )}
          {rightDescription && (
            <Switch.Description
              as="span"
              className="text-sm text-gray-500 ml-4"
            >
              {rightDescription}
            </Switch.Description>
          )}
        </span>
      </Switch.Group>
    </Fragment>
  );
};
