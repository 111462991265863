import React, { Fragment, Suspense } from 'react';
import classNames from 'classnames';
import 'react-datepicker/dist/react-datepicker.css';
// @ts-ignore
const ReactDatePicker = React.lazy(() => import('react-datepicker'));

type DatePickerProps = {
  value?: Date | null;
  setValue: (value: any) => void;
  placeHolder?: string;
  dateFormat?: string;
  selectsRange?: boolean;
  isClearable?: boolean;
  showYearDropdown?: boolean;
  showMonthDropdown?: boolean;
  className?: string;
  startDate?: Date | null;
  endDate?: Date | null;
  monthsShown?: number;
};

export const DatePicker = ({
  value,
  setValue,
  placeHolder,
  dateFormat,
  isClearable = false,
  showYearDropdown = false,
  showMonthDropdown = false,
  className,
  selectsRange = false,
  startDate,
  endDate,
  monthsShown = 2,
}: DatePickerProps) => {
  return (
    <Fragment>
      <Suspense fallback={<div></div>}>
        <ReactDatePicker
          wrapperClassName="testPicker"
          selected={value}
          onChange={setValue}
          className={classNames(
            'focus:outline-indigo-500 focus:border-indigo-500 border border-gray-300 text-gray-600',
            'w-56',
            className,
          )}
          placeholderText={placeHolder}
          showYearDropdown={showYearDropdown}
          showMonthDropdown={showMonthDropdown}
          isClearable={isClearable}
          popperPlacement="bottom-end"
          popperModifiers={[
            {
              name: 'offset',
              options: {
                offset: [5, 10],
              },
            },
            {
              name: 'preventOverflow',
              options: {
                rootBoundary: 'viewport',
                tether: false,
                altAxis: true,
              },
            },
          ]}
          selectsRange={selectsRange}
          startDate={startDate}
          endDate={endDate}
          monthsShown={monthsShown}
          dateFormat={dateFormat}
        />
      </Suspense>
    </Fragment>
  );
};
