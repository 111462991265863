import { useEffect, useState } from 'react';
import { Button } from './Button';
import { BodyText } from './MardownBodyText';
import { Text } from './Text';
import shipaidLogo from '../assets/images/shipaid_logo_300x300.webp';

const steps = [
  {
    title: 'Welcome',
    media: <img width="180" height="180" src={shipaidLogo} alt="Logo" />,
    description:
      'Thank you for installing the Shipaid shopify app! To get the most out of Shipaid, please follow the following installation steps.',
    actions: [
      {
        text: 'Get Started',
      },
    ],
  },
  {
    title: '1. Customize your Theme',
    media: (
      <div style={{ width: 501 }}>
        <video
          controls
          className="h-80"
          src="https://res.cloudinary.com/media-resource/video/upload/v1657888220/shipaid-dev/shipaid%20walkthrough/WalkthroughShipaid1_ktlbie.mp4"
        />
      </div>
    ),
    description:
      'On your Shopify dashboard, under **Sales Channels** > **Online Store**, click the **Themes** submenu and then select **Customize**',
    actions: [
      {
        text: 'Previous Step',
      },
      {
        text: 'Next Step',
      },
    ],
  },
  {
    title: '2. Add the Shipaid Widget',
    media: (
      <div style={{ width: 501 }}>
        <video
          controls
          className="h-80 w-full"
          src="https://res.cloudinary.com/media-resource/video/upload/v1657888219/shipaid-dev/shipaid%20walkthrough/WalkthroughShipaid2_hplp1u.mp4"
        />
      </div>
    ),
    description:
      'Select **Cart** at the top of the page. Click **Subtotal** on the left and select **Add block**. Look for the **Shipaid Widget** block and drag it above the **Subtotal** block and hit save',
    actions: [
      {
        text: 'Previous Step',
      },
      {
        text: 'Next Step',
      },
    ],
  },
  {
    title: '3. See It In Action',
    media: (
      <div style={{ width: 501 }}>
        <video
          controls
          className="h-80"
          src="https://res.cloudinary.com/media-resource/video/upload/v1657888220/shipaid-dev/shipaid%20walkthrough/WalkthroughShipaid3_dkxeu0.mp4"
        />
      </div>
    ),
    description:
      'Go to your store and add a product to your cart. The Shipaid protection widget should now be available to add to the cart.',
    actions: [
      {
        text: 'Previous Step',
      },
      {
        text: 'Finish',
      },
    ],
  },
];

interface OnboardingWizardProps {
  closeWizard: (shouldCompleteOnboarding: boolean) => void;
}

export const OnboardingWizard = ({ closeWizard }: OnboardingWizardProps) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const currentStep = steps[currentStepIndex];
  const previousStep = () =>
    setCurrentStepIndex(currentStepIndex > 1 ? currentStepIndex - 1 : 0);
  const nextStep = () => setCurrentStepIndex(currentStepIndex + 1);

  useEffect(() => {
    if (currentStepIndex >= steps.length) {
      // reinitialize the wizard and quit
      setCurrentStepIndex(0);
      closeWizard(true);
    }
  }, [currentStepIndex, closeWizard]);

  if (!currentStep) return null;

  return (
    <div className="fixed px-6 py-8 left-0 top-0 right-0 bottom-0 bg-slate-500 bg-opacity-50 z-50 flex flex-col items-center justify-center">
      <div
        className="bg-white p-6 pt-8 border-t-4 border-blue-600 flex flex-col justify-between items-center relative"
        style={{ width: '100%', maxWidth: 720, height: 640 }}
      >
        <button
          onClick={() => closeWizard(false)}
          className="text-gray-500 text-sm absolute top-6 right-6"
        >
          Skip
        </button>
        <Text className="text-center" type="h1" value={currentStep.title} />
        {currentStep.media}
        <BodyText
          className="max-w-sm text-center"
          text={currentStep.description}
        />
        <div className="mt-4 flex justify-center">
          {currentStep.actions.map((action, buttonIndex) => (
            <Button
              key={action.text}
              className="mr-6 w-32 justify-center"
              onClick={() => {
                if (currentStepIndex !== 0 && buttonIndex === 0) {
                  // this button goes back
                  previousStep();
                } else {
                  // we want to move forward
                  nextStep();
                }
              }}
            >
              {action.text}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};
