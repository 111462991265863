import { Claim } from 'types/claim';
import { Order } from 'types/order';
import { StoreAnalyticsInterface } from 'types/store';
import { getStoreCurrencyFormatter } from 'utils';

export const todos = [
  {
    title: 'Create Your Company Account',
    to: '/settings/team',
    key: 'companyUpdate',
  },
  {
    title: 'Set Your Protection Preferences',
    to: '/settings/protection',
    key: 'protection',
  },
  {
    title: 'Set Customer Portal preferences',
    to: '/settings/customer',
    key: 'customer',
  },
  {
    title: 'Set Notifications',
    to: '/settings/notifications',
    key: 'notifications',
  },
  {
    title: 'Set Tracking Templates',
    to: '/settings/tracking-templates',
    key: 'trackingPage',
  },
  {
    title: 'Invite Your Team',
    to: '/settings/team',
    key: 'teamInvite',
  },
];

export const formatRevenueAndClaimsCost = (data: any, currency: string) => {
  const protectionRevenue = data.protectedOrders.reduce(
    (total: number, order: Order) => {
      return total + order.protectionTotal;
    },
    0,
  );

  const claimCost = data.claimsPaid.reduce((total: number, claim: Claim) => {
    return total + claim.total;
  }, 0);

  return [
    {
      name: 'GUARANTEE REVENUE',
      currentStat: getStoreCurrencyFormatter(currency, protectionRevenue),
      link: {
        text: 'Manage Issues',
        url: '/claims',
        classes: 'text-blue-700 text-right',
      },
    },
    {
      name: 'ISSUES COST',
      currentStat: getStoreCurrencyFormatter(currency, claimCost),
      link: {
        text: 'Manage Issues',
        url: '/claims',
        classes: 'text-blue-700 text-right',
      },
    },
  ];
};

export const dashboardStats = (
  storeAnalytics: StoreAnalyticsInterface,
  barData: any,
  storeCurrency: string = 'USD',
) => {
  const protectedRevenueAndClaimsCost = barData
    ? formatRevenueAndClaimsCost(barData, storeCurrency)
    : [];
  const stats = [
    ...protectedRevenueAndClaimsCost,
    {
      name: 'TOTAL ORDERS',
      currentStat: `${storeAnalytics?.totalOrders?.aggregate.total}` ?? '0',
      link: {
        text: 'View Orders',
        url: '/orders',
        classes: 'text-blue-700 text-right',
      },
    },
    {
      name: 'GUARANTEED ORDERS',
      currentStat: `${storeAnalytics?.protectedOrders.aggregate.total}` ?? '0',
      link: {
        text: 'Manage',
        url: '/orders',
        classes: 'text-blue-700 text-right',
      },
    },
    {
      name: 'OTHER ORDERS',
      currentStat:
        `${storeAnalytics?.unprotectedOrders.aggregate.total}` ?? '0',
      link: {
        text: 'Manage Issues',
        url: '/claims',
        classes: 'text-blue-700 text-right',
      },
    },
    {
      name: 'TOTAL ISSUES',
      currentStat: `${storeAnalytics?.totalClaims.aggregate.count}` ?? '0',
      link: {
        text: 'Manage Issues',
        url: '/claims',
        classes: 'text-blue-700 text-right',
      },
    },
    {
      name: 'NEW ISSUES',
      currentStat: `${storeAnalytics?.newClaims.aggregate.count}` ?? '0',
      link: {
        text: 'Manage Issues',
        url: '/claims',
        classes: 'text-blue-700 text-right',
      },
    },
    {
      name: 'ISSUES IN REVIEW',
      currentStat: `${storeAnalytics?.reviewClaims.aggregate.count}` ?? '0',
      link: {
        text: 'Manage Issues',
        url: '/claims',
        classes: 'text-blue-700 text-right',
      },
    },
    {
      name: 'RESOLVED ISSUES',
      currentStat: `${storeAnalytics?.resolvedClaims.aggregate.count}` ?? '0',
      link: {
        text: 'Manage Issues',
        url: '/claims',
        classes: 'text-blue-700 text-right',
      },
    },
    {
      name: 'DECLINED ISSUES',
      currentStat: `${storeAnalytics?.deniedClaims.aggregate.count}` ?? '0',
      link: {
        text: 'Manage Issues',
        url: '/claims',
        classes: 'text-blue-700 text-right',
      },
    },
  ];
  return stats;
};

export const protectedOrdersData = [
  { name: 'Guaranteed Orders', value: 2400, fill: '#2564eb' },
  { name: 'Other Orders', value: 1398, fill: '#5288ff' },
];

export const revenueData = [
  {
    name: '2/3/22',
    orders: 4000,
    claims: 1000,
  },
  {
    name: '3/3/22',
    orders: 3000,
    claims: 2000.5,
  },
  {
    name: '4/3/22',
    orders: 3500,
    claims: 500,
  },
  {
    name: '5/3/22',
    orders: 200,
    claims: 450,
  },
  {
    name: '6/3/22',
    orders: 3500,
    claims: 3500,
  },
];

export const claimsFiledData = [
  {
    name: '2/3/22',
    number: 400,
  },
  {
    name: '3/3/22',
    number: 300,
  },
  {
    name: '4/3/22',
    number: 550,
  },
  {
    name: '5/3/22',
    number: 250,
  },
  {
    name: '6/3/22',
    number: 350,
  },
];

export const rateData = [
  {
    name: '2/3/22',
    amount: 4000,
  },
  {
    name: '3/3/22',
    amount: 3000,
  },
  {
    name: '4/3/22',
    amount: 5500,
  },
  {
    name: '5/3/22',
    amount: 2500,
  },
  {
    name: '6/3/22',
    amount: 3500,
  },
];
