import { Claim, ClaimItemValues } from 'types/claim';
import { Text } from './Text';
import { Itempill } from 'components/Itempill';
import { OrderItem } from 'components/OrderItem';
import { getStoreCurrencyFormatter } from 'utils';
import { format } from 'date-fns';
import { useStore } from 'context/store-context';
import { ClaimResolutionActivities } from './ClaimResolutionActivities';
import { ClaimResolvedStatusBadges } from 'containers/NestedContainers/Claim';

type ResolutionOtherProps = {
  resolution: any;
  claim: Claim;
  claimItem: ClaimItemValues;
};

export const ResolutionOther = ({
  resolution,
  claim,
  claimItem,
}: ResolutionOtherProps) => {
  const { storeProperties } = useStore();

  return (
    <div className="mt-5">
      <div className="flex">
        <Text value={`Issue ${claim.number}`} type="h4" className="mr-2" />
        <ClaimResolvedStatusBadges status="OTHER" />
      </div>
      <div className="flex items-center mt-2">
        <Text
          value="Date Updated:"
          className="mr-2 font-bold text-gray-500"
          type="sm"
        />
        <Text
          value={`${format(
            new Date(resolution.updatedAt),
            'do MMM yyyy hh:mm',
          )}`}
          type="sm"
          className="text-gray-500"
        />
      </div>
      <div className="sm:flex gap-4 sm:overflow-none overflow-x-scroll mt-5">
        <div className="w-full sm:w-2/3 h-fit">
          <div className="border border-gray-300 p-3 rounded-md">
            <Text
              value={`${claim?.items.length} Items`}
              type="h4"
              className="px-3 py-2 bg-gray-50 rounded-md"
            />
            <div className="h-96 overflow-scroll mt-3">
              {claim?.items &&
                claim?.items.map((claimItem, index) => (
                  <OrderItem
                    key={claimItem.id}
                    order={claimItem.orderItem}
                    onRemoveItem={() => {}}
                    showSku
                    isDisplay
                    claimItem={claimItem}
                    index={index}
                    staticClaimItems={claim.items}
                    resolution
                  />
                ))}
            </div>
          </div>
          <div className="border border-gray-300 p-3 rounded-md mt-4">
            <Text
              value="Resolved"
              type="h4"
              className="px-3 py-2 bg-gray-50 rounded-md"
            />
            <div className="flex items-center py-3 border-b border-gray-300">
              <div className="flex items-center">
                <Text
                  value="Original Order date:"
                  className="mr-2 font-normal text-gray-500"
                  type="sm"
                />
                <Text
                  value={`${format(
                    new Date(claim.order.createdAt),
                    'do MMM yyyy hh:mm',
                  )}`}
                  type="sm"
                  className="text-gray-500"
                />
              </div>
            </div>
            <div className="flex py-2">
              <Text
                value="Initial order total"
                type="body"
                className="w-1/3 text-gray-500"
              />
              <Text
                value={`${claim.order.items.length} Items (inc. paid protection)`}
                type="body"
                className="w-1/3 text-gray-500"
              />
              <Text
                value={`${getStoreCurrencyFormatter(
                  storeProperties?.currency,
                  claim.order.total,
                )}`}
                type="body"
                className="w-1/3 text-gray-500"
              />
            </div>
            <div className="py-2 mt-3">
              <div className="flex justify-between w-full border-y py-4 border-gray-300">
                <Text
                  value="Paid by Customer"
                  type="body"
                  className="text-gray-500"
                />
                <Text
                  value={`${getStoreCurrencyFormatter(
                    storeProperties?.currency,
                    claim.order.total,
                  )}`}
                  type="bold"
                  className="text-gray-500"
                />
              </div>
              <div className="flex justify-between w-full border-b py-4 border-gray-300">
                <Text
                  value="Guarantee total paid"
                  type="body"
                  className="text-gray-500"
                />
                <Text
                  value={`${getStoreCurrencyFormatter(
                    storeProperties?.currency,
                    claim.order.protectionTotal,
                  )}`}
                  type="bold"
                  className="text-gray-500"
                />
              </div>
              <div className="flex justify-between w-full py-2">
                <Text
                  value="Resolution Cost"
                  type="body"
                  className="text-gray-500"
                />
                <Text
                  value={`-${getStoreCurrencyFormatter(
                    storeProperties?.currency,
                    claim.settlements[0]?.metadata?.amount ?? 0,
                  )}`}
                  type="bold"
                  className="text-gray-500"
                />
              </div>
            </div>
          </div>
          <ClaimResolutionActivities claim={claim} claimItem={claimItem} />
        </div>
        <div className="border border-gray-300 p-3 rounded-md w-full sm:w-1/3 sm:mt-0 mt-4">
          <Text
            value="Customer"
            type="h4"
            className="px-3 py-2 bg-gray-50 rounded-md"
          />
          <div className="border-b border-gray-300 pb-4">
            <Text
              value={claim?.order.customerName}
              type="body"
              className="text-gray-500 mt-4"
            />
            <span className="flex mt-2">
              <Text
                value={claim?.items.length}
                type="bold"
                className="text-blue-500"
              />
              <Text value="Items" className="text-gray-500 ml-2" />
            </span>
          </div>
          <div className="py-4">
            <Text value="Contact information" type="bold" className="" />
            <Text
              value={claim?.order.customerEmail}
              type="body"
              className="text-gray-500 mt-4"
            />
            <Text
              value={claim?.order.customerPhone}
              type="body"
              className="text-gray-500 mt-4"
            />
          </div>
          <div className=" mt-4 pb-4">
            <Text
              value="Notes"
              type="h4"
              className="px-3 py-2 bg-gray-50 rounded-md"
            />
            <Text value={claim?.notes} className="text-gray-500 mt-4" />
          </div>
          <div className="py-4">
            <Text
              value="Tags"
              type="h4"
              className="px-3 py-2 bg-gray-50 rounded-md mb-4"
            />
            {resolution?.metadata?.order &&
              resolution?.metadata?.order?.tags.map(
                (tag: string, i: number) => (
                  <Itempill
                    item={tag}
                    key={i}
                    className="mr-2 h-max mt-3 w-fit"
                  />
                ),
              )}
          </div>
        </div>
      </div>
    </div>
  );
};
