import { CodeBlock } from 'components/CodeBlock';
import { Text } from 'components/Text';
import ReactMarkdown from 'react-markdown';

const Step = ({ num }: { num: number }) => (
  <div className="text-left max-w-2xl w-full">
    <Text className="text-left" value={`Step ${num}: `} type="bold" />
  </div>
);
const BodyText = ({ text, indent = 0 }: { text: string; indent?: number }) => (
  <ReactMarkdown className={'text-gray-800 max-w-2xl w-full'}>
    {(indent ? '&nbsp;&nbsp;&nbsp;&nbsp;' : '') + text}
  </ReactMarkdown>
);
const Spacer = () => <div className="mb-6" />;

export const ManualInstallInstructions = () => (
  <div style={{ maxWidth: '756px', margin: 'auto' }}>
    <div className="mb-4 items-center flex flex-col">
      <BodyText text="To install the ShipAid protection widget to your store via the **Manual** installation method, follow the below instructions:" />
      <Spacer />
      <Step num={1} />
      <BodyText text="Go to your online store themes page, and find the theme you want to install the widget to. Click the **Actions** dropdown and select the **Edit code** option" />
      <Spacer />
      <Step num={2} />
      <BodyText text="Once the theme editor has loaded, find the file called `theme.liquid` under the **Layout** section in the left hand menu and click to open it." />
      <Spacer />
      <Step num={3} />
      <BodyText text="Scroll to the bottom of the page and find the closing `</body>` tag. Paste the below script import **before** this closing tag and click the green save button at the top right of the editor screen:" />
      <CodeBlock>
        {`
          <!-- ShipAid protection widget -->
          <script src="https://unpkg.com/ui.shipaid.com" type="module"></script>
          `}
      </CodeBlock>
      <Spacer />
      <BodyText text="*Example*" />
      <CodeBlock>
        {` 
        <!-- Other page content -->
          
        <!-- ShipAid protection widget -->
        <script src="https://unpkg.com/ui.shipaid.com" type="module"></script>
      </body>

          `}
      </CodeBlock>
      <Spacer />
      <Step num={4} />
      <BodyText text="This step may differ, depending on how your theme implements the cart." />
      <Spacer />
      <div className="text-left max-w-2xl w-full">
        <BodyText text="**a. Cart Page**" />
      </div>
      <BodyText text="i. Find the cart page template under the **Templates** section in the editor file menu and choose your cart template - i.e. `cart.liquid`." />
      <BodyText text="ii. Paste the below code where you want the widget to appear in your cart and save the file:" />
      <CodeBlock>
        {`
          <shipaid-widget>
            <p>Loading ShipAid Protection</p>
          </shipaid-widget>
        `}
      </CodeBlock>
      <Spacer />
      <BodyText text="*Example*" />
      <CodeBlock>
        {`
          </div>
            </div>
            
              <shipaid-widget>
                <p>Loading ShipAid Protection</p>
              </shipaid-widget>

              <p class="visually-hidden" id="cart-live-region-text" aria-live="polite" role="status"></p>
              <p class="visually-hidden" id="shopping-cart-line-item-status" aria-live="polite" aria-hidden="true" role="status">{{ 'accessibility.loading' | t }}</p>
            </form>
          </cart-items>
        `}
      </CodeBlock>
      <Spacer />
      <div className="text-left max-w-2xl w-full">
        <BodyText text="**b. AJAX Cart (Cart sidebar/flyout)**" />
      </div>
      <BodyText text="If you have an AJAX cart implementation, then some JavaScript experience is recommended to ensure the best implementation. By hooking the widget up to the cart events, we can better ensure the most responsive UI." />
      <BodyText text="i. Find the cart flyout/sidebar liquid file - this could be under the Sections or the Snippets file menu sections." />
      <BodyText text="ii. Open the file and paste the below code where you want the widget to appear." />
      <CodeBlock>
        {`
          <shipaid-widget>
            <p>Loading ShipAid Protection</p>
          </shipaid>
        `}
      </CodeBlock>
      <Spacer />
      <BodyText text="iii. Find the JavaScript code for the cart implementation; in this code, we need to let the ShipAid widget know when an event has happened in the cart - I.e. a product has been added, updated, or removed." />
      <BodyText text="iv. For any function or method that updates the AJAX cart, add the below code to also update the ShipAid widget UI:" />
      <CodeBlock>
        {`
          /** Example function */
          async function addToCart (...args) {
            // ...
            const shipaid = document.querySelector('shipaid-widget')
            if (shipaid) await shipaid.updateCart()
          }

          /** If a cart function fetches the current cart at some point,
            * then we can pass that in to the ShipAid update function
            */
          async function updateCart (...args) {
            // ...
            const res = await fetch('/cart/update.js', // ...)
            const cartJSON = await res.json()
            const shipaid = document.querySelector('shipaid-widget')
            if (shipaid) await shipaid.updateCart(cartJSON )
            }
          `}
      </CodeBlock>
      <Spacer />
      <BodyText text="v. When a customer adds the ShipAid protection product to the cart, the widget emits an event which we can listen to, to update the cart UI state:" />
      <CodeBlock>
        {`
          // Example code
          function reloadCartUI () {
            // ...
          }

          document.addEventListener('shipaid-protection-status', ({ detail }) => {
            reloadCartUI()
          })
        `}
      </CodeBlock>
    </div>
  </div>
);
