import { useState, useEffect } from 'react';
import { Text } from 'components/Text';
import { Button } from 'components/Button';
import classNames from 'classnames';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  XCircleIcon,
  CashIcon,
  ArchiveIcon,
  CreditCardIcon,
  PencilAltIcon,
} from '@heroicons/react/outline';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tabs } from 'components/Tabs';
import { Reorder } from './Reorder';
import { Refund } from './Refund';
import { Denyclaim } from './DenyClaim';
import { InReview } from './InReview';
import { CauseOfClaim } from './CauseOfClaim';
import { ClaimSelectionItem } from 'components/ClaimItem';
import { LoadingScreen } from 'components/LoadingScreen';
import { Order, OrderItemValues } from 'types/order';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  GET_ORDER_BY_ID,
  GET_ORDER_BY_NUMBER,
  GET_CLAIM,
  GET_PROTECTED_ORDERS_WITH_NO_CLAIMS,
} from 'gql/queries';
import {
  START_CLAIM,
  REORDER_CLAIM,
  REFUND_CLAIM,
  DENY_CLAIM,
  OTHER_CLAIM,
} from 'gql/mutations';
import { withNotification } from 'components/Notification';
import { apiErrorHandler, NotificationTypes, SizeEnum } from 'utils';
import { useStore } from 'context/store-context';
import { ClaimItemValues } from 'types/claim';
import { AutoComplete } from 'components/AutoComplete';
import { SelectedOrderItem } from 'components/SelectedOrderItem';
import { Other } from './Other';

const tabs = [
  { name: 'Reorder', href: '#', icon: ArchiveIcon, current: false },
  { name: 'Refund', href: '#', icon: CashIcon, current: false },
  { name: 'Other', href: '#', icon: PencilAltIcon, current: false },
  { name: 'Deny Issue', href: '#', icon: XCircleIcon, current: true },
  { name: 'In Review', href: '#', icon: CreditCardIcon, current: true },
];

export const NewClaim = withNotification(({ showNotification }: any) => {
  const [orderNo, setOrderNo] = useState('');
  const [direction, setDirection] = useState('');
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedOrderItems, setSelectedOrderItems] = useState<
    OrderItemValues[]
  >([]);
  const [selectedClaimItems, setSelectedClaimItems] = useState<
    ClaimItemValues[]
  >([]);
  const [orderItem, setOrderItem] = useState<Order | null>(null);
  const [orderReference, setOrderReference] = useState<Order | null>(null);
  const [activeStep, setActiveStep] = useState(1);
  const [selectedTab, setSelectedTab] = useState('Reorder');
  const [selectedCause, setSelectedCause] = useState('');
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [notes, setNotes] = useState('');
  const [claimId, setClaimId] = useState('');
  const [searchValue, setSearchValue] = useState('');

  const steps = [1, 2, 3, 4];
  const limit = 10;
  const location = useLocation();

  const { storeId, storeProperties } = useStore();
  const navigate = useNavigate();

  const [getOrder, { data: orderData, loading: orderLoading }] = useLazyQuery(
    GET_ORDER_BY_ID,
    {
      onError(error) {
        const newError = apiErrorHandler(error);
        showNotification(NotificationTypes.error, newError?.message);
      },
    },
  );

  const [getOrderByNumber] = useLazyQuery(GET_ORDER_BY_NUMBER, {
    onError(error) {
      const newError = apiErrorHandler(error);
      showNotification(NotificationTypes.error, newError?.message);
    },
  });
  const [getProtectedOrdersWithNoClaims] = useLazyQuery(
    GET_PROTECTED_ORDERS_WITH_NO_CLAIMS,
    {
      onCompleted: (data) => {
        if (data?.orders) {
          setOrders(
            data?.orders.map((order: any) => ({
              ...order,
              name: order.number,
            })),
          );
        }
      },
      onError(error) {
        const newError = apiErrorHandler(error);
        showNotification(NotificationTypes.error, newError?.message);
      },
    },
  );

  const [startClaim, { loading: startClaimLoading, error: startClaimError }] =
    useMutation(START_CLAIM, {
      onError(error) {
        const newError = apiErrorHandler(error);
        showNotification(NotificationTypes.error, newError?.message);
      },
    });

  const [reorderClaim, { loading: reorderClaimLoading }] = useMutation(
    REORDER_CLAIM,
    {
      onError(error) {
        const newError = apiErrorHandler(error);
        showNotification(NotificationTypes.error, newError?.message);
      },
    },
  );

  const [refundClaim, { loading: refundClaimLoading }] = useMutation(
    REFUND_CLAIM,
    {
      onError(error) {
        const newError = apiErrorHandler(error);
        showNotification(NotificationTypes.error, newError?.message);
      },
    },
  );

  const [otherClaim, { loading: otherClaimLoading }] = useMutation(
    OTHER_CLAIM,
    {
      onError(error) {
        const newError = apiErrorHandler(error);
        showNotification(NotificationTypes.error, newError?.message);
      },
    },
  );

  const [denyClaim, { loading: denyClaimLoading }] = useMutation(DENY_CLAIM, {
    onError(error) {
      const newError = apiErrorHandler(error);
      showNotification(NotificationTypes.error, newError?.message);
    },
  });

  const [fetchClaim, { data: fetchedClaim }] = useLazyQuery(GET_CLAIM, {
    onError(error) {
      const newError = apiErrorHandler(error);
      showNotification(NotificationTypes.error, newError?.message);
    },
  });

  const onChangeValue = (option: any) => {
    setSelectedOrder(option);
    setOrderNo(option.number);
  };

  useEffect(() => {
    const state = location.state as { order: Order };

    if (state && state.order) {
      setOrderNo(state?.order?.number);
      getOrder({ variables: { storeId, id: state?.order?.id } });
    } else {
      // if there's no orders in location state, fetch all with no claims
      getProtectedOrdersWithNoClaims({
        variables: {
          storeId,
          number: `%${searchValue}%`,
          limit,
        },
      });
    }
  }, [
    location,
    getOrder,
    storeId,
    getProtectedOrdersWithNoClaims,
    searchValue,
  ]);

  useEffect(() => {
    if (searchValue && searchValue !== '') {
      getProtectedOrdersWithNoClaims({
        fetchPolicy: 'cache-and-network',
        variables: {
          storeId,
          number: `%${searchValue}%`,
          limit,
        },
      });
    }
  }, [getProtectedOrdersWithNoClaims, searchValue, storeId]);

  useEffect(() => {
    if (orderData && activeStep === 1 && direction !== 'prev') {
      setActiveStep(activeStep + 1);
      orderData?.orders.length > 0 && setOrderItem(orderData?.orders[0]);
      orderData?.orders.length > 0 && setOrderReference(orderData?.orders[0]);
    }
  }, [orderData, activeStep, direction]);

  useEffect(() => {
    if (fetchedClaim?.claims && activeStep === 3) {
      setSelectedClaimItems(fetchedClaim.claims[0].items);
      setActiveStep(activeStep + 1);
    }
  }, [fetchedClaim, activeStep]);

  const handleNextStep = () => {
    const state = location.state as { order: Order };
    if (activeStep === steps.length && claimId) return navigate(`/claims`);

    if (
      !orderItem?.protectionTotal &&
      !orderItem?.isReorder &&
      activeStep !== 1
    )
      return showNotification(
        NotificationTypes.error,
        'This order is not protected, cannot create a claim for an unprotected order',
      );
    setDirection('next');
    if (activeStep === 3 && !selectedCause)
      return showNotification(
        NotificationTypes.error,
        'Please select a cause for the claim',
      );
    if (!state?.order?.number && orderNo === '') {
      return showNotification(
        NotificationTypes.error,
        'Please Enter an order number',
      );
    }
    if (activeStep === 3 && selectedCause) {
      return handleStartClaim();
    }
    if (selectedOrder) {
      return getOrderByNumber({
        variables: { storeId, number: (selectedOrder as any).number },
      }).then((data) => {
        if (data?.data.orders) {
          setOrderItem(data?.data?.orders[0]);
          setOrderReference(data?.data?.orders[0]);
          setActiveStep(activeStep + 1);
        }
      });
    }
    if (orderNo !== orderItem?.number) {
      return handleFetchOrder(orderNo);
    }
    if (orderNo !== '' && !orderData && !orderItem) {
      return handleFetchOrder();
    }
    setActiveStep(activeStep + 1);
  };

  const handleStartClaim = () => {
    let payload;
    payload = {
      notes,
      reason: selectedCause,
      storeId,
      orderId: orderItem?.id,
    };
    if (selectedOrderItems.length > 0) {
      payload = {
        ...payload,
        items: selectedOrderItems.map((item) => {
          return {
            id: item.id,
            reason: selectedCause,
            quantity: item.quantity,
          };
        }),
      };
    }
    if (imageUrls.length > 0) {
      payload = {
        ...payload,
        images: imageUrls.map((item) => {
          return { url: item, notes };
        }),
      };
    }
    startClaim({
      variables: {
        input: payload,
      },
    })
      .then((data) => {
        fetchClaim({
          variables: {
            storeId,
            id: data?.data.startClaim[0].id,
          },
        });
        return setClaimId(data?.data.startClaim[0].id);
      })
      .catch((err) =>
        showNotification(
          NotificationTypes.error,
          startClaimError
            ? startClaimError.message
            : 'oops something went wrong',
        ),
      );
  };

  const handleReorderClaim = (input: any) => {
    let payload = {
      ...input,
      storeId,
      id: claimId,
      items: selectedClaimItems
        .map((item) => ({
          id: item.id,
          quantity: item.quantity,
        }))
        .filter((item) => item.quantity), // Filter items with 0 quantity,
    };
    if (!payload.items.length) {
      showNotification(NotificationTypes.error, 'Invalid Item Quantity');
    }
    reorderClaim({
      variables: { input: payload },
    }).then((data) => {
      navigate(`/claims`);
      return window.open(data?.data.reorderClaim[0].orderUrl, '_blank');
    });
  };

  const handleRefundClaim = (input: any) => {
    let payload = {
      ...input,
      storeId,
      id: claimId,
      items: selectedClaimItems
        .map((item) => ({
          id: item.id,
          quantity: item.quantity,
        }))
        .filter((item) => item.quantity), // Filter items with 0 quantity,
    };
    refundClaim({
      variables: { input: payload },
    }).then(() => navigate(`/claims`));
  };

  const handleOtherClaim = (input: any) => {
    let payload = {
      ...input,
      storeId,
      id: claimId,
      items: selectedClaimItems.map((item) => ({
        id: item.id,
        quantity: item.quantity,
      })),
    };
    otherClaim({
      variables: { input: payload },
    }).then(() => navigate(`/claims`));
  };

  const handleDenyClaim = (input: any) => {
    let payload = {
      ...input,
      storeId,
      id: claimId,
    };
    denyClaim({
      variables: { input: payload },
    }).then(() => navigate(`/claims`));
  };

  const handleFetchOrder = (order?: string) => {
    getOrderByNumber({
      variables: { storeId, number: order ? `#${order}` : `#${orderNo}` },
    }).then((data) => {
      if (data?.data.orders) {
        setOrderItem(data?.data?.orders[0]);
        setOrderReference(data?.data?.orders[0]);
        setActiveStep(activeStep + 1);
      }
    });
  };

  const handlePrevStep = () => {
    setDirection('prev');
    if (activeStep === 1) return;
    setActiveStep(activeStep - 1);
  };

  const handleSelectOrderItems = (orderItem: OrderItemValues) => {
    const selectedIds = selectedOrderItems.map((item) => item.id);
    if (selectedIds.includes(orderItem.id)) {
      const filtered = selectedOrderItems.filter(
        (item) => item.id !== orderItem.id,
      );
      return setSelectedOrderItems(filtered);
    }
    setSelectedOrderItems([...selectedOrderItems, orderItem]);
  };

  const handleOrderItemQuantityUpdate = (quantity: string, itemId: string) => {
    const foundSelectedOrderItem = selectedOrderItems.find(
      (item) => item.id === itemId,
    );

    if (selectedClaimItems.find((item) => item.id === itemId)) {
      const updatedClaimItems = [...selectedClaimItems].map((item) =>
        item.id === itemId
          ? {
              ...item,
              orderItem: { ...item.orderItem, quantity: Number(quantity) },
            }
          : item,
      );
      return setSelectedClaimItems(updatedClaimItems);
    }

    if (foundSelectedOrderItem) {
      const updatedSelectedOrderItems = selectedOrderItems.length
        ? [...selectedOrderItems].map((order) =>
            order.id === itemId
              ? {
                  ...order,
                  quantity: Number(quantity),
                }
              : order,
          )
        : [];
      setSelectedOrderItems(updatedSelectedOrderItems);
    }
    const updatedOrderItems = orderItem?.items.length
      ? [...orderItem?.items].map((order) =>
          order.id === itemId
            ? {
                ...order,
                quantity: Number(quantity),
              }
            : order,
        )
      : [];

    orderItem &&
      setOrderItem({
        ...orderItem,
        items: updatedOrderItems,
      });

    const foundOrderItem = updatedOrderItems.find((item) => item.id === itemId);

    if (!foundSelectedOrderItem) {
      foundOrderItem && handleSelectOrderItems(foundOrderItem);
    }
  };

  const handleSelectClaimItems = (claimItem: ClaimItemValues) => {
    if (selectedClaimItems.includes(claimItem)) {
      const filtered = selectedClaimItems.filter(
        (item) => item.id !== claimItem.id,
      );
      return setSelectedClaimItems(filtered);
    }
    setSelectedClaimItems([...selectedClaimItems, claimItem]);
  };

  const handleQuantityUpdate = (value: string, claimItem: ClaimItemValues) => {
    const updatedClaimItems = [...selectedClaimItems].map((item) =>
      item.id === claimItem.id ? { ...item, quantity: Number(value) } : item,
    );
    setSelectedClaimItems(updatedClaimItems);
  };

  if (orderLoading) return <LoadingScreen />;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <Text value="New Delivery Issue" type="h3" />
      {activeStep === 1 && (
        <div className="mt-8">
          <Text
            value="Order Number"
            type="body"
            className="mt-4 mb-2 text-gray-500"
          />
          <AutoComplete
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            options={orders}
            onChange={onChangeValue}
            selected={selectedOrder}
          />
        </div>
      )}
      {activeStep === 2 && (
        <div className="mt-8">
          <Text value="Items" type="h3" />
          <Text
            value="Select issue item(s)"
            type="body"
            className="mt-4 mb-2 text-gray-500"
          />
          <div className="sm:flex flex-wrap">
            {orderItem &&
              orderItem.items
                .filter(
                  (item) =>
                    !storeProperties?.protectionSettings.variantIds.includes(
                      item.variantId,
                    ),
                )
                .map((item: OrderItemValues, index) => (
                  <div key={item.id} className="sm:mr-5 mb-5">
                    <ClaimSelectionItem
                      orderItem={item}
                      onSelect={() => handleSelectOrderItems(item)}
                      selected={selectedOrderItems
                        .map((orderItem) => orderItem.id)
                        .includes(item.id)}
                      updateQuantity={handleOrderItemQuantityUpdate}
                      order={orderReference ?? orderItem}
                      index={index}
                    />
                  </div>
                ))}
          </div>
          <div className="block sm:flex justify-between gap-4 mt-4">
            <div className="sm:border-b py-3 pr-3 w-1/3">
              <Text value="Order Number" type="sm" className="font-bold" />
              <Text
                value={orderItem?.number}
                type="body"
                className="text-gray-500"
              />
            </div>
            <div className="sm:border-b py-3 pr-3 w-1/3">
              <Text value="Order Date" type="sm" className="font-bold" />
              <Text
                value={
                  orderItem
                    ? `${new Date(orderItem?.createdAt).toUTCString()}`
                    : ''
                }
                type="body"
                className="text-gray-500"
              />
            </div>
            <div className="sm:border-b py-3 pr-3 w-1/3">
              <Text value="Email" type="sm" className="font-bold" />
              <Text
                value={orderItem?.customerEmail ?? 'N/A'}
                type="body"
                className="text-gray-500"
              />
            </div>
          </div>
          <div className="block sm:flex justify-between gap-4 sm:mt-4">
            <div className="sm:border-b py-3 pr-3 w-1/3">
              <Text value="Tracking" type="sm" className="font-bold" />
              <Text
                value={orderItem?.tracking ?? 'N/A'}
                type="body"
                className="text-gray-500"
              />
            </div>
            <div className="sm:border-b py-3 pr-3 w-1/3">
              <Text
                value="Estimated Delivery Date"
                type="sm"
                className="font-bold"
              />
              <Text
                value={
                  orderItem?.items[0]?.estimatedDeliveredAt
                    ? `${new Date(
                        orderItem?.items[0]?.estimatedDeliveredAt,
                      ).toUTCString()}`
                    : 'N/A'
                }
                type="body"
                className="text-gray-500"
              />
            </div>
            <div className="sm:border-b py-3 pr-3 w-1/3">
              <Text value="Shipped to" type="sm" className="font-bold" />
              <Text
                value={`${orderItem?.customerName}`}
                type="body"
                className="text-gray-500"
              />
            </div>
          </div>
          <div className="block sm:flex justify-between gap-4 sm:mt-4">
            <div className="sm:border-b py-3 pr-3 w-1/3">
              <Text value="Order Total" type="sm" className="font-bold" />
              <Text
                value={`$ ${orderItem?.subtotal}`}
                type="body"
                className="text-gray-500"
              />
            </div>
            <div className="sm:border-b py-3 pr-3 w-1/3">
              <Text value="Guarantee paid" type="sm" className="font-bold" />
              <Text
                value={
                  orderItem?.protectionTotal
                    ? `$ ${orderItem?.protectionTotal}`
                    : 'Not Guaranteed'
                }
                type="body"
                className="text-gray-500"
              />
            </div>
            <div className="sm:border-b py-3 pr-3 w-1/3"></div>
          </div>
          <Text
            className="mt-3 text-red-500"
            type="sm"
            value="NB - If you do not select a quantity for a selected item, all the quantities of the item will be automatically selected"
          />
        </div>
      )}

      {activeStep === 3 && (
        <div className="mt-8">
          <Text value="Selected Item(s)" type="h4" />
          <div className="flex my-3 flex-wrap">
            {selectedOrderItems.length > 0
              ? selectedOrderItems.map((item) => (
                  <SelectedOrderItem orderItem={item} key={item.id} />
                ))
              : null}
          </div>
          <Text value="Cause" type="h4" />
          <Text
            value="Choose from the issue cause below and input additional information"
            type="body"
            className="mt-4 mb-2 text-gray-500"
          />
          <div>
            <CauseOfClaim
              handleImageUrls={(urls) => setImageUrls(urls)}
              handleSetCause={(cause) => setSelectedCause(cause)}
              setNotes={(note) => setNotes(note)}
            />
          </div>
        </div>
      )}

      {activeStep === 4 && (
        <div className="mt-8">
          <Text
            value="Resolution"
            type="h1"
            className="font-normal text-blue-700"
          />
          <Text
            value="Choose from the the resolutions available from the issue below"
            type="body"
            className="mt-4 mb-2 text-gray-500"
          />
          <div>
            <Tabs
              tabs={tabs}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
            <div>
              {selectedTab === 'Reorder' && (
                <Reorder
                  claimItems={selectedClaimItems}
                  handleRemoveItem={(item) => handleSelectClaimItems(item)}
                  handleReorderItems={handleReorderClaim}
                  loading={reorderClaimLoading}
                  handleQuantityUpdate={handleQuantityUpdate}
                  staticClaimItems={fetchedClaim.claims[0].items ?? []}
                  order={orderReference ?? selectedOrder}
                />
              )}
              {selectedTab === 'Refund' && (
                <Refund
                  claimItems={selectedClaimItems}
                  handleRemoveItem={(item) => handleSelectClaimItems(item)}
                  handleRefundItems={handleRefundClaim}
                  loading={refundClaimLoading}
                  handleQuantityUpdate={handleQuantityUpdate}
                  staticClaimItems={fetchedClaim.claims[0].items ?? []}
                  order={orderReference ?? selectedOrder}
                />
              )}
              {selectedTab === 'Other' && (
                <Other
                  claimItems={selectedClaimItems}
                  handleRemoveItem={(item) => handleSelectClaimItems(item)}
                  handleOtherItems={handleOtherClaim}
                  loading={otherClaimLoading}
                  handleQuantityUpdate={handleQuantityUpdate}
                  staticClaimItems={fetchedClaim.claims[0].items ?? []}
                  order={orderReference ?? selectedOrder}
                />
              )}
              {selectedTab === 'Deny Issue' && (
                <Denyclaim
                  handleDenyItems={handleDenyClaim}
                  loading={denyClaimLoading}
                />
              )}
              {selectedTab === 'In Review' && (
                <InReview
                  claimItems={selectedClaimItems}
                  handleRemoveItem={(item) => handleSelectClaimItems(item)}
                  handleSetInReview={() => {
                    const state = location.state as { returnLocation: string };
                    return navigate(`/claims/${claimId}`, {
                      state: { returnLocation: state?.returnLocation ?? null },
                    });
                  }}
                />
              )}
            </div>
          </div>
        </div>
      )}

      <div className="flex justify-evenly mt-8 border-t pt-6">
        {activeStep !== 1 && activeStep !== 4 ? (
          <Button
            onClick={handlePrevStep}
            className="bg-blue-600 hover:bg-blue-700"
          >
            <ChevronLeftIcon className="w-5 text-white" />
            Previous
          </Button>
        ) : (
          <div />
        )}
        <div className="flex items-center">
          <Text
            value={`Step ${activeStep} of ${steps.length}`}
            className="mr-3 font-bold text-gray-400"
            type="sm"
          />
          {steps.map((step) => (
            <div
              key={step}
              className={classNames(
                activeStep >= step ? 'bg-blue-600' : 'border border-gray-400',
                'h-2 w-2 mr-2 rounded-full',
              )}
            />
          ))}
        </div>
        <Button
          onClick={handleNextStep}
          size={activeStep === 4 ? SizeEnum.xs : SizeEnum.md}
          className={classNames(
            activeStep === 4
              ? 'bg-yellow-400 hover:bg-yellow-500'
              : 'bg-blue-600 hover:bg-blue-700',
            '',
          )}
          isLoading={startClaimLoading}
        >
          {activeStep === 3
            ? 'Create Issue'
            : activeStep === 4
            ? 'Skip Resolution'
            : 'Next'}
          <ChevronRightIcon className="w-5 text-white" />
        </Button>
      </div>
    </div>
  );
});
