import { useEffect, useState } from 'react';
import { LoadingScreen } from 'components/LoadingScreen';
import { GET_USER_REFERRAL_PROGRAM_INFO } from 'gql/queries';
import { useLazyQuery } from '@apollo/client';
import { useUser } from 'context/user-context';

interface ReferralProgramData {
  access_token: string;
  expires_in: number;
  hasAccount: boolean;
}

export const ReferralProgram = () => {
  const { user } = useUser();
  const [referralProgram, setReferralProgram] =
    useState<ReferralProgramData | null>(null);

  const [getUserReferralProgramInfo, { loading }] = useLazyQuery(
    GET_USER_REFERRAL_PROGRAM_INFO,
    {
      onCompleted(data) {
        if (data.getReferralProgramInfo) {
          const referralProgramInfo = { ...data.getReferralProgramInfo };
          delete referralProgramInfo.__typename;
          setReferralProgram(referralProgramInfo);
        }
      },
    },
  );

  useEffect(() => {
    (async () => {
      try {
        if (user) {
          getUserReferralProgramInfo({
            fetchPolicy: 'cache-and-network',
          });
        }
      } catch (e) {
        console.log('error', e);
      }
    })();
  }, [user, getUserReferralProgramInfo]);

  if (loading) return <LoadingScreen />;

  return (
    <div
      className={`h-[100vh] pt-${
        referralProgram?.hasAccount ? '6' : '0'
      } bg-[#f4f8fb]`}
    >
      {referralProgram?.hasAccount ? (
        <iframe
          title="First Promoter"
          style={{ width: '100%', height: '100%', border: 'none' }}
          allow="clipboard-write"
          src={`https://shipaid.firstpromoter.com/iframe?tk=${referralProgram.access_token}`}
        ></iframe>
      ) : (
        <iframe
          title="First Promoter"
          style={{ width: '100%', height: '100%', border: 'none' }}
          allow="clipboard-write"
          src={`https://shipaid.firstpromoter.com`}
        ></iframe>
      )}
    </div>
  );
};
