import React from 'react';
import { Modal } from 'components/Modal';
import { Text } from 'components/Text';
import { Button } from 'components/Button';
import { StoreValues } from 'types/store';

type AdminCreditModalProps = {
  show: boolean;
  setShowModal: (value: boolean) => void;
  handleUpdateStoreCredit?: (type: string) => void;
  store: StoreValues;
  isLoading?: boolean;
  storeCredit: string;
  setStoreCredit: (fee: string) => void;
};

export const AdminCreditModal = ({
  show,
  setShowModal,
  handleUpdateStoreCredit,
  store,
  isLoading,
  storeCredit,
  setStoreCredit,
}: AdminCreditModalProps) => {
  const handleSave = () => {
    handleUpdateStoreCredit && handleUpdateStoreCredit(storeCredit);
  };

  return (
    <Modal
      openModal={(value) => setShowModal(value)}
      open={show}
      containerClass="sm:max-w-lg sm:w-full"
    >
      <Text value={store?.name ?? 'Store'} type="h4" />
      <div className="block sm:flex sm:items-center">
        <Text
          value="Admin Credit Amount"
          type="sm"
          className="text-gray-500 mr-3 mb-2 sm:mb-0"
        />
        <input
          type="number"
          name="email"
          className="border border-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md p-2"
          placeholder="e.g 3"
          value={storeCredit}
          onChange={(e) => setStoreCredit(e.target.value)}
        />
      </div>
      <div className="flex mt-6">
        <Button
          onClick={handleSave}
          className="bg-blue-600 mr-3 hover:bg-blue-700"
          isLoading={isLoading}
        >
          Save Changes
        </Button>
        <Button
          onClick={() => setShowModal(false)}
          className="bg-transparent border border-rose-500 text-gray-500 hover:bg-rose-100"
        >
          <Text value="Cancel" className="text-rose-500" />
        </Button>
      </div>
    </Modal>
  );
};
