import React from 'react';
import classNames from 'classnames';
import { Text } from 'components/Text';

type PackagePlanProps = {
  active?: boolean;
  header: string | React.ReactNode;
  children?: React.ReactNode;
  actionHandler?: () => void;
  action?: string;
  activeTitle?: string;
  bottomBorder?: boolean;
  headerClassName?: string;
  containerClassName?: string;
  isAltVersion?: boolean;
};

export const PackagePlan = ({
  active,
  header,
  children,
  action,
  actionHandler,
  activeTitle,
  bottomBorder,
  headerClassName,
  containerClassName,
  isAltVersion = false,
}: PackagePlanProps) => {
  return (
    <div
      className={classNames(
        active ? 'border-blue-600 border-2' : 'border-gray-300 border',
        'shadow rounded-3xl h-full',
        isAltVersion && 'style-bg',
        containerClassName,
      )}
    >
      <div className="">
        {active && (
          <div className="flex justify-center">
            <div className="absolute bg-blue-600 rounded-full px-6 item-center -mt-3 w-max">
              <Text
                value={activeTitle ? activeTitle : 'Active'}
                type="body"
                className="text-white font-bold"
              />
            </div>
          </div>
        )}
        {typeof header === 'string' ? (
          <div className={classNames('mt-3 pt-6 pb-4', headerClassName)}>
            <Text value={header} type="h2" />
          </div>
        ) : (
          header
        )}
        <div className="h-max">{children}</div>
        {action ? (
          <div
            className={classNames(
              bottomBorder
                ? 'border-t border-gray-300 px-6 py-6 flex justify-center'
                : 'px-6 py-6 flex justify-center',
            )}
          >
            <button
              type="button"
              onClick={actionHandler}
              className={classNames(
                active
                  ? 'bg-blue-600 hover:bg-blue-700 text-white'
                  : 'border border-gray-200 text-blue-600',
                'inline-flex items-center justify-center px-6 py-2 text-sm font-bold rounded-md shadow-sm w-full mt-3',
              )}
            >
              {action}
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};
