import React from 'react';

interface AlertProps {
  message: string;
  type: 'warning' | 'info' | 'error' | 'success';
  actions?: React.ReactNode[];
}

const Alert: React.FC<AlertProps> = ({ message, type, actions }) => {
  const getAlertStyles = () => {
    switch (type) {
      case 'warning':
        return 'bg-yellow-50 border-yellow-400 text-yellow-700';
      case 'info':
        return 'bg-blue-50 border-blue-400 text-blue-700';
      case 'error':
        return 'bg-red-50 border-red-400 text-red-700';
      case 'success':
        return 'bg-green-50 border-green-400 text-green-700';
      default:
        return 'bg-gray-50 border-gray-400 text-gray-700';
    }
  };

  const getIconPath = () => {
    switch (type) {
      case 'warning':
        return (
          <path
            fillRule="evenodd"
            d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
            clipRule="evenodd"
          />
        );
      default:
        return (
          <path
            fillRule="evenodd"
            d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
            clipRule="evenodd"
          />
        );
    }
  };

  return (
    <div
      className={`border-l-4 p-4 mb-4 ${getAlertStyles()}`}
      style={{ width: '50%' }}
    >
      <div className="flex">
        <div className="flex-shrink-0">
          <svg
            className="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            {getIconPath()}
          </svg>
        </div>
        <div className="ml-3 flex-grow">
          <p className="text-sm">{message}</p>
          {actions && actions.length > 0 && (
            <div className="mt-4 flex-shrink-0 flex">
              {actions.map((action, index) => (
                <React.Fragment key={index}>
                  {index > 0 && <span className="ml-3" />}
                  {action}
                </React.Fragment>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Alert;
