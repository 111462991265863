import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DragHandle } from './DragHandle';

export const DraggableTableRow = ({
  row,
  columns,
}: {
  row: any;
  columns: any;
}) => {
  const {
    attributes,
    listeners,
    transform,
    transition,
    setNodeRef,
    isDragging,
  } = useSortable({
    id: row.id,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
  };
  return (
    <tr ref={setNodeRef} style={style}>
      {isDragging
        ? columns &&
          columns.map((column: any, index: number) => (
            <td key={index} className="py-10"></td>
          ))
        : columns &&
          columns.map((column: any, index: number) =>
            index === 0 ? (
              <td
                className="w-1/4 py-4 whitespace-nowrap text-gray-600 text-sm font-medium text-center"
                key={index}
              >
                <div className="flex justify-center items-center gap-4 relative">
                  <DragHandle {...attributes} {...listeners} />
                  <div className="text-base font-medium">
                    {column.render ? column.render(row) : null}
                    {column.key ? row[column.key] : null}
                  </div>
                </div>
              </td>
            ) : (
              <td
                key={column.id}
                className={`w-1/4 py-4 whitespace-nowrap text-gray-600 text-sm font-medium text-center table-switch-status`}
              >
                {column.render ? column.render(row) : null}
                {column.key ? row[column.key] : null}
              </td>
            ),
          )}
    </tr>
  );
};
