/* eslint-disable react/prop-types */
// Reference: https://github.com/christo-pr/dangerously-set-html-content/blob/main/src/index.js
import React, { useEffect, useRef } from 'react';

function DangerouslySetHtmlContent({ html, ...rest }) {
  const divRef = useRef(null);

  useEffect(() => {
    if (!html || !divRef.current) throw new Error("html prop can't be null");

    divRef.current.innerHTML = html; // Insert HTML

    const scripts = Array.from(divRef.current.getElementsByTagName('script'));
    scripts.forEach((script) => {
      const newScript = document.createElement('script');
      newScript.text = script.text; // Copy code
      script.parentNode.replaceChild(newScript, script); // Replace script
    });
  }, [html, divRef]);

  return <div {...rest} ref={divRef} />;
}

export default DangerouslySetHtmlContent;
