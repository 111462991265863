import { StoreAnalyticsInterface, StoreValues } from 'types/store';
import { DateTime } from 'luxon';
import { Order } from 'types/order';
import { Claim } from 'types/claim';
import { ApolloError } from '@apollo/client';
import { User, userRoles } from 'types/user';

export function truncateString(str: string, n: number) {
  if (str) {
    return str.length > n ? str.substr(0, n - 1) + '...' : str;
  }
  return str;
}

export enum SizeEnum {
  xs,
  sm,
  md,
  lg,
  xl,
}

export enum NotificationTypes {
  success = 'success',
  error = 'error',
  pending = 'pending',
}

export const calculatePriceSum = (items: any[], field: string) => {
  let count = 0;
  for (let i = 0; i < items.length; i++) {
    if (items[i][field]) {
      count += items[i][field];
    }
  }
  return Number(count).toFixed(2).toString();
  // .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatCurrency = (amount: number) => {
  return Number(amount)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

export const formatPieData = (obj: StoreAnalyticsInterface) => {
  const arr = [
    {
      name: 'Guaranteed Orders',
      value: obj?.protectedOrders?.aggregate.total ?? 0,
      fill: '#2564eb',
    },
    {
      name: 'Other Orders',
      value: obj?.unprotectedOrders?.aggregate.total ?? 0,
      fill: '#e46666',
    },
  ];
  return arr;
};

export const getStoreCurrencyFormatter = (
  currency: string = 'USD',
  value: number | string = 0,
) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  });

  if (value && String(value).indexOf(',') !== -1) {
    value = String(value).replace(/,/g, '');
  }

  const number = Number(value);

  return formatter.format(number);
};

export const getStoreCurrencySymbol = (currency: string | undefined) =>
  getStoreCurrencyFormatter(currency, 0).charAt(0);

export const checkTodoList = (store: StoreValues) => {
  let steps: string[] = [];

  if (store.contactEmail) {
    steps = [...steps, 'companyUpdate'];
  }
  if (store?.protectionSettings?.protectionType) {
    steps = [...steps, 'protection'];
  }
  if (store?.users?.length > 1 || store?.invites?.length > 0) {
    steps = [...steps, 'teamInvite'];
  }
  if (store?.completedSteps?.hasVisitedResolutionPage) {
    steps = [...steps, 'customer'];
  }
  if (store?.completedSteps?.hasVisitedNotificationPage) {
    steps = [...steps, 'notifications'];
  }
  if (store?.completedSteps?.hasVisitedTrackingPage) {
    steps = [...steps, 'trackingPage'];
  }
  return steps;
};

export const formatProtectedOrdersandClaimsGraphData = (
  data: any,
  startDate: Date | null,
  endDate: Date | null,
) => {
  // Check the date format to be used
  let differenceInYears = 0;
  if (endDate && startDate) {
    differenceInYears = endDate?.getFullYear() - startDate.getFullYear();
  }
  const dateFormatOptions: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: differenceInYears ? '2-digit' : undefined,
  };

  // Create an array of orders by date - [date: string, orders[]]

  if (data) {
    const protectionRevenue = data.protectedOrders.reduce(
      (total: number, order: Order) => {
        return total + order.protectionTotal;
      },
      0,
    );

    const claimsPaidSum = data.claimsPaid.reduce(
      (total: number, claim: Claim) => {
        return total + claim.total;
      },
      0,
    );

    const protectedOrdersByDate = Array.from(
      data.protectedOrders.reduce((group: any, order: any) => {
        const date = DateTime.fromISO(order.createdAt).toISODate();

        const existingDateOrders = group.get(date);
        if (existingDateOrders) group.set(date, [...existingDateOrders, order]);
        else group.set(date, [order]);

        return group;
      }, new Map()),
    );

    const protectedOrdersGraphData = protectedOrdersByDate.map(
      ([date, orders]: any) => {
        return {
          date: new Date(date).toLocaleDateString(undefined, dateFormatOptions),
          orders: orders.length,
        };
      },
    );

    return {
      protectedOrdersGraphData,
      protectionClaimsChart: [
        {
          name: 'Protection Revenue',
          value: protectionRevenue,
          fill: '#2564eb',
        },
        {
          name: 'Claims Paid',
          value: claimsPaidSum,
          fill: '#ed6666',
        },
      ],
    };
  }
};

export const isInThePast = (date: Date | string) => {
  const today = new Date();
  const dateFormatted = new Date(date);
  today.setHours(0, 0, 0, 0);

  return dateFormatted < today;
};

export const apiErrorHandler = (error: ApolloError) => {
  let updatedError = { ...error };

  if (
    error?.graphQLErrors &&
    error?.graphQLErrors[0]?.extensions?.code === 'invalid-jwt'
  ) {
    updatedError.message =
      'Your session has expired, the page will refresh in 5 seconds to reauthenticate you';

    setTimeout(() => window.location.reload(), 5000);
    return updatedError;
  }

  return error;
};

export const isShipaidAdmin = (user: User | undefined | null) => {
  return (
    user?.roles?.includes(userRoles.SHIPAID_ADMIN) ||
    user?.defaultRole === userRoles.SHIPAID_ADMIN
  );
};

export function getDateXDaysAgo(numOfDays: number, date = new Date()): Date {
  const daysAgo = new Date(date.getTime());

  daysAgo.setDate(date.getDate() - numOfDays);

  return daysAgo;
}

export function getDateXDaysInFuture(
  numOfDays: number,
  date = new Date(),
): Date {
  const daysInFuture = new Date(date.getTime());

  daysInFuture.setDate(date.getDate() + numOfDays);

  return daysInFuture;
}

export enum DateRangeOption {
  Today = 'Today',
  Yesterday = 'Yesterday',
  Last7Days = 'Last 7 Days',
  Last30Days = 'Last 30 Days',
  Last90Days = 'Last 90 Days',
}

export const getStartAndEndDate = (
  option: DateRangeOption,
): [Date | null, Date | null] => {
  const defaultNumOfDays = 30;
  let numOfDays;

  if (option === DateRangeOption.Today) {
    numOfDays = 0;
  } else if (option === DateRangeOption.Yesterday) {
    numOfDays = 1;
  } else if (option === DateRangeOption.Last7Days) {
    numOfDays = 7;
  } else if (option === DateRangeOption.Last30Days) {
    numOfDays = 30;
  } else if (option === DateRangeOption.Last90Days) {
    numOfDays = 90;
  } else {
    numOfDays = defaultNumOfDays;
  }

  const startDate = getDateXDaysAgo(numOfDays);
  const endDate =
    option === DateRangeOption.Today
      ? getDateXDaysInFuture(1)
      : getDateXDaysAgo(0);

  return [startDate, endDate];
};

export const getCurrentMonth = () => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const date = new Date();
  return months[date.getMonth()];
};

export const getCurrentTierInfo = (ordersCount: number = 0) => {
  if (ordersCount < 201) {
    return { percentage: 15 / 100, level: 1 };
  }
  if (ordersCount > 200 && ordersCount < 501) {
    return { percentage: 12 / 100, level: 2 };
  }
  if (ordersCount > 500) {
    return { percentage: 9 / 100, level: 3 };
  }

  return { percentage: 0, level: 0 };
};

export const getTierLevel = () => {};

export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const filterAndSliceFirstTrackingId = (items: any) => {
  const trackingIds = items
    .filter((i: { trackingId: string }) => i.trackingId && i.trackingId !== '')
    .slice(0, 1);
  const trackingId = trackingIds.length ? trackingIds[0].trackingId : 'N/A';
  return trackingId;
};

export const formatStatusText = (status?: string | null | undefined) => {
  if (!status) return '';
  return status.replace(/_/g, ' ');
};

/**
 * Extracts a numeric value from a string containing numbers and returns it as a float.
 *
 * @param price - The input string containing the price.
 * @returns The extracted numeric value as a float, or 0 if no valid number is found.
 */
export const getPriceValue = (price: string | number): number | bigint => {
  const typeInput = typeof price;

  if (typeInput === 'number' || typeInput === 'bigint') {
    return Number(price);
  }

  const numberVal = Number(String(price).replace(/[^\d.]/g, ''));

  return isNaN(numberVal) ? 0 : numberVal;
};

/**
 * Converts cloudinary video into thumbnail img.
 *
 * @param videoUrl - The video url string.
 * @returns The converted thumbnail in JPEG.
 */
export const getThumnailUrl = (videoUrl: string) => {
  return videoUrl.replace(/\.[^/.]+$/, '.jpeg');
};

/**
 * Returns a number formatted to two decimal places, removing any trailing zeros.
 *
 * @param {number} num - The number to be formatted.
 * @returns {string} The formatted number as a string, with up to two decimal places.
 */
export const getPreciseDecimal = (num: number): string => {
  let precisedNum = Number(num).toFixed(2);
  precisedNum = parseFloat(precisedNum).toString();
  return precisedNum;
};

export const isJSONString = (str: string | null | undefined) => {
  if (!str) return false;
  try {
    JSON.parse(str);
    return true;
  } catch (error) {
    return false;
  }
};
