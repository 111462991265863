import React from 'react';
import { UserProvider } from './user-context';
import { StoreProvider } from './store-context';

export const AppProviders = ({ children }: any) => {
  return (
    <StoreProvider>
      <UserProvider>{children}</UserProvider>
    </StoreProvider>
  );
};
