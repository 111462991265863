import { Button } from './Button';

export const TrackingPortalBtn = () => {
  const gotoCustomerPortal = () => {
    window.open('https://track.shipaid.com/', '_blank');
  };
  return (
    <Button onClick={gotoCustomerPortal} className="mr-2">
      Tracking Portal
    </Button>
  );
};
