import React, { useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { DotsVerticalIcon } from '@heroicons/react/outline';
import { CSVLink } from 'react-csv';
import { DataTable } from 'components/DataTable';
import { SearchBox } from 'components/Search';
import { Pagination } from 'components/Pagination';
import { Dropdown } from 'components/Dropdown';
import { LoadingScreen } from 'components/LoadingScreen';
import { withNotification } from 'components/Notification';
import { NotificationTypes } from 'utils';
import {
  GET_ADMIN_USERS,
  FILTER_ADMIN_USERS,
  GET_ALL_MERCHANT_EMAILS,
} from 'gql/queries';
import { MerchantUser } from 'types/admin';
import { merchantColumns } from './data/merchants-data';
import { userRoles } from 'types/user';

const merchantFilters = {
  roles: { role: { _neq: userRoles.SHIPAID_ADMIN } },
};

export const Merchants = withNotification(({ showNotification }: any) => {
  const limit = 20;
  const [allMerchants, setAllMerchants] = useState<[]>([]);
  const [merchantsCount, setMerchantsCount] = useState(0);
  const [filterInput, setFilterInput] = useState<any>({});
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const [allMerchantEmails, setAllMerchantEmails] = useState('');
  const { loading: fetchingAllMerchantEmails } = useQuery(
    GET_ALL_MERCHANT_EMAILS,
    {
      onCompleted: (data) => {
        setAllMerchantEmails(
          data?.users?.reduce(
            (users: string, user: any) => (users += user.email + ','),
            '',
          ),
        );
      },
    },
  );

  const [getMerchants, { data, loading }] = useLazyQuery(GET_ADMIN_USERS, {
    onCompleted: (data) => {
      setAllMerchants(data.users);
      setMerchantsCount(data?.usersAggregate?.aggregate?.count);
    },
    onError(error) {
      showNotification(NotificationTypes.error, error?.message);
    },
  });

  const [filterMerchants, { loading: filterLoading }] = useLazyQuery(
    FILTER_ADMIN_USERS,
    {
      onCompleted: (data) => {
        setAllMerchants(data?.users);
      },
      onError(error) {
        showNotification(NotificationTypes.error, error?.message);
      },
    },
  );

  useEffect(() => {
    getMerchants({
      variables: {
        filters: { ...merchantFilters },
        limit,
        offset,
        order_by: [
          {
            createdAt: 'desc',
          },
        ],
      },
    });
  }, [getMerchants, offset]);

  useEffect(() => {
    if (Object.keys(filterInput).length > 0)
      filterMerchants({
        variables: {
          filters: { ...merchantFilters, ...filterInput },
          order_by: [
            {
              createdAt: 'desc',
            },
          ],
        },
      });
  }, [filterInput, filterMerchants]);

  const onPageChange = (pageNumber: number) => {
    setPage(pageNumber);
    if (pageNumber < page) {
      if (pageNumber - 1 === 0) {
        return setOffset(0);
      }
      return setOffset(limit * (pageNumber - 1));
    }
    return setOffset(limit * (pageNumber - 1));
  };

  const handleSelectedRow = (item: MerchantUser) => {
    if (selectedRows.includes(item.id))
      return setSelectedRows(
        [...selectedRows].filter((row) => row !== item.id),
      );
    return setSelectedRows([...selectedRows, item.id]);
  };

  const handleSelectAllRows = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const rows = data?.users?.map((merchant: MerchantUser) => merchant.id);
    if (target.checked) return setSelectedRows(rows);
    return setSelectedRows([]);
  };

  const handleSearch = (value: string) => {
    if (value === '') {
      const obj = { ...filterInput };
      delete obj._or;
      setSearchValue(value);
      /**
       * this was done this way to ensure that whenever the search is cleared, 
        it also checks that there are no filter rules there to ensure that it sets the 
        table back to default
      */
      Object.keys(obj).length === 0 && setAllMerchants(data?.users);
      return setFilterInput(obj);
    }
    setSearchValue(value);
  };

  const search = () => {
    setFilterInput({
      ...filterInput,
      _or: [
        { displayName: { _ilike: `%${searchValue}%` } },
        { email: { _ilike: `%${searchValue}%` } },
      ],
    });
  };

  if (loading) return <LoadingScreen />;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <div className="mb-4 sm:mb-4 md:mb-2 flex items-center">
        {selectedRows.length > 0 ? (
          <div className="mr-4">
            <Dropdown
              icon={
                <DotsVerticalIcon className="h-7 text-blue-600 cursor-pointer" />
              }
              options={[]}
              onSelectOption={() => null}
            />
          </div>
        ) : null}
        <div className="mb-4 sm:mb-4 md:mb-2 flex items-center w-full">
          <div className="w-full flex">
            <SearchBox
              search={search}
              searchValue={searchValue}
              onChange={(searchValue) => handleSearch(searchValue)}
              placeholder="Search by merchant name or email"
            />
            <CSVLink
              data={allMerchantEmails}
              filename={`all_merchant_emails_${new Date().toLocaleDateString()}.csv`}
              className="border-transparent text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 text-xs flex text-center justify-center items-center leading-[1.2] border font-medium rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 ml-4"
            >
              {fetchingAllMerchantEmails ? 'Please wait...' : 'Download Emails'}
            </CSVLink>
          </div>
          {filterLoading && (
            <div
              style={{
                borderColor: 'rgba(37,99,235, 0.2)',
                borderTopColor: '#2564eb',
              }}
              className="inline-block border-2 rounded-full animate-spin w-3 h-3 ml-2"
            />
          )}
        </div>
      </div>
      <div className="">
        <DataTable
          data={allMerchants}
          columns={merchantColumns}
          handleSelectedRow={handleSelectedRow}
          onSelectAll={handleSelectAllRows}
          // checkbox
          allChecked={selectedRows}
          emptyMessage={
            searchValue
              ? `No search results matches "${searchValue}"`
              : 'No data'
          }
        />
      </div>
      <div className="mt-3">
        {merchantsCount > limit && (
          <Pagination
            totalPages={
              Object.keys(filterInput).length
                ? 1
                : Math.ceil(merchantsCount / limit)
            }
            currentPage={page}
            resultsRange={limit}
            totalResults={merchantsCount ?? 0}
            isFilterApplied={Object.keys(filterInput).length ? true : false}
            onPageClick={onPageChange}
          />
        )}
      </div>
    </div>
  );
});
