import React, { FormEvent, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Text } from '../components/Text';
import DoorImage from '../assets/images/doorstep.png';
import Google from '../assets/images/google.png';
import { Button } from '../components/Button';
import { withNotification } from 'components/Notification';
import { NotificationTypes } from 'utils';
import { SizeEnum, validateEmail } from '../utils';
import FormInput from '../components/FormInput';
import {
  useSignInEmailPassword,
  useProviderLink,
  useSignInEmailPasswordless,
} from '@nhost/react';
import classNames from 'classnames';
import AuthPage from '../components/AuthPage';

export const Login: React.FC = withNotification(({ showNotification }: any) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginWithLink, setLoginWithLink] = useState(true);
  const [errorObj, setErrorObj] = useState({
    message: '',
    error: '',
    status: 0,
  });
  const navigate = useNavigate();

  const { google } = useProviderLink();
  const { signInEmailPasswordless } = useSignInEmailPasswordless({
    redirectTo: '/login',
  });

  const {
    signInEmailPassword,
    isLoading,
    isSuccess,
    needsEmailVerification,
    error,
  } = useSignInEmailPassword();

  useEffect(() => {
    if (error) {
      setErrorObj({ ...error });
    }
  }, [error]);

  const renderForgotPasswordLink = () => {
    return (
      <span className="text-sm block text-shipaidBlue">
        <Link to="/forgot-password">Forgot your password?</Link>
      </span>
    );
  };

  const toggleLoginFields = () => {
    setLoginWithLink(!loginWithLink);
  };

  function handleValidation() {
    if (email.trim() === '') {
      const error = { message: 'Email is required', error: 'invalid-email' };
      setErrorObj({ ...errorObj, ...error });
      return error;
    }
    if (!validateEmail(email)) {
      const error = {
        message: 'Email is not valid, please enter a valid email',
        error: 'invalid-email',
      };
      setErrorObj({ ...errorObj, ...error });
      return error;
    }
    if (password.trim() === '') {
      const error = {
        message: 'Password is required',
        error: 'invalid-password',
      };
      setErrorObj({ ...errorObj, ...error });
      return error;
    }
    setErrorObj({ message: '', error: '', status: 0 });
    return false;
  }

  const handleLogin = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const hasError = handleValidation();
    if (!hasError) signInEmailPassword(email, password);
  };

  const handleMagicLinkLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const result = await signInEmailPasswordless(email);
    if (result.isError) {
      showNotification(NotificationTypes.error, result.error?.message || null);
    } else {
      showNotification(
        NotificationTypes.success,
        'A Login link has been sent to your mail',
      );
    }
  };

  if (isSuccess) {
    navigate('/');
  }

  const disableForm = isLoading || needsEmailVerification;

  return (
    <AuthPage backgroundImage={DoorImage}>
      <div className="text-center my-6">
        <h6 className="uppercase text-shipaidBlue my-4 font-bold">Account</h6>

        <h2 className="text-3xl md:text-5xl my-4">Log in to continue</h2>

        <div className="text-sm md:text-xl">
          <span>Don&apos;t have an account? </span>
          <span className="text-shipaidBlue">
            <Link to="/sign-up">Sign up</Link>
          </span>
        </div>

        <Button
          size={SizeEnum.sm}
          isRounded
          onClick={() => (window.location.href = google)}
          className="bg-transparent border border-black w-full rounded-full flex justify-center items-center align-middle text-black text-center mt-4 hover:bg-white"
        >
          <img className="h-6 w-6" src={Google} alt="google logo" />
          <span className="block pl-2"> Login with google</span>
        </Button>

        <div className="my-4">
          <fieldset className="border-t border-slate-300">
            <legend className="mx-auto px-2 text-black text-sm">
              Or Continue with
            </legend>
          </fieldset>
        </div>
      </div>
      <div className="form w-full  md:w-[450px]">
        <form
          className="w-full"
          onSubmit={loginWithLink ? handleMagicLinkLogin : handleLogin}
        >
          <div className="mb-4">
            <FormInput
              id="email"
              name="email"
              label="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              type="email"
            />
          </div>

          {loginWithLink === false ? (
            <>
              <div className="mb-4">
                <FormInput
                  id="password"
                  name="password"
                  label="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  type="password"
                  otherInfo={renderForgotPasswordLink()}
                />
              </div>
              <Text
                type="sm"
                value={errorObj?.message}
                className={classNames('text-center text-red-600')}
              />
            </>
          ) : null}
          <Button
            size={SizeEnum.xl}
            isRounded
            type="submit"
            disabled={disableForm}
            className="bg-shipaidBlue text-white w-full rounded-full flex justify-center items-center align-middle text-center mt-4 hover:bg-opacity-80"
          >
            {loginWithLink === false ? 'Login' : 'Send Link'}
          </Button>
          <Button
            size={SizeEnum.sm}
            onClick={toggleLoginFields}
            className="bg-transparent w-full rounded-full flex justify-center items-center align-middle text-black text-center mt-4 hover:bg-white"
          >
            <span className="block pl-2">
              {loginWithLink === false
                ? 'Login with magic link'
                : 'Login with Password'}
            </span>
          </Button>
        </form>
      </div>
    </AuthPage>
  );
});
