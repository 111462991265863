import handlebars from 'handlebars/dist/handlebars.min.js';
// import { reverse } from 'lodash';
import { format } from 'date-fns';

export const resolveTemplates = (templates, updatedTemplate) => {
  if (!templates.length) return [updatedTemplate];

  if (updatedTemplate?.isTrackingDefault)
    return templates.map((template) =>
      template.isTrackingDefault ? updatedTemplate : template,
    );

  const noTemplateMatch = !templates.some(
    (template) =>
      template.eventType === updatedTemplate.eventType &&
      template.usage === updatedTemplate.usage,
  );

  if (noTemplateMatch) return [...templates, updatedTemplate];

  return templates.map((template) =>
    template.eventType === updatedTemplate.eventType &&
    template.usage === updatedTemplate.usage
      ? updatedTemplate
      : template,
  );
};

export const populateTemplate = (template, values) => {
  if (!template) return '';

  return Object.keys(values)?.reduce(
    (acc, v) =>
      acc
        ?.toString()
        ?.replace(new RegExp(`\\{\\{\\s*${v}\\s*\\}\\}`, 'g'), values[v]),
    template,
  );
};

export const sortByIsTrackingDefault = (a, b) => {
  if (a.isTrackingDefault && !b.isTrackingDefault) {
    return -1;
  } else if (!a.isTrackingDefault && b.isTrackingDefault) {
    return 1;
  } else {
    return 0;
  }
};

export const getTrackingDetails = (details) => {
  const trackingDetails = {};
  details.forEach((det) => {
    const date = format(new Date(det.datetime), 'yyyy-MM-dd');
    const addr = [];
    if (det.tracking_location?.city) addr.push(det.tracking_location.city);
    if (det.tracking_location?.state) addr.push(det.tracking_location.state);
    if (det.tracking_location?.country)
      addr.push(det.tracking_location.country);
    if (det.tracking_location?.zip) addr.push(det.tracking_location.zip);
    const obj = {
      message: det.message,
      description: det.description,
      address: addr.join(', '),
      time: format(new Date(det.datetime), 'hh:mma'),
      status: det.status,
    };
    if (trackingDetails[date]) {
      trackingDetails[date].push(obj);
    } else {
      trackingDetails[date] = [obj];
    }
  });

  return trackingDetails;
};

export const populateTemplateWithDetails = ({
  trackingDetails,
  products,
  template,
}) => {
  if (!trackingDetails) return template;

  const trackingContainerRegex =
    /<article[^>]*class\s*=\s*(["'])tracking-container\1[^>]*>.*?<\/article>/i;
  const trackingContainerMatches = template?.templateHtml?.match(
    trackingContainerRegex,
  );

  const productsContainerRegex =
    /<article[^>]*class\s*=\s*(["'])product-container\1[^>]*>.*?<\/article>/i;
  const productsContainerMatches = template?.templateHtml?.match(
    productsContainerRegex,
  );

  if (!trackingContainerMatches && !productsContainerMatches)
    return template?.templateHtml;

  const compiledTemplate = handlebars.compile(template?.templateHtml);

  const data = {
    products: productsContainerMatches
      ? products.map((product) => ({
          item: product.title ?? product.name,
          item_quantity: product.quantity,
        }))
      : [],
    trackingDetails: trackingContainerMatches
      ? trackingDetails.map(([date_time, details]) => ({
          date_time,
          details,
        }))
      : [],
  };

  if (template.templateHtml) {
    template.templateHtml = compiledTemplate(data);
  }

  return template?.templateHtml;
};

export const trackingDetailsPreview = [
  {
    message: 'DELIVERED',
    status: 'delivered',
    status_detail: 'arrived_at_destination',
    datetime: '2022-11-26T12:44:47Z',
    source: 'UPS',
  },
  {
    message: 'OUT FOR DELIVERY',
    status: 'out_for_delivery',
    status_detail: 'out_for_delivery',
    datetime: '2022-11-26T05:21:47Z',
    source: 'UPS',
  },
  {
    message: 'ARRIVAL SCAN',
    status: 'in_transit',
    status_detail: 'arrived_at_facility',
    datetime: '2022-11-25T06:42:47Z',
    source: 'UPS',
  },
  {
    message: 'DEPARTURE SCAN',
    status: 'in_transit',
    status_detail: 'departed_facility',
    datetime: '2022-11-24T06:02:47Z',
    source: 'UPS',
  },
  {
    message: 'ORIGIN SCAN',
    description: 'test',
    status: 'in_transit',
    status_detail: 'arrived_at_facility',
    datetime: '2022-11-23T11:59:47Z',
    source: 'UPS',
  },
  {
    message: 'BILLING INFORMATION RECEIVED',
    status: 'pre_transit',
    status_detail: 'status_update',
    datetime: '2022-11-20T07:26:45Z',
    source: 'UPS',
  },
];

export const openOrFocusTab = (window, targetWindowName, targetUrl) => {
  let newTab = window.open('', targetWindowName);

  if (!newTab || newTab.closed || typeof newTab.closed === 'undefined') {
    newTab = window.open(targetUrl, targetWindowName);

    if (!newTab || newTab.closed || typeof newTab.closed === 'undefined') {
      alert('A pop-up blocker is enabled. Please allow pop-ups for this site.');
    }
  } else {
    newTab.location.href = targetUrl;
    newTab.focus();
  }
};
