import React, { useState } from 'react';
import { ImageUpload } from './ImageUpload';
import { Text } from './Text';
import { XIcon } from '@heroicons/react/outline';
import { Loader } from './ClaimsVideosUpload';

const DefaultThumbImage = () => (
  <label htmlFor="file-image-upload">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-24 w-24"
      fill="none"
      viewBox="0 0 24 24"
      stroke="#89A7FB"
      strokeWidth={0.5}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
      />
    </svg>
  </label>
);

export const ClaimsImagesUpload = ({
  onUpload,
  claimImages,
  onRemoveImage,
  onShowImage,
}: {
  onUpload: ({ urls }: { urls: string[] }) => void;
  noOfImages?: number;
  claimImages?: any[];
  onRemoveImage?: (id: string) => void;
  onShowImage?: (image: any) => void;
}) => {
  const maxImages = 5;
  const title = 'Upload issue images';
  const subtitle = `Add up to ${maxImages} images to support the issue and for future reference`;

  const [isUploading, setIsUploading] = useState(false);

  return (
    <ImageUpload
      shouldUploadOnDrop={true}
      onUpload={
        isUploading
          ? () => {
              alert('Please wait, upload is in progress');
            }
          : onUpload
      }
      allowMultiple={true}
      maxImages={maxImages}
      setIsUploading={setIsUploading}
    >
      {({ getRootProps, getInputProps, files }) => {
        let thumbs = claimImages
          ? claimImages.map((claimImage) => {
              return (
                <div
                  key={claimImage?.id}
                  className=" h-24 w-24 sm:h-28 sm:w-28 mr-3 relative"
                >
                  <div
                    className="rounded-full absolute bg-white p-1 right-[-10px] top-[-15px] border cursor-pointer z-10"
                    onClick={(e) => {
                      e.stopPropagation();
                      onRemoveImage && onRemoveImage(claimImage.id);
                    }}
                  >
                    <XIcon height={15} width={15} />
                  </div>
                  <img
                    alt={claimImage.id}
                    className="object-cover h-full w-full rounded cursor-pointer"
                    src={claimImage.url}
                    onClick={() => onShowImage && onShowImage(claimImage.url)}
                  />
                </div>
              );
            })
          : files.map((file) => {
              return (
                <div
                  key={file.name}
                  className="w-24 h-24 flex justify-center items-center"
                >
                  <img
                    alt={file.name}
                    className="object-cover"
                    src={file.preview}
                    style={{ width: 70, height: 70 }}
                  />
                </div>
              );
            });
        // If the thumbnails generated from the uploaded files
        // are less than the max images, display the default thumbnail for each
        // unused slot as per the designs
        if (thumbs.length < maxImages) {
          // add the remaining as default
          const count = maxImages - thumbs.length;
          for (let i = 0; i < count; i++) {
            // push a default image element into the array.
            thumbs.push(<DefaultThumbImage key={i} />);
          }
        }

        return (
          <div className="">
            <label className="flex">{thumbs}</label>
            <div className="flex" {...getRootProps()}>
              <div>
                <svg
                  className="mx-auto h-32 w-32 text-gray-400"
                  stroke="#1D4ED8"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <section className="mt-3 ml-4">
                <label htmlFor="file-image-upload" className="cursor-pointer">
                  <Text type="bold" value={title} />
                  <Text type="sm" value={subtitle} />
                  <input
                    id="file-image-upload"
                    name="file-image-upload"
                    type="file"
                    className="sr-only"
                    {...getInputProps()}
                  />
                </label>
              </section>
            </div>
            {isUploading && (
              <div className="flex items-center">
                <Loader /> <span className="ml-4">Upload in progress....</span>
              </div>
            )}
          </div>
        );
      }}
    </ImageUpload>
  );
};
