import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { ACCEPT_STORE_INVITE } from 'gql/mutations';
import { LoadingScreen } from 'components/LoadingScreen';
import { withNotification } from 'components/Notification';
import { ActionPage } from './NestedContainers/ActionPage';
import { useAuthenticationStatus } from '@nhost/react';

export const AcceptInvite = withNotification(({ showNotification }: any) => {
  const { isAuthenticated, isLoading } = useAuthenticationStatus();

  const [searchParams] = useSearchParams();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const navigate = useNavigate();

  enum Error_Messages {
    NO_ACCOUNT = 'The user this invite applies to must have a registered account.',
    NO_VALID_INVITE = 'No invite exists with this ID, or invite may have already been accepted.',
  }

  enum New_Messages {
    CREATE_ACCOUNT = 'Please create an account using the email this invite was sent to.',
    AUTH = 'Please authenticate yourself to be able to accept this invite ',
    USE_RIGHT_ACCOUNT = 'This invite was intended for a different user. Please sign in or create an account using the email that the invite was sent to.',
  }

  const inviteId = searchParams.get('invite');

  const [acceptInvite, { loading: inviteLoading, data }] = useMutation(
    ACCEPT_STORE_INVITE,
    {
      onCompleted() {
        localStorage.removeItem('inviteId');
        setTimeout(() => navigate('/dashboard'), 1000);
      },
      onError(error) {
        if (error.message === Error_Messages.NO_ACCOUNT) {
          setErrorMessage(New_Messages.CREATE_ACCOUNT);
        } else if (error.message === Error_Messages.NO_VALID_INVITE) {
          setErrorMessage(New_Messages.USE_RIGHT_ACCOUNT);
        } else {
          setErrorMessage(error.message);
        }
      },
    },
  );

  const reAuthenticate = () => {
    const loginUrl = `/login`;
    window.location.href = loginUrl;
  };

  const cleanupAndAuthenticate = () => {
    localStorage.clear();

    if (errorMessage !== New_Messages.USE_RIGHT_ACCOUNT) {
      localStorage.setItem('inviteId', `${inviteId}`);
    }

    setTimeout(() => {
      const loginUrl = `/login`;
      window.location.href = loginUrl;
    }, 1000);
  };

  useEffect(() => {
    const inviteId = searchParams.get('invite');

    if (isAuthenticated) {
      acceptInvite({
        variables: {
          input: { id: inviteId },
        },
      });
    } else {
      localStorage.setItem('inviteId', `${inviteId}`);

      setErrorMessage(New_Messages.AUTH);
    }
  }, [acceptInvite, searchParams, isAuthenticated, New_Messages.AUTH]);

  if (isLoading) return <LoadingScreen />;
  if (inviteLoading) return <LoadingScreen />;

  const title = data ? 'Welcome!' : errorMessage ? 'Please Try Again!' : '';

  const subtitle = data
    ? `Redirecting you to your dashboard`
    : errorMessage
    ? errorMessage
    : '';

  return (
    <div>
      {data || Boolean(errorMessage) ? (
        <ActionPage
          title={title}
          subtitle={subtitle}
          btnText={errorMessage ? 'Go to login' : ''}
          onClick={isAuthenticated ? cleanupAndAuthenticate : reAuthenticate}
        />
      ) : (
        <LoadingScreen />
      )}
    </div>
  );
});
