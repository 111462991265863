import React from 'react';
import { XIcon } from '@heroicons/react/outline';
import classNames from 'classnames';

type ItemPillProps = {
  item?: string;
  className?: string;
  handleRemoveitem?: () => void;
  showIcon?: boolean;
};

export const Itempill = ({
  item,
  className,
  handleRemoveitem,
  showIcon,
}: ItemPillProps) => {
  if (item?.trim() === '') return null;
  return (
    <div
      className={classNames(
        'bg-gray-200 flex items-center cursor-pointer justify-between text-gray-500 rounded px-2 py-1 text-sm',
        className,
      )}
    >
      {item}
      {showIcon && (
        <XIcon className="h-3 w-3 ml-2" onClick={handleRemoveitem} />
      )}
    </div>
  );
};
