import React, { useEffect, useState } from 'react';
import { XIcon, UploadIcon, RefreshIcon } from '@heroicons/react/outline';
import { ImageUpload } from './ImageUpload';
import { Text } from './Text';
import { Modal } from './Modal';

const ComponentWithImage = ({ thumbnail }: { thumbnail: JSX.Element }) => (
  <div
    onClick={(e) => e.stopPropagation()}
    className="bg-blue-300 h-20 w-20 rounded-full mr-3"
    style={{
      cursor: 'pointer',
    }}
  >
    {thumbnail}
  </div>
);

const ComponentWithoutImage = ({
  id,
  files,
  thumbnail,
  getInputProps,
}: {
  id: string | undefined;
  files: any;
  thumbnail: JSX.Element;
  getInputProps: any;
}) => (
  <label htmlFor={`${id}-file-upload`} onClick={(e) => e.stopPropagation()}>
    <div
      className="bg-blue-300 flex justify-center items-center h-20 w-20 rounded-full mr-3"
      style={{
        cursor: 'pointer',
      }}
    >
      <UploadIcon
        className="absolute opacity-50 hover:opacity-70"
        width={60}
        height={60}
      />
      {files[0] && thumbnail}
    </div>
    <input
      id={`${id}-file-upload`}
      name={`${id}-file-upload`}
      type="file"
      className="sr-only"
      {...getInputProps()}
    />
  </label>
);

export const TeamImageUpload = ({
  onUpload,
  image,
  isLoading,
  heading,
  subheading,
  id,
}: {
  onUpload: ({ urls }: { urls: string[] }) => void;
  image?: string;
  isLoading?: boolean;
  heading?: string;
  id?: string;
  subheading?: string;
}) => {
  const [showImageModal, setShowImageModal] = useState(false);
  const [, setIsUploading] = useState(false);
  const [teamImage, setTeamImage] = useState<string | null>('');
  const title = heading ? heading : 'Upload your profile photo';
  const subtitle = subheading
    ? subheading
    : 'This makes it easier for your teammates to recognize you';

  useEffect(() => {
    if (Boolean(teamImage)) {
      setShowImageModal(true);
    }
  }, [teamImage]);

  const handleImageModal = (value: any) => {
    setShowImageModal(value);
    if (!value) {
      setTeamImage(null);
    }
  };

  const handleShowImage = (image: any) => {
    setTeamImage(image);
  };

  return (
    <ImageUpload
      shouldUploadOnDrop={true}
      onUpload={onUpload}
      setIsUploading={setIsUploading}
    >
      {({ upload, files, getRootProps, getInputProps, clearImages }) => {
        const thumbnail = (
          <div
            className="relative flex flex-col justify-center items-center"
            onClick={() => handleShowImage(image)}
          >
            <img
              id="avatar-upload"
              className="object-cover h-20 w-20 rounded-full"
              src={files[0] ? files[0]?.preview : image}
              alt={files[0]?.name}
            />
          </div>
        );

        return (
          <>
            <Modal
              open={showImageModal}
              openModal={handleImageModal}
              containerClass="bg-transparent shadow-none max-w-2xl"
              iconClassName="text-white"
            >
              {teamImage && (
                <>
                  <img src={teamImage} alt="claim" className="max-w-full" />
                  <div
                    className="inline-flex mr-4 bg-red-700 z-10 items-center p-1 cursor-pointer"
                    onClick={() => {
                      clearImages();
                      handleImageModal(false);
                    }}
                  >
                    <XIcon height={15} width={15} color="#ffffff" />
                    <Text
                      className="text-gray-200 ml-2 text-sm"
                      value="Remove Image"
                    />
                  </div>
                  <label
                    className="inline-flex  bg-green-700 z-10 items-center p-1 cursor-pointer"
                    htmlFor={`modal-file-upload`}
                    onChange={() => handleImageModal(false)}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <input
                      id={`modal-file-upload`}
                      name={`modal-file-upload`}
                      type="file"
                      className="sr-only"
                      {...getInputProps()}
                    />
                    <RefreshIcon height={15} width={15} color="#ffffff" />
                    <Text
                      className="text-gray-200 ml-2 text-sm"
                      value="Replace Image"
                    />
                  </label>
                </>
              )}
            </Modal>

            <div className="block sm:flex justify-between py-6 sm:w-1/2">
              <section className="flex align-baseline" {...getRootProps()}>
                {image ? (
                  <ComponentWithImage thumbnail={thumbnail} />
                ) : (
                  <ComponentWithoutImage
                    id={id}
                    files={files}
                    thumbnail={thumbnail}
                    getInputProps={getInputProps}
                  />
                )}
                <label
                  htmlFor={`${id}-file-upload`}
                  onClick={(e) => e.stopPropagation()}
                  className="flex flex-col justify-center cursor-pointer"
                >
                  <Text
                    type="bold"
                    value={title}
                    className="text-gray-600 font-bold"
                  />
                  <Text
                    type="sm"
                    value={subtitle}
                    className="text-gray-400 w-64"
                  />
                </label>
              </section>
            </div>
          </>
        );
      }}
    </ImageUpload>
  );
};
