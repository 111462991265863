import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';

export const BodyText = ({
  text,
  className,
}: {
  text: string;
  className?: string;
}) => (
  <ReactMarkdown className={classNames(className, 'text-gray-800 w-full')}>
    {text}
  </ReactMarkdown>
);
