import React, { useState } from 'react';
import { SizeEnum } from 'utils';
import { Button } from './Button';
import { ImageUpload } from './ImageUpload';

export const CompanyLogoUpload = ({
  onUpload,
}: {
  onUpload: ({ urls }: { urls: string[] }) => void;
}) => {
  const label = 'Company Logo';
  const maxSize = 10;

  const [, setIsUploading] = useState(false);

  return (
    <ImageUpload
      shouldUploadOnDrop={false}
      onUpload={onUpload}
      setIsUploading={setIsUploading}
    >
      {({ getRootProps, getInputProps, upload, shouldUploadOnDrop, files }) => {
        const thumbs = files.map((file) => (
          <div
            key={file.name}
            className="ml-6 mt-6 border-2 border-gray-300 border-solid"
          >
            {/* explicit style required to match thumbnails to the dropzone component's height */}
            <img
              style={{ height: 140, width: 140 }}
              className="object-cover"
              src={file.preview}
              alt={file.name}
            />
          </div>
        ));

        return (
          <div className="flex">
            <section className="flex-grow max-w-xs">
              <label className="block text-sm font-medium text-gray-700">
                {label}
              </label>
              <div
                {...getRootProps()}
                className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md cursor-pointer"
              >
                <div className="space-y-1 text-center">
                  <svg
                    className="mx-auto h-12 w-12 text-gray-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <div className="flex text-sm text-gray-600">
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                    >
                      <span>Upload a file</span>
                      <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        className="sr-only"
                        {...getInputProps()}
                      />
                    </label>
                    <p className="pl-1">or drag and drop</p>
                  </div>
                  <p className="text-xs text-gray-500">
                    PNG, JPG, GIF up to {maxSize}MB
                  </p>
                </div>
              </div>
              {/* TODO: on upload button should be in loading state & if there are no files, the button should be disabled */}
              {/* WE RENDER A BUTTON IF WE DO NOT WANT AUTOMATIC UPLOADS ON DROP */}
              {!shouldUploadOnDrop && (
                <Button
                  size={SizeEnum.xs}
                  className="mt-4"
                  onClick={() => upload(files)}
                >
                  Upload
                </Button>
              )}
            </section>
            <aside className="flex-shrink-0">{thumbs}</aside>
          </div>
        );
      }}
    </ImageUpload>
  );
};
