import {
  Claim,
  ClaimItemResolvedStatusEnum,
  ClaimItemValues,
} from 'types/claim';
import { Text } from './Text';
import { format } from 'date-fns';
import { CheckCircleIcon } from '@heroicons/react/outline';
import { Dropdown } from './Dropdown';
import { OrderDetail } from './OrderDetail';
import { ClaimResolutionOptions } from 'containers/NestedContainers/Claim';
import { useStore } from 'context/store-context';

const EmptyRadioButton = () => (
  <div
    style={{ width: 25, height: 25, borderRadius: '50%', borderWidth: '1px' }}
  ></div>
);

const ClaimItemsList = ({
  claim,
  items,
  selectedItemId,
  resolution,
  onSelectAll,
  onSelectClaimItem,
  onSelectResolution,
  onShowResolutionAction,
  onViewResolution,
  onResolvePartialItems,
}: {
  claim: Claim | null;
  items: Claim['items'];
  selectedItemId?: string;
  onSelectAll?: () => void;
  onSelectClaimItem: (id: string | undefined) => void;
  resolution?: ClaimItemResolvedStatusEnum;
  onSelectResolution: (r: ClaimItemResolvedStatusEnum) => void;
  onShowResolutionAction: () => void;
  onViewResolution: (id: string) => void;
  onResolvePartialItems: (claimItem: ClaimItemValues) => void;
}) => {
  const { storeProperties } = useStore();
  const enableStoreCredit =
    storeProperties?.resolvePortalOptions?.enableStoreCredit || false;

  const ResolvedItems = (claim?.items || []).filter((i) => i.settlement);

  const ToBeResolvedItems = (claim?.items || []).filter((i) => !i.settlement);

  const isAllSelected = ToBeResolvedItems?.length === items?.length;
  const hasSingleItemSelected = items.length > 0;

  return (
    <div className="w-full sm:w-2/3 border rounded-md p-4">
      <div className="flex justify-between border-b border-gray-300 pb-4">
        <div>
          <Text value="Issue Filed" className="font-semibold text-gray-700" />
          <Text
            value={`${
              claim
                ? format(new Date(claim?.order?.createdAt), 'do MMM yyyy hh:mm')
                : ''
            }`}
            className="text-gray-500"
          />
        </div>
        <div>
          <Text value="Filed by" className="font-semibold text-gray-700" />
          <Text
            value={claim?.createdBy?.displayName}
            className="text-gray-500"
          />
        </div>
      </div>
      <Text value="Order Details (Issue items)" type="h4" className="mt-4" />
      {ToBeResolvedItems.length ? (
        <>
          <div className="flex justify-between items-center my-2">
            <div
              className="flex justify-start items-center w-40 cursor-pointer"
              onClick={onSelectAll}
            >
              {isAllSelected ? (
                <CheckCircleIcon color="blue" width={'25px'} />
              ) : (
                <EmptyRadioButton />
              )}
              <Text className="ml-2">Select All</Text>
            </div>
            <div>
              {hasSingleItemSelected && (
                <Dropdown
                  placeholder="Choose Resolution"
                  btnClassName="w-40 border rounded-sm"
                  options={ClaimResolutionOptions({ enableStoreCredit })}
                  onSelectOption={(o) => {
                    onShowResolutionAction();
                    onSelectResolution(o as ClaimItemResolvedStatusEnum);
                  }}
                />
              )}
            </div>
          </div>
          <div className="rounded-md mt-2 w-full overflow-auto">
            {ToBeResolvedItems.map((item) => {
              const isItemSelected = items.find((i) => i.id === item.id);
              return (
                <div
                  key={item.id}
                  className={`flex justify-start items-center`}
                >
                  <div
                    className={'cursor-pointer'}
                    onClick={onSelectClaimItem.bind(null, item.id)}
                  >
                    {isItemSelected ? (
                      <CheckCircleIcon color="blue" width={'25px'} />
                    ) : (
                      <EmptyRadioButton />
                    )}
                  </div>
                  <OrderDetail
                    order={item.orderItem}
                    claimItem={item}
                    showClaimItemResolution={!hasSingleItemSelected}
                    resolution={
                      item.resolution === ClaimItemResolvedStatusEnum.IN_REVIEW
                        ? undefined
                        : (item.resolution as ClaimItemResolvedStatusEnum)
                    }
                    onViewResolution={onViewResolution.bind(
                      null,
                      item.id as string,
                    )}
                    disabledClamResolution
                    showCategories
                    selectedCategory={claim?.reason}
                  />
                </div>
              );
            })}
          </div>
        </>
      ) : null}

      {ResolvedItems.length ? (
        <>
          <div className="bg-green-300 p-1 px-2 rounded-sm mt-5">
            <div className="font-bold">Resolved Claim Items</div>
          </div>
          <div className="rounded-md mt-2 w-full overflow-auto">
            {ResolvedItems.map((item) => {
              return (
                <div
                  key={item.id}
                  className={`flex justify-start items-center`}
                >
                  <OrderDetail
                    order={item.orderItem}
                    claimItem={item}
                    showClaimItemResolution={!hasSingleItemSelected}
                    resolution={
                      item.resolution === ClaimItemResolvedStatusEnum.IN_REVIEW
                        ? undefined
                        : (item.resolution as ClaimItemResolvedStatusEnum)
                    }
                    onViewResolution={onViewResolution.bind(
                      null,
                      item.id as string,
                    )}
                    disabledClamResolution
                    showCategories
                    selectedCategory={claim?.reason}
                    onResolvePartialItems={onResolvePartialItems}
                  />
                </div>
              );
            })}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default ClaimItemsList;
