import React, { useState } from 'react';
import { ImageUpload } from './ImageUpload';
import { XIcon } from '@heroicons/react/outline';
import { Button } from './Button';
import { SizeEnum } from 'utils';

const DefaultThumbImage = () => (
  <label htmlFor="file-upload">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-24 w-24"
      fill="none"
      viewBox="0 0 24 24"
      stroke="#89A7FB"
      strokeWidth={0.5}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
      />
    </svg>
  </label>
);

export const CoverImageUpload = ({
  onUpload,
  coverImages,
  onRemoveImage,
  onShowImage,
}: {
  onUpload: ({ urls }: { urls: string[] }) => void;
  noOfImages?: number;
  coverImages?: any[];
  onRemoveImage?: () => void;
  onShowImage?: (image: any) => void;
}) => {
  const maxImages = 1;

  const [, setIsUploading] = useState(false);

  return (
    <ImageUpload
      shouldUploadOnDrop={true}
      onUpload={onUpload}
      allowMultiple={false}
      maxImages={maxImages}
      setIsUploading={setIsUploading}
    >
      {({ upload, getRootProps, getInputProps, files }) => {
        let thumbs = coverImages
          ? coverImages.map((coverImage, index) => {
              return (
                <div
                  key={coverImage?.id ?? index}
                  className=" h-10 w-10 sm:h-28 sm:w-28 mr-3 relative"
                >
                  <div
                    className="rounded-full absolute bg-white p-1 right-[-10px] top-[-15px] border cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      onRemoveImage && onRemoveImage();
                    }}
                  >
                    <XIcon height={15} width={15} />
                  </div>
                  <img
                    alt={coverImage.id ?? index}
                    className="object-cover h-full w-full rounded cursor-pointer"
                    src={coverImage.url}
                    onClick={() => onShowImage && onShowImage(coverImage.url)}
                  />
                </div>
              );
            })
          : files.map((file) => {
              return (
                <div
                  key={file.name}
                  className="w-24 h-24 flex justify-center items-center"
                >
                  <img
                    alt={file.name}
                    className="object-cover"
                    src={file.preview}
                    style={{ width: 100, height: 100 }}
                  />
                </div>
              );
            });
        // If the thumbnails generated from the uploaded files
        // are less than the max images, display the default thumbnail for each
        // unused slot as per the designs
        if (thumbs.length < maxImages) {
          // add the remaining as default
          const count = maxImages - thumbs.length;
          for (let i = 0; i < count; i++) {
            // push a default image element into the array.
            thumbs.push(<DefaultThumbImage key={i} />);
          }
        }

        return (
          <div className="">
            <label className="flex">{thumbs}</label>
            <div className="flex" {...getRootProps()}>
              <div>
                <section className="flex flex-col justify-center">
                  <Button
                    size={SizeEnum.md}
                    onClick={() => {
                      upload(files);
                    }}
                    className="bg-blue-600 text-white hover:bg-blue-700 h-max px-6 mt-4 justify-center"
                  >
                    Upload Cover Image
                  </Button>
                </section>
              </div>
              <section className="mt-3 ml-4">
                <label htmlFor="file-upload cursor-pointer">
                  <input
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    className="sr-only"
                    {...getInputProps()}
                  />
                </label>
              </section>
            </div>
          </div>
        );
      }}
    </ImageUpload>
  );
};
