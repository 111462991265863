import { Button } from 'components/Button';
import { Text } from 'components/Text';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';

type ActionPageProps = {
  title: string;
  subtitle: string;
  redirect?: string;
  onClick?: () => void;
  btnText?: string;
};

export function ActionPage({
  title,
  subtitle,
  redirect,
  onClick,
  btnText,
}: ActionPageProps) {
  const navigate = useNavigate();

  useEffect(() => {
    redirect &&
      !onClick &&
      setTimeout(() => {
        navigate(redirect);
      }, 3000);
  }, [navigate, onClick, redirect]);

  return (
    <div className="flex justify-center flex-col items-center min-h-screen">
      <Text type="h2" value={title} className="mb-2" />
      <Text value={subtitle} className="mb-4" />
      {onClick && Boolean(btnText) && (
        <Button onClick={onClick}>{btnText || 'Click here'}</Button>
      )}
    </div>
  );
}
