import React from 'react';
import { Text } from 'components/Text';

export const info = [
  {
    title: 'Company Email',
    data: 'boomy@company.com',
    key: 'email',
  },
  {
    title: 'Company Name',
    data: 'Great company',
    key: 'name',
  },
  {
    title: 'Company Phone',
    data: '746748294',
    key: 'phone',
  },
  {
    title: 'Company Website',
    data: 'https://www.google.com',
    key: 'website',
  },
];

export const people = [
  {
    name: 'Stefan Alexiev',
    email: 'boomy@company.com',
    role: 'owner',
  },
  {
    name: 'John Doe',
    email: 'boomboom@mail.com',
    role: 'name',
  },
];

export const columns = [
  {
    name: 'Name',
    id: 1,
    render: (item: any) => (
      <div className="flex items-center">
        <img
          className="mr-4 h-10 w-10 sm:h-10 sm:w-10 rounded-full"
          src={item.user.avatarUrl}
          alt={item.user.displayName}
        />
        {item.user.displayName}
      </div>
    ),
  },
  {
    name: 'Email',
    id: 2,
    render: (item: any) => <Text value={item.user.email} type="sm" />,
  },
  {
    name: 'Role',
    id: 3,
    key: 'role',
  },
  {
    name: 'Action',
    id: 4,
    render: (item: any) => {
      return item.userEmail !== item.user.email && item.isAdmin ? (
        <div onClick={item.onRemoveUser}>
          <Text
            value="Remove"
            type="sm"
            className="text-red-500 cursor-pointer"
          />
        </div>
      ) : (
        <></>
      );
    },
  },
];

export const invitesColumns = [
  {
    name: 'Email',
    id: 1,
    render: (item: any) => <Text value={item.email} type="sm" />,
  },
  {
    name: 'Role',
    id: 2,
    key: 'role',
  },
  {
    name: 'Date Sent',
    id: 3,
    render: (item: any) => {
      return (
        <Text value={new Date(item.createdAt).toLocaleDateString()} type="sm" />
      );
    },
  },
];
