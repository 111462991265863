import React from 'react';

import { Text } from 'components/Text';
import { Toggle } from 'components/Toggle';
import { Button } from 'components/Button';
import { customEmailTemplateTypes } from './data/templates-data';

type EmailNotificationTemplateProps = {
  userTemplates: any[];
  isLoading: boolean;
  onEditTemplate: any;
  onChangeActiveTemplate: any;
};

export const EmailNotificationTemplates = ({
  userTemplates,
  isLoading,
  onEditTemplate,
  onChangeActiveTemplate,
}: EmailNotificationTemplateProps) => {
  return (
    <div className="mt-16">
      <div className="flex flex-col">
        <div className="-my-2 sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
            <div
              className={
                'shadow border-b border-gray-200 sm:rounded-lg overflow-x-scroll'
              }
            >
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50"></thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {userTemplates.map((template) => {
                    const type: any = Object.values(
                      customEmailTemplateTypes,
                    ).find((type: any) => type?.key === template.eventType);
                    return (
                      <tr className="table-data-row" key={template.id}>
                        <td
                          key={template.id + 1}
                          className="px-1 py-1 whitespace-nowrap text-gray-600 text-sm font-medium"
                        >
                          <img
                            style={{ width: 75, height: 75 }}
                            className="inline-block rounded-full"
                            src={type.imgSrc ?? ''}
                            alt={template.eventType}
                            referrerPolicy="no-referrer"
                          />
                        </td>
                        <td
                          key={template.id + 2}
                          className="px-6 py-4 whitespace-nowrap text-gray-600 text-sm font-medium"
                        >
                          <Text
                            value={type.text}
                            type="bold"
                            className="text-lg"
                          />
                          <Text
                            value={type.subText}
                            type="sm"
                            className="text-gray-400"
                          />
                        </td>
                        <td
                          key={template.id + 3}
                          className="px-6 py-4 whitespace-nowrap text-gray-600 text-sm font-medium"
                        >
                          <Text value={template.sentCount} type="sm" />
                          <Text
                            value={'Sent'}
                            type="sm"
                            className="text-gray-400"
                          />
                        </td>
                        <td
                          key={template.id + 4}
                          className="px-6 py-4 whitespace-nowrap text-gray-600 text-sm font-medium"
                        >
                          <Toggle
                            key={template.id + 6}
                            isEnabled={template.active}
                            onChange={() => onChangeActiveTemplate(template)}
                            isSmall
                          />
                        </td>
                        <td
                          key={template.id + 5}
                          className="px-6 py-4 whitespace-nowrap text-gray-600 text-sm font-medium"
                        >
                          <Button
                            isRounded
                            onClick={() => onEditTemplate(template)}
                          >
                            Edit
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
