import { DragHandle } from './DragHandle';

export const StaticTableRow = ({
  row,
  columns,
}: {
  row: any;
  columns: any;
}) => {
  return (
    <tr className="styled-static-table-row">
      {columns &&
        columns.map((column: any, index: number) =>
          index === 0 ? (
            <td
              className="w-1/4 py-4 whitespace-nowrap text-gray-600 text-sm font-medium text-center"
              key={index}
            >
              <div className="flex justify-center items-center gap-4 relative">
                <DragHandle isDragging />
                <div className="text-base font-medium">
                  {column.render ? column.render(row) : null}
                  {column.key ? row[column.key] : null}
                </div>
              </div>
            </td>
          ) : (
            <td
              key={column.id}
              className="w-1/4 py-4 whitespace-nowrap text-gray-600 text-sm font-medium text-center table-switch-status"
            >
              {column.render ? column.render(row) : null}
              {column.key ? row[column.key] : null}
            </td>
          ),
        )}
    </tr>
  );
};
