import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { Text } from 'components/Text';
import { Stats } from 'components/Stats';
import { DataFilter } from 'components/DataFilter';
import { SearchBox } from 'components/Search';
import { Pagination } from 'components/Pagination';
import { DatePicker } from 'components/DatePicker';
import { Button } from 'components/Button';
import { columns } from './data/claims-data';
import { LoadingScreen } from 'components/LoadingScreen';
import {
  GET_CLAIMS_BY,
  GET_CLAIMS_COUNT,
  GET_ORDERS_CLAIMS_COUNT,
  FILTER_CLAIMS,
} from 'gql/queries';
import { withNotification } from 'components/Notification';
import { apiErrorHandler, NotificationTypes } from 'utils';
import { Claim, ResolutionType } from 'types/claim';
import { useStore } from 'context/store-context';
import { format } from 'date-fns';
import { CustomerPortalBtn } from 'components/CustomerPortalBtn';
import { TrackingPortalBtn } from 'components/TrackingPortalBtn';
import { ClaimsTable } from './ClaimsTable';

export const Claims = withNotification(({ showNotification }: any) => {
  const limit = 20;
  const [allClaims, setAllClaims] = useState<[]>([]);
  const [ordersCount, setOrdersCount] = useState(0);
  const [claimsCount, setClaimsCount] = useState(0);
  const [filterInput, setFilterInput] = useState<any>({});
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [dateRangeClaims, setDateRangeClaims] = useState<
    [Date | null, Date | null]
  >([null, null]);
  const [dateRangeStats, setDateRangeStats] = useState<
    [Date | null, Date | null]
  >([new Date(), new Date()]);

  const navigate = useNavigate();
  const { storeId, storeProperties } = useStore();

  const [getClaimsCount, { data: countData }] = useLazyQuery(GET_CLAIMS_COUNT);

  useEffect(() => {
    storeId &&
      getClaimsCount({
        variables: {
          storeId,
        },
      });
  }, [storeId, getClaimsCount]);

  const [getTotalOrderandClaims] = useLazyQuery(GET_ORDERS_CLAIMS_COUNT, {
    onCompleted: (data) => {
      setOrdersCount(data.totalOrders?.aggregate.total);
    },
    onError(error) {
      const newError = apiErrorHandler(error);
      showNotification(NotificationTypes.error, newError?.message);
    },
  });

  const [filterClaims, { data, loading }] = useLazyQuery(FILTER_CLAIMS, {
    onCompleted: (data) => {
      setAllClaims(data?.claims);
      setClaimsCount(data?.claimsAggregate?.aggregate?.count);
    },
    onError(error) {
      const newError = apiErrorHandler(error);
      showNotification(NotificationTypes.error, newError?.message);
    },
  });

  const [orderedClaims] = useLazyQuery(GET_CLAIMS_BY, {
    onCompleted: (data) => {
      setAllClaims(data?.claims);
    },
    onError(error) {
      const newError = apiErrorHandler(error);
      showNotification(NotificationTypes.error, newError?.message);
    },
  });

  useEffect(() => {
    if (storeId) {
      getTotalOrderandClaims({
        variables: {
          storeId,
        },
      });
    }
  }, [getTotalOrderandClaims, offset, storeId]);

  useEffect(() => {
    filterClaims({
      variables: {
        filters: { ...filterInput, storeId: { _eq: storeId } },
        limit,
        offset,
        order_by: [
          {
            createdAt: 'desc',
          },
        ],
      },
    });
  }, [filterInput, filterClaims, storeId, offset]);

  const search = () => {
    setFilterInput({
      ...filterInput,
      _or: [
        { number: { _ilike: `%${searchValue}%` } },
        { order: { number: { _ilike: `%${searchValue}%` } } },
        { order: { customerEmail: { _ilike: `%${searchValue}%` } } },
        { order: { customerName: { _ilike: `%${searchValue}%` } } },
      ],
    });
  };

  const handleSearch = (value: string) => {
    if (value === '') {
      const obj = { ...filterInput };
      delete obj._or;
      setSearchValue(value);
      /**
       * this was done this way to ensure that whenever the search is cleared, 
        it also checks that there are no filter rules there to ensure that it sets the 
        table back to default
      */
      Object.keys(obj).length === 0 && setAllClaims(data?.claims);
      return setFilterInput(obj);
    }
    setSearchValue(value);
  };

  const onFilter = (option: string, category: string) => {
    if (option === '') {
      const obj = { ...filterInput };
      delete obj[category];
      setFilterInput(obj);
      return searchValue === '' && setAllClaims(data?.claims);
    }
    if (category === 'status') {
      return setFilterInput({
        ...filterInput,
        [category]: { _eq: option.split(' ').join('_').toUpperCase() },
      });
    }
    if (category === 'resolution') {
      return setFilterInput({
        ...filterInput,
        [category]: {
          _eq:
            option === 'Denied'
              ? ResolutionType.deny
              : option === 'Reordered'
              ? ResolutionType.reorder
              : option.split(' ').join('_').toUpperCase(),
        },
      });
    }
    return setFilterInput({
      ...filterInput,
      [category]: { _eq: option.split(' ').join('_').toUpperCase() },
    });
  };

  const onPageChange = (pageNumber: number) => {
    setPage(pageNumber);
    if (pageNumber < page) {
      if (pageNumber - 1 === 0) {
        return setOffset(0);
      }
      return setOffset(limit * (pageNumber - 1));
    }
    return setOffset(limit * (pageNumber - 1));
  };

  const handleSetDate = (date: any) => {
    setDateRangeClaims(date);
    const obj = { ...filterInput };
    if (!date[0] && !date[1]) {
      delete obj._and;
      setFilterInput(obj);
      return searchValue === '' && setAllClaims(data.claims);
    }
    if (date[1])
      setFilterInput({
        ...filterInput,
        _and: [
          { createdAt: { _gte: format(new Date(date[0]), 'yyyy-MM-dd') } },
          { createdAt: { _lte: format(new Date(date[1]), 'yyyy-MM-dd') } },
        ],
      });
  };

  const onViewClaim = (claim: Claim) => {
    navigate(`/claims/${claim.id}`);
  };

  const handleNewClaim = () => {
    navigate('/claims/new-claim', { state: { returnLocation: '/claims' } });
  };

  const handleOrderByColumn = (column: string, direction: string) => {
    orderedClaims({
      variables: {
        order_by: [
          {
            [column]: direction,
          },
        ],
      },
    });
  };

  const stats = [
    {
      name: 'New Issues',
      currentStat: countData ? `${countData.newClaims.aggregate.count}` : 0,
    },
    {
      name: 'Resoved Issues',
      currentStat: `${
        (countData && countData?.resolvedClaims.aggregate.count) ?? 0
      }`,
    },
    {
      name: 'Total Issues',
      currentStat: `${
        (countData && countData?.totalClaims.aggregate.count) ?? 0
      }`,
    },
  ];

  const reasonFilterOptions = ['Lost', 'Stolen', 'Damaged', 'Late', 'Other'];

  const statusFilterOptions = ['New', 'Resolved', 'In Review', 'Denied'];

  const resolutionFilterOptions = ['In Review', 'Deny', 'Reorder', 'Refund'];

  if (loading) return <LoadingScreen />;

  const orderByCols = ['createdAt', 'total'];
  const claims_count = claimsCount;
  // Object.keys(filterInput).length === 0 ? claimsCount : allClaims.length;
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <div className="md:flex justify-between items-center">
        <div className="md:flex items-center">
          <Text value="All Issues" type="h4" />
          <DatePicker
            selectsRange
            startDate={dateRangeStats[0]}
            endDate={dateRangeStats[1]}
            setValue={setDateRangeStats}
            placeHolder="Date Range"
            showYearDropdown
            isClearable
            className="md:ml-5 my-3 sm:my-0 w-80 sm:w-fit"
          />
        </div>
        <div>
          <TrackingPortalBtn />
          <CustomerPortalBtn />
          {ordersCount > 0 && (
            <Button onClick={handleNewClaim} className="sm:mt-2 ml-2">
              New Delivery Issue
            </Button>
          )}
        </div>
      </div>
      <div className="">
        <Stats stats={stats} isSpaced />
      </div>
      <div className="md:flex justify-between mb-2 mt-12 sm:block flex-wrap">
        <div className="mb-4 sm:mb-4 md:mb-2 flex items-center">
          <div className="w-80">
            <SearchBox
              search={search}
              searchValue={searchValue}
              onChange={(searchValue) => handleSearch(searchValue)}
              placeholder="Search by Issue no, Order no, Name, Email"
            />
          </div>
          {loading && (
            <div
              style={{
                borderColor: 'rgba(37,99,235, 0.2)',
                borderTopColor: '#2564eb',
              }}
              className="inline-block border-2 rounded-full animate-spin w-3 h-3 ml-2"
            />
          )}
        </div>
        <div className="flex flex-wrap">
          <DataFilter
            filterBy="Reason"
            options={reasonFilterOptions}
            setSelectedOption={(option) => onFilter(option, 'reason')}
            selectedOption={
              filterInput?.reason ? filterInput?.reason?._eq.toLowerCase() : ''
            }
            wrapperClass="mr-2"
          />
          <DataFilter
            filterBy="Status"
            options={statusFilterOptions}
            setSelectedOption={(option) => onFilter(option, 'status')}
            selectedOption={
              filterInput?.status
                ? filterInput?.status?._eq?.replace('_', ' ').toLowerCase()
                : ''
            }
            wrapperClass="mr-2"
          />
          <DataFilter
            filterBy="Resolution"
            options={resolutionFilterOptions}
            setSelectedOption={(option) => onFilter(option, 'resolution')}
            selectedOption={
              filterInput?.resolution
                ? filterInput?.resolution?._eq?.replace('_', ' ').toLowerCase()
                : ''
            }
            wrapperClass="mr-2"
          />
          <DatePicker
            selectsRange
            startDate={dateRangeClaims[0]}
            endDate={dateRangeClaims[1]}
            setValue={handleSetDate}
            placeHolder="Date Range"
            showYearDropdown
            isClearable
          />
        </div>
      </div>
      <div className="">
        <ClaimsTable
          claims={
            allClaims?.length > 0
              ? [...allClaims].map((claim: Claim) => ({
                  ...claim,
                  onViewClaim: () => onViewClaim(claim),
                  currency: storeProperties?.currency,
                }))
              : []
          }
          columns={columns}
          emptyMessage={
            searchValue
              ? `No search results matches "${searchValue}"`
              : 'No data'
          }
          orderByOptions={orderByCols}
          handleSelectedColumn={handleOrderByColumn}
        />
      </div>
      <div className="mt-3">
        {claims_count > limit && (
          <Pagination
            totalPages={Math.ceil(claims_count / limit)}
            currentPage={page}
            resultsRange={limit}
            totalResults={claims_count ?? 0}
            isFilterApplied={Object.keys(filterInput).length ? true : false}
            onPageClick={onPageChange}
          />
        )}
      </div>
    </div>
  );
});
