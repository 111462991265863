import { VideoUpload } from './VideoUpload';
import { Text } from './Text';
import { XIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { getThumnailUrl } from 'utils';

interface ClaimVideosUploadProps {
  onUpload: ({ name, urls }: { name: string; urls: string[] }) => void;
  noOfVideos?: number;
  videos?: { id: string; url: string }[];
  onRemoveVideo?: (id: string) => void;
  onShowVideo?: (video: any) => void;
}

const DefaultThumbVideo = () => (
  <label htmlFor="file-video-upload">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-24 w-24"
      fill="none"
      viewBox="0 0 24 24"
      stroke="#89A7FB"
      strokeWidth={0.5}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5 4h14a2 2 0 012 2v12a2 2 0 01-2 2H5a2 2 0 01-2-2V6a2 2 0 012-2zM10 8v8l5-4-5-4z"
      />
    </svg>
  </label>
);

const VideoUploadIcon = () => (
  <svg
    className="mx-auto h-32 w-32 text-gray-400 cursor-pointer"
    stroke="#1D4ED8"
    fill="none"
    viewBox="0 0 48 48"
    aria-hidden="true"
  >
    <path
      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />

    <path
      d="M20 16l12 8-12 8V16z"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Loader = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
    style={{
      width: '30px',
      height: '30px',
      strokeDasharray: '90,150',
      strokeDashoffset: '0',
      animation: 'dash 1.5s ease-in-out infinite',
    }}
  >
    <circle
      cx="25"
      cy="25"
      r="20"
      fill="none"
      strokeWidth="5"
      stroke="#2563eb"
      strokeLinecap="round"
      style={{
        strokeDasharray: '90,150',
        strokeDashoffset: '0',
        animation: 'dash 1.5s ease-in-out infinite',
      }}
    />
    <style>
      {`
          @keyframes rotate {
            100% { transform: rotate(360deg); }
          }
          @keyframes dash {
            0% { stroke-dasharray: 1, 150; stroke-dashoffset: 0; }
            50% { stroke-dasharray: 90, 150; stroke-dashoffset: -35; }
            100% { stroke-dasharray: 90, 150; stroke-dashoffset: -124; }
          }
        `}
    </style>
  </svg>
);

export function ClaimsVideosUpload({
  onUpload,
  videos = [],
  onRemoveVideo,
  onShowVideo,
}: ClaimVideosUploadProps) {
  const maxVideos = 5;
  const title = 'Upload Issue Videos';
  const subtitle = `Add up to ${maxVideos} videos to support the issue and for future reference`;

  const [isUploading, setIsUploading] = useState(false);

  return (
    <VideoUpload
      shouldUploadOnDrop={true}
      name="claimsVideos"
      onUpload={
        isUploading
          ? () => {
              alert('Please wait, upload is in progress');
            }
          : onUpload
      }
      allowMultiple={true}
      maxVideos={maxVideos}
      setIsUploading={setIsUploading}
    >
      {({ getRootProps, getInputProps }) => {
        let thumbs = videos.map((video, index) => {
          return (
            <div
              key={video.id}
              className="h-24 w-24 sm:h-24 sm:w-24 mr-3 flex justify-center items-center cursor-pointer relative"
            >
              <div
                className="rounded-full absolute bg-white p-1 right-[-10px] top-[-15px] border cursor-pointer z-10"
                onClick={(e) => {
                  // prevents the file picker from opening up
                  e.stopPropagation();
                  onRemoveVideo && onRemoveVideo(video.id);
                }}
              >
                <XIcon height={15} width={15} />
              </div>
              <div
                style={{
                  position: 'relative',
                  display: 'inline-block',
                  cursor: 'pointer',
                }}
                onClick={onShowVideo?.bind(null, video.url)}
              >
                <img
                  src={getThumnailUrl(video.url)}
                  alt="Video Thumbnail"
                  className="object-cover w-24 h-24 rounded cursor-pointer"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 64 64"
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '35px',
                    height: '35px',
                    opacity: 0.7,
                    cursor: 'pointer',
                  }}
                >
                  <circle cx="32" cy="32" r="32" fill="rgba(0, 0, 0, 0.5)" />
                  <polygon points="24,16 48,32 24,48" fill="white" />
                </svg>
              </div>
            </div>
          );
        });
        // If the thumbnails generated from the uploaded files
        // are less than the max videos, display the default thumbnail for each
        // unused slot as per the designs
        if (thumbs.length < maxVideos) {
          // add the remaining as default
          const count = maxVideos - thumbs.length;
          for (let i = 0; i < count; i++) {
            // push a default video element into the array.
            thumbs.push(<DefaultThumbVideo key={i} />);
          }
        }

        return (
          <div className="">
            <label className="flex cursor-pointer flex-wrap">{thumbs}</label>
            <div className="flex" {...getRootProps()}>
              <div>
                <VideoUploadIcon />
              </div>
              <section className="mt-3 ml-4 cursor-pointer">
                <label htmlFor="file-video-upload" className="cursor-pointer">
                  <Text className="cursor-pointer" type="bold" value={title} />
                  <Text
                    className="pr-8 cursor-pointer whitespace-normal"
                    type="sm"
                    value={subtitle}
                  />
                  <input
                    id="file-video-upload"
                    name="file-video-upload"
                    type="file"
                    className="sr-only"
                    {...getInputProps()}
                  />
                </label>
              </section>
            </div>
            {isUploading && (
              <div className="flex items-center">
                <Loader /> <span className="ml-4">Upload in progress....</span>
              </div>
            )}
          </div>
        );
      }}
    </VideoUpload>
  );
}
