import React, { useState } from 'react';
import { Modal } from 'components/Modal';
import { Text } from 'components/Text';
import { Button } from 'components/Button';
import { userRoles } from 'types/user';
import { INVITE_ADMIN_USER, UPDATE_USER_ROLE } from 'gql/mutations';
import { useMutation } from '@apollo/client';
import { apiErrorHandler, NotificationTypes } from 'utils';

type NewAdminModalProps = {
  show: boolean;
  setShowModal: (value: boolean) => void;
  showNotification: any;
  fetchAdmins: () => void;
};

export const NewAdminModal = ({
  show,
  setShowModal,
  showNotification,
  fetchAdmins,
}: NewAdminModalProps) => {
  const [input, setInput] = useState({
    name: '',
    email: '',
  });

  const [updateUserRoleMutation, { loading: updateRoleLoading }] = useMutation(
    UPDATE_USER_ROLE,
    {
      onError: (error) => {
        const newError = apiErrorHandler(error);
        showNotification(NotificationTypes.error, newError?.message);
      },
      onCompleted: () => {
        setShowModal(false);
        fetchAdmins();
      },
    },
  );

  const [inviteAdminMutation, { loading: inviteUserLoading }] = useMutation(
    INVITE_ADMIN_USER,
    {
      onError: (error) => {
        const newError = apiErrorHandler(error);
        showNotification(NotificationTypes.error, newError?.message);
      },
      onCompleted: (data) => {
        updateUserRoleMutation({
          variables: {
            userId: data.insertUser.id,
            role: userRoles.SHIPAID_ADMIN,
          },
        });
      },
    },
  );

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string,
  ) => {
    const { value } = e.target;

    setInput({ ...input, [field]: value });
  };

  const handleFormSubmit = async () => {
    const inviteObj = {
      email: input.email,
      emailVerified: true,
      displayName: input.name,
      defaultRole: userRoles.SHIPAID_ADMIN,
      locale: 'en',
    };
    inviteAdminMutation({
      variables: { inviteObj },
    });
  };

  return (
    <Modal
      openModal={(value) => setShowModal(value)}
      open={show}
      containerClass="sm:max-w-lg sm:w-full"
    >
      <Text value="Invite New Admin" type="h4" />
      <div className="block mt-4">
        <Text value="Name" type="sm" className="text-gray-700 mb-1" />
        <input
          name="name"
          className="border border-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md p-2 w-full"
          placeholder="Name"
          onChange={(e) => handleChange(e, 'name')}
        />
      </div>
      <div className="block mt-4">
        <Text value="Email" type="sm" className="text-gray-700 mb-1" />
        <input
          name="email"
          type="email"
          className="border border-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md p-2 w-full"
          placeholder="Email"
          onChange={(e) => handleChange(e, 'email')}
        />
      </div>
      <div className="flex mt-6">
        <Button
          onClick={handleFormSubmit}
          className="bg-blue-600 mr-3 hover:bg-blue-700"
          disabled={
            !input.email ||
            !input.name ||
            inviteUserLoading ||
            updateRoleLoading
          }
        >
          Invite
        </Button>
        <Button
          onClick={() => setShowModal(false)}
          className="bg-white border border-rose-500 text-gray-500 hover:bg-rose-600 bg-rose-500"
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};
