import React, { FormEvent, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Text } from '../components/Text';
import DoorImage from '../assets/images/doorstep.png';
import Google from '../assets/images/google.png';
import { Button } from '../components/Button';
import { withNotification } from 'components/Notification';
import { NotificationTypes } from 'utils';
import { SizeEnum } from '../utils';
import FormInput from '../components/FormInput';
import { useResetPassword, useProviderLink } from '@nhost/react';
import classNames from 'classnames';
import AuthPage from '../components/AuthPage';

export const ForgotPassword: React.FC = withNotification(
  ({ showNotification }: any) => {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    const { google } = useProviderLink();
    const { error, isLoading, resetPassword } = useResetPassword({
      redirectTo: '/settings/account?password=true',
    });

    const handleResetPassword = async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      let result = await resetPassword(email);
      if (result.isSent) {
        showNotification(
          NotificationTypes.success,
          'A reset password link has been sent to your mail',
        );
        setTimeout(function () {
          navigate('/login');
        }, 3000);
      }
    };

    const disableForm = isLoading;

    return (
      <AuthPage backgroundImage={DoorImage}>
        <div className="text-center my-6">
          <h6 className="uppercase text-shipaidBlue my-4 font-bold">Account</h6>

          <h2 className="text-3xl md:text-5xl my-4">Log in to continue</h2>

          <div className="text-sm md:text-xl">
            <span>Don&apos;t have an account? </span>
            <span className="text-shipaidBlue">
              <Link to="/sign-up">Sign up</Link>
            </span>
          </div>

          <Button
            size={SizeEnum.sm}
            isRounded
            onClick={() => (window.location.href = google)}
            className="bg-transparent border border-black w-full rounded-full flex justify-center items-center align-middle text-black text-center mt-4 hover:bg-white"
          >
            <img className="h-6 w-6" src={Google} alt="google logo" />
            <span className="block pl-2"> Login with google</span>
          </Button>

          <div className="my-4">
            <fieldset className="border-t border-slate-300">
              <legend className="mx-auto px-2 text-black text-sm">
                Or Continue with
              </legend>
            </fieldset>
          </div>
        </div>
        <div className="form w-full  md:w-[450px]">
          <form className="w-full" onSubmit={handleResetPassword}>
            <div className="mb-4">
              <FormInput
                id="email"
                name="email"
                label="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                type="email"
              />
            </div>
            <Text
              type="sm"
              value={error?.message}
              className={classNames('text-center text-red-600')}
            />

            <Button
              size={SizeEnum.xl}
              isRounded
              type="submit"
              disabled={disableForm}
              className="bg-shipaidBlue text-white w-full rounded-full flex justify-center items-center align-middle text-center mt-4 hover:bg-opacity-80"
            >
              Reset Password
            </Button>
          </form>
        </div>
      </AuthPage>
    );
  },
);
