import shipaidLogo from '../assets/images/shipaid_logo_300x300.webp';
import classes from '../modulecss/trackingFooter.module.css';

const TrackingFooter = () => {
  return (
    <div className={classes.container}>
      <img src={shipaidLogo} alt="logo" style={{ height: 50, width: 'auto' }} />
    </div>
  );
};

export default TrackingFooter;
