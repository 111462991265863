import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { SideBar } from 'components/Sidebar';
import { Merchants } from 'containers/NestedContainers/Admin/Merchants';
import { Admins } from 'containers/NestedContainers/Admin/Admins';
import { Account } from 'containers/NestedContainers/Admin/Account';
import { Announcements } from 'containers/NestedContainers/Admin/Announcements';
import { MerchantProfile } from 'containers/NestedContainers/Admin/MerchantProfile';
import { MenuIcon } from '@heroicons/react/outline';

export const AdminApp: React.FC = () => {
  const adminData = localStorage.getItem(
    process.env.REACT_APP_ADMIN_STORAGE_KEY || 'admin-context',
  );
  let adminContext: any;
  if (adminData) adminContext = JSON.parse(adminData);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    if (adminContext?.admin?.user?.user?.id) {
      localStorage.removeItem(
        process.env.REACT_APP_ADMIN_STORAGE_KEY || 'admin-context',
      );
    }
  }, [adminContext?.admin?.user?.user?.id]);

  return (
    <>
      <div>
        <SideBar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div>
          <div className="md:pl-64 flex flex-col flex-1">
            <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-white">
              <button
                type="button"
                className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <main className="flex-1">
              <div className="py-6">
                <Routes>
                  <Route
                    index
                    element={<Navigate to="merchants" replace={true} />}
                  />
                  <Route path="merchants" element={<Merchants />} />
                  <Route path="admins" element={<Admins />} />
                  <Route path="admin/account" element={<Account />} />
                  <Route path="announcements" element={<Announcements />} />
                  <Route path="merchants/:id" element={<MerchantProfile />} />
                  <Route
                    path="*"
                    element={<Navigate to="merchants" replace={true} />}
                  />
                </Routes>
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  );
};
