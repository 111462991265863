import React, { useState } from 'react';
import { Modal } from 'components/Modal';
import { Text } from 'components/Text';
import { Button } from 'components/Button';
import { Dropdown } from 'components/Dropdown';
import { customEmailTemplateTypes } from '../containers/NestedContainers/Tracking/data/templates-data';
import { useTemplateEmails } from '../hooks/useTemplateEmails';

type CloneTemplateModalProps = {
  template: any;
  setCloneModal: (value: any) => void;
  onClose: (a?: any, b?: any) => void;
  noCreatedTemplates: any;
};

export const CloneTemplateModal = ({
  template,
  setCloneModal,
  onClose,
  noCreatedTemplates,
}: CloneTemplateModalProps) => {
  const handleOnClose = (updatedTemplate: any, info: any) => {
    onClose?.(updatedTemplate, info);
    return null;
  };

  const { submitTemplate } = useTemplateEmails({
    callback: handleOnClose,
  });

  const [eventType, setEventType] = useState<string | null>(null);

  const handleSetEventType = (option: string) => {
    const eventType = emailTypes.find((t) => t.label === option);
    if (!eventType) return;
    setEventType(eventType.key);
    console.log('eventType', eventType);
  };

  const emailTypes = Object.values(customEmailTemplateTypes);

  return (
    <Modal
      openModal={() => setCloneModal(null)}
      open={!!template}
      containerClass="sm:max-w-lg sm:w-full"
    >
      <Text
        value={`Cloning Order ${
          emailTypes.find((type) => type.key === template?.eventType)?.label
        } template`}
        type="h4"
      />
      <div className="items-center mt-3">
        <div className="flex">
          <Text
            value="To"
            type="h4"
            className="text-gray-800 mr-3 mb-1 mt-3 ml-4"
          />
          <span className="mt-3 ml-5">
            <Dropdown
              options={noCreatedTemplates.map((t: any) => t.label)}
              onSelectOption={(option) => handleSetEventType(option)}
              btnClassName="border border-gray-300"
              placeholder="Select Event Type (Required)"
              selectedOption={
                eventType
                  ? emailTypes.find((t) => t.key === eventType)?.label
                  : ''
              }
              downCaret
            />
          </span>
        </div>
      </div>
      <div className="flex mt-6">
        <Button
          onClick={() => {
            submitTemplate({
              values: {
                ...template,
                eventType,
                simpleIntegration: true,
              },
            });
            setEventType(null);
          }}
          className="bg-blue-600 mr-3 hover:bg-blue-700"
        >
          Clone
        </Button>
        <Button
          onClick={() => setCloneModal(null)}
          className="bg-red-500 text-white hover:bg-red-700 ml-2"
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};
