import React from 'react';
import { Text } from 'components/Text';
import { getStoreCurrencyFormatter } from 'utils';

export const columns = [
  {
    name: 'Tracking Status',
    id: 1,
    render: (item: any) => {
      const status = shipmentStatus.find(
        (s) => s.value === item.trackingStatus,
      );
      return (
        <Text className={`${status?.textColor}`} value={status?.label ?? ''} />
      );
    },
  },
  {
    name: 'Tracking',
    id: 2,
    render: (item: any) => {
      const trackingIds = (item?.items ?? [])
        .filter(
          (i: { trackingId: string }) => i.trackingId && i.trackingId !== '',
        )
        .slice(0, 1);
      if (trackingIds.length) {
        const trackingId = trackingIds[0].trackingId;
        return (
          <span key={trackingId}>
            <a
              href={`${process.env.REACT_APP_TRACKING_PORTAL_URL}/${trackingId}`}
              target="_blank"
              rel="noreferrer"
            >
              <Text
                value={'Track'}
                type="body"
                className="text-blue-500 underline"
              />
            </a>
          </span>
        );
      }
      return <Text value={'N/A'} />;
    },
    value: 'tracking',
  },
  {
    name: 'Order Date',
    id: 3,
    render: (item: any) => (
      <Text value={new Date(item.createdAt).toLocaleDateString()} />
    ),
    value: 'createdAt',
  },
  {
    name: 'Guarantee Status',
    id: 4,
    render: (item: any) => {
      if (item?.protectionStatus?.toLowerCase() === 'protected')
        return (
          <Text
            value="Guaranteed"
            className="bg-green-100 px-3 py-1 rounded-full text-green-700 text-center"
            type="xs"
          />
        );
      if (item?.protectionStatus?.toLowerCase() === 'unprotected')
        return (
          <Text
            value="Not Guaranteed"
            className="bg-red-100 px-3 py-1 rounded-full text-red-700 text-center"
            type="xs"
          />
        );
      return (
        <Text
          value={`${item?.protectionStatus?.replace('Claim', 'Issue')}`}
          className="bg-blue-100 px-3 py-1 rounded-full text-blue-700 text-center"
          type="xs"
        />
      );
    },
    value: 'protectionStatus',
  },
  {
    name: 'Guarantee Paid',
    id: 5,
    render: (item: any) => (
      <Text
        value={`${getStoreCurrencyFormatter(
          item.currency,
          item.protectionTotal,
        )}`}
      />
    ),
    value: 'protectionTotal',
  },
  {
    name: 'Customer Name',
    id: 6,
    key: 'customerName',
    value: 'customerName',
  },
  {
    name: 'Customer Email',
    id: 7,
    render: (item: any) => <Text value={`${item.customerEmail ?? 'N/A'}`} />,
    value: 'customerEmail',
  },
  {
    name: 'Items',
    id: 8,
    render: (item: any) => (
      <Text
        value={`${
          item?.items?.filter((item: any) => !item.title?.includes('ShipAid'))
            .length
        } items`}
      />
    ),
    value: 'items',
  },
  {
    name: 'Order Total',
    id: 9,
    render: (item: any) => (
      <Text value={`${getStoreCurrencyFormatter(item.currency, item.total)}`} />
    ),
    value: 'subtotal',
  },
  {
    name: 'Issue Number',
    id: 10,
    render: (item: any) => {
      const text = item?.claims.length
        ? item?.claims.map((claim: any) => claim.number).join(', ')
        : '-';
      return <Text value={text} />;
    },
    value: 'claims',
  },
];

export const stats = [
  {
    name: 'Total Orders',
    currentStat: '70',
    previousStat: '60',
    change: '16',
    changeType: 'increase',
  },
  {
    name: 'Guaranteed Orders',
    currentStat: '60',
    previousStat: '70',
    change: '16',
    changeType: 'decrease',
  },
  {
    name: 'Reimbursed/Reshipped',
    currentStat: '100',
    previousStat: '50',
    change: '50',
    changeType: 'increase',
  },
];

export const trackingColumns = [
  {
    name: 'Tracking Number',
    id: 101,
    key: 'trackingNumber',
    value: 'trackingNumber',
  },
  {
    name: 'Tracking',
    id: 102,
    render: (item: any) => {
      return (
        <span key={item.trackingNumber}>
          <a
            href={`${process.env.REACT_APP_TRACKING_PORTAL_URL}/${item.trackingNumber}`}
            target="_blank"
            rel="noreferrer"
          >
            <Text
              value={'Track'}
              type="body"
              className="text-blue-500 underline"
            />
          </a>
        </span>
      );
    },
  },
  {
    name: 'Status',
    id: 103,
    render: (item: any) => {
      const status = shipmentStatus.find((s) => s.value === item.trackerStatus);
      return (
        <Text className={`${status?.textColor}`} value={status?.label ?? ''} />
      );
    },
  },
  {
    name: 'Item',
    id: 104,
    render: (item: any) => <Text value={item.orderItem.title} />,
  },
  {
    name: 'Carrier',
    id: 105,
    key: 'trackingCompany',
    value: 'trackingCompany',
  },
  {
    name: 'Est Delivery',
    id: 106,
    render: (item: any) => (
      <Text
        value={
          item.estDeliveryDate
            ? new Date(item.estDeliveryDate).toLocaleDateString()
            : 'N/A'
        }
      />
    ),
  },
];

export const shipmentStatus = [
  {
    label: 'Pre-Transit',
    value: 'PRE_TRANSIT',
    textColor: 'text-indigo-800',
  },
  {
    label: 'In-Transit',
    value: 'IN_TRANSIT',
    textColor: 'text-blue-500',
  },
  {
    label: 'Out For Delivery',
    value: 'OUT_FOR_DELIVERY',
    textColor: 'text-orange-500',
  },
  {
    label: 'Delivered',
    value: 'DELIVERED',
    textColor: 'text-green-500',
  },
  {
    label: 'Avaliable For Pickup',
    value: 'AVAILABLE_FOR_PICKUP',
    textColor: 'text-orange-500',
  },
  {
    label: 'Exception',
    value: 'ERROR',
    textColor: 'text-red-800',
  },
  {
    label: 'Failed Attempt',
    value: 'FAILURE',
    textColor: 'text-indigo-700',
  },
  {
    label: 'Cancelled',
    value: 'CANCELLED',
    textColor: 'text-gray-700',
  },
  {
    label: 'Return To Sender',
    value: 'RETURN_TO_SENDER',
    textColor: 'text-amber-700',
  },
  {
    label: 'Pending',
    value: 'UNKNOWN',
    textColor: 'text-gray-400',
  },
];

export const claimReasons = [
  {
    label: 'LOST',
    value: 'LOST',
    textColor: 'text-indigo-800',
  },
  {
    label: 'STOLEN',
    value: 'STOLEN',
    textColor: 'text-indigo-800',
  },
  {
    label: 'DAMAGED',
    value: 'DAMAGED',
    textColor: 'text-orange-500',
  },
  {
    label: 'LATE',
    value: 'LATE',
    textColor: 'text-blue-500',
  },
  {
    label: 'OTHER',
    value: 'OTHER',
    textColor: 'text-green-500',
  },
];
