import type { ReactElement } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

interface ChildrenRenderProps {
  getRootProps: any;
  getInputProps: any;
  upload: (files: File[]) => void;
  shouldUploadOnDrop: boolean;
  files: DropzoneFile[];
}

interface VideoUploadProps {
  shouldUploadOnDrop?: boolean;
  onUpload: ({ name, urls }: { name: string; urls: string[] }) => void;
  name: string;
  allowMultiple?: boolean;
  maxVideos?: number;
  children: ({
    getRootProps,
    getInputProps,
  }: ChildrenRenderProps) => ReactElement;
  setIsUploading: (isUploading: boolean) => void;
}

export interface DropzoneFile extends File {
  preview: string;
}

const MAX_SIZE_UPLOAD = 10485760; // Maximum file size in bytes (10 MB)

export const VideoUpload = ({
  allowMultiple = false,
  maxVideos = 1,
  shouldUploadOnDrop = true,
  onUpload,
  name,
  children,
  setIsUploading,
}: VideoUploadProps) => {
  const [files, setFiles] = useState<DropzoneFile[]>([]);

  const upload = useCallback(
    async (files: File[]) => {
      const uploadURL = process.env.REACT_APP_IMAGE_UPLOAD_URL;
      const uploadPreset = process.env.REACT_APP_UPLOAD_PRESET;

      if (!files?.length)
        return alert(
          'Invalid file selection, please check file size and format. Video file size should be max 10 MB',
        );

      const withinSizeLimit = files.every(
        (file) => file.size <= MAX_SIZE_UPLOAD,
      );

      if (!withinSizeLimit) return alert('Video file size should be max 10 MB');

      setIsUploading(true);
      try {
        await Promise.all(
          files.map(async (file) => {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('upload_preset', uploadPreset as string);

            const response = await fetch(uploadURL as string, {
              method: 'POST',
              body: formData,
            });

            const responseData = await response.json();
            const { secure_url: url } = responseData;
            return Promise.resolve(url);
          }),
        )
          .then((urls) => {
            onUpload({ name, urls });
            setIsUploading(false);
          })
          .finally(() => {
            setIsUploading(false);
          });
      } catch (error) {
        setFiles([]);
      } finally {
        setIsUploading(false);
      }
    },
    [name, onUpload, setIsUploading],
  );

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      // Do something with the files
      if (maxVideos > 1 && files.length === maxVideos) {
        return;
      }

      setFiles(
        allowMultiple
          ? [
              ...files,
              ...acceptedFiles.map((file: File) =>
                Object.assign(file, {
                  preview: URL.createObjectURL(file),
                }),
              ),
            ]
          : acceptedFiles.map((file: File) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              }),
            ),
      );

      if (shouldUploadOnDrop) {
        upload(acceptedFiles);
      }
    },
    [files, allowMultiple, maxVideos, shouldUploadOnDrop, upload],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: [
      '.mp4',
      '.avi',
      '.mov',
      '.mkv',
      '.webm',
      '.flv',
      '.wmv',
      '.mpeg',
      '.mpeg',
    ],
    maxSize: MAX_SIZE_UPLOAD,
  });

  // clean up
  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files],
  );

  return children({
    getRootProps,
    getInputProps,
    upload,
    files,
    shouldUploadOnDrop,
  });
};
