/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import {
  customEmailTemplateIntegrationTypes,
  templateEventTypesVariables,
  templatesUsageTypes,
} from '../data/templates-data';

import EmailEditor from '../EmailEditor';

const EditorField = React.memo(
  React.forwardRef(
    (
      { platformIntegrationType, values, setIsTemplateReady, template },
      ref,
    ) => {
      const handleCustomEditorChange = useCallback((html, design) => {
        localStorage.setItem(
          'template-preview',
          JSON.stringify({ isActive: true, html, design }),
        );
        return;
      }, []);

      const handleOnReady = useCallback(() => setIsTemplateReady(true), []);

      const renderCustomEditor = () => (
        <EmailEditor
          templateJson={values.templateJson}
          mergeTags={
            templateEventTypesVariables[
              template?.eventType ?? 'trackingDefault'
            ] ?? []
          }
          specialLinks={[
            {
              name: 'Tracking Page',
              href: '{{tracking_page_url}}',
              target: '_blank',
            },
          ]}
          tools={{
            'custom#shipaid_tools': {
              properties: {
                tool: {
                  editor: {
                    data: {
                      options: [
                        { label: 'Tracking', value: 'tracking' },
                        { label: 'Product', value: 'product' },
                        { label: 'Accordion', value: 'accordion' },
                      ],
                    },
                  },
                },
              },
            },
          }}
          displayMode={
            template?.usage === templatesUsageTypes.emails ? 'email' : 'web'
          }
          onReady={handleOnReady}
          onChange={handleCustomEditorChange}
          customJS={[
            `${window.location.protocol}//${window.location.host}/custom.js`,
          ]}
          ref={ref}
          style={{ height: 650, minHeight: 700 }}
        />
      );

      const RESOLVE_EDITION_FIELD = {
        [customEmailTemplateIntegrationTypes.custom]: renderCustomEditor,
      };

      return RESOLVE_EDITION_FIELD[platformIntegrationType]();
    },
  ),
);

export default EditorField;
