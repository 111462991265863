/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { ActionPage } from './NestedContainers/ActionPage';
import {
  populateTemplateWithDetails,
  trackingDetailsPreview,
  getTrackingDetails,
  populateTemplate,
} from '../containers/NestedContainers/Tracking/utils';
import { variablesPreviewResolver } from '../containers/NestedContainers/Tracking/data/templates-data';
import InnerHTML from '../components/HOC/DangerouslySetHtmlContent';
import TrackingFooter from 'components/TrackingFooter';
import classes from '../modulecss/trackingPreview.module.css';

export function TemplatePreview() {
  const navigate = useNavigate();
  const [templatePreview, setTemplatePreview] = useState({
    isActive: false,
    html: null,
    design: null,
  });

  const handleTemplatePreviewRefresh = async () => {
    const template = localStorage.getItem('template-preview');
    if (template) {
      const previewTemplate = JSON.parse(template);
      previewTemplate.html = previewTemplate.html.replaceAll('\n', '');
      if (previewTemplate.html !== templatePreview.html) {
        setTemplatePreview(previewTemplate);
      }
    } else {
      setTemplatePreview({ isActive: false, html: null, design: null });
    }
  };

  useEffect(() => {
    const template = localStorage.getItem('template-preview');
    if (template) {
      const previewTemplate = JSON.parse(template);
      previewTemplate.html = previewTemplate.html.replaceAll('\n', '');
      setTemplatePreview(previewTemplate);
      setInterval(() => handleTemplatePreviewRefresh(), 5000);
    } else {
      setTemplatePreview({ isActive: false, html: null, design: null });
    }
  }, []);

  const populatedHtml = templatePreview?.isActive
    ? populateTemplateWithDetails({
        products: [
          { title: 'Preview Item 1', quantity: 1 },
          { title: 'Preview Item 2', quantity: 2 },
        ],
        trackingDetails: Object.entries(
          getTrackingDetails(trackingDetailsPreview),
        ),
        template: {
          templateHtml: populateTemplate(
            templatePreview?.html,
            variablesPreviewResolver,
          ),
        },
      })
    : '';

  return (
    <div className={classes.container}>
      {!templatePreview?.isActive ? (
        <ActionPage
          title="No preview active"
          subtitle={
            'There is no currently active template preview. Please return to the templates page to select a template.'
          }
          onClick={() => navigate('/settings/tracking-templates')}
          btnText={'Click here to go back'}
        />
      ) : (
        <InnerHTML
          className={classes.templateContainer}
          html={populatedHtml || '<div></div>'}
        />
      )}
      <TrackingFooter />
    </div>
  );
}
