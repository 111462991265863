import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

interface ChildrenRenderProps {
  getRootProps: any;
  getInputProps: any;
  upload: (files: File[]) => void;
  shouldUploadOnDrop: boolean;
  files: DropzoneFile[];
  clearImages: () => void;
}
interface ImageUploadProps {
  shouldUploadOnDrop?: boolean;
  onUpload: ({ urls }: { urls: string[] }) => void;
  allowMultiple?: boolean;
  maxImages?: number;
  setIsUploading: (isUploading: boolean) => void;
  children: ({
    getRootProps,
    getInputProps,
  }: ChildrenRenderProps) => ReactElement;
}

export interface DropzoneFile extends File {
  preview: string;
}

export const ImageUpload = ({
  allowMultiple = false,
  maxImages = 1,
  shouldUploadOnDrop = true,
  onUpload,
  children,
  setIsUploading,
}: ImageUploadProps) => {
  const [files, setFiles] = useState([]);

  const clearImages = () => {
    setFiles([]);
    onUpload({ urls: [''] });
  };

  const upload = useCallback(
    async (files: File[]) => {
      const uploadURL = process.env.REACT_APP_IMAGE_UPLOAD_URL;
      const uploadPreset = process.env.REACT_APP_UPLOAD_PRESET;

      try {
        setIsUploading(true);
        await Promise.all(
          files.map(async (file) => {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('upload_preset', uploadPreset as string);
            setIsUploading(true);

            const response = await fetch(uploadURL as string, {
              method: 'POST',
              body: formData,
            });

            const responseData = await response.json();
            const { secure_url: url } = responseData;
            return Promise.resolve(url);
          }),
        )
          .then((urls) => {
            onUpload({ urls });
            setIsUploading(false);
          })
          .finally(() => setIsUploading(false));
      } catch (error) {
        console.error('error', (error as any).message);
        setFiles([]);
      } finally {
        setIsUploading(false);
      }
    },
    [onUpload, setIsUploading],
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      // Do something with the files
      if (maxImages > 1 && files.length === maxImages) {
        return;
      }

      setFiles(
        allowMultiple
          ? [
              ...files,
              ...acceptedFiles.map((file: File) =>
                Object.assign(file, {
                  preview: URL.createObjectURL(file),
                }),
              ),
            ]
          : acceptedFiles.map((file: File) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              }),
            ),
      );

      if (shouldUploadOnDrop) {
        upload(acceptedFiles);
      }
    },
    [files, allowMultiple, maxImages, shouldUploadOnDrop, upload],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png',
  });

  // clean up
  useEffect(
    () => () => {
      files.forEach((file: DropzoneFile) => URL.revokeObjectURL(file.preview));
    },
    [files],
  );

  return children({
    getRootProps,
    getInputProps,
    upload,
    files,
    shouldUploadOnDrop,
    clearImages,
  });
};
