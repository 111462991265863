import React, { Fragment } from 'react';
import classNames from 'classnames';
import { SizeEnum } from 'utils';

type ButtonProps = {
  size?: SizeEnum;
  color?: 'primary' | 'danger' | 'none';
  variant?: 'contained' | 'outlined';
  children?: React.ReactNode;
  onClick?: () => void;
  isRounded?: boolean;
  isIconOnly?: boolean;
  className?: string;
  isLoading?: boolean;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
};

export const Button = ({
  size = SizeEnum.md,
  color = 'primary',
  variant = 'contained',
  children,
  onClick,
  isRounded,
  isIconOnly,
  className,
  isLoading,
  disabled,
  type = 'button',
}: ButtonProps) => {
  let classes = classNames(
    disabled ? 'disabled:opacity-75' : '',
    'inline-flex items-center border font-medium rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2',
  );
  let spinnerClasses = 'inline-block border-2 rounded-full animate-spin';

  switch (size) {
    case SizeEnum.xs:
      classes = classNames(
        classes,
        `${isIconOnly ? 'p-1' : 'px-2.5 py-1.5'} text-xs`,
      );
      spinnerClasses = classNames('w-3 h-3 mr-2', spinnerClasses);
      break;
    case SizeEnum.sm:
      classes = classNames(
        classes,
        `${isIconOnly ? 'p-1.5' : 'px-3 py-2'} text-sm leading-4 rounded-md`,
      );
      spinnerClasses = classNames('w-3 h-3 mr-2', spinnerClasses);
      break;
    case SizeEnum.md:
      classes = classNames(
        classes,
        `${isIconOnly ? 'p-2' : 'px-4 py-2'} text-sm rounded-md`,
      );
      spinnerClasses = classNames('w-4 h-4 mr-2', spinnerClasses);
      break;
    case SizeEnum.lg:
      classes = classNames(
        classes,
        `${isIconOnly ? 'p-2' : 'px-4 py-2'} text-base rounded-md`,
      );
      spinnerClasses = classNames('w-4 h-4 mr-2', spinnerClasses);
      break;
    case SizeEnum.xl:
      classes = classNames(
        classes,
        `${isIconOnly ? 'p-3' : 'px-6 py-3'} text-base rounded-md`,
      );
      spinnerClasses = classNames('w-5 h-5 mr-2', spinnerClasses);
      break;
    default:
      classes = classNames(
        classes,
        `${isIconOnly ? 'p-2' : 'px-4 py-2'} text-sm rounded-md`,
      );
      spinnerClasses = classNames('w-4 h-4 mr-2', spinnerClasses);
  }
  if (variant === 'contained') {
    if (color === 'primary') {
      classes = classNames(
        classes,
        'border-transparent text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-500',
      );
    } else if (color === 'danger') {
      classes = classNames(
        classes,
        'border-transparent text-white bg-red-600 hover:bg-red-700 focus:ring-red-500',
      );
    }
  } else if (variant === 'outlined') {
    if (color === 'primary') {
      classes = classNames(
        classes,
        'border-blue-700 text-blue-700 bg-white hover:bg-blue-100 focus:ring-blue-500',
      );
    } else if (color === 'danger') {
      classes = classNames(
        classes,
        'border-red-500 text-red-500 bg-white hover:bg-red-100 focus:ring-red-500',
      );
    }
  }
  if (isRounded || isIconOnly) classes = classNames(classes, 'rounded-full');
  return (
    <Fragment>
      <button
        type={type}
        className={classNames(classes, className)}
        onClick={onClick}
        disabled={disabled || isLoading}
      >
        {isLoading && (
          <div
            style={{
              borderColor: 'rgba(255,255,255,0.2)',
              borderTopColor: '#ddd',
            }}
            className={spinnerClasses}
          />
        )}
        {children}
      </button>
    </Fragment>
  );
};
