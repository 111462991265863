import React from 'react';
import classNames from 'classnames';

type AnnouncementHeaderProps = {
  children?: React.ReactNode;
  className?: string;
};

export const AnnouncementHeader = ({
  children,
  className,
}: AnnouncementHeaderProps) => {
  return (
    <nav
      className={classNames(
        className,
        'w-full md:ml-64 ml-0 navbar navbar-expand-lg shadow-md py-1 px-2 bg-white relative flex items-center z-50',
      )}
    >
      <div className="w-full flex flex-wrap items-center justify-between">
        {children}
      </div>
    </nav>
  );
};
