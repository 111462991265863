import { useCallback, useEffect, useState } from 'react';
import { DataTable } from 'components/DataTable';
import { SearchBox } from 'components/Search';
import { Pagination } from 'components/Pagination';
import { Button } from 'components/Button';
import { columns } from './data/admins-data';
import { NewAdminModal } from './NewAdminModal';
import { useLazyQuery } from '@apollo/client';
import { withNotification } from 'components/Notification';
import { NotificationTypes } from 'utils';
import { AdminUser } from 'types/admin';
import { LoadingScreen } from 'components/LoadingScreen';
import { Dropdown } from 'components/Dropdown';
import { DotsVerticalIcon } from '@heroicons/react/outline';
import { GET_ADMIN_USERS, FILTER_ADMIN_USERS } from 'gql/queries';
import { userRoles } from 'types/user';

const adminFilters = {
  roles: { role: { _eq: userRoles.SHIPAID_ADMIN } },
};

export const Admins = withNotification(({ showNotification }: any) => {
  const limit = 20;
  const [allAdmins, setAllAdmins] = useState<[]>([]);
  const [adminsCount, setAdminsCount] = useState(0);
  const [filterInput, setFilterInput] = useState<any>({});
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [showNewAdminModal, setShowNewAdminModal] = useState(false);

  const [getAdmins, { data, loading }] = useLazyQuery(GET_ADMIN_USERS, {
    onCompleted: (data) => {
      setAllAdmins(data.users);
      setAdminsCount(data?.usersAggregate?.aggregate?.count);
    },
    onError(error) {
      showNotification(NotificationTypes.error, error?.message);
    },
  });

  const [filterAdmins, { loading: filterLoading }] = useLazyQuery(
    FILTER_ADMIN_USERS,
    {
      onCompleted: (data) => {
        setAllAdmins(data?.users);
      },
      onError(error) {
        showNotification(NotificationTypes.error, error?.message);
      },
    },
  );

  const fetchAdmins = useCallback(() => {
    getAdmins({
      variables: {
        filters: { ...adminFilters },
        limit,
        offset,
        order_by: [
          {
            createdAt: 'desc',
          },
        ],
      },
    });
  }, [getAdmins, offset]);

  useEffect(() => {
    fetchAdmins();
  }, [fetchAdmins, offset]);

  useEffect(() => {
    if (Object.keys(filterInput).length > 0)
      filterAdmins({
        variables: {
          filters: { ...adminFilters, ...filterInput },
          order_by: [
            {
              createdAt: 'desc',
            },
          ],
        },
      });
  }, [filterInput, filterAdmins]);

  const onPageChange = (pageNumber: number) => {
    setPage(pageNumber);
    if (pageNumber < page) {
      if (pageNumber - 1 === 0) {
        return setOffset(0);
      }
      return setOffset(limit * (pageNumber - 1));
    }
    return setOffset(limit * (pageNumber - 1));
  };

  const handleSelectedRow = (item: AdminUser) => {
    if (selectedRows.includes(item.id))
      return setSelectedRows(
        [...selectedRows].filter((row) => row !== item.id),
      );
    return setSelectedRows([...selectedRows, item.id]);
  };

  const handleSelectAllRows = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const rows = data?.users?.map((merchant: AdminUser) => merchant.id);
    if (target.checked) return setSelectedRows(rows);
    return setSelectedRows([]);
  };

  const handleSearch = (value: string) => {
    if (value === '') {
      const obj = { ...filterInput };
      delete obj._or;
      setSearchValue(value);
      /**
       * this was done this way to ensure that whenever the search is cleared, 
        it also checks that there are no filter rules there to ensure that it sets the 
        table back to default
      */
      Object.keys(obj).length === 0 && setAllAdmins(data?.users);
      return setFilterInput(obj);
    }
    setSearchValue(value);
  };

  const search = () => {
    setFilterInput({
      ...filterInput,
      _or: [
        { displayName: { _ilike: `%${searchValue}%` } },
        { email: { _ilike: `%${searchValue}%` } },
      ],
    });
  };

  if (loading) return <LoadingScreen />;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <NewAdminModal
        show={showNewAdminModal}
        setShowModal={setShowNewAdminModal}
        showNotification={showNotification}
        fetchAdmins={fetchAdmins}
      />
      <div className="mb-2 w-full sm:mt-2 flex justify-end">
        <Button onClick={() => setShowNewAdminModal(true)}>Invite Admin</Button>
      </div>
      <div className="mb-4 sm:mb-4 md:mb-2 flex items-center">
        {selectedRows.length > 0 ? (
          <div className="mr-4">
            <Dropdown
              icon={
                <DotsVerticalIcon className="h-7 text-blue-600 cursor-pointer" />
              }
              options={[]}
              onSelectOption={() => null}
            />
          </div>
        ) : null}
        <div className="mb-4 sm:mb-4 md:mb-2 flex items-center w-full">
          <div className="w-full">
            <SearchBox
              search={search}
              searchValue={searchValue}
              onChange={(searchValue) => handleSearch(searchValue)}
              placeholder="Search by merchant name or email"
            />
          </div>
          {filterLoading && (
            <div
              style={{
                borderColor: 'rgba(37,99,235, 0.2)',
                borderTopColor: '#2564eb',
              }}
              className="inline-block border-2 rounded-full animate-spin w-3 h-3 ml-2"
            />
          )}
        </div>
      </div>
      <div className="">
        <DataTable
          data={allAdmins}
          columns={columns}
          handleSelectedRow={handleSelectedRow}
          onSelectAll={handleSelectAllRows}
          // checkbox
          allChecked={selectedRows}
          emptyMessage={
            searchValue
              ? `No search results matches "${searchValue}"`
              : 'No data'
          }
        />
      </div>
      <div className="mt-3">
        {adminsCount > limit && (
          <Pagination
            totalPages={
              Object.keys(filterInput).length
                ? 1
                : Math.ceil(adminsCount / limit)
            }
            currentPage={page}
            resultsRange={limit}
            totalResults={adminsCount ?? 0}
            isFilterApplied={Object.keys(filterInput).length ? true : false}
            onPageClick={onPageChange}
          />
        )}
      </div>
    </div>
  );
});
