import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowCircleLeftIcon } from '@heroicons/react/outline';
import classNames from 'classnames';

type BackProps = {
  className?: string;
  returnLocation?: string;
};

export const Back = ({ className, returnLocation }: BackProps) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    if (returnLocation) {
      return navigate(returnLocation);
    }
    navigate(-1);
  };

  return (
    <div className={classNames('cursor-pointer w-auto', className)}>
      <ArrowCircleLeftIcon
        className="h-8 w-8 text-blue-600"
        onClick={handleGoBack}
      />
    </div>
  );
};
