/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';

import { Text } from 'components/Text';
import { withNotification } from 'components/Notification';
// import { LoadingScreen } from 'components/LoadingScreen';
import { NotificationTypes, apiErrorHandler } from 'utils';

import { GET_TRACKING_TEMPLATES } from 'gql/queries';
import { UPDATE_TRACKING_TEMPLATE, UPDATE_STORE } from 'gql/mutations';

import { useStore } from 'context/store-context';

import { EditEmailTemplateModal } from './EditEmailTemplateModal';
import { EmailNotificationTemplates } from './EmailNotificationTemplates';
import { SenderEmail } from './SenderEmail';
import {
  defaultEmailTemplates,
  templatesUsageTypes,
  defaultTrackingTemplate,
} from './data/templates-data';
import { resolveTemplates, sortByIsTrackingDefault } from './utils';

export const NotificationTemplates = withNotification(
  ({ showNotification }: any) => {
    const store = useStore();
    const { storeId } = store;

    const [currentTemplate, setCurrentTemplate] = useState<any | null>(null);
    const [templates, setTemplates] = useState<any>(null);
    const [templatesIntegrations, setTemplatesIntegrations] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const userDefaultTrackingTemplate =
      templates?.find((template: any) => template.isTrackingDefault) ??
      defaultTrackingTemplate;

    const [getTrackingTemplates] = useLazyQuery(GET_TRACKING_TEMPLATES, {
      onError(error) {
        const newError = apiErrorHandler(error);
        showNotification(NotificationTypes.error, newError?.message);
      },
      onCompleted(data) {
        const hasDefaultTemplate = data?.trackingTemplates?.some(
          (template: any) => template.isTrackingDefault,
        );
        setTemplates(
          hasDefaultTemplate
            ? (data?.trackingTemplates ?? []).sort(sortByIsTrackingDefault)
            : [userDefaultTrackingTemplate, ...data?.trackingTemplates],
        );
        setTemplatesIntegrations([]);
      },
    });

    const [updateTrackingTemplate] = useMutation(UPDATE_TRACKING_TEMPLATE, {
      onError(error) {
        const newError = apiErrorHandler(error);
        showNotification(NotificationTypes.error, newError?.message);
      },
      onCompleted(data) {
        setTemplates((templates: any) =>
          resolveTemplates(templates, data.saveTrackingTemplate),
        );
        showNotification(
          NotificationTypes.success,
          'Template Updated Successfully!',
        );
      },
    });

    const [updateStore] = useMutation(UPDATE_STORE, {
      onError(error) {
        const newError = apiErrorHandler(error);
        showNotification(NotificationTypes.error, newError?.message);
      },
    });

    const resolveDefaultsTemplates = (apiTemplates: any) => {
      return defaultEmailTemplates.map((defaultTemplate) => {
        const respectiveApiTemplate = apiTemplates.find((apiTemplate: any) => {
          return (
            apiTemplate.eventType === defaultTemplate.eventType &&
            apiTemplate.usage === defaultTemplate.usage
          );
        });

        return respectiveApiTemplate ?? defaultTemplate;
      });
    };

    const resolvedEmailTemplates = resolveDefaultsTemplates(templates ?? []);

    useEffect(() => {
      const getTemplates = async () => {
        try {
          if (storeId) {
            getTrackingTemplates({
              variables: {
                storeId,
              },
            });
          }
        } catch (e) {
          setTemplates(defaultEmailTemplates);
        }
      };

      getTemplates();
    }, []);

    useEffect(() => {
      if (
        store.storeProperties &&
        !store.storeProperties.completedSteps?.hasVisitedNotificationPage
      ) {
        updateStore({
          variables: {
            storeId,
            set: {
              completedSteps: {
                ...store.storeProperties.completedSteps,
                hasVisitedNotificationPage: true,
              },
            },
          },
        });
      }
    });

    const handleEditTemplate = (currTemplate: any) => {
      if (currTemplate.isTrackingDefault) {
        setCurrentTemplate(userDefaultTrackingTemplate);
        return;
      }

      setCurrentTemplate(
        (currTemplate.usage === templatesUsageTypes.emails
          ? resolvedEmailTemplates
          : templates
        )?.find(
          (template: any) =>
            template.eventType === currTemplate.eventType &&
            template.usage === currTemplate.usage,
        ) ?? defaultTrackingTemplate,
      );
    };

    const handleChangeActiveTemplate = async (template: any) => {
      if (!template) return;
      try {
        setIsLoading(true);
        await updateTrackingTemplate({
          variables: {
            input: {
              storeId,
              id: template.id,
              active: !template.active,
              eventType: template.eventType,
              platformIntegrationType: template.platformIntegrationType,
              subject: template.subject,
              templateHtml: template.templateHtml ?? '',
              templateJson: template.templateJson ?? '',
              usage: template.usage,
            },
          },
        });
        setIsLoading(false);
      } catch (e) {
        console.log('error', e);
      } finally {
        setIsLoading(false);
      }
    };

    const handleOnCloseModal = (updatedTemplate: any, info: any) => {
      if (info) {
        showNotification(
          info.success ? NotificationTypes.success : NotificationTypes.error,
          info.message,
        );

        if (info?.templateDesign) {
          setCurrentTemplate({
            ...currentTemplate,
            templateHtml: info.templateDesign.templateHtml,
            // templateJson: JSON.stringify(info.templateDesign.templateJson),
            templateJson: info.templateDesign.templateJson,
          });
        }
        if (!info?.updateTemplate) {
          return;
        }
      }
      setCurrentTemplate(null);
      if (!updatedTemplate) return;
      setTemplates((templates: any) =>
        resolveTemplates(templates, updatedTemplate),
      );
    };

    const renderEmailTemplates = () => {
      return (
        <EmailNotificationTemplates
          userTemplates={resolvedEmailTemplates}
          isLoading={isLoading}
          onChangeActiveTemplate={handleChangeActiveTemplate}
          onEditTemplate={handleEditTemplate}
        />
      );
    };

    return (
      <div>
        <SenderEmail />
        <div className="max-w-7xl mx-auto mb-12 px-4 sm:px-6 md:px-8 mt-12">
          <div className="flex items-center justify-between max-w-6xl">
            <div>
              <Text value="Customer Notifications" type="h1" />
            </div>
          </div>
        </div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          {renderEmailTemplates()}
        </div>
        <EditEmailTemplateModal
          template={currentTemplate}
          isOpen={!!currentTemplate}
          usageType={currentTemplate?.usage}
          templateIntegrations={templatesIntegrations}
          onClose={handleOnCloseModal}
          showNotification={showNotification}
          isLoading={null}
        />
      </div>
    );
  },
);
