import React from 'react';
import { SizeEnum } from 'utils';
import { Button } from '../components/Button';
import { Text } from '../components/Text';
import LostImg from '../assets/images/lost.svg';
import { useNavigate } from 'react-router';

export const NotFound = () => {
  const navigate = useNavigate();

  const onRefresh = () => {
    navigate('/');
  };

  return (
    <div className="w-full flex flex-col items-center mt-20">
      <img src={LostImg} alt="error" className="w-72 sm:w-96" />
      <Text
        value="Oops! Have you missed your way?"
        type="h1"
        className="mt-5 text-gray-800 text-center"
      />
      <Text
        value="Please click the button below to go back to the dashboard"
        type="sm"
        className="text-gray-500 w-72 sm:w-96 text-center mt-2"
      />
      <Button onClick={onRefresh} size={SizeEnum.md} className="w-fit mt-4">
        {'<'} Go back to dashboard
      </Button>
    </div>
  );
};
