import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { FilterIcon } from '@heroicons/react/solid';

type DataFilterProps = {
  filterBy: string;
  options: string[];
  setSelectedOption: (option: string) => void;
  selectedOption: string;
  wrapperClass?: string;
};

export const DataFilter = ({
  filterBy,
  options,
  setSelectedOption,
  selectedOption,
  wrapperClass,
}: DataFilterProps) => {
  return (
    <Menu
      as="div"
      className={classNames(
        'relative inline-block text-left mb-2',
        wrapperClass,
      )}
    >
      <div>
        <Menu.Button className="flex justify-evenly w-fit items-center capitalize rounded-md border border-gray-300 shadow-sm px-3 py-3 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-offset-gray-100">
          <FilterIcon
            className={classNames(
              selectedOption
                ? 'fill-blue-700 h-3 w-5 mr-1'
                : 'h-3 w-5 mr-1 stroke-gray-700',
            )}
            aria-hidden="true"
            fill={selectedOption ? 'gray' : 'none'}
            strokeWidth={selectedOption ? 'none' : 2}
          />
          {filterBy}
          <ChevronDownIcon
            className="ml-4 h-5 w-5 fill-gray-700"
            fill="gray"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
          <div className="py-1">
            {options.map((option) => (
              <Menu.Item key={option}>
                <p
                  onClick={() => setSelectedOption(option)}
                  className={classNames(
                    selectedOption === option.toLowerCase()
                      ? 'bg-gray-100 text-gray-900'
                      : 'text-gray-700',
                    'block px-4 py-2 text-sm hover:bg-gray-50',
                  )}
                >
                  {option}
                </p>
              </Menu.Item>
            ))}
            {selectedOption && (
              <Menu.Item>
                <p
                  onClick={() => setSelectedOption('')}
                  className={
                    'block px-4 py-2 text-xs text-blue-700 cursor-pointer'
                  }
                >
                  clear filter
                </p>
              </Menu.Item>
            )}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
