import React, { ChangeEvent } from 'react';

type Props = {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder: string | undefined;
  otherInfo?: React.ReactNode;
  value: string | number | readonly string[] | undefined;
  label: string;
  id: string;
  name: string;
  type: string;
};

function FormInput({
  onChange,
  placeholder,
  otherInfo,
  value,
  label,
  id,
  name,
  type = 'text',
}: Props) {
  let baseInputClass =
    'shadow w-full appearance-none h-12 transition-all  bg-blue-100 border rounded  py-2 px-3 text-gray-700 leading-tight focus:bg-transparent focus:outline-[#a279f9] focus:shadow-outline';
  return (
    <>
      <div className="flex items-center justify-between">
        <label
          htmlFor={id}
          className="block text-[rgba(0,0,0,.4)] text-sm font-[500]  mb-2"
        >
          {label}
        </label>

        {otherInfo && <>{otherInfo}</>}
      </div>

      <input
        type={type}
        id={id}
        value={value}
        name={name}
        onChange={onChange}
        className={baseInputClass}
        placeholder={placeholder}
      />
    </>
  );
}

export default FormInput;
