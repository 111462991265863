/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';

import { Text } from 'components/Text';
import { withNotification } from 'components/Notification';
import { NotificationTypes, apiErrorHandler } from 'utils';

import { GET_TRACKING_TEMPLATES } from 'gql/queries';
import { UPDATE_TRACKING_TEMPLATE, UPDATE_STORE } from 'gql/mutations';

import { useStore } from 'context/store-context';

import { CustomSubDomain } from './CustomSubDomain';
import { CoverImage } from './CoverImage';
import { TrackingTemplates } from './TrackingTemplates';
import { EditTemplateModal } from './EditTemplateModal';
import {
  defaultEmailTemplates,
  templatesUsageTypes,
  defaultTrackingTemplate,
} from './data/templates-data';
import { resolveTemplates, sortByIsTrackingDefault } from './utils';

export const TrackingPage = withNotification(({ showNotification }: any) => {
  const store = useStore();
  const { storeId } = store;
  const [currentTemplate, setCurrentTemplate] = useState<any | null>(null);
  const [templates, setTemplates] = useState<any>(null);
  const [templatesIntegrations, setTemplatesIntegrations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [getTrackingTemplates] = useLazyQuery(GET_TRACKING_TEMPLATES, {
    onError(error) {
      const newError = apiErrorHandler(error);
      showNotification(NotificationTypes.error, newError?.message);
    },
    onCompleted(data) {
      const hasDefaultTemplate = data?.trackingTemplates?.some(
        (template: any) => template.isTrackingDefault,
      );
      setTemplates(
        hasDefaultTemplate
          ? (data?.trackingTemplates ?? []).sort(sortByIsTrackingDefault)
          : [userDefaultTrackingTemplate, ...data?.trackingTemplates],
      );
      setTemplatesIntegrations([]);
    },
  });

  const [updateTrackingTemplate] = useMutation(UPDATE_TRACKING_TEMPLATE, {
    onError(error) {
      const newError = apiErrorHandler(error);
      showNotification(NotificationTypes.error, newError?.message);
    },
    onCompleted(data) {
      setTemplates((templates: any) =>
        resolveTemplates(templates, data.saveTrackingTemplate),
      );
      showNotification(
        NotificationTypes.success,
        'Template Updated Successfully!',
      );
    },
  });

  const [updateStore] = useMutation(UPDATE_STORE, {
    onError(error) {
      const newError = apiErrorHandler(error);
      showNotification(NotificationTypes.error, newError?.message);
    },
  });

  const resolveDefaultsTemplates = (apiTemplates: any) => {
    return defaultEmailTemplates.map((defaultTemplate) => {
      const respectiveApiTemplate = apiTemplates.find((apiTemplate: any) => {
        return (
          apiTemplate.eventType === defaultTemplate.eventType &&
          apiTemplate.usage === defaultTemplate.usage
        );
      });

      return respectiveApiTemplate ?? defaultTemplate;
    });
  };

  const resolvedEmailTemplates = resolveDefaultsTemplates(templates ?? []);

  const userDefaultTrackingTemplate =
    templates?.find((template: any) => template.isTrackingDefault) ??
    defaultTrackingTemplate;

  useEffect(() => {
    const getTemplates = async () => {
      try {
        if (storeId) {
          getTrackingTemplates({
            variables: {
              storeId,
            },
          });
        }
      } catch (e) {
        setTemplates(defaultEmailTemplates);
      }
    };

    getTemplates();
  }, []);

  useEffect(() => {
    if (
      store.storeProperties &&
      !store.storeProperties.completedSteps?.hasVisitedTrackingPage
    ) {
      updateStore({
        variables: {
          storeId,
          set: {
            completedSteps: {
              ...store.storeProperties.completedSteps,
              hasVisitedTrackingPage: true,
            },
          },
        },
      });
    }
  });

  const handleEditTemplate = (currTemplate: any) => {
    if (currTemplate.isTrackingDefault) {
      setCurrentTemplate(userDefaultTrackingTemplate);
      return;
    }

    setCurrentTemplate(
      (currTemplate.usage === templatesUsageTypes.emails
        ? resolvedEmailTemplates
        : templates
      )?.find(
        (template: any) =>
          template.eventType === currTemplate.eventType &&
          template.usage === currTemplate.usage,
      ) ?? defaultTrackingTemplate,
    );
  };

  const handleChangeActiveTemplate = async (template: any) => {
    if (!template) return;
    try {
      setIsLoading(true);
      await updateTrackingTemplate({
        variables: {
          input: {
            storeId,
            id: template.id,
            active: !template.active,
            eventType: template.eventType,
            platformIntegrationType: template.platformIntegrationType,
            subject: template.subject,
            templateHtml: template.templateHtml ?? '',
            templateJson: template.templateJson ?? '',
            usage: template.usage,
          },
        },
      });
      setIsLoading(false);
    } catch (e) {
      console.log('error', e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNewTrackingTemplate = () => {
    setCurrentTemplate({
      ...userDefaultTrackingTemplate,
      isTrackingDefault: false,
    });
  };

  const handleOnCloseModal = (updatedTemplate: any, info: any) => {
    if (info) {
      showNotification(
        info.success ? NotificationTypes.success : NotificationTypes.error,
        info.message,
      );

      if (info?.templateDesign) {
        setCurrentTemplate({
          ...currentTemplate,
          templateHtml: info.templateDesign.templateHtml,
          templateJson: info.templateDesign.templateJson,
        });
      }
      if (!info?.updateTemplate) {
        return;
      }
    }
    setCurrentTemplate(null);
    if (!updatedTemplate) return;
    setTemplates((templates: any) =>
      resolveTemplates(templates, updatedTemplate),
    );
  };

  return (
    <div>
      <CustomSubDomain />
      <CoverImage />
      <div className="max-w-7xl mx-auto mb-12 px-4 sm:px-6 md:px-8 mt-12">
        <div>
          <Text value="Tracking Templates" type="h1" />
        </div>
        <div>
          <Text
            value="You can build multiple brand tracking page and manage their look and feel."
            type="body"
            className="mt-8 mb-8 text-gray-500"
          />
        </div>
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <TrackingTemplates
          userTemplates={
            templates?.length
              ? templates.filter(
                  (template: any) =>
                    template.usage === templatesUsageTypes.tracking,
                )
              : []
          }
          onNewTemplate={handleNewTrackingTemplate}
          isLoading={isLoading}
          onChangeActiveTemplate={handleChangeActiveTemplate}
          onEditTemplate={handleEditTemplate}
          onCloseModal={handleOnCloseModal}
        />
      </div>
      <EditTemplateModal
        template={currentTemplate}
        isOpen={!!currentTemplate}
        usageType={currentTemplate?.usage}
        userTemplates={templates}
        templateIntegrations={templatesIntegrations}
        onClose={handleOnCloseModal}
        isLoading={null}
      />
    </div>
  );
});
