import React, { useEffect, useState } from 'react';
import { Text } from 'components/Text';
import { Button } from 'components/Button';
import { DataTable } from 'components/DataTable';
import { LoadingScreen } from 'components/LoadingScreen';
import { Dropdown } from 'components/Dropdown';
import { apiErrorHandler, SizeEnum } from 'utils';
import { columns } from './data/teams-data';
import { useMutation } from '@apollo/client';
import { useStore } from 'context/store-context';
import { useUser } from 'context/user-context';
import { Modal } from 'components/Modal';
import { INVITE_STORE_USER, REMOVE_STORE_USER } from 'gql/mutations';
import { withNotification } from 'components/Notification';
import { NotificationTypes } from 'utils';
import { User, userRoles } from 'types/user';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { useNavigate } from 'react-router';

export const Team = withNotification(({ showNotification }: any) => {
  const store = useStore();
  const user = useUser();
  const navigate = useNavigate();
  const { storeId } = store;
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any | null>(null);
  const [inviteData, setInviteData] = useState({
    email: '',
    role: '',
  });

  const [inviteStoreUser, { error: inviteError, loading: inviteLoading }] =
    useMutation(INVITE_STORE_USER, {
      onError(error) {
        const newError = apiErrorHandler(error);
        showNotification(NotificationTypes.error, newError?.message);
      },
    });

  const [
    removeStoreUser,
    { error: removeStoreError, loading: removeStoreLoading },
  ] = useMutation(REMOVE_STORE_USER, {
    onError(error) {
      const newError = apiErrorHandler(error);
      showNotification(NotificationTypes.error, newError?.message);
    },
  });

  useEffect(() => {
    if (selectedUser) {
      setShowConfirmationModal(true);
    }
  }, [selectedUser]);

  useEffect(() => {
    if (!showConfirmationModal) {
      setSelectedUser(null);
    }
  }, [showConfirmationModal]);

  const handleChangeInvite = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setInviteData({ ...inviteData, [name]: value });
  };

  const handleSendinvite = () => {
    const inviteObj = {
      role: inviteData.role,
      email: inviteData.email,
      storeId,
    };
    if (inviteData.role === '' || inviteData.email === '') {
      return showNotification(
        NotificationTypes.error,
        'Please add a role and an email',
      );
    }
    inviteStoreUser({ variables: { inviteObj } })
      .then((data) => {
        setShowModal(false);
        setInviteData({ email: '', role: '' });
        return showNotification(
          NotificationTypes.success,
          'Invite successfully sent',
        );
      })
      .catch((err) => {
        return showNotification(
          NotificationTypes.error,
          inviteError ? inviteError.message : 'Invite not sent',
        );
      });
  };

  const onRemoveUser = (user: User) => {
    setSelectedUser(user);
  };

  const handleRemoveUser = () => {
    removeStoreUser({ variables: { storeId, userId: selectedUser?.user?.id } })
      .then((data) => {
        setShowConfirmationModal(false);
        setSelectedUser(null);
        store.refetchStoreProperties && store.refetchStoreProperties();
        return showNotification(
          NotificationTypes.success,
          'User successfully removed',
        );
      })
      .catch((err) => {
        return showNotification(
          NotificationTypes.error,
          removeStoreError ? removeStoreError.message : 'Could not remove user',
        );
      });
  };

  if (store?.storeLoading && !store?.storeProperties) return <LoadingScreen />;

  return (
    <div>
      <ConfirmationModal
        showModal={showConfirmationModal}
        setShowModal={(value) => setShowConfirmationModal(value)}
        message={`Are you sure you want to remove ${selectedUser?.user?.displayName}?`}
        handleConfirm={handleRemoveUser}
        btnText="Remove user"
        mainBtnClassname="text-white"
        type={NotificationTypes.error}
        isLoading={removeStoreLoading}
      />
      <Modal
        openModal={(value) => setShowModal(value)}
        open={showModal}
        containerClass="sm:max-w-lg w-full sm:w-auto p-5"
      >
        <Text value="Invite a user" type="h2" />
        <div className="mt-4">
          <input
            type="email"
            name="email"
            id="email"
            className="border border-gray-300 p-2 w-full sm:w-64 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm rounded-md"
            placeholder="enter user's email e.g.you@example.com"
            value={inviteData.email}
            onChange={handleChangeInvite}
          />
        </div>
        <div>
          <Dropdown
            options={['Admin', 'Manager', 'Viewer'].map((role) =>
              role.toUpperCase(),
            )}
            onSelectOption={(option) =>
              setInviteData({ ...inviteData, role: option })
            }
            selectedOption={inviteData.role}
            downCaret
            placeholder="Select A role"
            btnClassName="bg-gray-50 hover:bg-gray-100 mt-3 w-full sm:w-64 sm:flex sm:justify-between px-4"
          />
        </div>
        <div></div>
        <Button
          onClick={handleSendinvite}
          size={SizeEnum.md}
          className="bg-blue-600 text-white hover:bg-blue-700 h-max px-6 mt-4 sm:w-64 w-full justify-center"
          isLoading={inviteLoading}
        >
          Invite
        </Button>
      </Modal>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <Text value="Team" type="h1" />
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-3">
        <div className="flex justify-between mt-9 flex-wrap">
          <Text
            value={`People (${store.storeProperties?.users.length ?? 0})`}
            type="h3"
            className="text-gray-500"
          />
          <div className="mt-3 sm:mt-0">
            {user.role === 'ADMIN' && (
              <>
                <Button
                  onClick={() => setShowModal(true)}
                  size={SizeEnum.md}
                  className="text-white bg-blue-600 hover:bg-blue-700 h-max px-6"
                >
                  Invite People
                </Button>
                <Button
                  onClick={() => navigate('/settings/team/invites')}
                  size={SizeEnum.md}
                  className="text-blue-600 bg-transparent border-blue-600 h-max px-6 ml-4 hover:bg-transparent"
                >
                  View invites
                </Button>
              </>
            )}
          </div>
        </div>
        <div className="mt-5">
          {store.storeProperties?.users && (
            <DataTable
              data={[...store.storeProperties?.users].map((person: User) => ({
                ...person,
                userEmail: user.user?.email,
                isAdmin: [userRoles.SHIPAID_ADMIN, userRoles.ADMIN].includes(
                  user.role as userRoles,
                ),
                onRemoveUser: () => onRemoveUser(person),
              }))}
              columns={columns} //work on not showing the remove button for other users
            />
          )}
        </div>
      </div>
    </div>
  );
});
