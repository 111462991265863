import React, { useEffect, useState } from 'react';
import {
  useUserData,
  useResetPassword,
  useChangeEmail,
  useChangePassword,
} from '@nhost/react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { User, UserContextInterface } from 'types/user';
import { GET_ACTIVE_ANNOUNCEMENTS, GET_USER_ROLE } from 'gql/queries';
import { useStore } from './store-context';
import { isShipaidAdmin } from 'utils';
import { Announcement } from 'types/announcement';

const UserContext = React.createContext<UserContextInterface>({});

const UserProvider = (props: any) => {
  const adminData = localStorage.getItem(
    process.env.REACT_APP_ADMIN_STORAGE_KEY || 'admin-context',
  );
  let adminContext: any;
  if (adminData) adminContext = JSON.parse(adminData);

  const [user, setUser] = useState<User | null>(useUserData());
  const [announcement, setAnnouncement] = useState<Announcement | null>(null);
  const resetPassword = useResetPassword();
  const changeEmail = useChangeEmail();
  const changePassword = useChangePassword();
  const isUserShipaidAdmin = isShipaidAdmin(user);

  const [getUserRole, { data: userRoleData }] = useLazyQuery(GET_USER_ROLE);
  const { storeId } = useStore();

  useQuery(GET_ACTIVE_ANNOUNCEMENTS, {
    onCompleted: ({ announcements }) => {
      setAnnouncement(announcements[0]);
    },
  });

  useEffect(() => {
    if (user && storeId && !isUserShipaidAdmin) {
      getUserRole({
        variables: { userId: user?.id, storeId },
      });
    }
  }, [user, getUserRole, storeId, isUserShipaidAdmin]);

  if (adminContext?.admin?.user?.user?.id && adminContext?.userContextData) {
    return (
      <UserContext.Provider
        value={{
          ...adminContext.userContextData,
          setUser,
        }}
        {...props}
      />
    );
  }

  return (
    <UserContext.Provider
      value={{
        user,
        role: userRoleData?.storeRoles[0]?.role,
        resetPassword,
        changePassword,
        changeEmail,
        setUser,
        announcement,
      }}
      {...props}
    />
  );
};

function useUser() {
  const context = React.useContext(UserContext);

  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider`);
  }
  return context;
}

export { UserProvider, useUser };
