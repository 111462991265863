import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Modal } from 'components/Modal';
import { Text } from 'components/Text';
import { XIcon } from '@heroicons/react/outline';
import { Button } from './Button';
import { GENERATE_INSTALL_LINK } from 'gql/mutations';
import { useStore } from 'context/store-context';

export const UpdateAppInstallModal = ({
  isOpen,
  onClose,
}: {
  isOpen: null | boolean;
  onClose: () => void;
}) => {
  const [error, setError] = useState('');
  const [generateInstallLink, { loading }] = useMutation(
    GENERATE_INSTALL_LINK,
    {
      onCompleted: (data) => {
        window.open(data?.generateInstallLink, '_blank');
      },
      onError: () => {
        setError('An error occured. Please try again or contact support');
      },
    },
  );

  const { storeDomain } = useStore();

  return (
    <Modal
      openModal={() => {}}
      open={Boolean(isOpen)}
      containerClass="sm:max-w-lg sm:w-full shadow-none"
      overlayClassName="bg-opacity-100 bg-white"
      isFull
    >
      <XIcon
        onClick={() => onClose()}
        className="w-5 h-5 absolute right-0 cursor-pointer"
        aria-hidden="true"
      />
      <Text
        value="Please update your store permissions"
        className="text-center"
        type="h4"
      />

      <div className="text-gray-500">
        <Text
          className="my-4 text-center"
          value={`Click the link below to update the ShipAid app permissions on Shopify.`}
        />
        <Button
          isLoading={loading}
          onClick={() =>
            generateInstallLink({
              variables: {
                store: storeDomain,
              },
            })
          }
          className="bg-blue-600 hover:bg-blue-700 w-full justify-center"
        >
          Update Permissions
        </Button>
        <Text
          value={error}
          className="text-red-500 text-center mt-2"
          type="xs"
        />
      </div>
    </Modal>
  );
};
