/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import { startCase } from 'lodash';
import React, { useCallback, useEffect } from 'react';

import UnlayerEditor from '../UnlayerEditor';

const EmailEditorComponent = React.memo(
  React.forwardRef(
    (
      {
        customJS = [],
        displayMode = 'email',
        tools,
        specialLinks = [],
        onReady,
        onChange,
        templateJson,
        mergeTags = null,
        style = {},
      },
      ref,
    ) => {
      const handleOnChange = useCallback(() => {
        if (ref.current && ref.current?.editor) {
          ref.current.editor?.exportHtml((data) => {
            const { html, design } = data;
            onChange?.(html, design);
          });
        }
      }, [ref, onChange]);

      const handleOnReady = useCallback(() => {
        if (ref.current && ref.current?.editor) {
          if (templateJson) {
            ref.current.editor?.loadDesign(templateJson);
          }
          onReady?.();
          ref.current.editor?.addEventListener(
            'design:updated',
            handleOnChange,
          );
        }
      }, [ref, templateJson, onReady, handleOnChange]);

      useEffect(() => {
        if (ref.current && ref.current?.editor) {
          handleOnReady();

          return () => {
            ref.current?.editor?.removeEventListener(
              'design:updated',
              handleOnChange,
            );
          };
        }
      }, [ref, handleOnReady, handleOnChange]);

      return (
        <UnlayerEditor
          ref={ref}
          style={style}
          projectId={Number(process.env.REACT_APP_UNLAYER_PROJECT_ID)}
          onReady={handleOnReady}
          tools={tools}
          options={{
            customJS,
            displayMode,
            specialLinks,
            mergeTags: mergeTags?.reduce(
              (acc, a) => ({
                ...acc,
                [a]: {
                  name: startCase(a),
                  value: `{{ ${a} }}`,
                },
              }),
              {},
            ),
          }}
        />
      );
    },
  ),
);

export default EmailEditorComponent;
