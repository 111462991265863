import React from 'react';
import { Text } from 'components/Text';
import { statusType } from 'types/claim';
import { getStoreCurrencyFormatter } from 'utils';

export const columns = [
  {
    name: 'Issue Date',
    id: 2,
    render: (item: any) => (
      <Text value={new Date(item.createdAt).toLocaleDateString()} />
    ),
    value: 'createdAt',
  },
  {
    name: 'Order Number',
    id: 3,
    key: 'order_no',
    render: (item: any) => <Text value={`${item.order.number}`} />,
    value: 'order.number',
  },
  {
    name: 'Tracking',
    id: 4,
    render: (item: any) => {
      const trackingIds = (item?.order?.items ?? [])
        .filter(
          (i: { trackingId: string }) => i.trackingId && i.trackingId !== '',
        )
        .slice(0, 1);
      if (trackingIds.length) {
        const trackingId = trackingIds[0].trackingId;
        return (
          <span key={trackingId}>
            <a
              href={`${process.env.REACT_APP_TRACKING_PORTAL_URL}/${trackingId}`}
              target="_blank"
              rel="noreferrer"
            >
              <Text
                value={'Track'}
                type="body"
                className="text-blue-500 underline"
              />
            </a>
          </span>
        );
      }
      return <Text value={'N/A'} />;
    },
    value: 'order.tracking',
  },
  {
    name: 'Reason',
    id: 5,
    key: 'reason',
    value: 'reason',
  },
  {
    name: 'Issue Total',
    id: 6,
    render: (item: any) => (
      <Text value={`${getStoreCurrencyFormatter(item.currency, item.total)}`} />
    ),
    value: 'total',
  },
  {
    name: 'Customer Name',
    id: 7,
    render: (item: any) => <Text value={`${item.order.customerName}`} />,
    value: 'order.customerName',
  },
  {
    name: 'Customer Email',
    id: 8,
    render: (item: any) => (
      <Text value={`${item.order.customerEmail ?? 'N/A'}`} />
    ),
  },
  {
    name: 'Guarantee Status',
    id: 9,
    render: (item: any) => {
      if (item.order?.protectionStatus.toLowerCase() === 'protected')
        return (
          <Text
            value="Guaranteed"
            className="bg-green-100 px-3 py-1 rounded-full text-green-700 text-center"
            type="xs"
          />
        );
      if (item.order?.protectionStatus.toLowerCase() === 'unprotected')
        return (
          <Text
            value="Not Guaranteed"
            className="bg-red-100 px-3 py-1 rounded-full text-red-700 text-center"
            type="xs"
          />
        );
      return (
        <Text
          value={`${item.order?.protectionStatus?.replace('Claim', 'Issue')}`}
          className="bg-blue-100 px-3 py-1 rounded-full text-blue-700 text-center"
          type="xs"
        />
      );
    },
    value: 'order.protectionStatus',
  },
  {
    name: 'Guarantee Paid',
    id: 10,
    render: (item: any) => (
      <Text
        value={`${getStoreCurrencyFormatter(
          item.currency,
          item.order.protectionTotal,
        )}`}
      />
    ),
    value: 'order.protectionTotal',
  },
  {
    name: 'Issue Status',
    id: 11,
    render: (item: any) => {
      if (item.status === statusType.new)
        return (
          <Text
            value={`${item.status?.replace('_', ' ')}`}
            className="bg-green-100 px-3 py-1 rounded-full text-green-700 text-center"
            type="xs"
          />
        );
      if (item.status === statusType.denied)
        return (
          <Text
            value={`${item.status?.replace('_', ' ')}`}
            className="bg-red-100 px-3 py-1 rounded-full text-red-700 text-center"
            type="xs"
          />
        );
      if (item.status === statusType.resolved)
        return (
          <Text
            value={`${item.status?.replace('_', ' ')}`}
            className="bg-blue-100 px-3 py-1 rounded-full text-blue-700 text-center"
            type="xs"
          />
        );
      return (
        <Text
          value={`${item.status?.replace('_', ' ')}`}
          className="bg-yellow-100 px-3 py-1 rounded-full text-yellow-700 text-center"
          type="xs"
        />
      );
    },
    value: 'status',
  },
  {
    name: 'Order Date',
    id: 12,
    key: 'order_date',
    render: (item: any) => (
      <Text value={`${new Date(item.order.createdAt).toLocaleDateString()}`} />
    ),
    value: 'order.createdAt',
  },
  {
    name: 'Resolution',
    id: 13,
    render: (item: any) => (
      <Text value={`${item.resolution?.replace('_', ' ')}`} />
    ),
    value: 'resolution',
  },
];

export const stats = [
  {
    name: 'New Claims',
    currentStat: '70',
    previousStat: '60',
    change: '16',
    changeType: 'increase',
  },
  {
    name: 'Resoved Claims',
    currentStat: '60',
    previousStat: '70',
    change: '16',
    changeType: 'decrease',
  },
  {
    name: 'Total Claims',
    currentStat: '100',
    previousStat: '50',
    change: '50',
    changeType: 'increase',
  },
];

export const claimItem = {
  imageSrc:
    'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
  title: 'Melonade',
  updatedAt: 'Thu, March 3, 2022',
  quantity: 4,
};
