import React, { Fragment } from 'react';
import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { Text } from 'components/Text';
import { Link } from 'react-router-dom';

type Stat = {
  id?: number;
  name?: string;
  currentStat?: string | number;
  previousStat?: string | number;
  change?: string;
  changeType?: string;
  icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  link?: {
    text: string;
    url: string;
    className?: string;
  };
};

type StatsProps = {
  isSpaced?: boolean;
  containerClassName?: string;
  cardClassName?: string;
  stats: Stat[];
};

export const Stats = ({
  isSpaced,
  containerClassName,
  cardClassName,
  stats,
}: StatsProps) => {
  let outerClassName =
    'mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-3 md:divide-y-0 md:divide-x';
  let innerClassName =
    'relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden';
  if (isSpaced) {
    outerClassName = 'mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3';
    innerClassName =
      'p-10 bg-white shadow rounded-lg overflow-hidden relative border-l-8 border-l-gray-100';
  }
  if (containerClassName) {
    outerClassName = classNames(outerClassName, containerClassName);
  }
  if (cardClassName) {
    innerClassName = classNames(innerClassName, cardClassName);
  }
  return (
    <Fragment>
      <dl className={outerClassName}>
        {stats.map((item) => (
          <div key={item.id || item.name} className={innerClassName}>
            {item.icon && (
              <dt>
                <div className="absolute bg-blue-500 rounded-md p-3">
                  <item.icon
                    className="h-7 w-7 text-white"
                    aria-hidden="true"
                  />
                </div>
                {item.name && (
                  <Text
                    value={item.name}
                    className="ml-16 text-gray-500 truncate"
                  />
                )}
              </dt>
            )}

            {!item.icon && item.name && (
              <Text value={item.name} className="text-gray-500 truncate" />
            )}

            <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
              {item.currentStat && (
                <div
                  className={classNames(
                    'flex items-baseline',
                    item.icon && 'ml-14 pl-2',
                  )}
                >
                  <Text
                    value={item.currentStat}
                    type="h2"
                    className="text-blue-600"
                  />
                  {item.previousStat && (
                    <span className="ml-2 text-sm font-medium text-gray-500">
                      from {item.previousStat}
                    </span>
                  )}
                </div>
              )}

              {item.changeType && item.change && (
                <div
                  className={classNames(
                    item.changeType === 'increase'
                      ? 'bg-green-100 text-green-800'
                      : 'bg-red-100 text-red-800',
                    'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0',
                    item.link && 'mb-5',
                  )}
                >
                  {item.changeType === 'increase' ? (
                    <ArrowSmUpIcon
                      className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                      aria-hidden="true"
                    />
                  ) : (
                    <ArrowSmDownIcon
                      className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                      aria-hidden="true"
                    />
                  )}
                  <span className="sr-only">
                    {item.changeType === 'increase' ? 'Increased' : 'Decreased'}{' '}
                    by
                  </span>
                  {item.change}%
                </div>
              )}

              {item.link && (
                <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                  <div className={classNames('text-sm', item.link.className)}>
                    <Link
                      to={item.link.url}
                      className="font-medium text-blue-600 hover:text-blue-500"
                    >
                      {item.link.text}
                    </Link>
                  </div>
                </div>
              )}
            </dd>
          </div>
        ))}
      </dl>
    </Fragment>
  );
};
