/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Modal } from 'components/Modal';
import { Text } from 'components/Text';
import { Button } from 'components/Button';

import {
  customEmailTemplateIntegrationTypes,
  customEmailTemplateTypes,
  templatesUsageTypes,
} from './data/templates-data';
import { useTemplateEmails } from '../../../hooks/useTemplateEmails';
import EditorField from './EditorField';

export const EditEmailTemplateModal = ({
  isOpen,
  onClose,
  isLoading,
  template,
  usageType,
  templateIntegrations,
  showNotification,
}) => {
  const {
    resetTemplate,
    submitTemplate,
    emailEditorRef,
    sendTestEmail,
    loading,
  } = useTemplateEmails({
    callback: onClose,
  });

  const [subject, setSubject] = useState(template?.subject ?? null);
  const [isTemplateReady, setIsTemplateReady] = useState(false);

  const emailTypes = Object.values(customEmailTemplateTypes);

  const templateValues = {
    subject: subject ?? template?.subject ?? '',
    id: template?.id,
    eventType: template?.eventType,
    usage: template?.usage,
    platformIntegrationType:
      template?.platformIntegrationType ??
      customEmailTemplateIntegrationTypes.custom,
    integrationTemplateId: template?.integrationTemplateId,
    // templateJson: JSON.parse(template?.templateJson?.trim?.() ?? null),
    templateJson: template?.templateJson,
    templateHtml: template?.templateHtml,
    active: template?.active,
  };

  const renderCustomEditor = (values) => {
    return (
      <EditorField
        platformIntegrationType={values?.platformIntegrationType}
        values={values}
        ref={emailEditorRef}
        setIsTemplateReady={setIsTemplateReady}
        template={template}
      />
    );
  };

  return (
    <Modal
      openModal={(value) => onClose(value)}
      open={isOpen}
      containerClass="sm:max-w-lg sm:w-full"
      customStyle={{ maxWidth: '90rem' }}
    >
      <div className="md:flex justify-between items-center">
        <div className="md:flex items-center">
          <Text
            value={`Template: Order ${
              emailTypes.find((et) => et.key === template?.eventType)?.label ??
              ''
            }`}
            type="h4"
          />
        </div>
        <div className="flex justify-end">
          <Button
            onClick={() => {
              setSubject(null);
              onClose(false);
            }}
            className="bg-red-500 mr-3 hover:bg-red-700"
          >
            <Text value="Close" />
          </Button>
          <Button
            onClick={() =>
              resetTemplate(template.eventType, template.usage, template)
            }
            className="bg-blue-600 mr-3 hover:bg-blue-700"
            isLoading={isLoading}
            disabled={loading || isLoading || !isTemplateReady}
          >
            Reset
          </Button>
          <Button
            onClick={() => sendTestEmail({ values: templateValues })}
            className="bg-blue-600 mr-3 hover:bg-blue-700"
            isLoading={isLoading}
            disabled={loading || isLoading || !isTemplateReady}
          >
            Send Test Email
          </Button>
          <Button
            onClick={() => {
              submitTemplate({
                values: {
                  ...templateValues,
                  subject: subject ? subject : templateValues.subject,
                },
              });
              setSubject(null);
            }}
            className="bg-blue-600 mr-12 hover:bg-blue-700"
            isLoading={isLoading}
            disabled={loading || isLoading || !isTemplateReady}
          >
            Save Changes
          </Button>
        </div>
      </div>
      {templatesUsageTypes.emails === usageType ? (
        <div className="flex mt-3">
          <Text
            value="Subject: "
            type="bold"
            className="text-gray-700 mr-3 mb-1 mt-1"
          />
          <input
            name="subject"
            className="border border-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md p-2"
            placeholder="Subject"
            value={templateValues.subject}
            style={{ width: '100%' }}
            onChange={(e) => setSubject(e.target.value ?? '')}
          />
        </div>
      ) : null}

      {renderCustomEditor(templateValues)}
    </Modal>
  );
};
