import React, { useMemo } from 'react';
import { Text } from 'components/Text';
import { XCircleIcon } from '@heroicons/react/outline';
import { OrderItemValues } from 'types/order';
import { truncateString, getStoreCurrencyFormatter } from 'utils';
import classNames from 'classnames';
import { useStore } from 'context/store-context';
import { ClaimItemValues } from 'types/claim';
import { Dropdown } from './Dropdown';

type OrderItemProps = {
  order: OrderItemValues | undefined;
  staticClaimItems?: ClaimItemValues[];
  claimItem?: ClaimItemValues;
  onRemoveItem: () => void;
  showIcon?: boolean;
  showSku?: boolean;
  isDisplay?: boolean;
  resolution?: boolean;
  containerClassName?: string;
  onHandleQuantityUpdate?: (value: string) => void;
  index?: number;
  shouldUseNumberInputType?: boolean;
};

export const OrderItem = ({
  order,
  onRemoveItem,
  showIcon,
  containerClassName,
  showSku,
  onHandleQuantityUpdate,
  staticClaimItems,
  index = 0,
  isDisplay,
  claimItem,
  resolution,
  shouldUseNumberInputType = false,
}: OrderItemProps) => {
  const { storeProperties } = useStore();

  const staticClaimItem = staticClaimItems?.find(
    (ci) => ci.id === claimItem?.id,
  );

  const maxAllowedQuantity = useMemo(() => {
    if (!(staticClaimItem && staticClaimItem.quantity)) return 0;

    if (staticClaimItem.settlement) {
      return staticClaimItem.quantity - staticClaimItem.settlement.quantity;
    }
    return (
      staticClaimItem?.quantity ?? staticClaimItem?.orderItem?.quantity ?? 0
    );
  }, [staticClaimItem]);

  const handleInputQuantity = (option: string) => {
    if (Number(option) > maxAllowedQuantity) return;
    onHandleQuantityUpdate && onHandleQuantityUpdate(option);
  };

  const handleDropDownQuantity = (option: string) => {
    // const { target } = e;
    onHandleQuantityUpdate && onHandleQuantityUpdate(option);
  };
  const quantityValue = staticClaimItems
    ? staticClaimItems.find((item) => item.id === claimItem?.id)?.quantity
    : 0;

  const getQuantityArrValues = () => {
    return staticClaimItems && staticClaimItems[index].quantity
      ? Array.from({ length: Number(quantityValue) }, (v, i) => `${i + 1}`)
      : [];
  };

  const claimQuantity = claimItem?.settlement
    ? claimItem?.settlement?.quantity
    : claimItem?.quantity ?? 0;

  return (
    <div
      className={classNames(
        'flex flex-row justify-between items-top sm:items-center border-b sm:p-3 py-3 flex-wrap',
        containerClassName,
      )}
    >
      <div className="flex basis-1/3 sm:basis-1/3 items-center">
        <img
          className="h-14 w-14 sm:h-20 sm:w-20 rounded object-cover"
          src={order?.imageSrc}
          alt="order"
        />
        <div className="ml-3 sm:ml-5">
          <span className="text-gray-600 font-bold text-sm sm:text-base">
            {order ? truncateString(order?.title, 25) : ''}
          </span>
          {showSku && order?.sku && (
            <span className="text-gray-400 font-normal ">
              {truncateString(order?.sku, 25)}
            </span>
          )}
        </div>
      </div>
      <div className="basis-1/3 sm:justify-center sm:basis-1/3 flex">
        {shouldUseNumberInputType ? (
          <input
            type="number"
            name="amount"
            className="border border-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md p-2 w-14 opacity-1"
            placeholder={''}
            onChange={(e) => handleInputQuantity(e.target.value)}
            max={maxAllowedQuantity}
            min={1}
            defaultValue={claimQuantity}
          />
        ) : (
          <Dropdown
            options={getQuantityArrValues()}
            onSelectOption={(option) => handleDropDownQuantity(option)}
            selectedOption={`${
              resolution ? claimItem?.settlement?.quantity : claimItem?.quantity
            }`}
            btnClassName="py-2 px-3 border-gray-300 border"
            placeholder="select quantity"
            downCaret
            disabled={isDisplay}
          />
        )}
      </div>

      <div className="flex justify-between basis-1/3 sm:basis-1/3 items-center">
        {/* <div className="basis-1/4 sm:basis-2/4 sm:ml-5">
         
        </div> */}
        <div className="basis-1/4 sm:basis-2/4">
          {(order?.discountTotal ?? 0) > 0 ? (
            <Text
              value={`${claimQuantity} x ${getStoreCurrencyFormatter(
                storeProperties?.currency,
                Number(order?.price ?? 0),
              )}`}
              type="body"
              className="text-gray-500 line-through"
            />
          ) : null}
          <Text
            value={`${claimQuantity} x ${getStoreCurrencyFormatter(
              storeProperties?.currency,
              Number(
                (order?.price ?? 0) -
                  (order?.discountTotal ?? 0) / (order?.quantity ?? 1),
              ),
            )}`}
            type="body"
          />
          <Text
            value={`${getStoreCurrencyFormatter(
              storeProperties?.currency,
              order
                ? Number(
                    Number(claimQuantity) *
                      Number(
                        (order?.price ?? 0) -
                          (order?.discountTotal ?? 0) / (order?.quantity ?? 1),
                      ),
                  )
                : 0,
            )}`}
            type="bold"
          />
        </div>
        {showIcon && (
          <div>
            <XCircleIcon
              onClick={onRemoveItem}
              className="w-5 h-5 text-red-500 cursor-pointer ml-3"
            />
          </div>
        )}
      </div>
    </div>
  );
};
