import { useState, useEffect } from 'react';

const useGodUser = () => {
  const [isGodUser, setIsGodUser] = useState(false);

  useEffect(() => {
    const checkGodUser = () => {
      const adminData = localStorage.getItem(
        process.env.REACT_APP_ADMIN_STORAGE_KEY || 'admin-context',
      );
      if (adminData) {
        const adminContext = JSON.parse(adminData);
        setIsGodUser(!!adminContext?.admin?.user?.user?.id);
      } else {
        setIsGodUser(false);
      }
    };

    checkGodUser();
    window.addEventListener('storage', checkGodUser);

    return () => {
      window.removeEventListener('storage', checkGodUser);
    };
  }, []);

  return isGodUser;
};

export default useGodUser;
