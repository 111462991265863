import React from 'react';
import { Modal } from 'components/Modal';
import { Text } from 'components/Text';
import { Button } from 'components/Button';

type BrandEmailModalProps = {
  show: boolean;
  setShowModal: (value: boolean) => void;
  brandEmailInput: {
    id: string | null;
    senderName: string;
    senderEmail: string;
    replyToName: string;
    replyToEmail: string;
  };
  brandEmailVerification: any;
  setInput: (field: string, value: string) => void;
  onVerifyEmails: () => void;
  handleSave: () => void;
  handleDelete: () => void;
};

export const BrandEmailModal = ({
  show,
  setShowModal,
  brandEmailInput,
  brandEmailVerification,
  setInput,
  onVerifyEmails,
  handleSave,
  handleDelete,
}: BrandEmailModalProps) => {
  return (
    <Modal
      openModal={(value) => setShowModal(value)}
      open={show}
      containerClass="sm:max-w-lg sm:w-full"
    >
      <Text value="Setup Sender Email" type="h4" />
      <div className="items-center mt-3">
        <Text
          value="Sender Name and Email will be displayed in the From field of your recipient's email client"
          type="sm"
          className="text-gray-800 mr-3 mb-1"
        />
        <Text
          value="Sender Name"
          type="sm"
          className="text-gray-500 mr-3 mb-1 mt-3"
        />
        <input
          name="senderName"
          className="border border-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md p-2"
          value={brandEmailInput.senderName}
          onChange={(e) => setInput('senderName', e.target.value)}
        />
      </div>
      <div className="items-center mt-3">
        <Text
          value="Sender Email"
          type="sm"
          className="text-gray-500 mr-3 mb-1"
        />
        <input
          name="senderEmail"
          className="border border-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md p-2"
          value={brandEmailInput.senderEmail}
          onChange={(e) => setInput('senderEmail', e.target.value)}
        />
      </div>
      <div className="items-center mt-8">
        <Text
          value="Reply To Name and Email will be used when the recipient replies to your email"
          type="sm"
          className="text-gray-800 mr-3 mb-1"
        />
        <Text
          value="Reply To Name"
          type="sm"
          className="text-gray-500 mr-3 mb-1 mt-3"
        />
        <input
          name="replyToName"
          className="border border-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md p-2"
          value={brandEmailInput.replyToName}
          onChange={(e) => setInput('replyToName', e.target.value)}
        />
      </div>
      <div className="items-center mt-3">
        <Text
          value="Reply To Email"
          type="sm"
          className="text-gray-500 mr-3 mb-1"
        />
        <input
          name="replyToEmail"
          className="border border-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md p-2"
          value={brandEmailInput.replyToEmail}
          onChange={(e) => setInput('replyToEmail', e.target.value)}
        />
      </div>
      <div className="items-center mt-3">
        <Text
          value={`${
            brandEmailVerification?.validations?.senderEmail?.message ?? ''
          } ${
            brandEmailVerification?.validations?.replyToEmail?.message ?? ''
          }`}
          type="sm"
          className="text-red-500 mr-3 mb-1"
        />
      </div>
      <div className="flex mt-6">
        <Button
          onClick={onVerifyEmails}
          className="bg-blue-600 mr-3 hover:bg-blue-700"
        >
          Send Verification
        </Button>
        <Button
          onClick={handleSave}
          className="bg-blue-600 mr-3 hover:bg-blue-700"
          disabled={
            !brandEmailVerification?.validations?.senderIndentityCreated ?? true
          }
        >
          Confirm
        </Button>
        <Button
          onClick={handleDelete}
          className="bg-red-500 text-white hover:bg-red-700"
          disabled={!Boolean(brandEmailInput.id)}
        >
          Delete
        </Button>
        <Button
          onClick={() => setShowModal(false)}
          className="bg-red-500 text-white hover:bg-red-700 ml-2"
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};
