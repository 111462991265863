import { Text } from 'components/Text';
import { Order, OrderItemValues } from 'types/order';
import classNames from 'classnames';

type ClaimSelectionItemProps = {
  orderItem: OrderItemValues;
  onSelect?: (itemId: string) => void;
  order?: Order;
};

export const SelectedOrderItem = ({
  orderItem,
  onSelect,
  order,
}: ClaimSelectionItemProps) => {
  return (
    <div
      className={classNames(
        'border-2 border-blue-600 flex items-top sm:items-center sm:p-3 p-1 rounded-md w-72 mr-5 my-4',
      )}
    >
      <img
        className="mr-4 h-12 w-12 sm:h-12 sm:w-12 rounded object-cover"
        src={orderItem.imageSrc}
        alt="orderItem"
      />
      <div>
        <Text value={orderItem.title} type="sm" />
        <div className="flex mt-2 h-auto items-center">
          <Text
            value={`${orderItem.quantity}`}
            type="xs"
            className="bg-blue-600 rounded text-white py-1 px-2 mr-3"
          />
        </div>
      </div>
    </div>
  );
};
