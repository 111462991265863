import { Order, OrderItemValues } from './order';
import { User } from './user';

export interface Claim {
  createdAt: string;
  date: Date;
  id: string;
  notes: string;
  number: string;
  orderId: string;
  customerNotes: string;
  status: string;
  storeId: string;
  tags: string[];
  updatedAt: string;
  userId: string;
  createdBy: User;
  images: any[];
  videos: any[];
  items: ClaimItemValues[];
  resolution: ResolutionType;
  reason: string;
  order: Order;
  settlements: any;
  total: number;
  discountTotal: number;
  preferredResolution: string | null;
  preferredResolutionNotes: string | null;
  suggestedRefund: any;
}

export interface ClaimItemValues {
  claim: Claim;
  claimId: string;
  createdAt?: string;
  id?: string;
  orderItem: OrderItemValues;
  orderItemId?: string;
  reason?: string;
  resolution?: string;
  settlement: any;
  total?: number;
  discountTotal?: number;
  quantity?: number;
  updatedAt?: string;
}

export enum ResolutionType {
  reorder = 'REORDER',
  refund = 'REFUND',
  deny = 'DENY',
  in_review = 'IN_REVIEW',
  other = 'OTHER',
}

export enum statusType {
  new = 'NEW',
  resolved = 'RESOLVED',
  partially_resolved = 'PARTIALLY_RESOLVED',
  denied = 'DENIED',
  in_review = 'IN_REVIEW',
  archived = 'ARCHIVED',
}

export enum ClaimItemResolvedStatusEnum {
  IN_REVIEW = 'IN_REVIEW',
  REORDER = 'REORDER',
  REFUND = 'REFUND',
  STORE_CREDIT = 'STORE_CREDIT',
  DENY = 'DENY',
  OTHER = 'OTHER',
}
