import React from 'react';
import { Text } from 'components/Text';
import { OrderItem } from 'components/OrderItem';
import { QuestionMarkCircleIcon } from '@heroicons/react/solid';
import { ClaimItemValues } from 'types/claim';
import { Button } from 'components/Button';
import { calculatePriceSum } from 'utils';

type InReviewProps = {
  claimItems: ClaimItemValues[];
  handleRemoveItem?: (order: ClaimItemValues) => void;
  handleSetInReview?: () => void;
};

export const InReview = ({
  claimItems,
  handleRemoveItem,
  handleSetInReview,
}: InReviewProps) => {
  const handleSetReview = () => {
    handleSetInReview && handleSetInReview();
  };

  return (
    <div className="block sm:flex sm:gap-4 mt-4">
      <div className="sm:w-2/3 border rounded p-5">
        <div className="flex justify-between">
          <Text value="In Review" type="bold" className="mb-3" />
          <QuestionMarkCircleIcon className="h-5 w-5" />
        </div>{' '}
        <div>
          {claimItems.map((claimItem) => (
            <OrderItem
              order={claimItem.orderItem}
              key={claimItem.id}
              onRemoveItem={() =>
                handleRemoveItem && handleRemoveItem(claimItem)
              }
              claimItem={claimItem}
              isDisplay
            />
          ))}
        </div>
        <div className="mt-6">
          <Text
            value="Internal Comments"
            type="body"
            className="text-gray-500"
          />
          <textarea
            rows={4}
            className="border border-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 w-full sm:text-sm border-gray-300 rounded-md mr-3 mt-3 p-2"
            placeholder="Comments"
          />
          <Text
            type="sm"
            value={
              'NB - Resolution notes are visible to customers on the customer portal'
            }
            className="text-gray-400"
          />
          <div className="mt-2 flex items-center">
            <input
              name="notification"
              type="checkbox"
              className="focus:ring-indigo-500 h-3 w-3 text-indigo-600 border-gray-300 rounded mr-1"
            />
            <Text
              value="Send a notification to customers"
              className="text-gray-500"
              type="sm"
            />
          </div>
          <Button onClick={handleSetReview} className="bg-blue-600 mt-4">
            Mark as in-review
          </Button>
        </div>
      </div>
      <div className="sm:w-1/3 border rounded p-5 mt-3 sm:mt-0 h-max">
        <Text value="Summary" type="bold" />

        <div className="flex justify-between py-3">
          <Text value="Items" type="sm" className="text-gray-600 font-bold" />
          <Text
            value={`${claimItems?.length} items`}
            className="text-gray-500"
          />
        </div>
        <div className="flex justify-between py-3">
          <Text
            value="Sub Total"
            type="sm"
            className="text-gray-600 font-bold"
          />
          <Text
            value={
              claimItems && claimItems[0].orderItem
                ? `$${calculatePriceSum(
                    claimItems?.map((item) => {
                      return { ...item, ...item.orderItem };
                    }),
                    'total',
                  )}`
                : claimItems?.length
                ? `$${calculatePriceSum(claimItems, 'total')}`
                : ''
            }
            className="text-gray-500"
          />
        </div>
        <div className="flex justify-between py-3">
          <Text
            value="Discount"
            type="sm"
            className="text-gray-600 font-bold"
          />
          <Text value="$0.00" className="text-gray-500 line-through" />
        </div>
      </div>
    </div>
  );
};
