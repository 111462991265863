import { Modal } from 'components/Modal';
import classNames from 'classnames';
import {
  CheckCircleIcon,
  XCircleIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/outline';
import { NotificationTypes } from 'utils';
import { Text } from './Text';

type ConfirmatoinModalProps = {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  message?: string;
  subMessage?: string;
  mainBtnClassname: string;
  title?: string;
  btnText: string;
  handleConfirm: () => void;
  type?: NotificationTypes;
  isLoading?: boolean;
};

export const ConfirmationModal = ({
  showModal,
  setShowModal,
  message,
  subMessage,
  mainBtnClassname,
  btnText,
  handleConfirm,
  type,
  isLoading,
  title,
}: ConfirmatoinModalProps) => {
  return (
    <Modal
      openModal={(value) => setShowModal(value)}
      open={showModal}
      containerClass="sm:max-w-lg w-full sm:w-auto p-5"
    >
      <div className="sm:flex sm:items-start mt-3">
        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10">
          {type === NotificationTypes.error ? (
            <XCircleIcon
              className="h-10 w-10 text-red-400"
              aria-hidden="true"
            />
          ) : type === NotificationTypes.success ? (
            <CheckCircleIcon
              className="h-10 w-10 text-red-400"
              aria-hidden="true"
            />
          ) : (
            <ExclamationCircleIcon
              className="h-10 w-10 text-yellow-400"
              aria-hidden="true"
            />
          )}
        </div>
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <div className="mt-2">
            {title && (
              <Text className="text-sm text-gray-500" type="h4" value={title} />
            )}
            {message && <p className="text-sm text-gray-500">{message}</p>}
            {subMessage && (
              <p className="text-sm text-gray-500">{subMessage}</p>
            )}
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          className={classNames(
            'w-full inline-flex justify-center items-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium focus:outline-none focus:ring-2 sm:ml-3 sm:w-auto sm:text-sm',
            mainBtnClassname,
          )}
          onClick={handleConfirm}
        >
          {isLoading && (
            <div
              style={{
                borderColor: 'rgba(255,255,255,0.2)',
                borderTopColor: '#ddd',
              }}
              className="inline-block border-2 rounded-full animate-spin w-4 h-4 mr-2"
            />
          )}
          {btnText}
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
          onClick={() => setShowModal(false)}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};
