import { useStore } from 'context/store-context';
import { Text } from 'components/Text';
import classNames from 'classnames';
import { ChevronRightIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { Button } from '../Button';
import { useMutation } from '@apollo/client';
import { GENERATE_INSTALL_LINK } from 'gql/mutations';

export const StoreSelection = ({ onNextStep }: { onNextStep: () => void }) => {
  const [storeDomainInputValue, setStoreDomainInputValue] = useState('');
  const {
    storeId,
    storeDomain,
    userStores,
    updateUserStore,
    isLoading,
    storeProperties,
  } = useStore();

  const [error, setError] = useState('');
  const [installLink, setInstallLink] = useState('');

  const [generateInstallLinkMutation] = useMutation(GENERATE_INSTALL_LINK, {
    onError: () => {
      setError('Something went wrong, please try again');
    },
    onCompleted: (data) => {
      setInstallLink(data?.generateInstallLink);
    },
  });

  useEffect(() => {
    if (storeProperties?.uninstalledAt && storeDomain && storeId) {
      generateInstallLinkMutation({
        variables: {
          store: storeDomain,
        },
      });
    }
  }, [
    storeProperties?.uninstalledAt,
    storeDomain,
    storeId,
    generateInstallLinkMutation,
  ]);

  const generateInstallLink = () => {
    generateInstallLinkMutation({
      variables: {
        store: storeDomainInputValue,
      },
    });
  };

  const hasUninstalledApp =
    storeProperties?.uninstalledAt && storeDomain && storeId;

  return (
    <div className="sm:max-w-lg mt-5 sm:w-full text-center">
      {Array.isArray(userStores) && userStores.length > 0 && (
        <Text value="Select A Store" type="h4" />
      )}
      {Array.isArray(userStores) && !hasUninstalledApp && (
        <ul className="my-8">
          {userStores?.map((store) => (
            <li
              key={store.domain}
              onClick={() => {
                updateUserStore && updateUserStore(store);
              }}
              className="col-span-1 flex shadow-sm rounded-md mb-4 cursor-pointer"
            >
              <div
                className={classNames(
                  `${store.storeColor}`,
                  'flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md',
                )}
              >
                {store.initials}
              </div>
              <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                <div className="flex-1 px-4 py-2 text-sm truncate">
                  <Text
                    value={store.name || store.domain}
                    className="text-gray-900 font-medium hover:text-gray-600"
                  />
                  <Text className="text-gray-500" value={store.domain} />
                </div>
                <div className="flex-shrink-0 pr-2">
                  <button
                    type="button"
                    className="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <span className="sr-only">Open options</span>
                    <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
      {!isLoading && (
        <div className="text-gray-500">
          {installLink ? (
            <Text
              className="my-4 text-center"
              value={
                hasUninstalledApp
                  ? 'Seems like you have uninstalled the shipaid app from your store, click below to re-install the Shipaid app'
                  : `Click the link below to install the Shipaid app on Shopify. You'll be redirected once installation is complete`
              }
            />
          ) : (
            <Text className="my-4 text-center">
              {!userStores?.length
                ? "Looks like you don't have a store yet. Enter a store domain link below and we'll generate a link!"
                : 'Add another store'}
            </Text>
          )}

          {installLink ? (
            <a rel="noreferrer" href={installLink}>
              <Button
                onClick={() => {}}
                className="bg-blue-600 hover:bg-blue-700 w-full justify-center"
              >
                Install Shipaid on Shopify
              </Button>
            </a>
          ) : (
            <>
              <input
                name="name"
                className="border border-1 my-6 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md p-2 w-full"
                placeholder="mystore.myshopify.com"
                onChange={(e) => setStoreDomainInputValue(e.target.value)}
                value={storeDomainInputValue}
              />
              {error && (
                <Text
                  className="text-center text-red-500 mb-2"
                  value={error}
                  type="sm"
                />
              )}
              <Button
                onClick={generateInstallLink}
                className="bg-blue-600 hover:bg-blue-700 w-full justify-center"
                disabled={
                  !storeDomainInputValue ||
                  !storeDomainInputValue.includes('myshopify.com')
                }
              >
                Generate Install Link
              </Button>
            </>
          )}
        </div>
      )}
    </div>
  );
};
