import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Protection } from './Protection';
import { Customer } from './Customer';
import { Team } from './Team';
import { Account } from './Account';
import { Notifications } from './Notifications';
import { TrackingPage } from './Tracking/TrackingPage';
import { Install } from './Install';
import { Invites } from './Invites';
import { Integrations } from './Integrations';
import { AutomationRules } from './AutomationRules';
import { OrderSettings } from './OrderEditing/OrderSettings';

export const Settings = () => {
  return (
    <div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8"></div>
      <Routes>
        <Route
          index
          element={<Navigate to="/settings/protection" replace={true} />}
        />
        <Route path="/order-settings" element={<OrderSettings />} />
        <Route path="/protection" element={<Protection />} />
        <Route path="/install" element={<Install />} />
        <Route path="/customer" element={<Customer />} />
        <Route path="/team" element={<Team />} />
        <Route path="/team/invites" element={<Invites />} />
        <Route path="/account" element={<Account />} />
        <Route path="notifications" element={<Notifications />} />
        <Route path="tracking-templates" element={<TrackingPage />} />
        <Route path="/integration" element={<Integrations />} />
        <Route path="/claim-automations" element={<AutomationRules />} />
      </Routes>
    </div>
  );
};
