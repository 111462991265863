import { Modal } from 'components/Modal';
import { RuleItem } from 'types/plans';
import { Text } from './Text';
import { getStoreCurrencyFormatter } from 'utils';
import { useStore } from 'context/store-context';

type AllFixedRulesModalProps = {
  show: boolean;
  rules: RuleItem[];
  setShowModal: (value: boolean) => void;
};
export const AllFixedRulesModal = ({
  show,
  rules,
  setShowModal,
}: AllFixedRulesModalProps) => {
  const { storeProperties } = useStore();

  return (
    <Modal
      openModal={(value) => setShowModal(value)}
      open={show}
      containerClass="sm:max-w-lg sm:w-full text-center"
    >
      <Text value="All Fixed rules" type="h4" />
      <div>
        {rules.map((rule, i) => {
          return (
            <div
              className="flex items-end py-2 my-3 border-gray-100 border-b"
              key={i}
            >
              <Text
                value={`${getStoreCurrencyFormatter(
                  storeProperties?.currency,
                  rule.fee,
                )}`}
                type="h3"
                className="font-bolder mr-2"
              />
              <Text
                value={`/Orders ${getStoreCurrencyFormatter(
                  storeProperties?.currency,
                  rule.rangeLower,
                )} - ${getStoreCurrencyFormatter(
                  storeProperties?.currency,
                  rule.rangeUpper,
                )}`}
                type="sm"
                className="text-gray-500 mb-1"
              />
            </div>
          );
        })}
      </div>
    </Modal>
  );
};
