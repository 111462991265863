import React, { useEffect, useMemo, useState } from 'react';
import { Text } from 'components/Text';
import { Toggle } from 'components/Toggle';
import { Button } from 'components/Button';
import { DataTable } from 'components/DataTable';
import { columns } from './data/notifications-data';
import { apiErrorHandler, SizeEnum } from 'utils';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_USER_STORE_NOTIFICATIONS } from 'gql/queries';
import {
  CREATE_USER_STORE_NOTIFICATION,
  UPDATE_USER_STORE_NOTIFICATION,
  REMOVE_USER_STORE_NOTIFICATIONS,
  INSERT_STORE_USER_NOTIFICATIONS,
} from 'gql/mutations';
import { useUserId } from '@nhost/react';
import { LoadingScreen } from 'components/LoadingScreen';
import { withNotification } from 'components/Notification';
import { NotificationTypes } from 'utils';
import { useStore } from 'context/store-context';
import { useUser } from 'context/user-context';
import { User } from 'types/user';
import { NotificationTemplates } from './Tracking/NotificationTemplates';

export const Notifications = withNotification(({ showNotification }: any) => {
  // TODO: in finished product this comes from a query or localStorage
  const { storeId } = useStore();
  const userId = useUserId();
  const user = useUser();
  const [notifications, setNotifications] = useState({
    claimNew: false,
    claimStatus: false,
    claimReorder: false,
    claimRefund: false,
    // newsletterFeatures: false,
  });
  const [unsubscribed, setUnsubscribed] = useState(false);

  const [updateUserStoreNotifications, { loading: updateLoading }] =
    useMutation(UPDATE_USER_STORE_NOTIFICATION, {
      onError(error) {
        const newError = apiErrorHandler(error);
        showNotification(NotificationTypes.error, newError?.message);
      },
      onCompleted(data) {
        showNotification(NotificationTypes.success, data?.message);
      },
    });

  const [createUserStoreNotifications] = useMutation(
    CREATE_USER_STORE_NOTIFICATION,
    {
      onError(error) {
        const newError = apiErrorHandler(error);
        showNotification(NotificationTypes.error, newError?.message);
      },
      onCompleted(data) {
        showNotification(NotificationTypes.success, data?.message);
      },
    },
  );

  const [removeUserStoreNotifications] = useMutation(
    REMOVE_USER_STORE_NOTIFICATIONS,
    {
      onError(error) {
        const newError = apiErrorHandler(error);
        showNotification(NotificationTypes.error, newError?.message);
      },
      onCompleted(data) {
        showNotification(
          NotificationTypes.success,
          'User notification turned off',
        );
      },
    },
  );

  const [addUserStoreNotifications] = useMutation(
    INSERT_STORE_USER_NOTIFICATIONS,
    {
      onError(error) {
        const newError = apiErrorHandler(error);
        showNotification(NotificationTypes.error, newError?.message);
      },
      onCompleted(data) {
        showNotification(
          NotificationTypes.success,
          'User notification turned on',
        );
      },
    },
  );

  const [getUserStoreNotifications, { loading, data }] = useLazyQuery(
    GET_USER_STORE_NOTIFICATIONS,
    {
      onError(error) {
        const newError = apiErrorHandler(error);
        showNotification(NotificationTypes.error, newError?.message);
      },
    },
  );

  useEffect(() => {
    storeId &&
      getUserStoreNotifications({
        variables: {
          storeId,
        },
      });
  }, [storeId, getUserStoreNotifications]);

  const currentUserNotifications = useMemo(() => {
    const currentUsersNotifications = data?.storeUserNotifications?.find(
      ({ user }: { user: User }) => user.id === userId,
    );

    return currentUsersNotifications;
  }, [data, userId]);

  useEffect(() => {
    // we want to make a distinction between getting empty data
    // and if we need to actually update the default state

    if (currentUserNotifications) {
      // update the state
      const { claimNew, claimRefund, claimReorder, claimStatus } =
        currentUserNotifications;

      setNotifications({
        claimNew: Boolean(claimNew),
        claimRefund: Boolean(claimRefund),
        claimReorder: Boolean(claimReorder),
        claimStatus: Boolean(claimStatus),
      });
    }
  }, [currentUserNotifications]);

  const handleSetNotification = async (value: boolean, key: string) => {
    try {
      if (currentUserNotifications && storeId) {
        updateUserStoreNotifications({
          variables: {
            userId,
            storeId,
            ...notifications,
            [key]: value,
          },
        });
      } else {
        // we need to create
        storeId &&
          (await createUserStoreNotifications({
            variables: {
              storeId,
              userId,
              [key]: value,
            },
            refetchQueries: ['userStoreNotifications'],
          }));
      }
    } catch (error: any) {
      if (error.message) {
        // throw the error with the message
        showNotification(NotificationTypes.error, error?.message);
      } else {
        // throw the error with a generic message. 'Something went wrong, try again later'
        showNotification(
          NotificationTypes.error,
          'Something went wrong, please try toggling the field again.',
        );
      }
    }
  };

  const handleUnsubscribeAll = () => {
    setUnsubscribed(true);
    try {
      if (data?.storeUserNotifications.length) {
        updateUserStoreNotifications({
          variables: {
            userId,
            storeId,
            claimNew: false,
            claimRefund: false,
            claimStatus: false,
            claimReorder: false,
          },
        })
          .then((data) => {
            setUnsubscribed(false);
            showNotification(
              NotificationTypes.success,
              'Unsubscribed from all notifications',
            );
          })
          .catch((err) => {
            setUnsubscribed(false);
            showNotification(
              NotificationTypes.error,
              'Something went wrong, please try Unsubscribing again',
            );
          });
      }
    } catch (error: any) {
      setUnsubscribed(false);
      if (error.message) {
        showNotification(NotificationTypes.error, error?.message);
      }
      showNotification(NotificationTypes.error, 'Oops! Something went wrong');
    }
  };

  const handleRemoveUser = ({ user }: { user: User }) => {
    const storeId = currentUserNotifications?.storeId;

    storeId &&
      removeUserStoreNotifications({
        variables: {
          userId: user.id,
          storeId,
        },
        awaitRefetchQueries: true,
        refetchQueries: ['storeUserNotifications'],
      });
  };

  const handleAddUserNotifications = (id: string) => {
    addUserStoreNotifications({
      variables: {
        object: {
          storeId,
          userId: id,
          claimNew: true,
          claimStatus: true,
          claimReorder: true,
          claimRefund: true,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: ['storeUserNotifications'],
    });
  };

  if (loading) return <LoadingScreen />;

  const people = currentUserNotifications?.store?.users || [];

  return (
    <div>
      <NotificationTemplates />
      <div className="max-w-7xl mx-auto my-12 px-4 sm:px-6 md:px-8">
        <Text value="Merchant Notifications" type="h1" />
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <Text
          value="The choice is yours. Decide what email you would like to receve from us"
          type="body"
          className="mt-1 text-gray-500"
        />
        <div className="border-b border-gray-200 pt-3 px-4 pb-8 sm:px-6">
          <div className="flex w-full mt-6">
            <Toggle
              isEnabled={notifications.claimNew}
              onChange={(value) => handleSetNotification(value, 'claimNew')}
              rightLabel="New Issue Filed"
              isSmall
              disabled={!storeId}
            />
          </div>
          <div className="flex w-full mt-6">
            <Toggle
              isEnabled={notifications.claimStatus}
              onChange={(value) => handleSetNotification(value, 'claimStatus')}
              rightLabel="Issue Status Changed"
              isSmall
              disabled={!storeId}
            />
          </div>
          <div className="flex w-full mt-6">
            <Toggle
              isEnabled={notifications.claimReorder}
              onChange={(value) => handleSetNotification(value, 'claimReorder')}
              isSmall
              rightLabel="Reorder Issued"
              disabled={!storeId}
            />
          </div>
          <div className="flex w-full mt-6">
            <Toggle
              isEnabled={notifications.claimRefund}
              onChange={(value) => handleSetNotification(value, 'claimRefund')}
              isSmall
              rightLabel="Refund Issued"
              disabled={!storeId}
            />
          </div>
          {data?.storeUserNotifications.length &&
            Object.values(notifications).includes(true) && (
              <Button
                onClick={handleUnsubscribeAll}
                size={SizeEnum.md}
                className="bg-red-500 text-white hover:bg-red-700 h-max px-6 mt-8"
                isLoading={unsubscribed && updateLoading}
              >
                Unsubscribe from all
              </Button>
            )}
        </div>
        <div className="mt-8">
          <Text value="Additional Merchant Notification Recipients" type="h3" />
          <Text
            value="Choose which users should receive active notifications"
            type="body"
            className="mt-1 text-gray-500"
          />
          <div className="mt-6">
            <DataTable
              data={people.map((person: any) => ({
                ...person,
                authuser: user.user,
                authUserRole: user.role,
                removeUser: () => handleRemoveUser(person),
                addUser: () =>
                  handleAddUserNotifications(person?.user?.id ?? ''),
              }))}
              columns={columns}
            />
          </div>
        </div>
      </div>
    </div>
  );
});
