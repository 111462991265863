import React from 'react';
import { CursorClickIcon } from '@heroicons/react/outline';

export const people = [
  {
    name: 'Jane Cooper',
    title: 'Regional Paradigm Technician',
    department: 'Support',
    role: 'Admin',
    email: 'jane.cooper@example.com',
    image:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
  },
  {
    name: 'John Cooper',
    title: 'Regional Paradigm Technician',
    department: 'Optimization',
    role: 'supervisor',
    email: 'john.cooper@example.com',
    image:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
  },
  // More people...
];
export const columns = [
  {
    name: 'Name',
    id: 1,
    key: 'name',
  },
  {
    name: 'Title',
    id: 2,
    key: 'title',
  },
  {
    name: 'Department',
    id: 3,
    key: 'department',
  },
  {
    name: 'Role',
    id: 4,
    key: 'role',
  },
  {
    name: 'Email',
    id: 5,
    key: 'email',
  },
  {
    name: 'image',
    id: 6,
    render: (item: any) => (
      <img src={item.image} className="h-5 w-5 rounded-full" alt="user" />
    ),
  },
];

// stats
export const stats = [
  {
    name: 'Total Orders',
    currentStat: '70',
    previousStat: '60',
    change: '16',
    changeType: 'increase',
    icon: CursorClickIcon,
  },
  {
    name: 'Guaranteed Orders',
    currentStat: '60',
    previousStat: '70',
    change: '16',
    changeType: 'decrease',
    link: { url: '#', text: 'View' },
  },
  {
    name: 'Reimbursed/Reshipped',
    currentStat: '100',
    previousStat: '50',
    change: '50',
    changeType: 'increase',
  },
];
