import { Modal } from 'components/Modal';
import React from 'react';

interface ViewVideoProps {
  videoUrl: string;
  onCloseView: () => void;
}

export const ViewVideo = ({ videoUrl, onCloseView }: ViewVideoProps) => {
  const openModal = (isOpenModal: boolean) => {
    if (!isOpenModal) {
      onCloseView();
    }
  };
  return (
    <Modal
      containerClass="bg-transparent shadow-none max-w-2xl"
      open
      openModal={openModal}
    >
      <div className="mt-2">
        <video className="modal-video" src={videoUrl} autoPlay controls />
      </div>
    </Modal>
  );
};
